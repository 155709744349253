import { Category, Data, Item, ItemGroup, Member, Offer, Order, OrderItem, Period, Spot, Store, OrderManager } from 'aigens-ng-core';
import { ConfigService } from '../services/config.service';
import { Http } from '@angular/http';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { SystemConfigService } from '../framework/services/systemConfig.service';
import { UUID } from 'angular2-uuid';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AiService extends BaseService {

    aq: AQuery;

    order: Order;
    email: string;
    member: any = {};
    firstName: string;
    lastName: string;
    address: any = {};
    phone: string;
    count = 0;
    total = 0;
    spot: string;
    session: string;
    redirectOrderId: any = null;
    upSellingSession: any;
    pickUpTime: string;
    pickUpLabel: string;
    maxPickupTime: string;
    uuid: string;
    currency = 'HKD';
    sign = '$';
    mode = 'byod';
    selectedDineinTakeoutPrompt = 'firstView';
    urlMode: string;
    selfPickUp = false;
    delivery = false;
    takeout = false;
    isBK = false;
    isLP = false;
    type: string;
    store: Store;
    storeId: number;
    deviceId: string;
    currentPeriod: Period;
    hiddenNav = false;
    hideTotal = false;
    comeInHasSession = false;
    hasServiceChargeOrTax = false;
    isCourt = false;
    lpWords: string;
    preOrder = false;
    chargeDesc: string;
    selectedReward: Offer;
    passcode;
    login = false;
    isStaff = false;
    isHomeButton = false;
    surveyUrl: string;
    surveyExtBrowser: boolean;
    courtId: number;
    orderExpire: number;
    settings = {};

    // cache of main item quantities
    quantities = {};
    promoCodeGift =  [];

    constructor(private http: HttpClient, public configs: ConfigService , public orderManager: OrderManager) {
        super();

        this.aq = new AQuery(http, configs);
    }

    /**
     * @deprecated Use getOrderSession instead.
     */
    getRecommend(member: Member): Observable<any> {

        let url = '/api/v1/ai/recommend.json';

        let storeId = this.orderManager.store.id;
        let mode = this.orderManager.mode;
        // let url = this.getOrderSesisonUrl(storeId, mode, 'recommend');
        // todo change new api return val to old api val form

        let aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        if (member)
        {
            aq.body = member;
            return aq.auth(true).getJson().map(jo => jo['data']);
        }else
        {
            aq.body = member;
            return aq.auth(false).getJson().map(jo => jo['data']);
        }

    }



}
