<!-- <ion-content class="content animated fadeInUp" (click)="dismissClicked()"> -->
<div (click)="dismissClicked()" [ngStyle]="{'height': '100vh'}">
    <div class="combo-wrapper fix-bottom widthMax" (click)="$event.stopPropagation();">
        <div class="top-counter" (click)="$event.stopPropagation()" id="content-top">
            <!-- <div class="list-header">
                <span>{{title}}</span>
                <button ion-button icon-only clear (click)="dismissClicked()">
                    <ion-icon name="md-close" color="dark"></ion-icon>
                </button>
            </div> -->
            <div class="list-header-wrapper">
                <div class="list-header">
                    <!-- <span>{{title}}</span> -->
                    <ion-button fill="clear" (click)="dismissClicked()" size="small">
                        <ion-icon slot="icon-only" name="md-close" color="dark"></ion-icon>
                    </ion-button>
                </div>
            </div>




            <div class="item-content-wrapper animated fadeInRights">
                <ion-list class="couponBS-wrapper">
                    <div *ngFor="let filter of filters;let i = index" [id]="'fGroup' + i"
                        [ngClass]="{'current': i == currentIndex}">
                        <div class="modi-item-title">
                            <p class="ig-title">{{filter.question}}</p>
                        </div>
                        <div class="div-padding">
                            <div class="ig-modifier-request-box"
                                [ngClass]="{'modifier-selected AQA-mod-completed': isFilterSelected(filter), 'AQA-mod-not-completed': !isFilterSelected(filter) }">
                                <!-- <ion-icon name="checkmark" color="medium" class="checkmark-icn"></ion-icon> -->
                                <p class="ig-modifier-request">
                                    {{'pages.item-select.select-items' | translate:{count: 1} }}</p>
                            </div>
                        </div>
                        <div class="modifier-item-wrapper">
                            <div class="modifier-item-box dropShadow" *ngFor="let tag of filter.tags" tappable
                                (click)="selectTag(tag,filter)" [hidden]="!isAvailableTag(tag)" text-wrap
                                [ngClass]='{selected: isSelectedTag(tag)}'>
                                <!--please help selected class to handle the selection-->
                                <img src="{{tags[tag].getImage('default', 500)}}">
                                <p>{{tags[tag].name}}</p>

                                <!-- <ion-icon name="{{isActiveTag(tag)? 'checkmark-circle' : 'radio-button-off'}}"
                                        color="success"></ion-icon> -->


                            </div>
                        </div>

                    </div>

                </ion-list>
            </div>

            <div class="btn-box animated fadeInUps">
                <div class="confirm-btn green" (click)="confirmClicked()"
                    [ngClass]="{'completed flashit': showConfirm()}">
                    {{("pages.variation.confirm" | translate) + (showConfirm() ? ' ' + ((filteredItem?.price || 0) | priceDisplay) : '')}}
                </div>
            </div>

        </div>
    </div>
</div>
<!-- </ion-content> -->