var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { SessionService } from '../services/session.service';
import { UUID } from 'angular2-uuid';
import { PaymentService } from '../services/payment.service';
import { MultOrderManager } from '../managers/mult-order-manager';
import { MenuService } from '../services/menu.service';
import { OrderService } from '../services/order.service';
import { BrowserService } from '../services/browser.service';
import { BasePage } from './base-page';
import { Injector, OnInit } from '@angular/core';
import { LiveDataService } from '../services/live-data.service';
import { MonitoringService } from '../services/monitoring.service';
import { SdkConfigService } from '../services/sdk-config-service';
import { MemberService } from '../services/member.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe } from '@angular/common';
import { Data, InventoryManager, MenuManager, OrderManager, Period, PriceManager, RecommendationManager, Setting, Store, OpeningHours } from 'aigens-ng-core';
import { CartService } from '../services/cart.service';
import { getClosedTime, getPickupDelayTime, getTandCUrl, getTodayMillisecondsFromTimeString, getWeekdayStartAndEnd, hasHoliday, hasNumber, isAvailablePeriod, isEmailValid, isPeriodClosed, isUnitNumberFormat } from '../../shared/utilities/checkFormatTool';
import { SystemConfigService } from '../services/systemConfig.service';
import { MemberApiService } from '../services/member.api.service';
import { ThemeService } from '../services/theme.service';
import { RouteTable } from '../route-table';
import { AiService } from '../services/ai.service';
import { myLeaveAnimation, popDrownAnimation, popUpAnimation } from '../../shared/animations/leaveAnimation';
import { AddressManagerService } from '../services/address-manager.service';
import { LayoutService } from '../services/layout.service';
import { OrderSessionService } from '../services/order-session.service';
import { MiniAlipayHKService } from '../services/mini-alipayhk.service';
import { FirestoreService } from "../services/firestore.service";
var ROOT_URL = '/home';
var MobileBasePage = /** @class */ (function (_super) {
    __extends(MobileBasePage, _super);
    function MobileBasePage(injector) {
        var _this = _super.call(this, injector) || this;
        _this.inApp = false;
        _this.inAppNav = false;
        _this.phoneLength = { '852': 8, '853': 8, '65': 8, '60': 10, '886': 10, '86': 11 };
        _this.hasBg = false;
        _this.t = injector.get(TranslateService);
        _this.browserService = injector.get(BrowserService);
        _this.orderService = injector.get(OrderService);
        _this.paymentService = injector.get(PaymentService);
        _this.menuService = injector.get(MenuService);
        _this.orderSessionService = injector.get(OrderSessionService);
        _this.memberService = injector.get(MemberService);
        _this.aiService = injector.get(AiService);
        _this.sessionService = injector.get(SessionService);
        _this.monitoringService = injector.get(MonitoringService);
        _this.gaService = injector.get(GoogleAnalyticsService);
        _this.themeService = injector.get(ThemeService);
        _this.decimalPipe = injector.get(DecimalPipe);
        _this.recommendationManager = injector.get(RecommendationManager);
        _this.inventoryManager = injector.get(InventoryManager);
        _this.multOrderManager = injector.get(MultOrderManager);
        _this.liveDataService = injector.get(LiveDataService);
        _this.sdkConfigService = injector.get(SdkConfigService);
        _this.miniAlipayHKService = injector.get(MiniAlipayHKService);
        _this.orderManager = injector.get(OrderManager);
        _this.priceManager = injector.get(PriceManager);
        _this.firestoreService = injector.get(FirestoreService);
        _this.menuManager = injector.get(MenuManager);
        _this.cartService = injector.get(CartService);
        _this.memberApiService = injector.get(MemberApiService);
        _this.inApp = _this.browserService.isInApp();
        _this.systemConfigService = injector.get(SystemConfigService);
        _this.addressManagerService = injector.get(AddressManagerService);
        _this.layoutService = injector.get(LayoutService);
        _this.inAppNav = _this.browserService.isWeChat();
        if (Object.keys(_this.orderService.settings).length > 0) {
            _this.settings = _this.orderService.getCurrentStoreSetting();
        }
        else {
            _this.settings = new Setting();
        }
        return _this;
    }
    MobileBasePage.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        // this.googleAnalyticPageViewTrack();
        // this.menuController.swipeEnable(false, 'menu1');
    };
    MobileBasePage.prototype.isSoldout = function (item, cat) {
        if (cat === void 0) { cat = null; }
        var now = new Date().getDate();
        if (this.orderManager.store) {
            now = this.convertNowDateToStoreTimeZone(this.orderManager.store.timezone).getTime();
        }
        return this.inventoryManager.isSoldout(item) || !this.isAvailableItemWithValidModifierSettings(this.orderManager.store, cat, item.mgroups, this.orderManager.isTakeout(), now, true);
    };
    // menuManger.isAvailableGroup(store, cat, item.mgroups[0], orderManager.order.takeout, now, true)
    MobileBasePage.prototype.isAvailableItemWithValidModifierSettings = function (store, cat, groups, takeout, now, modifier) {
        var result = true;
        // mgroups
        if (groups && groups.length > 0) {
            for (var _i = 0, groups_1 = groups; _i < groups_1.length; _i++) {
                var group = groups_1[_i];
                if (group.min > 0) {
                    if (!group.items || group.items.length < 1) {
                        result = false;
                    }
                    if (!result) {
                        break;
                    }
                }
                result = this.menuManager.isAvailableGroup(store, cat, group, takeout, now, modifier);
                if (!result) {
                    break;
                }
            }
        }
        return result;
    };
    MobileBasePage.prototype.isPublish = function (item) {
        return this.inventoryManager.isPublish(item);
    };
    MobileBasePage.prototype.monitoringStoreHandling = function (store, handler) {
        var _this = this;
        this.monitoringService.enable = store.brand.features.indexOf('monitoring') !== -1;
        if (this.monitoringService.enable) {
            this.loading(true);
            this.monitoringService.listenStoreKioskStatus(store, function () {
                _this.loading(false);
                _this.showAlert('', _this.t.instant('global.kiosk-offline-message'), [{
                        text: _this.t.instant('buttons.ok'),
                        handler: function () {
                        }
                    }]);
            }, function () {
                _this.loading(false);
                handler();
            });
        }
        else {
            handler();
        }
    };
    MobileBasePage.prototype.readArray = function (object, key) {
        var tmpResult = key.split('[');
        var tmpKey = tmpResult[0];
        var index = String(tmpResult[1].split(']')[0]);
        if (!object[tmpKey] || object[tmpKey].length <= 0)
            return null;
        return object[tmpKey][index];
    };
    MobileBasePage.prototype.readObject = function (object, path) {
        if (!(object instanceof Data))
            return null;
        var keys = path.split('.').slice(1);
        var result = object;
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            var tmpSet = new Set(key);
            if (tmpSet.has('[') && tmpSet.has(']')) {
                result = this.readArray(result, key);
            }
            else {
                result = result[key];
            }
            if (!result)
                return null;
        }
        return result;
    };
    MobileBasePage.prototype.checkStoreMaxItem = function (storeId) {
        var orderManager = this.multOrderManager.enableMutlOrder ? this.multOrderManager.getOrderManager(storeId) : this.orderManager;
        var maxItems = orderManager.store.menu.maxItems;
        var infinity = maxItems === 0;
        return (maxItems <= orderManager.count && !infinity);
    };
    MobileBasePage.prototype.showStoreMaxItemAlert = function () {
        return __awaiter(this, void 0, void 0, function () {
            var subtitle, confirm, alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        subtitle = this.t.instant('global.max-item-message');
                        confirm = this.t.instant('buttons.ok');
                        return [4 /*yield*/, this.alertController.create({
                                subHeader: subtitle,
                                buttons: [confirm]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    MobileBasePage.prototype.checkItemMax = function (item) {
        if (!item)
            return true;
        var currentQua = this.orderManager.getQuantity(item);
        console.log("currentQua", currentQua);
        if (item && item.max === 0)
            return false;
        return !(currentQua < item.max);
    };
    MobileBasePage.prototype.pushAndRemovePage = function (pushPage, param, remainPage, callBack) {
        // TODO: replace current history with remain page
        window.history.replaceState({}, 'Order.Place', RouteTable[remainPage] ? RouteTable[remainPage] : this.navigationService.getRootPageUrl());
        this.pushByName(pushPage, param).then(function () {
            // // find index of home page, and remove from home page
            // let index: number = this.navController.getViews().findIndex(
            //     view => (view.instance instanceof remainPage)
            // );
            // let current: number = this.navController.getActive().index;
            // console.log("views:" + this.navController.getViews()[0]);
            // console.log("index:" + index);
            // console.log("current:" + current);
            // this.navController.remove(index + 1, (current - 1) - index).then(() => {
            if (callBack) {
                callBack();
            }
            // })
        });
    };
    MobileBasePage.prototype.getEarliestClosedTime = function (stores) {
        var closeTime;
        stores.forEach(function (store) {
            // checking opening[weekdays] may  = null
            var storeCloseTime = getClosedTime(store);
            var hours = storeCloseTime.split(':')[0];
            var minutes = storeCloseTime.split(':')[1];
            if (!closeTime) {
                closeTime = storeCloseTime;
            }
            else if (closeTime.split(':')[0] > hours) {
                closeTime = storeCloseTime;
            }
            else if (closeTime.split(':')[0] === hours) {
                if (closeTime.split(':')[1] >= minutes) {
                    closeTime = storeCloseTime;
                }
            }
        });
        return closeTime;
    };
    MobileBasePage.prototype.getEarliestCategoryClosedTime = function (orderItems) {
        var _this = this;
        var closeTime;
        orderItems.forEach(function (orderItem) {
            var catCloseTime = _this.getCategoryClosedTime(orderItem);
            if (catCloseTime && catCloseTime.split(':').length >= 2) {
                var hours = catCloseTime.split(':')[0];
                var minutes = catCloseTime.split(':')[1];
                if (!closeTime) {
                    closeTime = catCloseTime;
                }
                else if (closeTime.split(':')[0] > hours) {
                    closeTime = catCloseTime;
                }
                else if (closeTime.split(':')[0] === hours) {
                    if (closeTime.split(':')[1] >= minutes) {
                        closeTime = catCloseTime;
                    }
                }
            }
        });
        return closeTime;
    };
    MobileBasePage.prototype.getCategoryClosedTime = function (orderItem) {
        var closeTime;
        var weekdaysMap = {};
        var openings = orderItem && orderItem.category && orderItem.category.openings;
        if (!openings || !openings.weekdays) {
            return null;
        }
        openings.weekdays.forEach(function (weekday) {
            weekdaysMap[weekday.weekday] = weekday;
        });
        closeTime = weekdaysMap[new Date().getDay()].endTime;
        return closeTime;
    };
    MobileBasePage.prototype.getEarliestPeriodClosedTime = function (stores) {
        var _this = this;
        var closeTime;
        stores.forEach(function (store) {
            var storeCloseTime = _this.getPeriodClosedTime(store);
            var hours = storeCloseTime.split(':')[0];
            var minutes = storeCloseTime.split(':')[1];
            if (!closeTime) {
                closeTime = storeCloseTime;
            }
            else if (closeTime.split(':')[0] > hours) {
                closeTime = storeCloseTime;
            }
            else if (closeTime.split(':')[0] === hours) {
                if (closeTime.split(':')[1] >= minutes) {
                    closeTime = storeCloseTime;
                }
            }
        });
        return closeTime;
    };
    MobileBasePage.prototype.getPeriodClosedTime = function (store) {
        var storeCurrentPeriodClosedTime;
        var _a = this.stoppedPreOrder(store), stopped = _a[0], period = _a[1];
        if (!period) {
            storeCurrentPeriodClosedTime = getClosedTime(store);
        }
        else {
            var holidaySlot = hasHoliday(store.brand, period.openings);
            if (holidaySlot) {
                storeCurrentPeriodClosedTime = holidaySlot.endTime;
            }
            else if (period.openings && period.openings.weekdays) {
                var _b = getWeekdayStartAndEnd(period), dateStart = _b[0], startString = _b[1], dateEnd = _b[2], endString = _b[3];
                storeCurrentPeriodClosedTime = endString;
            }
            else {
                storeCurrentPeriodClosedTime = period.endTime;
            }
        }
        return storeCurrentPeriodClosedTime;
    };
    MobileBasePage.prototype.getMaxPickupDelaytime = function (stores) {
        var maxTime = 0;
        stores.forEach(function (store) {
            if (getPickupDelayTime(store) > maxTime) {
                maxTime = getPickupDelayTime(store);
            }
        });
        return maxTime;
    };
    // modal on modal
    /**
     *
     * @param component : Component  // control the modal-component when is poping modal . now is two conditions : MultiStepComboPage or ComboPage;
     * @param page : string //  directive route-page when is not pop up modal.
     * @param params //
     * @param extras
     * @param cssClass
     */
    MobileBasePage.prototype.navToAssignLayoutItems = function (component, page, params, extras, cssClass) {
        if (extras === void 0) { extras = { relativeTo: this.route }; }
        return __awaiter(this, void 0, void 0, function () {
            var store, category, groups, item, handler, edit, componentProps, popup, popup;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        store = params.store, category = params.category, groups = params.groups, item = params.item, handler = params.handler, edit = params.edit;
                        console.log('navToAssignLayoutItems:', page, params);
                        if (!(page === 'MultiStepComboPage')) return [3 /*break*/, 2];
                        componentProps = {
                            multi_store: store,
                            store: store,
                            multi_category: category,
                            multi_groups: groups,
                            multi_item: item,
                            multi_handler: handler,
                            multi_edit: edit
                            //  multi_upSelling: upSelling
                        };
                        return [4 /*yield*/, this.modalController.create({ component: component, componentProps: componentProps, backdropDismiss: true, })];
                    case 1:
                        popup = _a.sent();
                        popup.present();
                        return [2 /*return*/, popup];
                    case 2:
                        if (!(page === 'ComboPage')) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.modalController.create({
                                component: component,
                                componentProps: params,
                                cssClass: cssClass,
                                backdropDismiss: true,
                                enterAnimation: popUpAnimation,
                                leaveAnimation: popDrownAnimation
                            })];
                    case 3:
                        popup = _a.sent();
                        popup.present();
                        return [2 /*return*/, popup];
                    case 4:
                        this.pushByName(page, __assign({}, params), extras);
                        return [2 /*return*/];
                }
            });
        });
    };
    MobileBasePage.prototype.isValidPickupTime = function () {
        var currentTime = new Date().getTime();
        var timelabel = this.orderService.pickUpLabel ? this.orderService.pickUpLabel : this.t.instant('global.eat-now');
        var pickedTimeNum = getTodayMillisecondsFromTimeString(timelabel);
        if (pickedTimeNum > currentTime || timelabel === this.t.instant('global.eat-now')) {
            return true;
        }
        else {
            this.showAlert('', this.t.instant('global.pick-up-time-invalid'));
            return false;
        }
    };
    MobileBasePage.prototype.genSetSession = function (key, callBack) {
        var _this = this;
        if (callBack === void 0) { callBack = null; }
        console.log('generating session id');
        this.configService.getPreference(key + '.session').then(function (session) {
            if (!session) {
                var uuid = UUID.UUID().toString();
                console.log('generated session', uuid);
                session = uuid;
                _this.configService.putPreference(key + '.session', uuid);
            }
            else {
                console.log('existing session', session);
            }
            _this.orderService.session = session;
            if (callBack) {
                callBack();
            }
        });
    };
    MobileBasePage.prototype.clickReward = function (offer, store, page) {
        if (offer.reward.discount && offer.reward.discount.benefits && offer.reward.discount.benefits[0]) {
            var benefit = offer.reward.discount.benefits[0];
            if (benefit) {
                switch (benefit.type) {
                    case 'unlock': {
                        this.offerHandling(offer);
                        // let category = store.menu['cmap'][benefit.code];
                        // let layout = category.layout && category.layout !== 'none' ? category.layout : store.menu.settings.itemLayout;
                        // let cb = (oi) => {
                        //     let tmpoi = Data.toData(OrderItem, JSON.parse(JSON.stringify(oi)));
                        //     offer.orderitems.push(tmpoi);
                        //     this.offerHandling(offer);
                        // };
                        // this.pushByName(page, {store: store, category: category, layout: layout, handler: cb});
                        break;
                    }
                    case 'gift': {
                        this.offerHandling(offer);
                        break;
                    }
                    case 'percent': {
                        this.offerHandling(offer);
                        break;
                    }
                    case 'amount': {
                        this.offerHandling(offer);
                        break;
                    }
                    default: {
                        // this.offerHandling(offer);
                        break;
                    }
                }
            }
            else {
                this.offerHandling(offer);
            }
        }
        else {
            this.offerHandling(offer);
        }
    };
    MobileBasePage.prototype.offerHandling = function (offer) {
        this.orderService.selectedReward = offer;
        this.orderManager.order.offers.push(this.orderService.selectedReward);
        this.orderManager.calculate();
        // this.showToast(this.t.instant('global.add-reward-msg'), 2000, 'bottom', 'add-item-toast');
        // this.showToast(this.t.instant('global.add-reward-msg'));
    };
    MobileBasePage.prototype.checkingStorePeriodClosed = function () {
        var _this = this;
        var _a = this.multOrderManager.getStoresCurrentOrderingPeriod(), periods = _a[0], storeIds = _a[1];
        var closeStoreIds = [];
        var now = new Date();
        periods.forEach(function (period, index) {
            var store = _this.multOrderManager.getStore(storeIds[index]);
            if (!period) {
                var closeTime = getTodayMillisecondsFromTimeString(getClosedTime(store));
                var currentTime = new Date().getTime();
                if (currentTime >= closeTime) {
                    closeStoreIds.push(storeIds[index]);
                }
            }
            else if (!isAvailablePeriod(store, period, now)) {
                closeStoreIds.push(storeIds[index]);
            }
        });
        return closeStoreIds;
    };
    MobileBasePage.prototype.saveWeekDay = function (period, isMulti) {
        if (isMulti === void 0) { isMulti = false; }
        if (period.openings) {
            var todayWeeday = new Date().getDay();
            var tmpWeekday = todayWeeday - 1 < 0 ? 6 : todayWeeday - 1;
            var tmpStartHour = new Date().setHours(Number(period.openings.weekdays[tmpWeekday]['startTime'].split(':')[0]), Number(period.openings.weekdays[tmpWeekday]['startTime'].split(':')[1]), 0, 0);
            var tmpEndHour = new Date().setHours(Number(period.openings.weekdays[tmpWeekday]['endTime'].split(':')[0]), Number(period.openings.weekdays[tmpWeekday]['endTime'].split(':')[1]), 0, 0);
            if (tmpEndHour < tmpStartHour) {
                if (new Date().getTime() < tmpEndHour) {
                    period['savedWeekday'] = tmpWeekday;
                }
                else {
                    period['savedWeekday'] = todayWeeday;
                }
            }
            else {
                period['savedWeekday'] = todayWeeday;
            }
        }
        if (!isMulti) {
            this.orderService.currentPeriod = period;
        }
    };
    MobileBasePage.prototype.stoppedPreOrder = function (store) {
        var _this = this;
        var periods = store.menu ? store.menu.periods : [];
        var currentPeriod;
        var now = new Date();
        var currentTime = now.getTime();
        var stopped = null;
        if (!periods) {
            periods = [];
        }
        if (Store.isWholeDayOpen(store)) {
            var period = new Period();
            period.endTime = store.menu.periods[store.menu.periods.length - 1].endTime;
            period.end = store.menu.periods[store.menu.periods.length - 1].end;
            period.startTime = store.menu.periods[0].startTime;
            period.start = store.menu.periods[0].start;
            period['prestop'] = store.menu.periods[store.menu.periods.length - 1]['prestop'] ? store.menu.periods[store.menu.periods.length - 1]['prestop'] : 0;
            stopped = isPeriodClosed(store, period, currentTime);
            return [stopped, period];
        }
        periods.forEach(function (period, index) {
            if (isAvailablePeriod(store, period, now)) {
                if (stopped === null) {
                    _this.saveWeekDay(period, true);
                    stopped = isPeriodClosed(store, period, currentTime);
                    currentPeriod = period;
                }
            }
        });
        if (periods.length <= 0) {
            var closeTime = getTodayMillisecondsFromTimeString(getClosedTime(store));
            var current = new Date().getMilliseconds();
            stopped = (current >= closeTime);
            return [stopped, null];
        }
        if (stopped === null) {
            return [true, null];
        }
        return [stopped, currentPeriod];
    };
    MobileBasePage.prototype.checkingStoresStopOrdering = function (stores) {
        var _this = this;
        var closeStoreId = [];
        stores.forEach(function (store) {
            var _a = _this.stoppedPreOrder(store), stopped = _a[0], period = _a[1];
            if (stopped) {
                closeStoreId.push(store.id);
            }
        });
        return closeStoreId;
    };
    MobileBasePage.prototype.haveStoresStopOrdering = function (needToPop, popTarget) {
        var _this = this;
        if (needToPop === void 0) { needToPop = false; }
        var closeStoreId = this.checkingStorePeriodClosed();
        closeStoreId = closeStoreId.concat(this.checkingStoresStopOrdering(this.multOrderManager.getStores()).filter(function (storeId) {
            return closeStoreId.indexOf(storeId) === -1;
        }));
        var storeName = [];
        closeStoreId.forEach(function (id) {
            storeName.push(_this.multOrderManager.getStore(id).name);
        });
        if (closeStoreId.length > 0) {
            var message = storeName.join(',') + this.t.instant('global.store-closed-message');
            this.showAlert('', message, [{
                    text: this.t.instant('ok'),
                    handler: function (data) {
                        closeStoreId.forEach(function (id) {
                            _this.multOrderManager.deleteOrderManager(id);
                            _this.multOrderManager.deleteStoreCurrentOrderingPeriod(id);
                        });
                        if (needToPop && popTarget) {
                            // find index of home page, and remove from home page
                            // TODO:check logic
                            _this.navController.back();
                            // let index: number = this.navController.getViews().findIndex(
                            //     view => (view.instance instanceof popTarget)
                            // );
                            // let current: number = this.navController.getActive().index;
                            // this.navController.remove(index + 1, (current - 1) - index).then(() => {
                            //     this.navController.pop();
                            // });
                        }
                    }
                }]);
            return true;
        }
        return false;
    };
    MobileBasePage.prototype.googleAnalyticPageViewTrack = function (oninit) {
        if (oninit === void 0) { oninit = false; }
        if (this.pageName() !== '') {
            this.gaService.pageViewTrack(this.pageName(), oninit);
        }
    };
    MobileBasePage.prototype.googleAnalyticEventTrack = function (label, action) {
        // this.gaService.eventTrack(this.pageName(),event,label);
        // ga('send', 'event', page, event, label);
        if (action) {
            this.buttonPerformanceTracker(action, label);
        }
        else {
            this.pagePerformanceTracker(label);
        }
    };
    MobileBasePage.prototype.pagePerformanceTracker = function (label) {
        if (!this.gaService.targetId) {
            var found = false;
            if (this.orderService.isBK) {
                this.gaService.targetId = '800001';
            }
            else {
                for (var _i = 0, _a = window.location.pathname.split('/'); _i < _a.length; _i++) { // v2 ulrPamrams is not in hash
                    var value = _a[_i];
                    if (found) {
                        this.gaService.targetId = value;
                        break;
                    }
                    if (value === 'store' || value === 'brand' || value === 'storeId') {
                        found = true;
                    }
                }
            }
        }
        console.log('targetId:', this.gaService.targetId);
        if (this.gaService.targetId) {
            this.gaService.eventTrack(this.gaService.targetId, this.pageName(), label);
        }
    };
    MobileBasePage.prototype.buttonPerformanceTracker = function (action, label) {
        this.gaService.eventTrack('button performance', action, label);
    };
    MobileBasePage.prototype.checkingDeliveryInformation = function (showAlert) {
        if (showAlert === void 0) { showAlert = true; }
        var message = 'Please enter ';
        if (this.orderService.isBK) {
            if (!this.orderService.address.block || this.orderService.address.block === '') {
                message = message + 'Block / House';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }
            if (!this.orderService.address.street || this.orderService.address.street === '') {
                message = message + 'Street';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }
            if (!this.orderService.address.number || this.orderService.address.number === '' || isUnitNumberFormat(this.orderService.address.number)) {
                message = message + 'valid Unit Number';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }
            if (!this.orderService.firstName || this.orderService.firstName === '' || hasNumber(this.orderService.firstName)) {
                message = message + 'First Name';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }
            if (!this.orderService.lastName || this.orderService.lastName === '' || hasNumber(this.orderService.lastName)) {
                message = message + 'Last Name';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }
            if (!isEmailValid(this.orderService.email)) {
                message = message + 'vaild Email';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }
            if (!this.orderService.phone || this.orderService.phone === '') {
                message = message + 'Phone';
                if (showAlert) {
                    this.showAlert('', message);
                }
                return false;
            }
        }
        return true;
    };
    MobileBasePage.prototype.showToast = function (message, duration, position, cssClass) {
        if (duration === void 0) { duration = 2000; }
        if (position === void 0) { position = 'bottom'; }
        if (cssClass === void 0) { cssClass = 'toast-box'; }
        return __awaiter(this, void 0, void 0, function () {
            var buttons, toast;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        buttons = [];
                        if (message === this.t.instant('global.item-added')) {
                            buttons = [
                                {
                                    side: 'start',
                                    icon: 'item-added-tick'
                                }
                            ];
                        }
                        else if (message === this.t.instant('global.add-reward-msg')) {
                            buttons = [
                                {
                                    side: 'start',
                                    icon: 'reward-gift'
                                }
                            ];
                        }
                        return [4 /*yield*/, this.toastController.create({
                                message: message,
                                duration: duration,
                                position: position,
                                cssClass: cssClass,
                                buttons: buttons,
                                leaveAnimation: myLeaveAnimation
                            })];
                    case 1:
                        toast = _a.sent();
                        toast.onDidDismiss().then(function () {
                            console.log('Dismissed toast');
                        });
                        toast.present();
                        return [2 /*return*/, toast];
                }
            });
        });
    };
    MobileBasePage.prototype.openTermsModal = function (brand, handler, page, needToAgreeMarketing, isFb, handleFail, orderPlaceMarketing, useByodTnc, hideFooter, dismissBtnHandler) {
        if (needToAgreeMarketing === void 0) { needToAgreeMarketing = false; }
        if (isFb === void 0) { isFb = false; }
        if (handleFail === void 0) { handleFail = false; }
        if (orderPlaceMarketing === void 0) { orderPlaceMarketing = false; }
        if (useByodTnc === void 0) { useByodTnc = true; }
        if (hideFooter === void 0) { hideFooter = false; }
        return __awaiter(this, void 0, void 0, function () {
            var url, params, modal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = getTandCUrl(brand, useByodTnc ? 'byodTc' : 'regTc');
                        if (!url) return [3 /*break*/, 2];
                        params = { url: url, needToAgreeMarketing: needToAgreeMarketing, hideFooter: hideFooter, dismissBtnHandler: dismissBtnHandler };
                        if (isFb) {
                            params['fbHandler'] = handler;
                        }
                        params['brandName'] = brand.name;
                        return [4 /*yield*/, this.modalController.create({ component: page, componentProps: params, backdropDismiss: false })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (result) {
                            console.log('tnc result', result.data);
                            if (result && handler && !isFb) {
                                handler(result.data);
                            }
                            _this.loading(false);
                        });
                        modal.present();
                        return [3 /*break*/, 3];
                    case 2:
                        console.warn('Please add Terms and Conditions');
                        this.loading(false);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MobileBasePage.prototype.openFAQTermsModal = function (brand, handler, page, needToAgreeMarketing, isFb, handleFail, orderPlaceMarketing) {
        if (needToAgreeMarketing === void 0) { needToAgreeMarketing = false; }
        if (isFb === void 0) { isFb = false; }
        if (handleFail === void 0) { handleFail = false; }
        if (orderPlaceMarketing === void 0) { orderPlaceMarketing = false; }
        return __awaiter(this, void 0, void 0, function () {
            var url, params, modal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = getTandCUrl(brand, 'faq');
                        if (!url) return [3 /*break*/, 2];
                        params = { url: url, isFAQ: true };
                        if (isFb) {
                            params['fbHandler'] = handler;
                        }
                        params['brandName'] = brand.name;
                        return [4 /*yield*/, this.modalController.create({ component: page, componentProps: params, backdropDismiss: false })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (result) {
                            console.log('tnc result', result.data);
                            if (result && handler && !isFb) {
                                handler(result.data);
                            }
                            _this.loading(false);
                        });
                        modal.present();
                        return [3 /*break*/, 3];
                    case 2:
                        console.warn('Please add Terms and Conditions');
                        this.loading(false);
                        this.showAlert(this.t.instant('pages.base.missing-tnc'), '');
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MobileBasePage.prototype.settingInventory = function (store) {
        var type = store.menu.settings.inventory;
        if (!type || type === 'default') {
            var isChina = this.configService.getCountry() === 'cn';
            this.getInventory(!isChina, store.id);
        }
        else if (type === 'server' || type === 'firebase') {
            var isFirebase = type === 'firebase';
            this.getInventory(isFirebase, store.id);
        }
        else if (type !== 'none') {
            console.log('new type');
            // handling....
        }
    };
    MobileBasePage.prototype.getInventory = function (isFirebase, storeId) {
        var _this = this;
        if (!isFirebase) {
            this.menuService.getInventory(storeId).subscribe(function (data) {
                console.log('inventory', data);
                _this.inventoryManager.inventory = data['inventory'];
            }, function (err) {
                _this.showError(err);
            });
        }
        else {
            this.liveDataService.unlistenAll();
            this.liveDataService.listenInventory(storeId);
        }
    };
    MobileBasePage.prototype.setTheme = function (brand, store) {
        if (brand && brand.themes && brand.themes['byod'] && brand.themes['byod']['fav'] && brand.themes['byod']['fav'][0] && brand.themes['byod']['fav'][0]['url']) {
            this.themeService.setFavicon(brand.themes['byod']['fav'][0]['url']);
        }
        var sheets = document.styleSheets;
        var sheet = sheets[sheets.length - 1];
        var insertFn = sheet['insertRule'];
        if (!insertFn) {
            return;
        }
        if (screen.width > 500) {
            var ionPage = document.getElementsByTagName('ion-app')[0];
            ionPage.id = 'ion-page-best-fit';
        }
        if (!this.orderService.isCourt && store && store.brandId) {
            this.setID(String(store.brandId));
        }
        var bg = store && this.getColor(store, 'bg');
        if (!bg && brand)
            bg = this.getColor(brand, 'bg');
        var textcolor = store && this.getColor(store, 'text');
        if (!textcolor && brand)
            textcolor = this.getColor(brand, 'text');
        var img;
        if (brand) {
            img = this.getSplashBackground(brand, ((screen.width * 16) / 9), store);
        }
        if (bg) {
            // this.settingCssClass('header',"toolbar-background","background-color",bg);
            // this.settingCssClass('footer',"toolbar-background","background-color",bg);
            // this.addCss(sheet, ".theme1 .fab", "background-color", bg);
            // this.addCss(sheet, ".theme1 .toolbar-background", "background-color", bg);
            // this.addCss(sheet, ".theme1","background-color",bg);
            this.addCssInHead('toolbarCss', '.theme1 .toolbar-background', 'background-color', bg);
            this.addCssInHead('backgroundCss', '.theme1', 'background-color', bg);
        }
        if (textcolor) {
            this.addCssInHead('splashTextColorCss', '.themeText ion-icon, .themeText ion-label, .themeText p', 'color', textcolor);
        }
        else {
            this.addCssInHead('splashTextColorCss', '.themeText ion-icon, .themeText ion-label, .themeText p', 'color', '#000');
        }
        if (img) {
            this.hasBg = true;
            var urlString = 'url(\'' + img + '\')';
            this.addCssInHead('backgroundImageCss', '.theme1.content', 'background-image', urlString);
            this.addCssInHead('backgroundPositionCss', '.theme1.content', 'background-position', 'center');
            this.addCssInHead('backgroundSizeCss', '.theme1.content', 'background-size', 'cover');
        }
        else if (!img && !bg) {
            this.hasBg = true;
            this.addCssInHead('backgroundImageCss', '.theme1.content', 'background-image', 'url(\'../assets/images/defaultSplash.jpg\')');
            this.addCssInHead('backgroundPositionCss', '.theme1.content', 'background-position', 'center');
            this.addCssInHead('backgroundSizeCss', '.theme1.content', 'background-size', 'cover');
        }
    };
    MobileBasePage.prototype.setID = function (brandID) {
        var body = document.getElementsByTagName('BODY')[0];
        if (brandID) {
            body.id = 'cId' + brandID;
        }
        else {
            body.removeAttribute('id');
        }
    };
    MobileBasePage.prototype.isProfileCompleted = function (user) {
        if (!user.firstName || user.firstName === '') {
            return false;
        }
        if (!user.lastName || user.lastName === '') {
            return false;
        }
        if (!user.gender || user.gender === '') {
            return false;
        }
        if (!user.birthdate) {
            return false;
        }
        return true;
    };
    MobileBasePage.prototype.checkingMembership = function (store, handler, closeLoading) {
        var _this = this;
        if (closeLoading === void 0) { closeLoading = true; }
        this.loading(true);
        this.memberApiService.checkingMembership(store.brandId).subscribe(function (membership) {
            var member = _this.memberService.getMember();
            var has = membership;
            if (has) {
                member.membership = membership[0];
                // handler();
            }
            handler(has);
            // this.loading(false);
        }, function (err) {
            _this.loading(false);
            if (err.status === 401) {
                _this.showAlert(err.status, err['_body'], [{
                        text: _this.t.instant('buttons.ok'),
                        handler: function () {
                            _this.memberService.clear();
                            _this.navigationService.popToRoot();
                            _this.loading(false);
                        }
                    }]).then(function (alert) {
                    alert.present();
                });
            }
            else {
                _this.showError(err);
            }
        }, function () {
            if (closeLoading)
                _this.loading(false);
        });
    };
    MobileBasePage.prototype.checkingMembershipOfBrand = function (brandId, handler, closeLoading) {
        var _this = this;
        if (closeLoading === void 0) { closeLoading = true; }
        if (brandId) {
            this.loading(true);
            this.memberApiService.checkingMembership(brandId).subscribe(function (membership) {
                var member = _this.memberService.getMember();
                var has = membership;
                if (has) {
                    member.membership = membership[0];
                    // handler();
                }
                handler(has);
                // this.loading(false);
            }, function (err) {
                _this.loading(false);
                if (err.status === 401) {
                    _this.showAlert(err.status, err['_body'], [{
                            text: _this.t.instant('buttons.ok'),
                            handler: function () {
                                _this.memberService.clear();
                                _this.navigationService.popToRoot();
                                _this.loading(false);
                            }
                        }]).then(function (alert) {
                        alert.present();
                    });
                }
                else {
                    _this.showError(err);
                }
            }, function () {
                if (closeLoading)
                    _this.loading(false);
            });
        }
        else {
            this.showAlert('', 'can not find bandId');
        }
    };
    MobileBasePage.prototype.getDefaultImage = function (isCategory, brand, height) {
        if (!brand) {
            return;
        }
        var theme = brand['themes']['byod'];
        if (theme) {
            if (isCategory && theme['category-fb'] && theme['category-fb'].length > 0) {
                var catImage = theme['category-fb'][0];
                return this.getImageUrl(catImage, height);
            }
            if (!isCategory && theme['item-fb'] && theme['item-fb'].length > 0) {
                var itemImage = theme['item-fb'][0];
                return this.getImageUrl(itemImage, height);
            }
        }
        return null;
    };
    MobileBasePage.prototype.getSplashBackground = function (brand, height, store) {
        if (store === void 0) { store = null; }
        var theme = brand['themes']['byod'];
        // store level override
        if (store) {
            if (store.images && store.images['bg']) {
                var storeSplash = store.images['bg'];
                return this.getImageUrl(storeSplash, height);
            }
        }
        if (theme) {
            if (theme['welcome'] && theme['welcome'].length > 0) {
                var splash = theme['welcome'][0];
                return this.getImageUrl(splash, height);
            }
        }
        return null;
    };
    MobileBasePage.prototype.checkingMobileTimeAvailable = function () {
        var today = new Date();
        if (!this.configService.serverTime) {
            return true;
        }
        var forceRefreshInterval = this.settings.forceRefreshInterval || 60;
        return (today.getTime() + (forceRefreshInterval * 60000) > this.configService.serverTime && this.configService.serverTime > today.getTime() - (forceRefreshInterval * 60000));
    };
    MobileBasePage.prototype.addCourtReward = function (orderManager) {
        if (!this.orderService.isCourt) {
            return false;
        }
        if (!this.orderService.selectedReward) { // 未选优惠劵
            return false;
        }
        orderManager.order.offers.push(this.orderService.selectedReward); // 在各个latyou里，创建order时 自行push selectreward
        orderManager.calculate();
    };
    //set court level discount to order
    MobileBasePage.prototype.addCourtDiscount = function (orderManager) {
        if (!this.orderService.isCourt) {
            return false;
        }
        var promotions = [];
        if (this.orderService.courtBrand && this.orderService.courtBrand.discounts) {
            promotions = this.orderService.courtBrand.discounts;
        }
        if (promotions && promotions.length > 0) {
            orderManager.order.promotions = promotions;
        }
    };
    MobileBasePage.prototype.backButtonClick = function (toRoot, route) {
        // if the outlet has page stack, pop
        // else redirect to root page
        console.log('on back button click');
        console.log('is navigating?', this.navigationService.navigating);
        if (!toRoot) {
            console.log('back by pop');
            this.googleAnalyticEventTrack('back');
            // if (this.backByLocation) {
            //     this.location.back();
            //     window.onpopstate
            // window.history.replaceState({},this.location.path());
            // } else {
            if (!this.navigationService.poping) {
                this.navigationService.lastParams = null;
                console.log('pop start');
                this.navigationService.popPage();
            }
            else {
                console.log('pop is in progress, try again later');
            }
            // }
        }
        else {
            this.googleAnalyticEventTrack('back');
            console.log('back by navigate to root');
            this.navigationService.popToRoot();
        }
        // this.stackController.pop(0)
        // this.navController.goBack();
        // this.navController.pop();
    };
    MobileBasePage.prototype.homeButtonClick = function () {
        return __awaiter(this, void 0, void 0, function () {
            var alert_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // no need alert msg
                        this.orderService.isHomeButton = true;
                        if (!this.multOrderManager.enableMutlOrder) return [3 /*break*/, 1];
                        // TODO: check logic
                        this.navController.navigateRoot(ROOT_URL, {});
                        this.multOrderManager.clearAll();
                        this.orderService.isHomeButton = false;
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(!this.multOrderManager.enableMutlOrder && this.orderManager.isEmpty())) return [3 /*break*/, 2];
                        // this.router.
                        // TODO: check logic
                        this.navController.navigateRoot(ROOT_URL, {});
                        this.orderManager.clear();
                        this.orderService.isHomeButton = false;
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.alertController.create({
                            header: this.t.instant('pages.brand-store-list.alert-title'),
                            buttons: [
                                {
                                    text: this.t.instant('buttons.cancel'),
                                    handler: function () {
                                        // to do
                                        // this.googleAnalyticEventTrack('back-button-event', 'cancel');
                                    }
                                },
                                {
                                    text: this.t.instant('buttons.leave'),
                                    handler: function () {
                                        // to do
                                        // TODO: check logic
                                        // this.googleAnalyticEventTrack('back-button-event', 'back');
                                        _this.navController.navigateRoot(ROOT_URL);
                                        if (_this.multOrderManager.enableMutlOrder) {
                                            _this.multOrderManager.clearAll();
                                        }
                                        else {
                                            _this.orderManager.clear();
                                            _this.addressManagerService.clear();
                                        }
                                        _this.orderService.isHomeButton = false;
                                    }
                                }
                            ]
                        })];
                    case 3:
                        alert_1 = _a.sent();
                        alert_1.present();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MobileBasePage.prototype.pageName = function () {
        return '';
    };
    MobileBasePage.prototype.couponErrorHandle = function (payCash, cb) {
        if (payCash === void 0) { payCash = false; }
        this.orderService.clearCoupon();
        if (!payCash) {
            this.navController.pop();
        }
        if (cb) {
            cb();
        }
    };
    MobileBasePage.prototype.convertNowDateToStoreTimeZone = function (timeZone) {
        timeZone = timeZone ? timeZone : 'GMT+8';
        var timeZoneValue = timeZone.split('GMT')[1];
        // let isPositive = timeZoneValue.startsWith('+');
        var integerTimeZoneValue = parseFloat(timeZoneValue);
        var now = new Date();
        now.setHours(now.getUTCHours() + integerTimeZoneValue);
        return now;
    };
    // return: to control the modeSelectAlert pop up
    MobileBasePage.prototype.isPopupModeSelectModalAccordingToStoreMenuMode = function (store) {
        // TODO: add other modes?
        var menu = store.menu;
        var modes = menu.modes;
        // if (this.orderManager.mode === 'byod' || !modes || modes.length === 0) {
        if ((!modes || modes.length === 0)) {
            if (this.orderManager.mode === 'byod') {
                console.warn('no mode available for given menu, use byod as default');
                return false;
            }
            else {
                // avoid null exception
                modes = [];
            }
        }
        var dinein = modes.indexOf('dinein') >= 0;
        var takeout = modes.indexOf('takeaway') >= 0;
        if (!dinein || !takeout) { // one must false
            if (dinein) {
                this.orderManager.mode = 'dinein';
            }
            else if (takeout) {
                this.orderManager.mode = 'takeaway';
            }
            return false;
        }
        return true;
    };
    MobileBasePage.prototype.getColor = function (data, name) {
        var colors = data['colors'];
        if (!colors) {
            return null;
        }
        return colors[name];
    };
    MobileBasePage.prototype.getImageUrl = function (image, width) {
        /*
        var url = image.url;

        if (!url) return null;
        if (url.indexOf("googleuser") > 0 && height) {
            var suffix = "=s" + height.toFixed(0);
            url += suffix;
        }


        if (url.indexOf("http://") === 0 && url.indexOf("localhost") === -1) {
            url = url.replace("http:", "https:");
        }

        return url;
        */
        var url = image.url;
        if (!url) {
            return null;
        }
        return Data.getImageUrl(image.url, width, false);
    };
    MobileBasePage.prototype.addCss = function (sheet, className, cssAttribute, cssValue) {
        var rule = className + '{' + cssAttribute + ':' + cssValue + '}';
        console.log('adding css', rule);
        // sheet['insertRule'](rule, this.staticService.themeCount++);
    };
    MobileBasePage.prototype.addCssInHead = function (id, className, cssAttribute, cssValue) {
        var css = className + '{' + cssAttribute + ':' + cssValue + '}', head = document.head || document.getElementsByTagName('head')[0], style = document.createElement('style'), element = document.getElementById(id);
        if (element) {
            element.remove();
        }
        style.appendChild(document.createTextNode(css));
        style.setAttribute('id', id);
        head.appendChild(style);
    };
    // according store.menu.mode to init the orderManager.mode
    MobileBasePage.prototype.settingCssClass = function (id, className, cssAttribute, cssValue) {
        document.getElementById(id).getElementsByClassName(className)[0].setAttribute('style', cssAttribute + ':' + cssValue);
    };
    MobileBasePage.prototype.openFAQ = function (page) {
        this.openFAQTermsModal(this.orderManager.store.brand, function (handle) {
        }, page);
    };
    MobileBasePage.prototype.isStoreBusinessHours = function (store) {
        var now = this.convertNowDateToStoreTimeZone(store.timezone).getTime();
        var open = OpeningHours.isTimeOpen(store.brand.openings, store.openings, now);
        console.log('checkStoreBusinessHours', open);
        return open;
    };
    MobileBasePage.prototype.updateMenuPrice = function () {
        var store = this.orderManager.store;
        if (!this.orderManager.store || !this.orderManager.store) {
            return;
        }
        var menu = store.menu;
        if (!menu) {
            return;
        }
        console.log('update menu price');
        this.priceManager.updateBasePrice(menu, this.orderManager.mode, this.memberService.getMember(), store);
    };
    MobileBasePage.prototype.openSettings = function (setting) {
        if (setting === void 0) { setting = 'settings'; }
        var cordova = window.cordova;
        if (cordova && cordova.plugins && cordova.plugins.settings) {
            console.log('openNativeSettingsTest is active');
            cordova.plugins.settings.open(setting, function () {
                console.log('opened settings');
            }, function () {
                console.log('failed to open settings');
            });
        }
        else {
            console.log('openNativeSettingsTest is not active!');
        }
    };
    MobileBasePage.prototype.canLogin = function () {
        return false;
        // return this.systemConfigService.deliveryCanLogin && this.memberService.member && this.memberService.member['method'] === 'device';
    };
    MobileBasePage.prototype.isAvailablePeriod = function (store, period, now) {
        // var today: Date = new Date();
        // today.setHours(0, 0, 0, 0);
        // var diff = now.getTime() - today.getTime();
        // var start:number;
        // var end:number;
        // //console.log("diff", diff);
        // if(period.openings && period.openings.weekdays){
        //     var [dateStart,startString,dateEnd,endString] = this.getWeekdayStartAndEnd(period);
        //     start = dateStart.getTime() - today.getTime();
        //     end = dateEnd.getTime() - today.getTime();
        // }else{
        //     if (!period.start || !period.end) return false;
        //     start = period.start;
        //     end = period.end;
        // }
        // return diff >= start && diff < end;
        if (period.openings) {
            console.log('isAvailable : check by opening rules');
            var open_1 = OpeningHours.isTimeOpen(store.brand.openings, period.openings, now.getTime());
            return open_1;
        }
        else {
            // console.log("diff", diff);
            if (!period.start || !period.end)
                return false;
            console.log('isAvailable : check by start & end');
            return Period.isTimeOpen(period, now.getTime());
        }
    };
    MobileBasePage.prototype.getNavParamsOrParams = function (key) {
        return this.getNavParams(key) ? this.getNavParams(key) : this.navigationService.getNavigationParams()[key];
    };
    MobileBasePage.prototype.genAlertButton = function (text, handler, role, cssClass) {
        var alert = {
            text: text,
            handler: handler,
            role: role,
            cssClass: cssClass
        };
        return alert;
    };
    MobileBasePage.isAlerting = false;
    return MobileBasePage;
}(BasePage));
export { MobileBasePage };
