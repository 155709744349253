var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
var SelectStoreFilterComponent = /** @class */ (function (_super) {
    __extends(SelectStoreFilterComponent, _super);
    function SelectStoreFilterComponent(injector, navParams) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        _this.filterGroups = [];
        _this.inputs = [];
        _this.keywords = [];
        _this.filterGroups = _this.navParams.get('filterGroups');
        console.log('ServiceChoiceComponent constructor()', _this.filterGroups);
        return _this;
    }
    SelectStoreFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log("ngOnInit");
        this.filterGroups.forEach(function (group) {
            group.subs && group.subs.map(function (sub) {
                if (sub.checked)
                    _this.keywords.push(sub.name);
            });
        });
        console.log("init keywords");
    };
    SelectStoreFilterComponent.prototype.dismiss = function () {
        this.modalController.dismiss({ keywords: this.keywords });
    };
    SelectStoreFilterComponent.prototype.addKeywords = function (ev, list) {
        console.log("addKeywords", ev, list);
        var checked = ev.detail.checked;
        var val = ev.detail.value;
        if (checked) {
            this.keywords.push(val);
        }
        else {
            this.keywords = this.keywords.filter(function (el) { return val != el; });
        }
        console.log("keywords", this.keywords);
    };
    return SelectStoreFilterComponent;
}(MobileBasePage));
export { SelectStoreFilterComponent };
