import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

import * as Color from 'color';
import {Store} from 'aigens-ng-core';

const defaults = {
    primary: '#c81929',
    secondary: '#0cd1e8',
    tertiary: '#7044ff',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    dark: '#222428',
    medium: '#989aa2',
    light: '#f4f5f8',
    secondaryTint: '#d03745',
    successContrast: '#ffffff',
    successTint: '#c81929',
    secondaryShade: '#c81929',
    extTxt: '#c81929',
    catTabUnselected: "#ffffff",
    catTabSelected: "#ebebeb",
    primaryTextColor: '#c81929',
    storenameShadow: 'rgba(255, 255, 255, 0.8) 1px 0px 0px, rgba(255, 255, 255, 0.8) 0.540302px 0.841471px 0px, rgba(255, 255, 255, 0.8) -0.416147px 0.909297px 0px, rgba(255, 255, 255, 0.8) -0.989992px 0.14112px 0px, rgba(255, 255, 255, 0.8) -0.653644px -0.756802px 0px, rgba(255, 255, 255, 0.8) 0.283662px -0.958924px 0px, rgba(255, 255, 255, 0.8) 0.96017px -0.279415px 0px',
    storeaddrShadow: 'rgba(255, 255, 255,0.8) 2px 0px 0px, rgba(255, 255, 255,0.8) 1.75517px 0.958851px 0px, rgba(255, 255, 255,0.8) 1.0806px 1.68294px 0px, rgba(255, 255, 255,0.8) 0.141474px 1.99499px 0px, rgba(255, 255, 255,0.8) -0.832294px 1.81859px 0px, rgba(255, 255, 255,0.8) -1.60229px 1.19694px 0px, rgba(255, 255, 255,0.8) -1.97998px 0.28224px 0px, rgba(255, 255, 255,0.8) -1.87291px -0.701566px 0px, rgba(255, 255, 255,0.8) -1.30729px -1.5136px 0px, rgba(255, 255, 255,0.8) -0.421592px -1.95506px 0px, rgba(255, 255, 255,0.8) 0.567324px -1.91785px 0px, rgba(255, 255, 255,0.8) 1.41734px -1.41108px 0px, rgba(255, 255, 255,0.8) 1.92034px -0.558831px 0px',
    storeName: '#c81929',
    storeLocation: '#000000',
    displayStore: 'block',
    tablenumShadow: 'none',
    tableNum: '#000000',
    startbtnBg: "#c81929",
    startbtnActive: "#c81929",
    startbtnShadow: "0px 0px 16px rgba(0,0,0,.22)",
    historybtnBg: "#222428",
    historybtnActive: "#222428",
    historytextColor: "#ffffff",
    startextColor: "#ffffff",
    splashinfoBg: "rgba(253, 252, 249, 0)",
    chipBadge: '#ffffff',
    chipNum: '#000000',
    contentBg: '#f1f1f1',
    itemlistBg: '#ffffff',
    backBtn: '#ffffff',
    toolbarBg: '#c81929'
};

function contrast(color, ratio = 0.6) {
    color = Color(color);
    console.log('color', color);
    return color.isDark() ? color.darken(ratio) : color.lighten(ratio);
}

function CSSTextGenerator(colors) {
    if (!colors['toolbarBg']) {
        colors['toolbarBg'] = 'var(--ion-color-primary)';
    }
    if (!colors['startbtnBg']) {
        colors['startbtnBg'] = 'var(--ion-color-primary)';
    }
    if (!colors['startbtnActive']) {
        colors['startbtnActive'] = 'var(--ion-color-primary)';
    }
    if (!colors['historybtnBg']) {
        colors['historybtnBg'] = defaults.historybtnBg;
    }
    if (!colors['historybtnActive']) {
        colors['historybtnActive'] = defaults.historybtnActive;
    }
    if (!colors['historytextColor']) {
        colors['historytextColor'] = defaults.historytextColor;
    }
    if (!colors['secondaryTint'] ) {
        colors['secondaryTint'] = 'var(--ion-color-primary)';
    }
    if (!colors['secondaryShade'] ) {
        colors['secondaryShade'] = 'var(--ion-color-primary)';
    }
    if (!colors['backBtn']) {
        colors['backBtn'] = defaults.backBtn;
    }
    if (!colors['successContrast']) {
        colors['successContrast'] = defaults.successContrast;
    }
    if (!colors['catTabUnselected']) {
        colors['catTabUnselected'] = defaults.catTabUnselected;
    }
    if (!colors['catTabSelected']) {
        colors['catTabSelected'] = defaults.catTabSelected;
    }
    if (!colors['extTxt']) {
        colors['extTxt'] = 'var(--ion-color-primary)';
    }
    if (!colors['primaryTextColor']) {
        colors['primaryTextColor'] = 'var(--ion-color-primary)';
    }
    if (!colors['storeName']) {
        colors['storeName'] = defaults.storeName;
    }
    if (!colors['storeLocation']) {
        colors['storeLocation'] = defaults.storeLocation;
    }
    if (!colors['storenameShadow']) {
        colors['storenameShadow'] = defaults.storenameShadow;
    }
    if (!colors['storeaddrShadow']) {
        colors['storeaddrShadow'] = defaults.storeaddrShadow;
    }
    if (!colors['displayStore']) {
        colors['displayStore'] = defaults.displayStore;
    }
    if (!colors['tablenumShadow']) {
        colors['tablenumShadow'] = defaults.tablenumShadow;
    }
    if (!colors['tableNum']) {
        colors['tableNum'] = defaults.tableNum;
    }
    if (!colors['splashinfoBg']) {
        colors['splashinfoBg'] = defaults.splashinfoBg;
    }
    if (!colors['startbtnShadow']) {
        colors['startbtnShadow'] = defaults.startbtnShadow;
    }
    // if (!colors['startbtnBg']) {
    //     colors['startbtnBg'] = defaults.startbtnBg;
    // }
    // if (!colors['startbtnActive']) {
    //     colors['startbtnActive'] = defaults.startbtnActive;
    // }
    if (!colors['startextColor']) {
        colors['startextColor'] = defaults.startextColor;
    }
    if (!colors['chipBadge']) {
        colors['chipBadge'] = defaults.chipBadge;
    }
    if (!colors['chipNum']) {
        colors['chipNum'] = defaults.chipNum;
    }
    if (!colors['contentBg']) {
        colors['contentBg'] = defaults.contentBg;
    }
    if (!colors['itemlistBg']) {
        colors['itemlistBg'] = defaults.itemlistBg;
    }
    if (!colors['successTint'] ) {
        colors['successTint'] = 'var(--ion-color-primary)';
    }
    colors = {...defaults, ...colors};

    const {
        primary,
        secondary,
        secondaryTint,
        secondaryShade,
        tertiary,
        success,
        warning,
        danger,
        dark,
        medium,
        light,
        toolbarBg,
        successContrast,
        successTint,
        catTabUnselected,
        catTabSelected,
        extTxt,
        primaryTextColor,
        storenameShadow,
        storeaddrShadow,
        storeName,
        storeLocation,
        splashinfoBg,
        displayStore,
        tablenumShadow,
        tableNum,
        startbtnShadow,
        startbtnActive,
        startbtnBg,
        startextColor,
        historybtnBg,
        historybtnActive,
        historytextColor,
        chipNum,
        chipBadge,
        contentBg,
        itemlistBg,
        backBtn
    } = colors;

    const shadeRatio = 0.1;
    const tintRatio = 0.1;

    return `
    --ion-color-base: ${light};
    --ion-color-contrast: ${dark};

    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: 56,128,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade:  ${Color(primary).darken(shadeRatio)};

    --ion-color-tertiary: ${tertiary};
    --ion-color-tertiary-rgb: 44,27,100;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade:  ${Color(tertiary).darken(shadeRatio)};

    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: 56,128,255;
    --ion-color-warning-contrast: ${contrast(warning)};
    --ion-color-warning-contrast-rgb: 255,255,255;
    --ion-color-warning-shade:  ${Color(warning).darken(shadeRatio)};

    --ion-color-success: ${success};
    --ion-color-success-rgb: 56,128,255;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade:  ${Color(success).darken(shadeRatio)};

    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: 56,128,255;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade:  ${Color(danger).darken(shadeRatio)};

    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: 56,128,255;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade:  ${Color(dark).darken(shadeRatio)};

    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: 56,128,255;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade:  ${Color(medium).darken(shadeRatio)};

    --ion-color-light: ${light};
    --ion-color-light-rgb: 56,128,255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade:  ${Color(light).darken(shadeRatio)};

    --ion-color-secondary-shade: ${secondaryShade};
    --ion-color-secondary-tint: ${secondaryTint};

    --ion-color-success-contrast: ${successContrast};
    --ion-color-success-tint: ${successTint};

     --toolbar-bg: ${toolbarBg};
     --back-btn:${backBtn};
     --chip-badge:${chipBadge};
     --chip-num:${chipNum};
     --content-bg:${contentBg};
     --itemlist-bg:${itemlistBg};
     --startext-color:${startextColor};
     --startbtn-bg:${startbtnBg};
     --startbtn-active:${startbtnActive};     
     --startbtn-shadow:${startbtnShadow};
     --historybtn-bg:${historybtnBg};
     --historybtn-active:${historybtnActive};     
     --historytext-color:${historytextColor};
     --table-num:${tableNum};
     --tablenum-shadow:${tablenumShadow};
     --display-store: ${displayStore};
     --store-name: ${storeName};
     --store-location: ${storeLocation};
     --storename-shadow: ${storenameShadow};
     --storeaddr-shadow: ${storeaddrShadow};
     --splashinfo-bg: ${splashinfoBg};
     --primary-text-color:${primaryTextColor};
     --cat-tab-unselected:${catTabUnselected};
     --cat-tab-selected:${catTabSelected};
     --ext-txt:${extTxt};
`;
}

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    constructor(@Inject(DOCUMENT) private document: Document,) {
        // storage.get('theme').then(cssText => {  // <--- GET SAVED THEME
        //     this.setGlobalCSS(cssText);
        // });
    }

    // Override all global variables with a new theme
    setStoreTheme(store: Store) {
        console.log('set theme', store.colors);
        // let overwrites = {};
        if (store.colors) {
            // let colors = store.colors;
            // // primary: store.colors['primary'],
            // //     light: store.colors['light'],
            // //     dark: store.colors['dark'],
            //  if(colors['primary'])
            //      overwrites['primary'] =
            const cssText = CSSTextGenerator(store.colors);
            this.setGlobalCSS(cssText);
            console.log('generate theme', cssText);
        }

        // this.storage.set('theme', cssText); // <--- SAVE THEME HERE
    }

    setFavicon(iconUrl: string) {
        const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
        link['type'] = 'image/x-icon';
        link['rel'] = 'shortcut icon';
        link['href'] = iconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    setConfigTheme(colors: any) {
        console.log('set theme', colors);
        // let overwrites = {};
        if (colors) {
            // let colors = store.colors;
            // // primary: store.colors['primary'],
            // //     light: store.colors['light'],
            // //     dark: store.colors['dark'],
            //  if(colors['primary'])
            //      overwrites['primary'] =
            const cssText = CSSTextGenerator(colors);
            this.setGlobalCSS(cssText);
            console.log('generate theme', cssText);
        }

        // this.storage.set('theme', cssText); // <--- SAVE THEME HERE
    }

    // Define a single CSS variable
    setVariable(name, value) {
        this.document.documentElement.style.setProperty(name, value);
    }

    private setGlobalCSS(css: string) {
        this.document.documentElement.style.cssText = css;
    }

}
