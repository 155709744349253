/**
 *  Add logic here to decide which config to which page
 * */

export function getCategoryPage(systemConfigService, catLayout): string {
    if (!catLayout || catLayout === 'none') {
        // if(setLayout==='single-page'){
        //     return 'ItemGridScrollPage'
        // }
        // return 'ItemGridPage';

        if (systemConfigService && systemConfigService['systemConfig'] && systemConfigService['systemConfig']['data'] && systemConfigService['systemConfig']['data']['forceOldLayout']) {
            return 'ItemGridPage';
        }
        return 'ItemGridScrollPage';
    } else {
        switch (catLayout) {
            case 'showAll':
                return 'CategoryItemListPage';

            case 'groupCat':
                return 'CategoryGroupListPage';

            case'sideMenu':
                return 'SideMenuCategoryListPage';
            case 'topMenu':
                return 'TopMenuCategoryListPage';
            case'list':
            case'grid3':
            case'grid2':
            default:
                return 'CategoryListPage';

        }
    }
}

export function getSetLayout(setLayout): string {
    if (!setLayout) {
        console.warn('no setLayout');
    }
    // return 'ComboPage';
    switch (setLayout) {
        case 'multi-steps':
            return 'MultiStepComboPage';
        case 'multi-page':
            return 'PopUpSetMenuPage';
        case 'single-page':
            return 'ComboPage';
        default:
            return 'ComboPage';
    }

}
