var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Item, ItemGroup, ModifierBuilder, OrderItem, OrderItemBuilder, Store } from 'aigens-ng-core';
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavParams, ToastController } from '@ionic/angular';
import { ModifierDialog } from '../../../dialogs/modifier/modifier';
import { ModifierItemSelectPageModal } from './../modifier-item-select-modal/modifier-item-select-modal';
import { BaseComboPage } from '../../../core/base/base-combo-page';
var MultiStepComboPage = /** @class */ (function (_super) {
    __extends(MultiStepComboPage, _super);
    function MultiStepComboPage(injector, modalNavParams, toastCtrl, cd) {
        var _this = _super.call(this, injector) || this;
        _this.modalNavParams = modalNavParams;
        _this.toastCtrl = toastCtrl;
        _this.cd = cd;
        // isAutoPickGroupFunc = this.isAutoPickGroup;
        _this.screenWidthUnit = 0;
        _this.autoPickupGroups = [];
        _this.nonAutoPickupGroups = [];
        // this.edit = this.getNavParams('edit');
        // this.store = this.getNavParams('store');
        _this.defaultModifierPage = ModifierDialog;
        console.log('toggle set menu page construction', typeof _this.defaultModifierPage);
        _this.store = _this.modalNavParams.get('multi_store');
        _this.edit = _this.modalNavParams.get('multi_edit');
        if (_this.store) {
            _this.preselectItem = _this.settings.preselectItem;
            _this.isAutoPickup = _this.settings.autoPickup;
        }
        var groups;
        var mainItem;
        _this.builder = new OrderItemBuilder(_this.store);
        if (!_this.edit) {
            // this.category = this.getNavParams('category');
            _this.category = _this.modalNavParams.get('multi_category');
            // category.groups
            // groups = this.getNavParams('groups');
            // mainItem = this.getNavParams('item');
            groups = _this.modalNavParams.get('multi_groups');
            mainItem = _this.modalNavParams.get('multi_item');
        }
        else {
            var origins = _this.builder.findOrigins(_this.edit);
            _this.category = origins.category;
            groups = origins.groups;
            mainItem = origins.item;
        }
        _this.builder.category = _this.category;
        // this.isGroupCompleted = {};
        _this.isExpand = [];
        // make deep copy
        // const [tmpItem, tmpGroups] = this.menuService.clone(mainItem, groups, new Date().getTime());
        // mainItem = tmpItem;
        // groups = tmpGroups;
        if (mainItem.groups && mainItem.groups.length > 0) {
            _this.mainGroup = groups[0]; // buger页的所有数据
            groups = mainItem.groups; // 当前特定bugger的所有items Groups
            _this.mainItem = mainItem;
            _this.mainItem.quantity = 1;
        }
        else {
            // var choices = groups[0].choices;
            var min = groups[0].min;
            var max = groups[0].max;
            var choices = groups[0].choices;
            if ((max === 1 && min === 1) || choices === 1) {
                _this.mainGroup = groups[0];
                groups = groups.slice(1);
                _this.mainItem = mainItem;
                _this.mainItem.quantity = 1;
            }
        }
        _this.mainItemImage = _this.mainItem.getImage('default', 200, false);
        if (_this.store) {
            _this.handleOrderManager();
        }
        console.log('111', groups);
        // DEEP CLONE!
        groups = groups && groups.filter(function (group) {
            console.log('111', _this.store, _this.category, group, _this.orderManager.order.takeout, Date.now());
            return _this.menuManager.isAvailableGroup(_this.store, _this.category, group, _this.orderManager.order.takeout, Date.now(), false);
        });
        _this.groups = groups;
        console.log(_this.groups);
        // if (!this.edit) {
        _this.builder.setItem(_this.mainGroup, _this.mainItem, 0, _this.mainItem.quantity, false, false);
        // }
        if (_this.edit) {
            _this.builder.transferSubitems(_this.edit, _this.groups);
            // build all no need subitem group
            for (var j = 0; j < _this.groups.length; j++) {
                var matched = false;
                for (var i = 0; i < _this.edit.groups.length; i++) {
                    // pass main item
                    if (i !== 0) {
                        if (_this.groups[j].id === _this.edit.groups[i].id) {
                            // console.log("matched and put", this.groups[j].name);
                            matched = true;
                            break;
                        }
                    }
                }
                if (!matched) {
                    // console.log('i am putting null clickedNoNeed: ', this.groups[j].name);
                    _this.noNeedConfirm[_this.groups[j].id] = true;
                    _this.builder.clearItems(j + 1);
                    _this.builder.build();
                }
            }
        }
        var orderItem = _this.builder.build();
        _this.isExpand[_this.groups[0].id] = true;
        // this.isExpand[this.groups[0].id] = !this.preselectItem;
        _this.modifierBuilder = new ModifierBuilder(_this.store, orderItem);
        if (_this.edit) {
            _this.modifierBuilder.transfer(_this.edit);
            _this.modifierBuilder.build();
        }
        _this.flatten();
        _this.total = _this.mainItem.price;
        console.log(_this.groups);
        _this.screenWidthUnit = _this.platform.width() / 100;
        if (_this.screenWidthUnit > 4.75) {
            /// Largest screen with : 475px
            _this.screenWidthUnit = 4.75;
        }
        return _this;
    }
    MultiStepComboPage.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        console.log('on init');
        this.handleModifier();
        this.checkAutoPickupHasModifier();
        this.checkAutoPickupModifierCompleted();
        this.cd.detectChanges();
        for (var key in this.isExpand) {
            if (this.isExpand[key]) {
                this.preOpenTitleGroup(key, -1);
            }
        }
    };
    MultiStepComboPage.prototype.ngOnDestroy = function () {
        // this.groups.forEach((group) => {
        //         group.items.forEach((item) => {
        //                 item['groupIndex'] = null;
        //         });
        // });
    };
    MultiStepComboPage.prototype.dismissModal = function () {
        // canActivate(
        //     next: ActivatedRouteSnapshot,
        //     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        //     console.log('item guard');
        //     if ((this.navigationService.lastParams && (this.navigationService.lastParams['edit'] || this.navigationService.lastParams['item'])) || this.navigationService.poping) {
        //         return true;
        //     } else {
        //         // this.navController.pop().then(data => {
        //         //     console.log('pop ok', data);
        //         // }, error => {
        //         //     console.log('cannot pop', error);
        //         const t = this.navigationService.getRootPageUrl();
        //         console.log('home guard', t);
        //         if (t) {
        //             // TODO: not allowed to enter target root. go to parent route?
        //             this.navController.setDirection('root');
        //             this.router.navigate([this.navigationService.getRootPageUrl()]);
        //         }
        //     }
        //     return false;
        // }
        this.modalController.dismiss();
    };
    MultiStepComboPage.prototype.toggleGroupExpansion = function (groupId) {
        var _this = this;
        console.log('toggleGroupExpansion:', groupId);
        if (!this.isExpand[groupId]) {
            this.isExpand[groupId] = true; // 展开
            setTimeout(function () {
                _this.preOpenTitleGroup(groupId, -1);
            }, 100);
        }
        else {
            this.isExpand[groupId] = false; // 收缩
            this.openedTitleGroup[groupId] = null;
            for (var key in this.isTitleGroupExpand[groupId]) {
                this.isTitleGroupExpand[groupId][key] = false;
            }
        }
        this.cd.detectChanges();
    };
    MultiStepComboPage.prototype.modifierClicked = function () {
        return __awaiter(this, void 0, void 0, function () {
            var ois, params, popup;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.modifierShown)
                            return [2 /*return*/];
                        ois = this.builder.build();
                        console.log('modifierClicked');
                        params = { store: this.store, orderitem: ois, edit: true };
                        return [4 /*yield*/, this.modalController.create({ component: ModifierItemSelectPageModal, componentProps: params, cssClass: 'modal-largescreen variation-item-modifier-select' })];
                    case 1:
                        popup = _a.sent();
                        popup.onDidDismiss().then(function (data) {
                            _this.modifierShown = false;
                            _this.checkAutoPickupModifierCompleted();
                            _this.cd.detectChanges();
                        });
                        popup.present().then(function (data) {
                            _this.modifierShown = true;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    // noNeed
    MultiStepComboPage.prototype.clickedNoNeed = function (group, index) {
        if (this.noNeedConfirm[group.id]) {
            return;
        }
        var groupIndex = this.groups.indexOf(group) + 1;
        console.log('group clickedNoNeed: ', groupIndex, group);
        console.log('this.builder', this.builder.groups[groupIndex]);
        var quantities = null;
        if (this.builder.groups[groupIndex] && this.builder.groups[groupIndex].items && this.builder.groups[groupIndex].items.length) {
            quantities = 0;
            // quantities = quantities + this.builder.getQuantity
            for (var _i = 0, _a = this.builder.groups[groupIndex].items; _i < _a.length; _i++) {
                var item = _a[_i];
                quantities = quantities + this.builder.getQuantityInGroup(groupIndex, item.id);
            }
            console.log('check quantities', quantities);
        }
        this.noNeedConfirm[group.id] = true;
        this.builder.clearItems(groupIndex);
        this.builder.build();
        this.isExpand[group.id] = false;
        // console.log("this.displayGroups[index-1]", this.displayGroups[index-1]);
        // for checking next group
        // TODO: go to next incomplete group
        var itemGroup = this.groups[index + 1];
        console.log('checking next itemGrp', this.groups, index + 1);
        if (itemGroup) {
            this.isExpand[itemGroup.id] = true;
        }
        // this.checkingGroupCompleted(groupIndex);
        this.updateSelectedModifier();
        // this.next();
    };
    MultiStepComboPage.prototype.addToCartClicked = function () {
        var _this = this;
        this.googleAnalyticEventTrack('add to cart');
        console.log(this.groups);
        console.log('isCompletedisCompleted', this.isCompleted());
        console.log('isAutoPickupModifierCompletedisAutoPickupModifierCompleted', this.isAutoPickupModifierCompleted);
        if (!this.isCompleted() || !this.isAutoPickupModifierCompleted) {
            // let checked = false;
            this.missing = true;
            for (var i = 0; i < this.groups.length; i++) {
                this.isExpand[this.groups[i].id] = !this.isGroupCompleted(this.groups[i]);
            }
            // TODO: scroll to the first incomplete group
            this.showNotCompletedAlert();
            return;
        }
        this.missing = false;
        var oi = this.builder.build();
        oi.combo = true;
        var orderManager;
        if (this.multOrderManager.enableMutlOrder) {
            orderManager = this.multOrderManager.getOrderManager(this.store.id);
        }
        else {
            orderManager = this.orderManager;
        }
        // const handler = this.getNavParams('handler');
        var handler = this.modalNavParams.get('multi_handler');
        if (!handler) {
            if (this.edit) {
                // this.orderService.replaceOrderItem(this.edit, this.category, igs);
                orderManager.replaceOrderItem(this.edit, oi);
            }
            else {
                // this.orderService.addSet(this.category, igs);
                orderManager.addOrderItem(oi);
            }
        }
        if (orderManager.store.menu && orderManager.store.menu.quickCheckout || handler) {
            this.modalController.dismiss().then(function () {
                // this.pushByUrl(this.multOrderManager.enableMutlOrder ? RouteTable['MultiOrderCartPage'] : RouteTable['CartPage'], {}, {replaceUrl: true}).then(
                _this.pushByName(_this.multOrderManager.enableMutlOrder ? 'MultiOrderCartPage' : 'CartScrollPage', {}).then(function () {
                    if (handler) {
                        handler(oi);
                    }
                });
            });
        }
        else {
            this.modalController.dismiss();
            // this.navigationService.popPage();
        }
    };
    // new thing
    MultiStepComboPage.prototype.flatten = function () {
        var _this = this;
        var i = 0;
        // let y = 0;
        console.log(this.groups);
        var _loop_1 = function (group) {
            var items = [];
            // get all available items of the given group
            var mainItems = this_1.menuManager.getAvailableItems(this_1.store, this_1.category, group, this_1.orderManager.isTakeout());
            group.items = mainItems;
            // let isSet = false;
            // init status for title group
            this_1.isTitleGroupExpand[group.id] = {};
            // make a copy of itemGroupMap with some special value?
            var modifiedItems = [];
            var isAutoPickupGroup = this_1.isAutoPickup && this_1.isAutoPickGroup(group, mainItems);
            if (isAutoPickupGroup) {
                this_1.autoPickupGroups.push(group);
            }
            else {
                this_1.nonAutoPickupGroups.push(group);
            }
            if (mainItems && mainItems.length > 0) {
                mainItems.forEach(function (item, index) {
                    // item['groupIndex'] = i + 1;
                    // for variations?
                    /* if (item.variations && item.variations.length > 0) {
                             console.log('wtf is variations');
                             item.variations = item.variations.filter((vartion) => {
                                     vartion['groupIndex'] = item['groupIndex'];
                                     if (this.itemMap[vartion.id]) {
                                             return false;
                                     } else {
                                             this.itemMap[vartion.id] = vartion;
                                             return true;

                                     }
                             });

                     }
                     */
                    // put all itemGroupMap in itemMap
                    _this.itemMap[item.id] = item;
                    // if not edit mode and should do auto pickup
                    if (isAutoPickupGroup && !_this.edit) {
                        console.log('auto pick group', group);
                        _this.addClicked(item, group, false, true);
                        // isSet = true;
                    }
                });
                // handle drinks
                modifiedItems = this_1.groupingTitle(group, mainItems);
                // if (this.edit) {
                //         if (!(group.min === group.max && mainItems.length === group.min && this.isAutoPickup) || !this.isAutoPickup) {
                //                 group['isAutopickUp'] = true; // show item
                //         } else {
                //         }
                // } else {
                // if (!this.isGroupCompleted(group) || !this.isAutoPickup) {
                // } else if (group.optional === false) {
                //         // 因為auto pickup 出錯，特定條件下會把item group一些 un-available itemGroupMap 都pickup 起來,应该忽略optional === true 的 item group
                //
                // }
                // }
                items.push.apply(items, modifiedItems);
            }
            if (this_1.preselectItem && !this_1.edit) {
                var preselectItem = null;
                // if (this.store.pos.type !== "eats365") preselectItem = mainItems[0];
                if (group.defaultId && this_1.itemMap[group.defaultId]) {
                    preselectItem = this_1.itemMap[group.defaultId];
                    if (preselectItem) {
                        // if (!this.isSoldout(preselectItem[i], this.category)) {
                        if (!this_1.isSoldout(preselectItem, this_1.category)) {
                            this_1.addClicked(preselectItem, group, false, true);
                            this_1.addOrderItemMap(group, preselectItem[0]);
                        }
                    }
                }
                else if ((!(group.min === 0) && !group.optional)) {
                    // if there is no default item, it is not optional and group min > 0
                    // do not pick
                    // preselectItem = mainItems[0];
                    // if (preselectItem) {
                    //     this.addClicked(preselectItem, false);
                    // }
                }
                else {
                    // pick no need
                    // let groupIndex = this.groups.indexOf(group) + 1;
                    // console.log('#group clickedNoNeed: ', groupIndex, group);
                    // this.builder.clearItems(groupIndex);
                    // this.builder.build();
                    // this.checkingGroupCompleted(groupIndex);
                }
            }
            this_1.itemGroupMap[group.id] = items;
            if (this_1.sameTitleItems[group.id]) {
                for (var key in this_1.sameTitleItems[group.id]) {
                    var index = this_1.titles[group.id].indexOf(key);
                    this_1.isTitleGroupExpand[group.id][key] = false;
                    // this.titleGroupClicked(key, index, group.id);
                }
                // for (const key in this.isTitleGroupExpand[group.id]) {
                //     console.log('loop title expand?', this.isTitleGroupExpand);
                //     if (this.isTitleGroupExpand[group.id][key]) {
                //         const index = this.titles[group.id].indexOf(key);
                //         this.hasItemTitleGroup[group.id] = {title: key, index: index};
                //         this.titleGroupClicked(key, index, group.id);
                //     }
                // }
                // init sub item groups
            }
            if (!this_1.isGroupCompleted(group) || !this_1.isNoNeedSelected(group)) {
                this_1.isExpand[group.id] = true;
            }
            i++;
        };
        var this_1 = this;
        for (var _i = 0, _a = this.groups; _i < _a.length; _i++) {
            var group = _a[_i];
            _loop_1(group);
        }
        console.log(this.itemGroupMap);
    };
    MultiStepComboPage.prototype.add = function (item, group, isPreselect) {
        var _this = this;
        if (isPreselect === void 0) { isPreselect = false; }
        var oi;
        var groupIndex = this.getGroupIndex(group);
        if (groupIndex === -1) {
            groupIndex = this.groups.findIndex(function (g) {
                // TODO: is compared by id safe?
                return (g !== null && g !== undefined && g.id === group.id);
            }) + 1;
        }
        var isOptional = group.min === 0;
        if (this.getGroupMode(group) === 'single') {
            var max = group.max;
            var able = true;
            if (max >= 1) {
                var count = this.totalQuantity(groupIndex);
                if (count >= max && max !== 1) {
                    able = false;
                }
            }
            if (max === 1 && !this.has(item, group)) {
                this.builder.clearItems(groupIndex);
            }
            if (able && !this.has(item, group)) {
                this.builder.plusItem(group, item, groupIndex);
                oi = this.builder.build();
            }
            else if (this.has(item, group)) {
                this.discard(item, group);
            }
            if (!isPreselect) {
                if (this.isGroupCompleted(group)) {
                    this.handlingModifier(oi, group, this.itemMap, function () {
                        // if (this.groups[index]) {
                        _this.toggleGroupExpansion(group.id);
                        // console.log('close' + group.id);
                        // if next incomplete group exists, expand it
                        for (var i = groupIndex; i < _this.groups.length; i++) {
                            if (_this.groups[i].id === group.id && _this.isGroupCompleted(_this.groups[i])) {
                                _this.toggleGroupExpansion(_this.groups[groupIndex].id);
                                break;
                            }
                        }
                        // if (group && this.isGroupCompleted(group)) { //？？？
                        //     this.toggleGroupExpansion(group.id);
                        // }
                        // ???
                        //         this.toggleGroupExpansion(this.groups[index - 1].id);
                        //         this.toggleGroupExpansion(this.groups[index].id);
                        // }
                    }, function () {
                        _this.minusClicked(item, group);
                    });
                }
                else if (isOptional) {
                    var realItem = this.itemMap[item.id];
                    if (realItem.mgroups && realItem.mgroups.length > 0) {
                        this.handlingModifier(oi, group, this.itemMap, function () {
                        }, function () {
                            _this.minusClicked(item, group);
                        }, true);
                    }
                }
            }
        }
        else if (this.getGroupMode(group) === 'quantity') {
            var max = group.max;
            if (max <= this.totalQuantity(groupIndex)) {
                console.log('max reached');
                this.toggleGroupExpansion(group.id);
                return;
            }
            this.builder.plusItem(group, item, groupIndex);
            oi = this.builder.build();
            if (!isPreselect) {
                if (this.isGroupCompleted(group)) {
                    this.handlingModifier(oi, group, this.itemMap, function () {
                        if (_this.groups[groupIndex]) {
                            var currentQty = _this.totalQuantity(groupIndex);
                            if (_this.groups[groupIndex - 1].max === _this.groups[groupIndex - 1].min) {
                                _this.toggleGroupExpansion(_this.groups[groupIndex - 1].id);
                                _this.toggleGroupExpansion(_this.groups[groupIndex].id);
                            }
                            else if ((_this.groups[groupIndex - 1].max !== _this.groups[groupIndex - 1].min && currentQty === _this.groups[groupIndex - 1].max
                                && _this.groups[groupIndex - 1].max !== 99)) {
                                _this.toggleGroupExpansion(_this.groups[groupIndex - 1].id);
                                _this.toggleGroupExpansion(_this.groups[groupIndex].id);
                            }
                        }
                    }, function () {
                        var tmpItems = [];
                        for (var _i = 0, _a = oi.groups[groupIndex].items; _i < _a.length; _i++) {
                            var oiItem = _a[_i];
                            for (var i = 0; i < item.quantity; i++) {
                                tmpItems.push(_this.itemMap[oiItem.id]);
                            }
                        }
                        for (var _b = 0, tmpItems_1 = tmpItems; _b < tmpItems_1.length; _b++) {
                            var tmpItem = tmpItems_1[_b];
                            _this.minusClicked(tmpItem, group);
                        }
                    });
                }
                else if (isOptional) {
                    var realItem = this.itemMap[item.id];
                    if (realItem.mgroups && realItem.mgroups.length > 0) {
                        this.handlingModifier(oi, group, this.itemMap, function () {
                        }, function () {
                            _this.minusClicked(item, group);
                        }, true);
                    }
                }
            }
        }
    };
    MultiStepComboPage.prototype.titleGroupClicked = function (title, index, groupId, overwrite) {
        var childNode = document.getElementById('child-' + groupId + '-' + index);
        if (this.isTitleGroupExpand[groupId]) {
            if (overwrite) {
                this.isTitleGroupExpand[groupId][title] = overwrite === 1;
            }
            else {
                this.isTitleGroupExpand[groupId][title] = !this.isTitleGroupExpand[groupId][title];
            }
            if (childNode) {
                childNode.style.maxHeight = this.isTitleGroupExpand[groupId][title] ? childNode.scrollHeight + 'px' : '0px';
            }
        }
        // let currentStatus = this.isTitleGroupExpand[groupId][title];
        // if (this.openedTitleGroup && this.openedTitleGroup !== {}) {
        //     this.isTitleGroupExpand[groupId][title] = false;
        //     this.openedTitleGroup['childNode'].style.maxHeight = '0px';
        // }
        // if (this.openedTitleGroup && this.openedTitleGroup['title'] === title) {
        //     const needReturn = this.openedTitleGroup['groupId'] === groupId;
        //     this.openedTitleGroup = null;
        //     if (needReturn && !isOpeningGroupCell) {
        //         return;
        //     }
        // }
        // // this.isTitleGroupExpand[title] = !this.isTitleGroupExpand[title];
        // const childNode = document.getElementById('child-' + groupId + '-' + index);
        // if (this.isTitleGroupExpand[groupId][title]) {
        //     this.isTitleGroupExpand[groupId][title] = false;
        //     this.openedTitleGroup = null;
        //     childNode.style.maxHeight = '0px';
        // } else if (this.titles[groupId].length === 1) {
        //     this.isTitleGroupExpand[groupId][title] = true;
        //     childNode.style.maxHeight = childNode.scrollHeight + 'px';
        //     this.openedTitleGroup = {childNode: childNode, title: title, groupId: groupId};
        //
        // } else {
        //     this.isTitleGroupExpand[groupId][title] = true;
        //     childNode.style.maxHeight = childNode.scrollHeight + 'px';
        //     this.openedTitleGroup = {childNode: childNode, title: title, groupId: groupId};
        //
        // }
    };
    MultiStepComboPage.prototype.addClicked = function (item, group, detectChanges, isPreselect) {
        if (detectChanges === void 0) { detectChanges = true; }
        if (isPreselect === void 0) { isPreselect = false; }
        // to do
        // this.googleAnalyticEventTrack("add-item", item.name);
        var groupId = group.id;
        if (this.noNeedConfirm && this.noNeedConfirm[groupId])
            delete this.noNeedConfirm[groupId];
        console.log('addClicked');
        if (this.isSoldout(item)) {
            console.log('item sold out, return', item);
            return;
        }
        console.log('page点击传递的参数', item, group, isPreselect);
        this.add(item, group, isPreselect);
        this.handleModifier();
        if (detectChanges) {
            this.cd.detectChanges();
        }
        console.log('build', this.builder);
        // fixed: 取消選擇後, css 要回到未選擇時的style
        // this.checkingGroupCompleted(this.getItemRealGroupIndex(item));
    };
    MultiStepComboPage.prototype.showNotCompletedAlert = function () {
        return __awaiter(this, void 0, void 0, function () {
            var subtitle, confirm, alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        subtitle = this.t.instant('pages.set-select.select-error');
                        confirm = this.t.instant('buttons.ok');
                        return [4 /*yield*/, this.alertController.create({
                                message: subtitle,
                                buttons: [confirm]
                            })];
                    case 1:
                        alert = _a.sent();
                        alert.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    MultiStepComboPage.prototype.presentModifierPopup = function (store, orderItem, group, itemMap, page, handler, showLastOne) {
        return __awaiter(this, void 0, void 0, function () {
            var input, modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        input = { store: store, orderitem: orderItem, group: group, itemMap: itemMap };
                        if (showLastOne) {
                            input['showLastOne'] = showLastOne;
                        }
                        return [4 /*yield*/, this.modalController.create({
                                component: page,
                                componentProps: input,
                                animated: true,
                                showBackdrop: true,
                                backdropDismiss: true,
                                cssClass: 'modal-largescreen modifier-modal'
                            })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (confirm) {
                            if (handler) {
                                handler(confirm);
                            }
                        });
                        modal.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    MultiStepComboPage.prototype.getSelectedItemNamesModifier = function (group, i) {
        //    if (this.builder.groups) return ;
        console.log('groupgroup', group);
        var itemGroup = this.builder.groups.filter(function (_group) {
            if (_group) {
                return _group.id === group.id;
            }
            else {
                return false;
            }
        })[0];
        // if (itemGroup === null) {
        //     return this.t.instant("pages.item-select.no-need");
        //     // return "不需要";
        // }
        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }
        if (!itemGroup) {
            return '';
        }
        var selected = itemGroup.items;
        if (!selected) {
            return '';
        }
        // get modifier
        var firstItem = true;
        console.log('selectedselected', selected, i);
        var output = '';
        for (var _i = 0, selected_1 = selected; _i < selected_1.length; _i++) {
            var select = selected_1[_i];
            if (select.id === i.id) {
                if (!select.mgroups)
                    continue;
                for (var _a = 0, _b = select.mgroups; _a < _b.length; _a++) {
                    var mgroup = _b[_a];
                    if (!mgroup.items)
                        continue;
                    if (select.id === i.id)
                        for (var _c = 0, _d = mgroup.items; _c < _d.length; _c++) {
                            var item = _d[_c];
                            if (firstItem) {
                                output = item.name + ' x ' + item.quantity;
                                firstItem = false;
                            }
                            else {
                                output = output + ', ' + item.name + ' x ' + item.quantity;
                            }
                        }
                    // return output;
                }
            }
        }
        return output;
    };
    MultiStepComboPage.prototype.currentGroupItemHasModifier = function (group, item) {
        // return this.isCurrentGroupHasModifier(group);
        return item && item.mgroups && item.mgroups.length > 0;
    };
    MultiStepComboPage.prototype.isRequiredModifierSelect = function (index) {
        var _this = this;
        var oi = this.currentOrderItem ? this.currentOrderItem : this.builder.build();
        this.currentOrderItem = oi;
        var tmpIndex = index + 1;
        var group = oi.groups[tmpIndex];
        if (group && this.hasRequiredModifier(group.items)) {
            var builderItems = group.items;
            var selected = !builderItems.some(function (item) {
                var builderMgroups = item.mgroups;
                var mgroups = _this.itemMap[item.id].mgroups;
                if (mgroups) {
                    for (var i = 0; i < mgroups.length; i++) {
                        if (mgroups[i].items && mgroups[i].items.length === 0) {
                            continue;
                        }
                        if (builderMgroups) {
                            for (var _i = 0, builderMgroups_1 = builderMgroups; _i < builderMgroups_1.length; _i++) {
                                var b = builderMgroups_1[_i];
                                if (b.id === mgroups[i].id && mgroups[i].min > 0) {
                                    var quantity = 0;
                                    for (var _a = 0, _b = b.items; _a < _b.length; _a++) {
                                        var t = _b[_a];
                                        quantity += 1 * t.quantity;
                                    }
                                    return !(quantity >= mgroups[i].min);
                                }
                            }
                        }
                        // if (builderMgroups && builderMgroups[i] && mgroups[i].min > 0) {
                        //     let quantity = 0;
                        //     for (const t of builderMgroups[i].items) {
                        //         quantity += 1 * t.quantity;
                        //     }
                        //     return !(quantity >= mgroups[i].min);
                        // } else if ((!builderMgroups || !builderMgroups[i])) {
                        //     return mgroups[i].min > 0;
                        // }
                    }
                }
                else {
                    return false;
                }
            });
            return selected;
        }
        else {
            return true;
        }
    };
    return MultiStepComboPage;
}(BaseComboPage));
export { MultiStepComboPage };
