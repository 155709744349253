import {Category, Item, ItemGroup, ModifierBuilder, OrderItem, OrderItemBuilder, Store, Data, Image} from 'aigens-ng-core';
import {MobileBasePage} from './mobile-base-page';
import {ModifierDialog} from '../../dialogs/modifier/modifier';
import {Injectable, Injector, OnInit} from '@angular/core';
import {ModifierItemSelectPage} from '../../routes/item/modifier-item-select/modifier-item-select';

@Injectable()
export class MobileBaseItemPage extends MobileBasePage implements OnInit{

    // main item category
    category: Category;
    currentOrderItem: OrderItem;
    // item groups
    groups: ItemGroup[];
    groupIdMap = {};
    isAutoPickup = false;
    isGroupCompleted = {};
    isGroupSelected = {};
    group: ItemGroup;
    builderGroupsCount = 0;
    mainItem: Item;
    itemMap = {};
    mainGroup: ItemGroup;
    mainItemImage: string;
    total = 0;
    openSelectedBox = false;
    endMove = false;
    preselectItem = false;

    builder: OrderItemBuilder;

    // the target edit item
    edit: OrderItem;

    modifierBuilder: ModifierBuilder;
    modifiable = false;
    missing = false;
    hasSelectedModifiers: boolean;
    selectedModifiersName: string;
    store: Store;
    modeMap: Map<number, string> = new Map();

    autoPickupGroupIndexs: number[] = [];
    mainItemAndAutoPickupGroupIndex: number[] = [];
    requiredModifierSelectedMap = {};
    hasModifierMap = {};

    isUpselling = false;

    constructor(injector: Injector) {

        super(injector);
        console.log('set select page construction');
        this.edit = this.getNavParams('edit');
        this.store = this.getNavParams('store');
        this.isUpselling = !!this.getNavParams('upSelling');
        if (this.store) {
            this.preselectItem = this.settings.preselectItem;
            this.isAutoPickup = this.settings.autoPickup;

        }


    }

    isAutoPickupGroup(group: ItemGroup): boolean {

        return false;
    }

    modifierClicked(indexs: number[] = []) {
        // console.log("modifiable itemGroupMap", this.modifiableItems);
        const ois = this.builder.build();
        this.pushByName('ModifierItemSelectPage', {store: this.store, orderitem: ois, mainItemAndAutoPickupGroupIndex: indexs});
    }

    showModifierPopup(g: ItemGroup) {

        const oi = this.builder.build();
        const index = this.groupIdMap[g.id];
        const group = oi.groups[index];
        if (this.hasModifier(group.items, this.itemMap)) {

            this.presentModifierPopup(this.store, oi, group, this.itemMap, ModifierDialog, (result) => {
                this.handleModifier();
            });

        }

    }

    numberToFixed(number: number): number {
        if ('HKD' === this.orderService.currency) {
            return Number(number.toFixed(1));
        } else if ('CNY' === this.orderService.currency) {
            return Number(number.toFixed(1));

        } else if ('SGD' === this.orderService.currency) {
            return Number(number.toFixed(2));

        } else {
            return Number(number.toFixed(1));

        }
    }

    getSelectRules(igroup: ItemGroup): string {
        if (!igroup || isNaN(igroup.max) || isNaN(igroup.min)) return '';
        let min = Number(igroup.min);
        let max = Number(igroup.max);
        if (min === max && min === 0) return '';
        // 請選擇{{count}}項
        if (min === max) return this.t.instant('pages.item-select.select-items', { count: min });
        // 加配項目
        if (min === 0 && max === 99) return this.t.instant('pages.item-select.optional');
        // 請選最多max項
        if (min === 0 && max !== 99) return this.t.instant('pages.item-select.chooseAtMost', { max: max });
        // 請選至少{{min}}項
        if (min !== 0 && max === 99) return this.t.instant('pages.item-select.chooseUp', { min: min });
        // 請選{{min}} - {{max}}項
        if (min < max && max !== 99) return this.t.instant('pages.item-select.chooseTo', { min: min, max: max });
        return '';
    }

    matchingGroupIndex(oi: OrderItem) {
        const groups = oi.groups;
        if (groups.length !== this.builderGroupsCount && groups.length - 1 <= this.groups.length) {
            this.groupIdMap = {};
            for (let i = 0; i < groups.length; i++) {
                this.groupIdMap[groups[i].id] = i;
            }

        }
        this.builderGroupsCount = groups.length;
    }

    getGroupsByIndex(indexs: number[]) {
        const indexSet = new Set(indexs);
        const oi = this.currentOrderItem ? this.currentOrderItem : this.builder.build();
        const groups = oi && oi.groups && oi.groups.filter((group, index) => {
            return indexSet.has(index);
        });

        return groups;
    }

    areRequiredModifierSelectByIndex(indexs: number[], map?: any) {
        const groups = this.getGroupsByIndex(indexs);
        return this.areRequiredModifierSelect(groups, map);
    }

    ngOnInit() {
        super.ngOnInit();
        this.handleModifier();
    }

    updateSelectedModifier() {
        const oi: OrderItem = this.builder.build();

        let modifiers: Item[] = [];
        let total = 0;
        oi.groups.forEach(group => {

            const items: Item[] = group.items;

            items.forEach(item => {
                total = this.numberToFixed(total + item.price * item.quantity);
            });

            items
                .filter(item => item.mgroups && item.mgroups.length > 0)
                .forEach(item => {

                    const mgroups: ItemGroup[] = item.mgroups;

                    mgroups.forEach(mgroup => {

                        modifiers = modifiers.concat(mgroup.items);

                    });

                });

        });

        modifiers = modifiers.filter(item => item.quantity > 0);

        modifiers.forEach(item => {
            total = this.numberToFixed(total + item.price * item.quantity);
        });

        if (modifiers && modifiers.length > 0) {
            this.hasSelectedModifiers = true;
            this.selectedModifiersName = modifiers.map(item => item.name).join(',');

        } else {
            this.hasSelectedModifiers = false;
            this.selectedModifiersName = null;
        }

        console.log('update price');
        this.total = total;
    }

    handleModifier() {
        if (this.builder) {
            const oi: OrderItem = this.builder.build();
            this.currentOrderItem = oi;
            const modifiable: Item[] = this.modifierBuilder.findItems(oi);
            this.modifiable = modifiable.length > 0;
            this.matchingGroupIndex(this.currentOrderItem);
            this.checkingStatus();
            console.log('modifiable?', this.modifiable, modifiable);

            this.updateSelectedModifier();
        }

    }

    checkingStatus() {
        console.log('check status~');
    }

    areGroupsHaveModifierByIndex(indexs: number[], map?: any) {
        const groups = this.getGroupsByIndex(indexs);
        return this.areGroupsHaveModifier(groups, map);
    }

    areGroupsHaveModifier(groups: ItemGroup[], map?: any): boolean {
        let haveModifier = false;
        for (const group of groups) {
            const tmpHaveModifier = this.isCurrentGroupHasModifier(group, map);
            if (map) {
                map[group.id] = tmpHaveModifier;
            }
            if (tmpHaveModifier) {
                haveModifier = tmpHaveModifier;
            }
        }

        return haveModifier;
    }

    isCurrentGroupHasModifier(g: ItemGroup, map?: any): boolean {
        const index = this.groupIdMap[g.id];

        const group = this.currentOrderItem && this.currentOrderItem.groups ? this.currentOrderItem.groups[index] : null;
        if (group) {
            if (map) {
                map[group.id] = this.hasModifier(group.items, this.itemMap);
            }

            const g = this.currentOrderItem && this.currentOrderItem.groups ? this.currentOrderItem.groups[index] : null;
            if (g) {
                if (map) {
                    map[g.id] = this.hasModifier(g.items, this.itemMap);
                }

                return this.hasModifier(g.items, this.itemMap);

            } else {
                return false;
            }
        }
    }

    addToCartClicked() {
        if (this.openSelectedBox) {
            this.googleAnalyticEventTrack('add to cart', 'open selected');
        }
        this.googleAnalyticEventTrack('add to cart');
        console.log(this.groups);
        if (!this.isCompleted()) {
            this.showNotCompletedAlert();
            return;
        }

        const oi: OrderItem = this.builder.build();
        oi.combo = true;

        let orderManager;
        if (this.multOrderManager.enableMutlOrder) {
            orderManager = this.multOrderManager.getOrderManager(this.store.id);
        } else {
            orderManager = this.orderManager;
        }


        const handler = this.getNavParams('handler');
        if (!handler) {
            if (this.edit) {
                // this.orderService.replaceOrderItem(this.edit, this.category, igs);
                orderManager.replaceOrderItem(this.edit, oi);
            } else {
                // this.orderService.addSet(this.category, igs);
                orderManager.addOrderItem(oi);
            }

        }

        if (!this.edit && !this.isUpselling && (this.settings.quickCheckout || handler)) {
            this.pushByName(this.multOrderManager.enableMutlOrder ? 'MultiOrderCartPage' : 'CartScrollPage', null, {replaceUrl: true}).then(
                () => {
                    // find index of home page, and remove from home page
                    // let page: any;
                    const catLayout = this.settings.catLayout;

                    // if (this.orderService.isBK) {
                    //     page = CategoryItemListPage;
                    // } else {
                    //     let noneBKPage: any;
                    //     if (catLayout === 'showAll') {
                    //         noneBKPage = CategoryItemListPage;
                    //
                    //     } else if (catLayout === 'sideMenu') {
                    //         noneBKPage = SideMenuCategoryListPage;
                    //
                    //     } else if (!catLayout || catLayout === 'none') {
                    //
                    //         noneBKPage = ItemGridPage;
                    //
                    //     } else {
                    //
                    //         noneBKPage = CategoryListPage;
                    //     }
                    //
                    //     page = this.edit ? (this.multOrderManager.enableMutlOrder ? BrandStoreListPage : noneBKPage) : noneBKPage;
                    // }
                    // TODO: remove history

                    // let index: number = this.navController.getViews().findIndex(
                    //     view => (view.instance instanceof (page))
                    // );
                    // let current: number = this.navController.getActive().index;
                    // console.log('views:' + this.navController.getViews()[0]);
                    // console.log('index:' + index);
                    // console.log('current:' + current);
                    // this.navController.remove(index + 1, (current - 1) - index);

                    if (handler) {
                        handler(oi);
                    }

                }
            );
        } else {
            if (!this.edit) {
                //this.showToast(this.t.instant('global.item-added'), 600, "bottom", 'add-item-toast');

            }
            // this.navController.pop();
            // this.navController.setDirection('back');
            this.navigationService.popPage();
        }


    }

    totalQuantity(groupIndex: number): number {
        /* let count: number = 0;
        if (this.quantityMap) {
            for (let id in this.quantityMap) {
                count += this.quantityMap[id];
            }
        }
        return count; */

        return this.builder.getChoices(groupIndex);
    }


    isCompleted(): boolean {
        let completed = true;
        const groups: ItemGroup[] = this.builder.groups;

        // index 0 is main group, which must has an selected item
        for (let i = 0; i < this.groups.length; i++) {
            /*
            * Suppose now there are 5 groups and they are [A, B, C, D, E] and A is the main group,
            * this.groups is an array [B, C, D, E],
            * builder.groups is an array with groups which have selected itemGroupMap, says [A, B, C, (null), (null)]
            *
            * then we check this.groups one by one (except main group)
            * if this.groups[i] is not optional
            * builder.groups[i + 1] must has an selected item
            * if not, then it is incompleted
            */

            const realGroup: ItemGroup = this.groups[i];
            const builderGroup: ItemGroup = groups[i + 1];

            // console.log("realGroup" ,realGroup);
            // console.log("builderGroup", builderGroup);

            // if (((!realGroup.optional && !realGroup.modifier) || realGroup.min > 0)) {
            //     if (!builderGroup || !this.hasSelectedItems(i + 1) || this.totalQuantity(i + 1) < realGroup.min) {
            //         completed = false;
            //         break;
            //     }
            // }

            if (!realGroup.optional) {
                if (realGroup.min > 0) {
                    if (!builderGroup || !this.hasSelectedItems(i + 1) || this.totalQuantity(i + 1) < realGroup.min) {
                        completed = false;
                        break;
                    }
                }

                // else
                // {
                //     if (!builderGroup || !this.hasSelectedItems(i + 1) || this.totalQuantity(i + 1) < realGroup.min) {
                //         completed = false;
                //         break;
                //     }
                // }
            } else {

                if (realGroup.skippable === false) {
                    // for some extreme cases, itemgroup is optional but the min > 0
                    if (this.selectedNoNeed(i + 1)) {
                        // if they selected no need, then shoud pass


                    } else if (builderGroup) {
                        if (this.hasSelectedItems(i + 1)) {
                            if (this.totalQuantity(i + 1) < realGroup.min) {
                                completed = false;
                                break;
                            }
                        } else {
                            completed = false;
                            break;
                        }
                    } else {
                        completed = false;
                        break;
                    }

                } else {
                    if (this.selectedNoNeed(i + 1)) {
                        // if they selected no need, then shoud pass

                    } else if (builderGroup) {
                        if (this.hasSelectedItems(i + 1)) {
                            if (this.totalQuantity(i + 1) < realGroup.min) {
                                completed = false;
                                break;
                            }
                        } else {

                        }
                    } else {

                    }

                    // else if (!builderGroup && this.hasSelectedItems(i + 1)) {

                    //     if (this.totalQuantity(i + 1) < realGroup.min) {
                    //         completed = false;
                    //         break;
                    //     }
                    // }
                }

                // completed = this.isRequiredModifierSelect(i);

                // if (!completed) {
                //     break;
                // }

            }

            // else
            // {
            //     if (!builderGroup || !this.hasSelectedItems(i + 1) || this.totalQuantity(i + 1) < realGroup.min) {
            //         completed = false;
            //         break;
            //     }
            // }

            completed = this.isRequiredModifierSelect(i);

            if (!completed) {
                break;
            }
        }
        return completed;

    }

    async showNotCompletedAlert() {


        const subtitle = this.t.instant('pages.set-select.select-error');
        const confirm = this.t.instant('buttons.ok');

        const alert = await this.alertController.create({
            message: subtitle,
            buttons: [confirm]
        });
        alert.present();
    }

    hasSelectedItems(i: number): boolean {
        const itemGroup: ItemGroup = this.builder.groups[i];
        if (!itemGroup) {
            return false;
        }
        const selected = itemGroup.items;
        if (!selected) {
            return false;
        }

        return selected.filter(item => item.quantity > 0).length > 0;
    }

    getSelectedItemNames(i: number): string {
        // console.log("check get selected");
        const itemGroup: ItemGroup = this.builder.groups[i];
        // console.log("itemG", itemGroup);
        if (itemGroup === null) {
            return this.t.instant('pages.item-select.no-need');
            // return "不需要";
        }
        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }

        if (!itemGroup) {
            return '';
        }
        const selected = itemGroup.items;
        if (!selected) {
            return '';
        }
        return selected
            .filter(item => item.quantity > 0)
            .map(item => {
                let qtyPart = '';
                if (item.quantity > 1) {
                    qtyPart = ' x' + item.quantity;
                }
                return (item.name + qtyPart);
            })
            .join(',');
    }


    async presentModifierPopup(store: Store, orderItem: OrderItem, group: ItemGroup, itemMap: any, page: any, handler?: any, showLastOne?: boolean) {
        // let options = {showBackdrop: true, backdropDismiss: true, cssClass: 'modal-largescreen'};
        const input = {store: store, orderitem: orderItem, group: group, itemMap: itemMap};
        if (showLastOne) {
            input['showLastOne'] = showLastOne;
        }
        const modal = await this.modalController.create({
            component: page,
            componentProps: input,
            animated: true,
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'modal-largescreen modifier-modal'
        });
        modal.onDidDismiss().then((confirm) => {
            if (handler) {
                handler(confirm);
            }
        });
        modal.present();
    }

    areRequiredModifierSelect(groups: ItemGroup[], map?: any): boolean {
        let finalResult = true;
        for (const group of groups) {
            const selected = this.isRequiredModifierSelectByGroup(group);
            if (map) {
                map[group.id] = selected;
            }
            if (!selected) {
                finalResult = selected;
            }
        }

        return finalResult;

    }


    hasRequiredModifier(items: Item[], itemMap: any): boolean {
        return this.hasModifier(items, itemMap, true);
    }

    hasModifier(items: Item[], itemMap: any, checkRequired: boolean = false): boolean {
        let required = false;
        let isAvailableMod = false;

        for (let item of items) {
            var tmpItem = itemMap[item.id];
            
            //console.log("checking tmpItem", tmpItem);
            if (tmpItem && tmpItem.mgroups && tmpItem.mgroups.length > 0) {

                for(let mgroup of tmpItem.mgroups)
                {
                    for(let mItem of mgroup.items)
                    {  
                        if(mItem && mItem.name)
                        {
                            isAvailableMod = true;
                            break;
                        }
                    }
                }

                if (checkRequired) {
                    for (let mgroup of tmpItem.mgroups) {
                        if (mgroup.min && mgroup.min > 0) {
                            required = true;
                            break;
                        }
                    }
                } else {
                    required = true;
                    break;

                }

            }
        }
        // console.log("checking required", required);
        // console.log("checking isAvailableMod", isAvailableMod);
        return required && isAvailableMod;
    }

    isRequiredModifierSelectByGroup(group: ItemGroup): boolean {
        const index = this.groupIdMap[group.id] - 1;
        const tmp = this.isRequiredModifierSelect(index);
        return tmp;
    }

    isRequiredModifierSelect(index: number): boolean {
        const oi = this.currentOrderItem ? this.currentOrderItem : this.builder.build();
        this.currentOrderItem = oi;
        const tmpIndex = index + 1;
        const group = oi.groups[tmpIndex];
        if (group && this.hasRequiredModifier(group.items, this.itemMap)) {

            const builderItems: Item[] = group.items;
            const selected = !builderItems
                .some(item => {
                    const builderMgroups: ItemGroup[] = item.mgroups;
                    const mgroups: ItemGroup[] = this.itemMap[item.id].mgroups;

                    if (mgroups) {
                        for (let i = 0; i < mgroups.length; i++) {

                            if (builderMgroups && builderMgroups[i] && mgroups[i].min > 0) {
                                let quantity = 0;
                                for (const t of builderMgroups[i].items) {
                                    quantity += 1 * t.quantity;
                                }
                                return !(quantity >= mgroups[i].min);

                            } else if ((!builderMgroups || !builderMgroups[i])) {
                                return mgroups[i].min > 0;
                            }


                        }
                    } else {
                        return false;
                    }


                });
            return selected;

        } else {
            return true;
        }

    }

    getSelectedItemNamesModifier(i: number): string {
        // console.log("check get selected modifier");
        const itemGroup: ItemGroup = this.builder.groups[i];

        // if (itemGroup === null) {
        //     return this.t.instant("pages.item-select.no-need");
        //     // return "不需要";
        // }

        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }

        if (!itemGroup) {
            return '';
        }
        const selected = itemGroup.items;
        if (!selected) {
            return '';
        }

        // get modifier
        let firstItem = true;

        let output = '';
        for (const select of selected) {
            if (!select.mgroups) {
                continue;
            }

            for (const mgroup of select.mgroups) {
                if (!mgroup.items) {
                    continue;
                }

                for (const item of mgroup.items) {
                    if (firstItem) {
                        output = item.name + ' x ' + item.quantity;
                        firstItem = false;

                    } else {
                        output = output + ', ' + item.name + ' x ' + item.quantity;
                    }
                }

                // return output;
            }
        }

        return output;
    }

    getSelectedItemsModifierPrice(items: Item[]): number {
        let price = 0;

        let priceString = this.settings.showDefaultPrice ? 'price0' : 'price';
        for (const select of items) {
            if (!select.mgroups) {
                continue;
            }
            for (const mgroup of select.mgroups) {
                if (!mgroup.items) {
                    continue;
                }

                for (const item of mgroup.items) {
                    price = price + item[priceString] * item.quantity;
                }

            }
        }

        return price;

    }

    selectedNoNeed(i: number): boolean {

        const itemGroup: ItemGroup = this.builder.groups[i];
        if (itemGroup === null) {
            return true;
        }

        // if (typeof itemGroup === 'undefined') {
        //     return false;
        // }

        return false;
    }


    getSelectedItemsPrice(i: number): number {
        const itemGroup: ItemGroup = this.builder.groups[i];

        if (itemGroup === null) { // no need
            return 0;
        }

        // if (typeof itemGroup === 'undefined') {
        //     return "";
        // }

        if (!itemGroup) {
            return 0;
        }
        const selected = itemGroup.items;
        if (!selected) {
            return 0;
        }
        let price = 0;

        let priceString = this.settings.showDefaultPrice ? 'price0' : 'price';

        selected.forEach((item) => {
            price = price + item[priceString] * item.quantity;
        });

        if (this.isCurrentGroupHasModifier(itemGroup)) {
            price = price + this.getSelectedItemsModifierPrice(selected);

        }

        return Number(price.toString());
    }


    setMode() {
        this.groups.forEach((group, index) => {
            if (group.repeat) {
                this.modeMap.set(index + 1, 'quantity');
            } else {
                this.modeMap.set(index + 1, 'checkbox');
            }

        });

    }


    checkingGroupCompleted(index: number) {
        // if (isNaN(index)) return;
        if (isNaN(index) || (!index && !isNaN(index))) {
            return;
        }
        const groupId = this.groups[index - 1].id;
        const min = this.groups[index - 1].min;
        const max = this.groups[index - 1].max;
        const currentQty = this.totalQuantity(index);
        // if (min === max) {
        this.isGroupSelected[groupId] = currentQty > 0 || this.selectedNoNeed(index);
        this.isGroupCompleted[groupId] = currentQty === max || currentQty >= min || this.selectedNoNeed(index);

        const itemGroup: ItemGroup = this.builder.groups[index];


        if (this.groups[index - 1] && this.groups[index - 1].optional) {
            if (this.groups[index - 1].skippable) {
                this.isGroupCompleted[groupId] = true;
            } else {
                if (this.selectedNoNeed(index)) {
                    this.isGroupCompleted[groupId] = true;
                } else if (this.hasSelectedItems(index)) {
                    if (this.totalQuantity(index) < this.groups[index - 1].min) {
                        this.isGroupCompleted[groupId] = false;
                    } else {
                        this.isGroupCompleted[groupId] = true;
                    }
                } else {
                    this.isGroupCompleted[groupId] = false;
                }
            }
        }
        // console.log(groupId);
        // console.log(min);
        // console.log(max);
        // console.log(currentQty);
        // console.log(this.isGroupSelected[groupId]);
        // console.log(this.isGroupCompleted[groupId]);

        return this.isGroupCompleted[groupId] || this.selectedNoNeed(index);
    }

    handlingModifier(oi: OrderItem, realGroup: ItemGroup, itemMap, addHandler: any, notAddHandler: any, showLastOne: boolean = false) {
        if (!oi) {
            return;
        }
        let group = oi.groups.find((g) => g.id === realGroup.id);
        if (!oi) {
            return;
        }
        group = oi.groups.find((g) => g.id === realGroup.id);
        if (this.hasRequiredModifier(group.items, this.itemMap)) {
            this.presentModifierPopup(this.store, oi, group, itemMap,  ModifierDialog, (confirm) => {
                if (confirm && confirm['data']) {
                    addHandler();

                } else {
                    notAddHandler(group);

                }
            }, showLastOne);
        } else {
            addHandler();
        }
    }

    getImage(data: Data, name: string, width: number, crop: boolean): string {
        if (!data.images) return null;
        let im = data.images[name] as Image;
        if (!im) return null;

        let url = im.url;
        if (!url) return null;

        return this.getImageUrl_(url, width, crop);
    }

    getImageUrl_(url: string, width: number, crop: boolean): string {

        if (!url) return null;

        width = Math.floor(width);

        if (url.indexOf('googleuser') > 0 && width) {
            let suffix = '=s' + width;
            if (crop) {
                suffix += '-c';
            }
            url += suffix;
        } else if ((url.indexOf('content.aigens.com.cn') > 0 || url.indexOf('cdn.aigens.com.cn') > 0 || url.indexOf('cdn.aigens.cn') > 0) && width) {
            let suffix = '?x-oss-process=image/resize';
            if (crop) {
                suffix += ',m_fill';
            }
            url += suffix + ',w_' + width;
        }


        if (url.indexOf('http://') === 0 && url.indexOf('localhost') === -1) {
            url = url.replace('http:', 'https:');
        }

        if (Data.imageHost) {
            let replace = 'lh3.googleusercontent.com';
            let i = url.indexOf(replace);
            if (i > 0) {
                let path = url.substring(i + replace.length, url.length);
                url = Data.imageHost + path;
            }
        }

        return url;
    }

}




