<!--
  Generated template for the ProgressBar component.

  See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
  for more info on Angular 2 Components.
-->

<ion-content>

    <ng-container *ngIf="stage == 1">
        <div class="animation-container stage-one">

            <!-- <div class="btn-mute">
                <span *ngIf="soundService.isMute" class="icon-info imgIcon" (click)="soundClicked()">
                    <button ion-button icon-only>
                        <ion-icon name="volume-off" color="dark"></ion-icon>
                    </button>
                </span>

                <span *ngIf="!soundService.isMute" class="icon-info imgIcon" (click)="soundClicked()">
                    <button ion-button icon-only>
                        <ion-icon name="volume-up" color="dark"></ion-icon>
                    </button>
                </span>
            </div> -->

            <!-- <div class="snow-fall"></div> -->
            <div class="new-img-box ray-expand">

            </div>




            <div class="new-img-box" [ngClass]="{'target': !shoot, 'target-bigger': shoot}">
                <img src="assets/images/pizzahut/image_target.png">
            </div>


            <div (click)="shootClicked()" [ngClass]="{'lower-group-out': !shoot, 'flyout': shoot}">
                <div class="lower-group-in" [ngClass]="{'shake': !shoot}">
                    <div class="new-img-box arrow">
                        <img src="assets/images/pizzahut/image_arrow.png">
                    </div>

                    <div class="new-img-box bullet">
                        <img src="assets/images/pizzahut/image_bullet.png">
                    </div>
                </div>
            </div>

            <!-- touch and shoot -->
            <div class="new-img-box text">
                <img src="assets/images/pizzahut/image_text.png">
            </div>

            <!-- light-background -->
            <div class="new-img-box" [ngClass]="{'light-bg': isFadeOut}">

            </div>


        </div>
    </ng-container>

















    <ng-container *ngIf="stage == 2">
        <!-- <div class="top-container" (click)="$event.stopPropagation();startClicked()" [ngStyle]="{'width.px': screenWidth - 32 , 'height.px':screenHeight - 32}">
            <img class="ad" src=""/>
        </div> -->

        <div class="animation-container stage-two">

                <!-- <div class="btn-mute">
                        <span *ngIf="soundService.isMute" class="icon-info imgIcon" (click)="soundClicked()">
                            <button ion-button icon-only>
                                <ion-icon name="volume-off" color="dark"></ion-icon>
                            </button>
                        </span>
        
                        <span *ngIf="!soundService.isMute" class="icon-info imgIcon" (click)="soundClicked()">
                            <button ion-button icon-only>
                                <ion-icon name="volume-up" color="dark"></ion-icon>
                            </button>
                        </span>
                    </div> -->


            <div class="text-congrats-container">
                <div class="new-img-box text-congrats">
                    <img src="assets/images/pizzahut/text_congrats.png" class="bounceIn animated delay-05">
                </div>
            </div>


            <div class="lower-part">
                <div class="detail-box">
                    <div class="new-img-box img-food">
                        <img src="{{offer?.reward?.getImage('default', 200)}}">
                        <!-- <img src="assets/images/pizzahut/img_food.jpg"> -->
                    </div>

                    <p class="text-food-desc">{{offer?.reward?.name}}</p>
                    <!-- <p class="text-food-desc">Food Description Chicken Wings x4</p> -->

                    <button type="button" class="btn-view-gift" (click)="dismissClicked('view')">View Gift</button>
                </div>
            </div>

        </div>

    </ng-container>



    <!--   <ng-container *ngIf="stage == 3">
        <div class="top-container" (click)="$event.stopPropagation();startClicked()" [ngStyle]="{'width.px': screenWidth - 32 , 'height.px':screenHeight - 32}">
            <img class="ad" src=""/>
        </div>
    </ng-container>

    <ng-container *ngIf="stage == 4">
        <div class="top-container" (click)="$event.stopPropagation();startClicked()" [ngStyle]="{'width.px': screenWidth - 32 , 'height.px':screenHeight - 32}">
            <img class="ad" src=""/>
        </div>
    </ng-container> -->

    <button class="close-btn" ion-button icon-only (click)="$event.stopPropagation();dismissClicked('leave')">
        <ion-icon name="ios-close" color="dark"></ion-icon>
    </button>

</ion-content>
