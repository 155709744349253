import { Component, OnInit, Injector } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import {NavParams} from '@ionic/angular';

@Component({
  selector: 'app-select-store-filter',
  templateUrl: './select-store-filter.component.html',
  styleUrls: ['./select-store-filter.component.scss'],
})
export class SelectStoreFilterComponent extends MobileBasePage implements OnInit {

  filterGroups=[];
  inputs:any =[];
  keywords = [];

  constructor(private injector: Injector,public navParams: NavParams) {
    super(injector);
    this.filterGroups = this.navParams.get('filterGroups');
    console.log('ServiceChoiceComponent constructor()', this.filterGroups);
   }

  ngOnInit() {
    console.log("ngOnInit");
    
    this.filterGroups.forEach((group)=>{
      group.subs&& group.subs.map((sub)=>{
        if(sub.checked)
          this.keywords.push(sub.name);
      })
    });
    console.log("init keywords");
    
  }

  dismiss(){
    this.modalController.dismiss({keywords:this.keywords});
  }

  addKeywords(ev,list){
    console.log("addKeywords",ev , list);
    let checked = ev.detail.checked;
    let val = ev.detail.value;
    if(checked){
      this.keywords.push(val);
    }else{
      this.keywords = this.keywords.filter((el)=>val!=el);
    }

    console.log("keywords",this.keywords);

  }

}
