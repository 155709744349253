<!-- <ion-header [ngClass]="{'address-dialog-modal': !isRoute, 'address-dialog-route': isRoute}">
  <ion-header> -->
<div class="toolbar-container">
  <div class="close-btn" >
    <ion-icon src="assets/images/v3_close.svg" (click)="closeClicked()"></ion-icon>
  </div>
  <div *ngIf="canLogin()" class="login-reg-btn">
    <ion-button (click)="goToLogin()" slot="end">
      {{"pages.store-list.login-register" | translate}}
    </ion-button>
  </div>

  <!-- <ion-title>{{'dialog.address-dialog.title'|translate}}</ion-title> -->
</div>


<h2 class="hi-name">
  <!-- {{"pages.store-list.hello" | translate}} {{displayName}}, -->
 {{"pages.store-list.greetings" | translate}}<span>{{displayName}}</span>
</h2>
<ion-content [ngClass]="{'address-dialog-modal': !isRoute, 'address-dialog-route': isRoute}">

  <ion-segment *ngIf="showModeSelect" (ionChange)="segmentChanged($event)" [(ngModel)]="segmentOption"
    class="status-segment-box">
    <ion-segment-button value="pickup" *ngIf="orderManager.mode === 'takeaway'">
      {{"pages.store-list.pickup" | translate}}
    </ion-segment-button>
    <ion-segment-button *ngIf="orderManager.mode === 'delivery'"  value="delivery">
      {{"pages.store-list.delivery" | translate}}
    </ion-segment-button>
  
  </ion-segment>
  <ng-container *ngIf="segmentOption === 'delivery'; else elseTemplate">
    <!-- 配送地址 -->
    <div class="content-container">

      <div class="title-container">
        <div class="title-address">
          <div class="title-image">
            <img src="assets/images/motorcycle.svg">
          </div>
          <span>{{'dialog.address-dialog.title'|translate}}</span>
        </div>
        <div class="remove-btn" (click)="deleteHandle()"
          *ngIf="addressArr.length > 0 && currentAddressId && !pickupAddressId">
          <p>{{'dialog.address-dialog.delete'|translate}}</p>
        </div>
      </div>

      <!-- when add-handle show  and the radio only select the one -->
      <ion-list>
        <!-- allow-empty-selection  (ionChange)="selectAddressChange($event)" -->
        <ion-radio-group [(ngModel)]="currentAddressId" (click)="setPickupAddressIdNull()"
          *ngIf="addressArr.length > 0">

          <div class="dialog-address ">
            <ion-item lines="none" class="address-too-far"
              *ngFor="let address of addressArr;let i = index; trackBy:trackAddressById">
              <div class="address-click-btn">
                <ion-radio slot="start" [value]="address?.id" mode="md"></ion-radio>
              </div>
              <div class="showed-address-wrapper">
                <p class="address-name" *ngIf="address?.name">{{address?.name}}</p>
                <p class="address-full" *ngIf="address?.full">{{address?.full}}</p>
                <!-- <p class="address-phone" *ngIf="address?.phone">{{'dialog.address-dialog.label-mobile'|translate}}: {{address?.phone}}</p> -->
                <div *ngIf="address['beyond']" class="too-far-row">
                  <div class="too-far-icon">
                    <ion-icon name="md-alert"></ion-icon>
                  </div>
                  <p>Delivery service <b>DOES NOT</b> cover this location.</p>
                </div>
              </div>
              <!-- <div>
                <ion-icon name="trash" *ngIf="isRemove" (click)="removeClicked(address);$event.stopPropagation;"></ion-icon>
              </div> -->
            </ion-item>

          </div>
        </ion-radio-group>
        <!-- add new address box -->
        <div class="add-address-column"  [ngClass]="{'animated flashit': isAddressNull()}"  (click)="addNewAdress();$event.stopPropagation;">
          <div class="add-address-btn">
            <ion-icon name="ios-add"></ion-icon>
          </div>
          <div class="add-address-text">
            <p>{{'dialog.address-dialog.add-new-address'|translate}}</p>
          </div>
        </div>


      </ion-list>

      <!-- when remove-handle show  and the radio can select one or more-->
      <!-- <ion-list *ngIf="addressArr.length > 0 && isRemove">
        <ion-item *ngFor="let address of addressArr;let i = index; trackBy:trackAddressById" (click)="removeAddressArrChange(i);$event.stopPropagation;">
          <div class="address-remove-checkbox">
            <ion-checkbox color="primary"></ion-checkbox>
          </div>
          <div class="showed-address-wrapper">
            <p class="address-name">{{address.name}}</p>
            <p class="address-full">{{address.full}}</p>
            <p class="address-phone">{{'dialog.address-dialog.label-mobile'|translate}}: {{address.phone}}</p>
          </div>
        </ion-item>
      
      </ion-list> -->
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <!-- pickup at address -->
    <div class="content-container" *ngIf="pickupAddressGroups.length > 0">

      <div class="title-container">
        <div class="title-address">
          <div class="title-image pickup">
            <img src="assets/images/pickup.svg">
          </div>
          <span>{{'dialog.address-dialog.pick-up-title'|translate}}</span>
        </div>
      </div>

      <!-- pick up item -->
      <ion-list *ngIf="pickupAddressGroups.length > 0">
        <!-- <ion-radio-group [(ngModel)]="pickupAddress" (ionChange)="pickUpClick($event);$event.stopPropagation;"> -->
        <ion-radio-group [(ngModel)]="pickupAddressId" (click)="setCurrentAddressIdNull()">
          <ion-item class="address-too-far" lines="none"
            *ngFor="let address of pickupAddressGroups;let i = index; trackBy:trackAddressById">
            <div class="address-click-btn">
              <ion-radio slot="start" [value]="address?.id" mode="md"></ion-radio>
            </div>
            <div class="showed-address-wrapper">
              <p class="address-name" *ngIf="address?.name">{{address?.name}}</p>
              <p class="address-full" *ngIf="address?.full">{{address?.full}}</p>
              <p class="address-phone" *ngIf="address?.phone">{{'dialog.address-dialog.label-mobile'|translate}}:
                {{address?.phone}}</p>
            </div>
          </ion-item>
        </ion-radio-group>

      </ion-list>
    </div>
  </ng-template>




</ion-content>

<ion-footer [ngClass]="{'address-dialog-modal': !isRoute, 'addresIds-dialog-route': isRoute}">

  <!-- confirm -->
  <div class="btn-box" *ngIf="currentAddressId || pickupAddressId">
    <ion-button expand="block" class="confirm-btn key-btn-box AQA-key-button ai-anime headShake" (click)="okClicked()"
      [disabled]="currentAddressId && checkComfirmAvai()">
      {{'dialog.address-dialog.confirm'|translate}}
    </ion-button>
  </div>
  <!-- remove -->
  <!-- <div class="btn-box" *ngIf="isRemove">
    <ion-button expand="block" class="confirm-btn key-btn-box AQA-key-button" (click)="removeClicked()">
      {{'dialog.address-dialog.remove'|translate}}
    </ion-button>
  </div> -->

</ion-footer>