<ion-header>
    <div class="list-header">
        <!--<span>{{title}}</span>-->
        <ion-button fill="clear" (click)="dismissClicked()">
            <ion-icon slot="icon-only" name="md-close" color="dark"></ion-icon>
        </ion-button>
    </div>
    <div class="tag-bar-wrapper">
        <div class="tag-bar">
            <div *ngFor="let item of modifiableItems;let i = index" (click)="selectItem(item,i)"
                [class.active]="i == currentIndex" tappable>{{item.name}} x{{itemsCount[item.id]}}</div>
        </div>
    </div>
</ion-header>

<ion-content class="content animated fadeInUp" (click)="dismissClicked()">

    <div class="content-box" (click)="$event.stopPropagation()" id="content-top">
        <ion-list class="couponBS-wrapper">

            <div class="item-content-wrapper animated fadeInRights">
                <ng-container *ngFor="let mgroup of currentItem.mgroups">

                    <ng-container *ngIf="mgroup.items && mgroup.items.length > 0">


                        <div class="item-content" [attr.data-mg-min]="mgroup.min" [attr.data-mg-max]="mgroup.max">
                            <p class="ig-title" *ngIf="!settings?.hideModIgName">{{mgroup.name}}</p>
                            <div class="ig-modifier-request-box" *ngIf="getSelectRules(mgroup)"
                                [ngClass]="{'modifier-selected AQA-mod-completed': isGroupSelected(currentItem, mgroup), 'AQA-mod-not-completed': !isGroupSelected(currentItem, mgroup) }">
                                <ion-icon name="checkmark" color="medium" class="checkmark-icn"></ion-icon>
                                <p class="ig-modifier-request">{{getSelectRules(mgroup)}}</p>
                            </div>
                            <div class="modifier-item-wrapper">
                                <ion-card lines="none" class="modifier-item-box" *ngFor="let item of mgroup.items"
                                    tappable (click)="itemClicked(currentItem, mgroup, item)"
                                    [hidden]="absentItemsId.indexOf(item.id) != -1" text-wrap>

                                    <!-- <div class="sold-out-box" (click)="$event.stopPropagation()" *ngIf="item.min == 0 && item.max == 0">
                                <p>{{"pages.item-grid.soldout" | translate}} </p>
                            </div> -->
                                    <!-- without quantity -->
                                    <ng-container *ngIf="item.baseQty <= 0 && !mgroup.repeat">
                                        <div slot="start" class="left-btn-box">
                                            <ion-icon
                                                name="{{has(currentItem, mgroup, item) ? 'checkmark-circle' : 'radio-button-off'}}"
                                                color="primary"></ion-icon>
                                            <div>
                                                <p class="itemPrice-text">{{item.name}} <ng-container
                                                        *ngIf="item.price != 0">
                                                        +{{orderManager?.store?.currencySign}}{{item.price}}
                                                    </ng-container>
                                                </p>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- with quantity -->
                                    <ng-container *ngIf="item.baseQty > 0 || mgroup.repeat">
                                        <ion-grid>
                                            <ion-row class="select-panel">
                                                <ion-col>
                                                    <p [ngClass]="{'noPrice': item.price == 0}">{{item.name}}</p>
                                                </ion-col>
                                                <ion-col *ngIf="item.price != 0">
                                                    <p class="itemPrice-text">
                                                        +{{orderManager?.store?.currencySign}}{{item.price}}</p>
                                                </ion-col>
                                            </ion-row>
                                            <ion-row class="btn-row"
                                                [ngClass]="{'btn-added': has(currentItem, mgroup, item)}">
                                                <ion-col>
                                                    <ion-button fill="clear" size="small"
                                                        (click)="minusClicked(currentItem, mgroup, item);$event.stopPropagation();"
                                                        class="qty-btn remove-qty-btn">
                                                        <ion-icon slot="icon-only" name="md-remove-circle-outline"
                                                            *ngIf="item.min != item.max && item.max != 0"
                                                            color="medium"></ion-icon>
                                                    </ion-button>
                                                </ion-col>
                                                <ion-col>
                                                    <p class="qty-text">
                                                        {{builder.getQuantity(currentItem,mgroup, item) || 0}}</p>
                                                </ion-col>
                                                <ion-col>
                                                    <ion-button fill="clear" size="small" class="qty-btn add-qty-btn">
                                                        <!-- how to get current item qty??? -->
                                                        <ion-icon slot="icon-only" name="md-add-circle-outline"
                                                            color="{{ (builder.getQuantity(currentItem,mgroup, item) >= item.max)?'medium':'primary'}}">
                                                        </ion-icon>
                                                    </ion-button>
                                                </ion-col>
                                            </ion-row>
                                        </ion-grid>
                                    </ng-container>
                                </ion-card>
                            </div>
                        </div>

                    </ng-container>

                </ng-container>
            </div>

        </ion-list>

    </div>

</ion-content>
<ion-footer>
    <div class="btn-box animated fadeInUps">
        <ion-button class="confirm-btn key-btn-box AQA-key-button" expand="block" color="primary"
            *ngIf="isAllCompleted(modifiableItems)" (click)="confirmClicked()">
            {{"pages.variation.confirm" | translate}}
        </ion-button>
    </div>
</ion-footer>
