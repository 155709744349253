import { Injectable } from '@angular/core';
import * as Bowser from 'bowser';

@Injectable({ providedIn: 'root' })
export class BrowserService {

    browser: any;
    userAgent: string;
    name: string;
    version: string | number;
    mobile: boolean;
    tablet: boolean;
    ios: boolean;
    android: boolean;

    supported: string[];

    constructor() {

        this.browser = Bowser;

        console.log(Bowser);

        if (window) {
            this.userAgent = window.navigator.userAgent;
        }

        this.name = this.getBrowserName();
        this.version = Bowser.version;
        this.mobile = Bowser.mobile;
        this.tablet = Bowser.tablet;
        this.android = Bowser.android;
        this.ios = Bowser.ios;

    }

    isSupported() {

        if (!this.isNgSupported()) {
            return false;
        }

        // if not set, everything is supported
        if (!this.supported) {
            return true;
        }


    }

    isNgSupported(): boolean {

        if (!this.browser.check({ msie: '9' })) {
            return false;
        }

        if (!this.browser.check({ msedge: '13' })) {
            return false;
        }

        return true;
    }

    isFastNgSupported(): boolean {

        if (!this.browser.check({ msie: '100' })) {
            return false;
        }

        if (!this.browser.check({ msedge: '15' })) {
            return false;
        }

        return true;
    }

    getBrowserName(): string {
        let result = Bowser.name;

        if (this.isWeChat()) {
            result = 'WeChat';
        } else if (this.isFacebook()) {
            result = 'Facebook';
        }

        return result;
    }

    isWeChat(): boolean {
        return this.userAgent.toLowerCase().indexOf('micromessenger') !== -1 && window['__wxjs_environment'] !== 'miniprogram';
    }

    isWeChatMiniProgramWebview(): boolean {
        return window['__wxjs_environment'] === 'miniprogram';
    }

    isWeChatHK(): boolean {
        return this.userAgent.toLowerCase().indexOf('micromessenger') !== -1 && window['__wxjs_environment'] !== 'miniprogram';
    }

    // cn alipay browser open
    isAlipay(): boolean {
        return this.userAgent.toLowerCase().indexOf('alipayclient') !== -1 && !this.isAlipayHK();
    }

    // HK alipay browser open
    isAlipayHK(): boolean {
        return this.userAgent.toLowerCase().indexOf('alipayclienthk') !== -1;
    }

    isAliMiniApp(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (window['my']) {

                window['my'].getEnv((res) => {
                    console.log('window[\'my\'].getEnv', res);
                    resolve(res.miniprogram || res.miniProgram);
                });
            } else {
                resolve(false);
            }
        });


    }

    isFacebook(): boolean {
        const ua = this.userAgent;
        return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
        // return "Facebook" === Bowser.version;
    }

    isBKApp(): boolean {
        const ua = this.userAgent;
        return (ua.indexOf('bkapp') !== -1);
    }

    isChrome(): boolean {
        return this.browser.name === 'Chrome';
    }

    isSupportGooglePay(): boolean {
        return this.isChrome() && Number(this.browser.version) >= 61;
    }

    isSupportNativeGooglePay(): boolean {
        return (window['GooglePayService']);
    }

    isInApp() {
        // return this.isWeChat() || this.isFacebook() || this.isBKApp();
        return this.isBKApp();
    }


}
