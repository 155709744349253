<ion-header>
    <div tappable class="dismiss-btn" (click)="dismisshandle()">
        <ion-icon name="md-close"></ion-icon>
    </div>
</ion-header>
<ion-content>
    <!-- <span expand="block"  (click)="dismiss(true)">
        X</span> -->
    <!-- <ion-icon class="close-btn" name="close" (click)="dismiss()"> </ion-icon> -->
    <div class="iframe-box">
        <iframe id="iframe" style="height:100%;width:100%" frameborder="0"></iframe>
    </div>
</ion-content>
<ion-footer *ngIf="!hideFooter">
    <ion-item *ngIf="needToAgreeMarketing" no-lines >
        <ion-checkbox color="primary" [(ngModel)]="marketing"></ion-checkbox>
        <ion-label class="check-box-label">{{"pages.terms-conditions.agree-text"|translate : input}}</ion-label>
    </ion-item>
    <div text-center class="btn-box" *ngIf="!isFAQ">
        <ion-button expand="block"  (click)="dismiss(true)">
            {{"pages.terms-conditions.confirm" | translate }}</ion-button>
    </div>
</ion-footer>