var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ConfigService } from '../services/config.service';
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, IonRouterOutlet, LoadingController, ModalController, NavController, PickerController, Platform, PopoverController, ToastController, MenuController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import { Location } from '@angular/common';
import { LoadingService } from '../services/loading.service';
// import { ToastController } from 'ionic-angular/components/toast/toast';
var BasePage = /** @class */ (function () {
    function BasePage(injector) {
        this.lastLoadingStatus = false;
        this.active = false;
        this.MAIN_MENU = "mainMenu";
        this.route = injector.get(ActivatedRoute);
        this.loadingController = injector.get(LoadingController);
        this.alertController = injector.get(AlertController);
        this.toastController = injector.get(ToastController);
        this.modalController = injector.get(ModalController);
        this.pickerController = injector.get(PickerController);
        this.actionSheetCtrl = injector.get(ActionSheetController);
        this.popoverController = injector.get(PopoverController);
        this.navController = injector.get(NavController);
        this.configService = injector.get(ConfigService);
        this.menuController = injector.get(MenuController);
        this.loadingService = injector.get(LoadingService);
        this.platform = injector.get(Platform);
        this.router = injector.get(Router);
        this.navParams = this.router.getCurrentNavigation();
        this.cb = this.getNavParams('callback');
        this.location = injector.get(Location);
        this.navigationService = injector.get(NavigationService);
    }
    BasePage.prototype.toggleMenu = function () {
        console.log("toggleMenu");
        this.menuController.toggle(this.MAIN_MENU).then(function (rsp) {
        });
    };
    // method to handle pull to refresh, use like this: <ion-refresher (ionRefresh)="baseRefresh($event)">
    BasePage.prototype.baseRefresh = function (refresher) {
        console.log('Begin async operation', refresher);
        this.refresher = refresher;
        this.refresh();
    };
    // child should overwrite this method if refresh
    BasePage.prototype.refresh = function () {
    };
    // TODO: the loading may not be dismissed since the creation is async
    BasePage.prototype.loading = function (show, message) {
        if (message === void 0) { message = null; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // console.log('loading, show', show);
                this.loadingService.loading(show, message, this.refresher);
                return [2 /*return*/];
            });
        });
    };
    BasePage.prototype.makeLoader = function (message) {
        if (message === void 0) { message = null; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(message == null || message.length === 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadingController.create({})];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.loadingController.create({
                            message: message
                        })];
                    case 3: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BasePage.prototype.showAlert = function (title, message, buttons, dismissCallBack) {
        if (buttons === void 0) { buttons = ['OK']; }
        return __awaiter(this, void 0, void 0, function () {
            var alert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            header: title,
                            message: message,
                            buttons: buttons
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        alert.onDidDismiss().then(function (v) {
                            if (dismissCallBack) {
                                dismissCallBack(v);
                            }
                        });
                        return [2 /*return*/, alert];
                }
            });
        });
    };
    BasePage.prototype.showToast = function (message) {
        return __awaiter(this, void 0, void 0, function () {
            var toast;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: message,
                            duration: 2000,
                            position: 'top'
                        })];
                    case 1:
                        toast = _a.sent();
                        // toast.onDidDismiss(() => {
                        //         //     console.log('Dismissed toast');
                        //         // });
                        toast.present();
                        return [2 /*return*/, toast];
                }
            });
        });
    };
    BasePage.prototype.showError = function (err) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log(err);
                this.loading(false);
                if (err.status === 0) {
                    return [2 /*return*/, this.showAlert(err.status, 'Network Error')];
                }
                else if (err.status === 401) {
                    return [2 /*return*/, this.showAlert(err.status, err['error'])];
                }
                else {
                    return [2 /*return*/, this.showAlert(err.status, err['error'])];
                }
                return [2 /*return*/];
            });
        });
    };
    BasePage.prototype.showPopoverController = function (component, componentProps, event, options) {
        if (options === void 0) { options = { backdropDismiss: true, showBackdrop: true }; }
        return __awaiter(this, void 0, void 0, function () {
            var popover;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.popoverController.create(__assign({ component: component,
                            componentProps: componentProps,
                            event: event, translucent: true, animated: true }, options))];
                    case 1:
                        popover = _a.sent();
                        return [4 /*yield*/, popover.present()];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    BasePage.prototype.isCalledIn = function (milliseconds) {
        var currentTimeStamp = new Date().getTime();
        var result;
        if (!this.lastTimeStamp) {
            this.lastTimeStamp = currentTimeStamp;
            return false;
        }
        result = currentTimeStamp - this.lastTimeStamp < milliseconds;
        this.lastTimeStamp = currentTimeStamp;
        return result;
    };
    /*
    private startTimer() {
        if (!this.timer) {
            console.log('start timer');
            this.timer = setInterval(() => {

                this.onRefreshUI();

            }, this.getUIRefreshInterval());
        }
    }

    private stopTimer() {
        console.log('stop timer');
        clearInterval(this.timer);
        this.timer = null;
    }
    */
    BasePage.prototype.ionViewWillEnter = function () {
        var interval = this.getUIRefreshInterval();
        if (interval > 0) {
            this.startTimer();
        }
        this.active = true;
    };
    BasePage.prototype.ionViewWillLeave = function () {
        var interval = this.getUIRefreshInterval();
        if (interval > 0) {
            this.stopTimer();
        }
        this.active = false;
    };
    BasePage.prototype.ngOnDestroy = function () {
        this.handleCallback(null);
    };
    BasePage.prototype.handleCallback = function (result) {
        if (this.cb) {
            var cb = this.cb;
            this.cb = null;
            cb(result);
        }
    };
    // TODO: see if can maintain state when refersh
    /*

    registerBrowserState() {
        console.log('registered back state');
        // history.pushState({page: 'Generic'}, null, '');
    }*/
    /**
     *  workaround: relative route seems not work properly after the first navigation
     *  this function will check if the request is relative routing by checking the extras['relativeTo']
     *  if yes, it will convert the relative path to absolute path by prefixing window.location.pathname
     *
     * */
    // replace for navController.push
    BasePage.prototype.push = function (page, params, extras) {
        if (params === void 0) { params = {}; }
        if (extras === void 0) { extras = {}; }
        return this.navigationService.push(page, params, extras);
    };
    BasePage.prototype.pushByName = function (pageName, params, extras) {
        if (params === void 0) { params = {}; }
        if (extras === void 0) { extras = {}; }
        return this.navigationService.pushByName(pageName, params, extras);
    };
    BasePage.prototype.pushByUrl = function (pageUrl, params, extras) {
        return this.navigationService.pushByUrl(pageUrl, params, extras);
        /*console.log('push by url', pageUrl);
        console.log('params', params);
        console.log('extras', extras);
        let temp = {};
        if (!this.navigationService.getRootPageUrl() || this.navigationService.getRootPageUrl() === '') {
            this.navigationService.configRootUrl();
        }
        if (extras) {
            extras['state'] = params;
            // will this be true if extras.relativeTo = this.route.root?
            if (extras.relativeTo === this.route) {
                pageUrl = window.location.pathname + '/' + pageUrl;
            } else if (!extras.relativeTo) {
                pageUrl = this.navigationService.getRootPageUrl() + '/' + pageUrl;
            }
            temp = extras;
        } else {
            pageUrl = this.navigationService.getRootPageUrl() + '/' + pageUrl;
            temp['state'] = params;
        }
        // temp['skipLocationChange'] = false;
        // temp['replaceUrl'] = false;
        this.navigationService.setNavigationParams(params);
        console.log('push to absolute url', pageUrl);
        this.navigationService.backByLocation = extras && (extras.skipLocationChange);
        if (this.navigationService.backByLocation) {
            window.history.pushState({}, 'Order.Place', window.location.href);
        }
        this.navigationService.lastParams = params;
        return this.navController.navigateForward([pageUrl], temp);*/
    };
    /*getRootUrl(): string {
        return this.navigationService.getRootPageUrl();
    }*/
    /*setRootUrl(newUrl, force: boolean = false) {
        if (!this.navigationService.getRootPageUrl() || force) {
            console.log(`rootUrl updated from ${this.navigationService.getRootPageUrl()} to ${newUrl}`);
            // BasePage.rootUrl = newUrl;
            // this.navigationService.setRootPage(newUrl);
        } else {
            console.log('try to update rootUrl', newUrl);
        }
    }*/
    // TODO: this removed the stack in html but cannot remove the history
    BasePage.prototype.popToRoot = function (params) {
        return this.navController.navigateRoot([this.navigationService.getRootPageUrl()]);
    };
    // TODO: implement the function
    BasePage.prototype.setRoot = function (page, params, attributes) {
        return this.router.navigate([page.getPageUrl()], { state: params });
    };
    BasePage.prototype.getNavParams = function (key) {
        var navigation = this.router.getCurrentNavigation();
        if (!navigation || !navigation.extras || !navigation.extras.state || !navigation.extras.state[key]) {
            // check route params;
            /**
             *  Please make sure your component is never reused, ie. change from one item to another without destroy the original one.
             *  Otherwise use the code below:
             *  this.route.paramMap.pipe(
             *  switchMap((params: ParamMap) =>
             *      params.get('id')
             *  );
             */
            if (this.route.snapshot.paramMap.has(key)) {
                return this.route.snapshot.paramMap.get(key);
            }
            else if (this.route.snapshot.data[key]) {
                return this.route.snapshot.data[key];
            }
            else {
                // check search params?
                var params = (new URL(window.location.href)).searchParams;
                if (params.get(key)) {
                    return params.get(key);
                }
                else {
                    return;
                }
            }
        }
        return navigation.extras.state[key];
    };
    BasePage.prototype.ngOnInit = function () {
    };
    BasePage.prototype.getUIRefreshInterval = function () {
        return 0;
    };
    BasePage.prototype.onRefreshUI = function () {
        console.log('refresh ui', new Date());
    };
    BasePage.prototype.startTimer = function () {
        var _this = this;
        console.log('start timeout');
        this.timer = setTimeout(function () {
            _this.onRefreshUI();
            clearTimeout(_this.timer);
            _this.startTimer();
        }, this.getUIRefreshInterval());
    };
    BasePage.prototype.stopTimer = function () {
        console.log('stop timeout');
        // clearInterval(this.timer);
        clearTimeout(this.timer);
        this.timer = null;
    };
    BasePage.rootUrl = '';
    return BasePage;
}());
export { BasePage };
