<!-- guest login and member login/register -->
<ng-container *ngIf="isLoginWaysSelecting">
    <ion-content [ngClass]="{'isModalPopup': true}" class="content-signup-or-acc" (click)="dismissClicked()">
        <div class="signup-acc-height">
            <div class="div-signup-or-acc">
                <div class="div-login-signup" (click)="changeToLoginAndRegister();$event.stopPropagation();">
                    <ion-icon class="icon-login-signup" src="assets/images/signup_login.svg"></ion-icon>
                    <h4>{{"dialog.guest-login.login-or-regsiter" | translate}}</h4>
                    <div class="icon-arrow-rig">
                        <ion-icon mode="md" name="arrow-round-forward"></ion-icon>
                    </div>
                    <!-- <ion-icon name="arrow-round-forward" class="icon-arrow-rig"></ion-icon> -->
                    <!-- <ion-icon name="arrow-dropright" ></ion-icon> -->
                </div>

                <div class="div-cont-guest" (click)="guestclick();$event.stopPropagation();">
                    <h4>{{"dialog.guest-login.continue-as-guest" | translate}}</h4>
                </div>
            </div>
        </div>



        <!-- <ion-button color="primary" (click)="guestclick()">{{"dialog.guest-login.continue-as-guest" | translate}}</ion-button>
        <ion-button (click)="changeToLoginAndRegister()">{{"dialog.guest-login.login-or-regsiter" | translate}}</ion-button> -->

    </ion-content>
</ng-container>

<!-- google and email login and 账号填写 ui -->
<ng-container>

    <div class="signup-login-header" [hidden]="isLoginWaysSelecting || (isEnterPWUi || showRegisterFlowUi)">
        <h3>{{"pages.login.signup-or-login" | translate}}</h3>
        <ion-button (click)="dismissClicked()">
            <ion-icon slot="icon-only" name="md-close"></ion-icon>
        </ion-button>
    </div>


    <ion-content [ngClass]="{'isModalPopup': false}"
        [hidden]="isLoginWaysSelecting || (isEnterPWUi || showRegisterFlowUi)">

        <div *ngIf="facebookLogin || googleLogin" class="social-login-btn-wrapper">
            <div class="social-login-btn-box">

                <!-- <button *ngIf="facebookLogin" class="social-login-btn" (click)="onFacebookLoginClick()">
                    <img src="../assets/images/pizzahut/facebook.svg">
                </button> -->
                
                <div class="fb-btn-bg-height">
                    <div *ngIf="facebookLogin" data-onlogin="checkFBLoginState()" class="fb-login-button" data-size="large" data-button-type="login_with" data-layout="default" data-auto-logout-link="true" data-use-continue-as="true" data-width="240px"></div>
                </div>
     

                <!---->
                <div *ngIf="googleLogin" class="social-login-btn" id="my-signin2" (click)="clickGoogleLogin()"
                    style="height: 50px;">
                </div>
            </div>
        </div>

        <div *ngIf="facebookLogin || googleLogin" class="tnc-text">
            <span>{{"pages.login.upon-logging-in-3rd" | translate}}</span> <span class="tnc-link"
                (click)="justShowTC()">{{"pages.login.loginTC" | translate}}.</span>

        </div>
        <!-- end of social login box -->

        <div *ngIf="facebookLogin || googleLogin" class="div-or">
            <span class="or-line-left"></span>
            <h5>OR</h5><span class="or-line-right"></span>
        </div>

        <!-- <p class="page-title">{{"pages.login.title" | translate}}</p> -->
        <div class="order-place-login">

            <div class="login-header">
                <div class="img-box">
                    <img src="assets/images/opLogo_wh.png">
                </div>
                <p class="page-title">{{"pages.login.order-place-login" | translate}}</p>
            </div>

            <div class="cus-segment-wrapper">
                <ion-button fill="clear" disable-hover (click)="selectMode('email')"
                    [ngClass]="{'isActive': mode == 'email'}">
                    <!-- <ion-icon name="ios-mail-outline"></ion-icon> -->
                    {{"pages.login.email" | translate}}
                </ion-button>
                <ion-button *ngIf="!systemConfigService.hideLoginPagePhoneInput" fill="clear" disable-hover
                    (click)="selectMode('phone')" [ngClass]="{'isActive': mode == 'phone'}">
                    <!-- <ion-icon name="ios-mail-outline"></ion-icon> -->
                    {{"pages.login.phone" | translate}}
                </ion-button>
            </div>

            <!-- new flow login -->
            <form class="f-input" padding>
                <div class="form-box">
                    <ng-container *ngIf="mode == 'phone'">
                        <div class="phone-input-box animated fadeInUps">
                            <ion-label position="stacked" class="std-input-label">{{"pages.login.phone" |
                                      translate}}
                            </ion-label>
                            <h6>{{"pages.login.check-acc" | translate}}</h6>
                            <ion-item class="phone-box std-input-item">
                                <!-- <ion-input class="std-input" type="tel" clearInput="true" name="countryCallingCode"
                                    [(ngModel)]="form.countryCallingCode" placeholder="{{getCountryCallingCode()}}">
                                </ion-input> -->
                                <ng-container *ngIf="countryCodes.length > 0; else elseTemplate">
                                    <ion-select [(ngModel)]="form.countryCallingCode" name="countryCallingCode"
                                        class="countryCode" (click)="changeCountryCode()">
                                        <ion-select-option *ngFor="let country of countryCodes" [value]="country.code">
                                            {{country.code}}
                                        </ion-select-option>
                                    </ion-select>
                                </ng-container>
                                <ng-template #elseTemplate>
                                    <!-- 防止数据未到ion-select-option出空value -->
                                </ng-template>
                                <ion-input class="std-input" type="tel" clearInput="true" name="phone"
                                    (ionBlur)="phoneChangeHandle()" [(ngModel)]="form.phone">
                                </ion-input>
                            </ion-item>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="mode == 'email'">
                        <ion-item class="std-input-item animated fadeInUps">
                            <ion-label position="stacked" class="std-input-label">{{"pages.login.email" |
                                      translate}}</ion-label>
                            <h6>{{"pages.login.check-acc" | translate}}</h6>
                            <ion-input type="email" clearInput="true" name="email" class="std-input"
                                (ionBlur)="emailChangeHandle()" [(ngModel)]="form.email">
                            </ion-input>
                        </ion-item>
                    </ng-container>
                </div>


            </form>

        </div>

    </ion-content>
    <ion-footer [hidden]="isLoginWaysSelecting || (isEnterPWUi || showRegisterFlowUi)">
        <ion-toolbar class="toolbar-next-btn">
            <!-- <ion-title>             -->
            <ion-button expand="block" (click)="newLoginFlowNext()"
                [disabled]="mode==='phone' ? !(form.phone) : !(form.email)">
                <span>{{"global.next" | translate}}</span>
            </ion-button>
            <!-- </ion-title> -->

        </ion-toolbar>

    </ion-footer>
</ng-container>

<!-- 账户存在就 login  -->
<ng-container *ngIf="isEnterPWUi && !showRegisterFlowUi">

    <!-- 输入密码直接login -->
    <ng-container *ngIf="!showResetPassword">
        <ion-header class="order-header">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button (click)="backLastUi()">
                        <ion-icon slot="icon-only" name="arrow-back" class="back-btn"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content class="memb-login-content">
            <!-- username -->
            <h2>{{"pages.login.hi" |
                        translate}},</h2>

            <form class="f-input" padding>
                <div class="form-box">
                    <!-- 账号 phone input-->
                    <ion-item *ngIf="mode == 'phone'" class="phone-box std-input-item">
                        <!-- <ion-input class="std-input" type="tel" name="countryCallingCode"
                                    [(ngModel)]="form.countryCallingCode" disabled placeholder="{{getCountryCallingCode()}}">
                                </ion-input> -->
                        <ng-container *ngIf="countryCodes.length > 0; else elseTemplate">
                            <ion-select [(ngModel)]="form.countryCallingCode" disabled name="countryCallingCode"
                                class="countryCode">
                                <ion-select-option *ngFor="let country of countryCodes" [value]="country.code">
                                    {{country.code}}
                                </ion-select-option>
                            </ion-select>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <!-- 防止数据未到ion-select-option出空value -->
                        </ng-template>
                        <ion-input class="std-input" type="tel" disabled name="phone" [(ngModel)]="form.phone">
                        </ion-input>
                    </ion-item>
                    <!-- <ion-item  class="std-input-item animated fadeInUps">
                                <ion-label position="stacked" class="std-input-label">{{"pages.login.phone" |
                                                                  translate}}</ion-label>
                                <ion-input [(ngModel)]="form.phone" disabled type="tel" name="phone" required class="std-input"></ion-input>
                            </ion-item> -->
                    <!-- 账号 email input-->
                    <ion-item *ngIf="mode == 'email'" class="std-input-item animated fadeInUps">
                        <ion-label position="stacked" class="std-input-label">{{"pages.login.email" |
                                                                      translate}}</ion-label>
                        <ion-input [(ngModel)]="form.email" disabled type="email" name="email" required
                            class="std-input">
                        </ion-input>
                    </ion-item>
                    <!-- 密码 input -->
                    <ion-item class="std-input-item animated fadeInUps">
                        <ion-label position="stacked" class="std-input-label">{{"pages.login.password" |
                                                          translate}}</ion-label>
                        <ion-input [(ngModel)]="form.password" type="password" autofocus id="loginPassword" name="password" minlength="1" required
                            class="std-input" (keyup.enter)="submitClicked();$event.stopPropagation();"></ion-input>
                    </ion-item>

                    <div class="login-feature-box">
                        <h6 size="small" fill="clear" color="primary" (click)="resetPassword()" class="reset-pw-btn">
                            {{"pages.login.reset-password" | translate}}
                        </h6>
                    </div>

                </div>

                <div class="checkbox-row" *ngIf="!storeMatketing">
                    <ion-checkbox [(ngModel)]="form.marketing" name="marketing" mode="md"></ion-checkbox>
                    <h6>{{'pages.terms-conditions.receive-brand-tnc2' | translate : TACKeyObj }}</h6>
                </div>

            </form>

        </ion-content>

        <ion-footer>
            <ion-toolbar class="toolbar-login-btn">

                <div class="tnc-text">
                    <span>{{"pages.login.upon-logging-in" | translate}}</span> <span class="tnc-link"
                        (click)="justShowTC()">{{"pages.login.loginTC" | translate}}.</span>
                </div>

                <ion-button expand="block" (click)="submitClicked()">
                    <span>{{"pages.login.login" | translate}}</span>
                </ion-button>

            </ion-toolbar>
        </ion-footer>
    </ng-container>

    <!-- reset password when click forget password -->
    <ng-container *ngIf="showResetPassword">
        <ion-header class="order-header">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button (click)="backLastUi()">
                        <ion-icon slot="icon-only" name="arrow-back" class="back-btn"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="memb-login-content">
            <page-reset-password [hideHeader]="true" [isForgotPassword_c]="true" [brand_c]="this.brand" [username_c]="this.form[this.mode]"
                [countryCallingCode_c]="this.form.countryCallingCode" [isModal]="true" (resetPasswordClose)="resetPasswordClose()"></page-reset-password>
        </ion-content>
    
    </ng-container>


</ng-container>

<!-- 账户不存在就 verify the phone or email -->
<ng-container *ngIf="showRegisterFlowUi">
    <!-- create an new account and input the message ui -->
    <ng-container *ngIf="true">

        <ion-header class="order-header">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button (click)="backLastUi()">
                        <ion-icon slot="icon-only" name="arrow-back" class="back-btn"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content class="create-acc-content">

            <!-- username -->
            <h2>{{"pages.login.createAcct" | translate}}</h2>

            <form *ngIf="registrationForm" [formGroup]="registrationForm" novalidate
                class="form-box animated fadeInRights">
                <div class="form-box">

                    <!-- email -->
                    <div text-wrap class="std-input-item">
                        <!-- <ion-label position="stacked" class="std-input-label">{{"pages.registration.email" | translate }}</ion-label> -->
                        <ion-label position="stacked" class="std-input-label">{{"pages.registration.email" | translate}}
                            <span class="error-msg c-ali">*</span>
                        </ion-label>
                        <ion-input type="email" class="std-input" (ionBlur)="emailChangeHandleOfAccount()"
                            formControlName="Email"></ion-input>
                    </div>

                    <!-- phone -->
                    <div text-wrap class="std-input-item item-phone">
                        <ion-label position="stacked" class="std-input-label">{{"pages.registration.phone" | translate}}
                            <span class="error-msg c-ali">*</span>
                        </ion-label>

                        <div class="inputs-n-otp">
                            <!-- phone  countryCallingCode -->
                            <!-- <ion-input type="number" class="std-input country-code" clearInput="true"
                                name="countryCallingCode" formControlName="CountryCallingCode"
                                [disabled]="showSecondsSms || token" placeholder="{{getCountryCallingCode()}}">
                            </ion-input> -->
                            <div class="phone-box std-input-item">
                                <ng-container *ngIf="countryCodes.length > 0; else elseTemplate">
                                    <ion-select formControlName="CountryCallingCode" [disabled]="showSecondsSms || token" name="countryCallingCode"
                                        class="std-input country-code" (click)="changeCountryCode()">
                                        <ion-select-option *ngFor="let country of countryCodes" [value]="country.code">
                                            {{country.code}}
                                        </ion-select-option>
                                    </ion-select>
                                </ng-container>
                                <ng-template #elseTemplate>
                                    <!-- 防止数据未到ion-select-option出空value -->
                                </ng-template>
                                <!-- phone number -->
                                <ion-input type="number" class="std-input phone-numb" [disabled]="showSecondsSms || token"
                                    formControlName="Phone_Number" (ionFocus)="phoneNumberBlur($event)"
                                    (ionBlur)="phoneChangeHandleOfAccount()">
                                </ion-input>
                            </div>
                            
                            <ion-button (click)="sendSms()" *ngIf="!showSecondsSms && secondsCount === basis"
                                size="default" [disabled]="token">{{"pages.registration.get-otp" | translate}}
                            </ion-button>
                            <ion-button *ngIf="showSecondsSms" disabled size="default">{{secondsCount}}s</ion-button>
                            <ion-button (click)="sendSms()" *ngIf="!showSecondsSms && secondsCount <= 0"
                                [disabled]="token" size="default">
                                {{"pages.verify-registration.re-send-code-message" | translate}}</ion-button>
                        </div>
                    </div>

                    <div text-wrap class="std-input-item verify-code-item" *ngIf="showVerifyCodeInput">
                        <ion-label position="stacked" class="std-input-label">
                            {{"pages.registration.verify-code" | translate}}
                            <span class="error-msg c-ali">*</span>
                        </ion-label>
                        <div class="row-verfi-code">
                            <ion-input type="number" class="std-input" [disabled]="this.token" formControlName="VerifyCode"
                            (ionChange)="autoVerifySms($event)"></ion-input>
                        <div class="div-checkmark" *ngIf="this.token">
                            <ion-icon  mode="md" item-right name="checkmark"></ion-icon>
                        </div>
                       
                        </div>
            
                    </div>

                    <!-- password -->
                    <div text-wrap class="std-input-item">
                        <!-- <ion-label position="stacked" class="std-input-label">{{"pages.registration.password" | translate }}</ion-label> -->
                        <ion-label position="stacked" class="std-input-label">
                            {{"pages.registration.password" | translate}}
                            <span class="error-msg c-ali">*</span>
                        </ion-label>
                        <ion-input class="std-input input-password" formControlName="Password" type="password"
                            placeholder="{{'pages.registration.password-rule' | translate}}"></ion-input>
                    </div>

                    <!-- confirm-password -->
                    <div text-wrap class="std-input-item">
                        <!-- <ion-label position="stacked" class="std-input-label">{{"pages.registration.password" | translate }}</ion-label> -->
                        <ion-label position="stacked" class="std-input-label">
                            {{"pages.registration.confirm-password" | translate}}
                            <span class="error-msg c-ali">*</span>
                        </ion-label>
                        <ion-input class="std-input reinput-password" formControlName="Confirm_Password" type="password"
                            placeholder="{{'pages.registration.password-rule' | translate}}"></ion-input>
                    </div>
                    <div class="hint-box">
                        <!-- <p>{{"pages.registration.pwdRule" | translate }}</p> -->
                        <!-- <p>(at least 8 characters with at least one letter and one number)</p> -->
                    </div>

                    <div class="std-multi-input-row">
                        <!-- firstName -->
                        <div text-wrap class="std-input-item item-first-name">
                            <!-- <ion-label position="stacked" class="std-input-label">{{"pages.registration.firstName" | translate }}</ion-label> -->
                            <ion-label position="stacked" class="std-input-label">
                                {{"pages.registration.first-name" | translate}}
                            </ion-label>
                            <ion-input class="std-input" formControlName="First_Name"></ion-input>
                        </div>
                        <!-- lastName -->
                        <div text-wrap class="std-input-item item-last-name">
                            <ion-label position="stacked" class="std-input-label">
                                {{"pages.registration.last-name" | translate}}
                            </ion-label>
                            <!-- <ion-label position="stacked" class="std-input-label">{{"pages.registration.lastName" | translate }}</ion-label> -->
                            <ion-input class="std-input" formControlName="Last_Name"></ion-input>
                        </div>
                    </div>
                    <div class="std-multi-input-row">
                        <!-- gender -->
                        <div text-wrap class="std-input-item item-gender">
                            <!-- <ion-label position="stacked" class="std-input-label">{{"pages.registration.gender" | translate }}</ion-label> -->
                            <ion-label position="stacked" class="std-input-label">
                                {{"pages.registration.gender" | translate}}
                                <!-- <span class="error-msg c-ali">*required</span> -->
                            </ion-label>
                            <ion-select class="std-input" okText="OK" cancelText="Cancel" formControlName="Gender">
                                <ion-select-option *ngFor="let gender of genders" value="{{gender.serverString}}">
                                    {{gender.clientString}}</ion-select-option>
                            </ion-select>
                        </div>
                        <!-- birthday -->
                        <div text-wrap class="std-input-item item-dob">
                            <!-- <ion-label position="stacked" class="std-input-label">{{"pages.registration.dob" | translate }}</ion-label> -->
                            <ion-label position="stacked" class="std-input-label">
                                {{"pages.registration.DOB" | translate}}
                                <!-- <span class="error-msg c-ali">*required</span> -->
                            </ion-label>
                            <ion-datetime class="std-input" displayFormat="DD-MM-YYYY" doneText="Done"
                                cancelText="Cancel" [max]="maxDateStr" formControlName="Birthday"></ion-datetime>
                        </div>
                    </div>



                </div>
            </form>

            <div class="checkbox-row mkt-checkbox">
                <ion-checkbox [(ngModel)]="form.marketing" name="marketing" mode="md"></ion-checkbox>
                <h6>{{'pages.terms-conditions.receive-brand-tnc2' | translate : TACKeyObj}}</h6>
            </div>

            <!-- <div class="btn-box mt4"> -->
            <!-- <button tappable type="button" ion-button (click)="clickRegister()">{{"pages.registration.register" | translate }}</button> -->
            <!-- <ion-button expand="full" type="submit" [disabled]="!token" (click)="clickRegister()">
                    {{"pages.registration.create-message" | translate}}</ion-button>
            </div> -->

        </ion-content>

        <ion-footer>
            <ion-toolbar class="toolbar-next-btn">

                <div class="tnc-text">
                    <span>{{"pages.login.upon-logging-in" | translate}}</span> <span class="tnc-link"
                        (click)="justShowTC()">{{"pages.login.loginTC" | translate}}.</span>
                </div>
                <ion-button expand="block" type="submit" [disabled]="!token"
                    (click)="clickRegister()">
                    <span> {{"pages.registration.create-message" | translate}}</span>
                </ion-button>

                <!-- <button tappable type="button" ion-button (click)="clickRegister()">{{"pages.registration.register" | translate }}</button> -->
                <!-- <ion-button expand="full" type="submit" [disabled]="!token" (click)="clickRegister()">
                                            {{"pages.registration.create-message" | translate}}</ion-button>
                                         </div> -->
                <!-- <ion-button expand="full" type="submit" [disabled]="!token" (click)="clickRegister()">
                                            {{"pages.registration.create-message" | translate}} ddsf</ion-button> -->

            </ion-toolbar>
        </ion-footer>

    </ng-container>
</ng-container>