<ion-content class="content animated fadeInUp">

    <div class="top-counter">
        <div class="list-header">
            <span>{{'dialog.offer-list.select-reward'|translate}}</span>
            <ion-button fill="clear" (click)="dismissClicked()">
                <ion-icon slot="icon-only" name="md-close" ></ion-icon>
            </ion-button>
        </div>
        <ion-list class="couponBS-wrapper">
            <ion-item *ngFor="let offer of offers" (click)="clickReward(offer)" no-lines
                      class="cardShadow AQA-reward-item"
                      [ngClass]="{'unavailable' : !isAvailableReward(offer), 'animated shake' : isBounce}">
                <ion-avatar slot="start" *ngIf="offer.reward.images.length > 0">
                    <img src="{{offer.reward.getImage('default', 100,false)}}">
                </ion-avatar>
                <ion-label>
                    <h2>{{offer.reward.name}}</h2>
                    <p *ngIf="!offer.start">{{'dialog.offer-list.valid-until'|translate}}{{offer.expire | date:'yyyy-MM-dd' }}</p>
                    <p *ngIf="offer.start">{{'dialog.offer-list.valid-from'|translate}} {{offer.start | date:'yyyy-MM-dd' }}
                        {{'dialog.offer-list.to'|translate}} {{offer.expire | date:'yyyy-MM-dd' }}</p>
                </ion-label>
                <ion-button slot="end" fill="outline" color="success">{{"global.select" | translate}}</ion-button>
            </ion-item>
        </ion-list>
    </div>

</ion-content>
