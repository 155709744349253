var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ConfigService } from './config.service';
import { BaseService } from '../base/base-service';
import { Config } from '../../config';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
var GoogleAnalyticsService = /** @class */ (function (_super) {
    __extends(GoogleAnalyticsService, _super);
    function GoogleAnalyticsService(http, configService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configService = configService;
        _this.config = new Config();
        _this.setting = _this.config.gaConfig();
        if (_this.setting) {
            _this.sender = _this.setting['name'] + '.send';
        }
        return _this;
        // this.createTracker(this.setting);
    }
    GoogleAnalyticsService.prototype.createTracker = function (setting) {
        if (!window['ga']) {
            return;
        }
        var trackerId = this.getMainTrackerId();
        console.log('init ga', trackerId);
        ga('create', trackerId, window.location.hostname);
        if (this.setting) {
            ga('create', setting['trackerId'], window.location.hostname, setting['name']);
            if (!this.trackerName) {
                this.trackerName = [setting['name']];
            }
            else if (this.trackerName.indexOf(setting['name']) == -1) {
                this.trackerName.push(setting['name']);
            }
            else {
                console.log('creating duplicated ga tracker', setting['name']);
            }
        }
    };
    GoogleAnalyticsService.prototype.createTrackerById = function (trackId, trackname) {
        if (!window['ga']) {
            return;
        }
        console.log('init ga', trackId + ':' + trackname);
        ga('create', trackId, window.location.hostname, trackname);
        if (!this.trackerName) {
            this.trackerName = [trackname];
        }
        else if (this.trackerName.indexOf(trackname) == -1) {
            this.trackerName.push(trackname);
        }
        else {
            console.log('creating duplicated ga tracker', trackname);
        }
    };
    GoogleAnalyticsService.prototype.pageViewTrack = function (pagePath, oninit) {
        if (!window['ga']) {
            return;
        }
        if (!oninit) {
            ga('send', 'pageview', pagePath);
        }
        // if (this.setting) {
        //     ga(this.sender, 'pageview', pagePath);
        // }
        if (this.trackerName) {
            this.trackerName.forEach(function (tracker) {
                ga(tracker + '.send', 'pageview', pagePath);
            });
        }
    };
    GoogleAnalyticsService.prototype.eventTrack = function (page, event, label) {
        if (!window['ga']) {
            return;
        }
        ga('send', 'event', page, event, label);
        // if (this.setting) {
        //     ga(this.sender, 'event', page, event, label);
        // }
        if (this.trackerName) {
            this.trackerName.forEach(function (tracker) {
                ga(tracker + '.send', 'event', page, event, label);
            });
        }
    };
    GoogleAnalyticsService.prototype.getMainTrackerId = function () {
        return this.configService.isPrd() ? this.config.ga_prd.trackerId : this.config.ga_uat.trackerId;
    };
    GoogleAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}(BaseService));
export { GoogleAnalyticsService };
