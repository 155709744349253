var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./config.service";
var MemberService = /** @class */ (function (_super) {
    __extends(MemberService, _super);
    function MemberService(configs) {
        var _this = _super.call(this) || this;
        _this.configs = configs;
        _this.hasMembership = false;
        _this.isUrlsessionFlow = false;
        _this.mode = 'anno';
        _this.hadCheckMemberShip = false;
        _this.bookmarksMap = {};
        _this.canBookmark = false;
        _this.memberSubscribe = new Subject();
        _this.loadAigensMember();
        return _this;
    }
    MemberService.prototype.loadAigensMember = function () {
        var _this = this;
        var orderSession = this.configs.getLocal('orderSession');
        var member = this.configs.getLocal('aigens.member');
        if (member) {
            // if (orderSession && orderSession.member){
            // orderSession 里面的member没有Session 所以拿Preference.member替换
            // this.configs.getPreference('aigens.member').then((member) => {
            //     if (member) {
            //         orderSession.member['sessionId'] = member.sessionId;
            //         this.member = orderSession.member;
            //         console.log("!!!!!!!!!!!!!!!!loadAigensMember", this.member);
            //         this.configs.setSessionId(member.sessionId);
            //     }
            //     this.memberSubscribe.next(this.member);
            // });
            // this.configs.setSessionId(member.sessionId);
            this.putMember(member);
        }
        else {
            this.memberSubscribe.next(null);
        }
        // this.configs.getPreference('aigens.member').then((member) => {
        //     debugger;
        //     console.log('member loaded', member);
        //     if (member) {
        //         this.member = member;
        //         this.configs.setSessionId(this.member.sessionId);
        //     }
        //     this.memberSubscribe.next(member);
        //     console.log('sub fired');
        // });
        this.configs.getPreference('crmId').then(function (crmId) {
            console.log('court crmId loaded', crmId);
            if (crmId) {
                _this.crmId = crmId;
            }
        });
    };
    MemberService.prototype.putMember = function (member) {
        this.member = member;
        console.log('!!!!!!!!!!!!!this.member', this.member, this.memberSubscribe);
        this.memberSubscribe.next(member);
        this.configs.setSessionId(this.member.sessionId);
        this.configs.setLocal('aigens.member', this.member); // 判别登录的唯一标准, orderSession 在storelist page cant call (not exist store.id)
        // this.configs.storage.set('aigens.member', member);
    };
    MemberService.prototype.isAdmin = function () {
        var member = this.getMember();
        if (member) {
            return member.admin === true;
        }
        return false;
    };
    MemberService.prototype.clearSession = function () {
        this.member = null;
        this.configs.clearSessionId();
        this.configs.storage.set('aigens.member', null);
        this.configs.setLocal('session.expire', null);
        this.configs.setLocal('aigens.member', null);
    };
    MemberService.prototype.clear = function () {
        this.clearSession();
        this.offers = [];
        this.hasMembership = false;
    };
    // getSessionId(): string {
    //
    //     const member = this.getMember();
    //     if (member) {
    //         return member.sessionId;
    //     }
    //     return this.configs.getCookie('sid');
    // }
    MemberService.prototype.getMember = function () {
        return this.member;
    };
    MemberService.prototype.isGuest = function () {
        var member = this.member;
        return !!(member && member['method'] === 'device');
    };
    MemberService.prototype.isMember = function () {
        var member = this.member;
        return !!(member && member['method'] !== 'device');
    };
    MemberService.prototype.setHadCheckMemberShip = function (bool) {
        this.hadCheckMemberShip = bool;
    };
    // true ==> had check
    MemberService.prototype.getIsHadCheckMemberShip = function () {
        return this.hadCheckMemberShip;
    };
    MemberService.prototype.isLogin = function () {
        // let member = this.configs.getLocal('aigens.member')
        return !!(this.member);
    };
    MemberService.prototype.initMember = function (mode) {
        if (mode === void 0) { mode = 'anno'; }
        // if (mode === 'anno') {
        //     this.mode = 'anno';
        //     // make a fake member;
        //     if (this.configs.getLocal('anno.uuid') && this.configs.getLocal('anno.name')) {
        //         this.uuid = this.configs.getLocal('anno.uuid');
        //         this.name = this.configs.getLocal('anno.name');
        //     } else {
        //         this.uuid = UUID.UUID();
        //         // this.name = new chance().name();
        //         this.configs.setLocal('anno.uuid', this.uuid);
        //         this.configs.setLocal('anno.name', this.name);
        //
        //     }
        //
        // }
    };
    MemberService.prototype.isBookmarked = function (id) {
        return this.bookmarksMap[id];
    };
    MemberService.prototype.addBookmark = function (id) {
        this.bookmarksMap[id] = true;
    };
    MemberService.prototype.removeBookmark = function (id) {
        this.bookmarksMap[id] = false;
    };
    MemberService.prototype.manualRedeemStampCardReward = function (stampCardTemplateId, stampIdx) {
        var url = '/api/v1/reward/offer.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.shouldAuth = true;
        var params = {};
        params['action'] = 'redeemstamp';
        params['membershipId'] = this.member['membership']['id'];
        params['stampCardTemplateId'] = stampCardTemplateId;
        params['stamp'] = stampIdx;
        aq.params = params;
        return aq.auth(true).getJson().map(function (jo) { return jo; });
    };
    MemberService.ngInjectableDef = i0.defineInjectable({ factory: function MemberService_Factory() { return new MemberService(i0.inject(i1.ConfigService)); }, token: MemberService, providedIn: "root" });
    return MemberService;
}(BaseService));
export { MemberService };
