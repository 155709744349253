var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from "@angular/core";
import { MobileBasePage } from '../../../core/base/mobile-base-page';
import { Data, Offer } from "aigens-ng-core";
var StampRedeemPage = /** @class */ (function (_super) {
    __extends(StampRedeemPage, _super);
    function StampRedeemPage(injector) {
        var _this = _super.call(this, injector) || this;
        _this.index = 0;
        _this.stampStatus = '';
        _this.reward = _this.navParams.get('reward');
        _this.stampCard = _this.navParams.get("stampCard");
        _this.index = _this.navParams.get('index');
        _this.stampStatus = _this.navParams.get('stampStatus');
        return _this;
    }
    StampRedeemPage.prototype.dismiss = function () {
        this.modalController.dismiss();
    };
    StampRedeemPage.prototype.redeemClick = function () {
        var _this = this;
        var status = this.stampStatus;
        if (status !== 'canRedeem') {
            this.modalController.dismiss();
        }
        var stampCardTemplateId, stampIdx;
        stampCardTemplateId = this.stampCard.template.id;
        stampIdx = this.index + 1;
        this.memberService.manualRedeemStampCardReward(stampCardTemplateId, stampIdx).subscribe(function (data) {
            console.log(data);
            if (data) {
                _this.showAlert('Redeemd Success', '');
                _this.modalController.dismiss('redeemed');
            }
        }, function (err) {
            _this.showAlert("Redeem Fail", '');
            _this.modalController.dismiss('redeemed');
        });
    };
    StampRedeemPage.prototype.getImageSrc = function () {
        var src = "";
        this.reward = Data.toData(Offer, this.reward);
        console.log(this.reward);
        src = 'assets/imgs/logo_hd.png';
        if (this.reward.getDefaultImage()) {
            src = this.reward.getDefaultImage();
        }
        return src;
    };
    return StampRedeemPage;
}(MobileBasePage));
export { StampRedeemPage };
