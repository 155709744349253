var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
import { UUID } from 'angular2-uuid';
import { MemberService } from 'src/app/core/services/member.service';
import { getCountryCallingCode, isPhoneValid, findCountryByCodes, firstNameFormatValidator, lastNameFormatValidator, emailFormatValidator, phoneValidator, passwordFormatValidator, confirmPasswordFormatValidator, getTandCUrl, isEmailValid } from 'src/app/shared/utilities/checkFormatTool';
import { TermsConditionsPage } from '../terms-conditions/terms-conditions';
import { Validators, FormBuilder } from '@angular/forms';
import { StoreService } from 'src/app/core/services/store.service';
var LoginModal = /** @class */ (function (_super) {
    __extends(LoginModal, _super);
    function LoginModal(injector, memberService, formBuilder, storeService) {
        var _this = _super.call(this, injector) || this;
        _this.memberService = memberService;
        _this.formBuilder = formBuilder;
        _this.storeService = storeService;
        _this.form = {
            marketing: true
        };
        _this.status = '';
        _this.googleLoginClicked = false;
        _this.mode = 'email';
        _this.hasCrmId = false;
        _this.isPopup = false;
        _this.facebookLogin = false;
        _this.googleLogin = false;
        _this.isFirstLoad = true;
        _this.isEnterPWUi = false;
        _this.showRegisterFlowUi = false;
        _this.isLoginWaysSelecting = true;
        _this.showResetPassword = false;
        _this.sent = false;
        _this.basis = 180;
        _this.countDown = _this.basis;
        _this.birthdayText = '';
        _this.isReceivePromotion = true;
        _this.isReceiveTAC = true;
        _this.showVerifyCodeInput = false;
        _this.showSecondsSms = false;
        _this.secondsCount = _this.basis;
        _this.countryCodes = [];
        _this.storeMatketing = false;
        _this.emailCheckingAlertExist = false;
        _this.firstTimeCheckingEamil = true;
        _this.phoneCheckingAlertExist = false;
        _this.firstTimeCheckingPhone = true;
        console.log('LoginModal constructor');
        _this.navParams = injector.get(NavParams);
        _this.store = _this.navParams.get('store');
        _this.isLoginWaysSelecting = _this.navParams.get('isLoginWaysSelecting');
        var brand = _this.navParams.get('brand');
        if (brand) {
            _this.brand = brand;
        }
        else if (_this.store) {
            _this.brand = _this.store.brand;
        }
        var member = _this.memberService.getMember();
        if (member) {
            if (member['method'] === 'device') {
                // no need logout because of the check phone or email isRegister need sid.
            }
            else
                _this.memberService.clearSession();
        }
        if (_this.brand) {
            // this.brand['crmId'] = ""
            _this.TACKeyObj = _this.brand;
            _this.hasCrmId = _this.brand['crmId'] ? true : false;
            _this.facebookLogin = _this.brand.hasFeature('flogin') && navigator.userAgent.indexOf('AlipayClient') < 0;
            _this.googleLogin = _this.brand.hasFeature('glogin') && navigator.userAgent.indexOf('AlipayClient') < 0;
        }
        if (_this.googleLogin) {
            // this.initGoogle();
            _this.googleLoginHandler = function (e) {
                var data = e.detail;
                _this.googleLoginHandling(data.id, data.token);
            };
            window.addEventListener('google-login-handling', _this.googleLoginHandler);
        }
        console.log('this.isLoginWaysSelecting', _this.isLoginWaysSelecting);
        window['checkFBLoginState'] = function () {
            console.log('window[\'checkFBLoginState\']');
            _this.checkFBLoginState();
        };
        return _this;
    }
    LoginModal.prototype.changeToLoginAndRegister = function () {
        this.isLoginWaysSelecting = false;
        this.getAddressField();
    };
    LoginModal.prototype.initRegisterForm = function () {
        this.maxDateStr = new Date().toISOString();
        this.creatForm();
        var male = this.t.instant('global.male');
        var female = this.t.instant('global.female');
        var other = this.t.instant('global.other');
        // let male = "Male";
        // let female = "Female";
        // let other = "Others";
        this.genders = [
            { serverString: 'M', clientString: male },
            { serverString: 'F', clientString: female },
            { serverString: 'O', clientString: other }
        ];
    };
    LoginModal.prototype.creatForm = function () {
        this.registrationForm = this.formBuilder.group({
            First_Name: [],
            Last_Name: [],
            Gender: [],
            Birthday: [],
            Email: [],
            CountryCallingCode: [],
            Phone_Number: [],
            VerifyCode: [],
            Password: [],
            Confirm_Password: []
        });
        this.firstName = this.registrationForm.get('First_Name');
        this.firstName.setValidators(Validators.compose([firstNameFormatValidator]));
        this.lastName = this.registrationForm.get('Last_Name');
        this.lastName.setValidators(Validators.compose([lastNameFormatValidator]));
        this.email = this.registrationForm.get('Email');
        this.email.setValidators(Validators.compose([Validators.required, emailFormatValidator]));
        if (this.form && this.form.email) {
            this.email.setValue(this.form.email);
        }
        this.countryCallingCode = this.registrationForm.get('CountryCallingCode');
        this.countryCallingCode.setValidators(Validators.compose([Validators.required]));
        if (this.form && this.form.countryCallingCode) {
            this.countryCallingCode.setValue(this.form.countryCallingCode);
        }
        this.phoneNumber = this.registrationForm.get('Phone_Number');
        this.phoneNumber.setValidators(Validators.compose([Validators.required, phoneValidator]));
        if (this.form && this.form.phone) {
            this.phoneNumber.setValue(this.form.phone);
        }
        this.verifyCode = this.registrationForm.get('VerifyCode');
        this.verifyCode.setValidators(Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)]));
        this.password = this.registrationForm.get('Password');
        this.password.setValidators(Validators.compose([Validators.required, Validators.minLength(8), passwordFormatValidator]));
        this.confirmPassword = this.registrationForm.get('Confirm_Password');
        this.confirmPassword.setValidators(Validators.compose([Validators.required, Validators.minLength(8), confirmPasswordFormatValidator]));
        this.gender = this.registrationForm.get('Gender');
        this.birthday = this.registrationForm.get('Birthday');
    };
    LoginModal.prototype.dismissClicked = function (params) {
        console.log('delivery login modal closing');
        this.modalController.dismiss(params);
    };
    LoginModal.prototype.ionViewDidEnter = function () {
        if (this.isFirstLoad) {
            this.initGoogle();
        }
        this.initFB();
        this.isFirstLoad = false;
    };
    LoginModal.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (!this.isLoginWaysSelecting)
            this.getAddressField();
    };
    LoginModal.prototype.getCountryCallingCode = function () {
        return getCountryCallingCode();
    };
    LoginModal.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        window.removeEventListener('google-login-handling', this.googleLoginHandler);
        if (this.facebookLogin) {
            var fbsdk = document.getElementById('facebook-jssdk');
            var head = document.getElementsByTagName('head')[0];
            if (fbsdk && head) {
                head.removeChild(fbsdk);
                window['FB'] = null;
            }
        }
        window['checkFBLoginState'] = null;
    };
    // login
    LoginModal.prototype.submitClicked = function () {
        var _this = this;
        console.log('login clicking', this.form);
        var username = (this.form[this.mode] ? this.form[this.mode] : '');
        var password = (this.form['password'] ? this.form['password'] : '');
        var countryCallingCode = this.form['countryCallingCode'];
        var tmpSet = new Set(username);
        var type = tmpSet.has('@') ? 'email' : 'phone';
        if (!countryCallingCode && type === 'phone') {
            countryCallingCode = getCountryCallingCode();
        }
        if (this.mode !== type) {
            if (this.mode === 'email') {
                this.showAlert(null, this.t.instant('pages.login.input-mismatch-email'));
                return;
            }
            if (this.mode === 'phone') {
                this.showAlert(null, this.t.instant('pages.login.input-mismatch-phone'));
                return;
            }
            return;
        }
        var brandId = null;
        if (this.hasCrmId) {
            if (!this.brand || !this.brand.id) {
                this.showAlert(null, 'Missing Brand ID');
                return;
            }
            if (!username) {
                this.showAlert(null, this.t.instant('pages.login.usernameEmpty'));
                return;
            }
            if (!password) {
                this.showAlert(null, this.t.instant('pages.login.passwordEmpty'));
                return;
            }
            type = 'crm';
            countryCallingCode = null;
            brandId = this.brand.id;
        }
        this.loading(true);
        this.memberApiService.login(username, password, type, countryCallingCode, brandId).subscribe(function (member) {
            _this.memberService.clearSession();
            _this.memberService.putMember(member);
            var branId = _this.brand && _this.brand.id;
            _this.checkingMembershipOfBrand(branId, function (has) {
                if (has && has[0]) {
                    // call api to get current member's address
                    if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway')) {
                        _this.deliveryHandling(true);
                        return;
                    }
                    else {
                        _this.loading(false);
                        _this.dismissClicked({ openAddressDialog: false });
                    }
                    // this.loading(false);
                }
                else {
                    // 直接加入 join membership when membership not exist
                    _this.memberApiService.join(_this.store ? _this.store.id : null, _this.brand.id, _this.form.marketing).subscribe(function (result) {
                        console.log('result', result);
                        if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway')) {
                            _this.deliveryHandling(true);
                            return;
                        }
                        else {
                            _this.loading(false);
                            _this.dismissClicked({ openAddressDialog: false });
                        }
                    }, function (err) {
                        _this.showError(err);
                        _this.loading(false);
                    });
                }
            }, false);
        }, function (err) {
            _this.loading(false);
            console.error(err);
            _this.showError(err);
        });
    };
    LoginModal.prototype.deliveryHandling = function (existLoading) {
        var _this = this;
        var needRecomend = this.settings.aiRecommendCount && this.settings.aiRecommendCount > 0;
        var fields = ['addresses', 'membership', 'offers'];
        needRecomend && fields.push('recommend');
        if (this.store && this.orderManager.mode) {
            if (!existLoading)
                this.loading(true);
            this.orderSessionService.getOrderSession(this.store.id, this.orderManager.mode, fields).subscribe(function (orderSession) {
                _this.loading(false);
                if (orderSession) {
                    var member = orderSession.member;
                    if (_this.memberService.member) {
                        if (_this.memberService.member.phone)
                            orderSession.member['phone'] = _this.memberService.member.phone;
                        orderSession.member.marketing = _this.memberService.member.marketing;
                    }
                    if (_this.store)
                        _this.orderSessionService.setLocalAddress(_this.addressManagerService.calAddressesDistance(member.addresses, _this.store));
                    _this.configService.setLocal('orderSession', orderSession);
                    var addresses = member.addresses;
                    if (addresses) {
                        _this.addressManagerService.addressArr = addresses;
                        _this.configService.setLocal('addressArrOfStoreList', addresses);
                    }
                    if (member.addresses && member.addresses.length > 0) {
                        var addresses_1 = member.addresses;
                        _this.addressManagerService.addressArr = addresses_1;
                        _this.dismissClicked({ openAddressDialog: true });
                    }
                    else {
                        _this.dismissClicked({ openAddressDialog: true });
                    }
                }
                else {
                    return;
                }
            }, function (err) {
                _this.loading(false);
                console.log('getReward error', err);
            });
        }
        else {
            if (!existLoading)
                this.loading(true);
            console.log('can not find store id or this.orderManager.mode, so geting address data by calling address.json not session.json');
            this.addressManagerService.getAddressesFromAddressJson().subscribe(function (addressArr) {
                _this.loading(false);
                console.log('getAddressesFromAddressJson success addressArr', addressArr);
                _this.configService.setLocal('addressArrOfStoreList', addressArr);
                _this.dismissClicked({ openAddressDialog: false });
            }, function (err) {
                _this.loading(false);
                console.log('getAddressesFromAddressJson error', err);
            });
        }
    };
    // checkIsFaceBookLogined() {
    //     FB.getLoginStatus((response) => {
    //         console.log('FB getLoginStatus', response, response.status);
    //     });
    // }
    LoginModal.prototype.onFacebookLoginClick = function () {
        var _this = this;
        this.loading(true);
        FB.login(function (response) {
            if (response.status === 'connected') {
                // Logged into your app and Facebook.
                var data = response.authResponse;
                _this.fbLoginHandling(data['userID'], data['accessToken'], true);
            }
            else {
                _this.loading(false);
                _this.showAlert('Login Fail', '');
                // The person is not logged into this app or we are unable to tell.
            }
        }, { auth_type: 'reauthenticate' });
    };
    LoginModal.prototype.checkFBLoginState = function () {
        var _this = this;
        console.log('checkFBLoginState', this);
        FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                // Logged into your app and Facebook.
                var data = response.authResponse;
                _this.fbLoginHandling(data['userID'], data['accessToken'], true);
            }
            else {
                _this.showAlert('Login Fail', '');
                // The person is not logged into this app or we are unable to tell.
            }
        });
    };
    LoginModal.prototype.fbLoginHandling = function (fbId, token, join) {
        var _this = this;
        if (join === void 0) { join = false; }
        // todo handle store.id when open this modal in storelist page
        this.memberApiService.FBloginOrRegistration(fbId, token, true, (this.store ? this.store.id : null), this.brand.id, true).subscribe(function (member) {
            console.log('FBloginOrRegistration', member);
            // this.loading(false);
            var sessionId = member.sessionId;
            // this.showAlert("",JSON.stringify(member));
            if (sessionId) {
                _this.memberService.putMember(member);
                var branId = _this.brand && _this.brand.id;
                _this.checkingMembershipOfBrand(branId, function () {
                    if ((!member.membership || !member.membership.marketing) && _this.store) {
                        _this.openTermsModal(_this.brand, function (data) {
                            // this.configService.putPreference('termsAgree', data['agree']);
                            _this.memberApiService.join(_this.store.id, _this.brand.id, data.marketing).subscribe(function (result) {
                                // this.loading(false);
                            }, function (err) {
                                console.log(err);
                                // this.loading(false);
                                _this.showAlert('Error ' + err.status, err._body);
                            }, function () {
                                _this.memberService.member.marketing = data.marketing;
                                if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway')) {
                                    _this.deliveryHandling(true);
                                }
                                else {
                                    _this.loading(false);
                                    _this.dismissClicked({ openAddressDialog: false });
                                }
                            });
                        }, TermsConditionsPage, true, true);
                    }
                    else {
                        if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway')) {
                            _this.deliveryHandling(true);
                        }
                        else {
                            _this.loading(false);
                            _this.dismissClicked({ openAddressDialog: false });
                        }
                    }
                });
            }
        }, function (err) {
            _this.loading(false);
            console.log(err);
            _this.showError(err);
        });
    };
    LoginModal.prototype.registration = function () {
        if (this.store)
            this.pushByName('VerifyRegistrationPage', { fromPage: LoginModal, store: this.store });
    };
    LoginModal.prototype.resetPassword = function () {
        var store = this.orderManager.store;
        if (!store && this.store)
            store = this.store;
        if (store && store.crm && store.crm.links && store.crm.links['forgetpw']) {
            // open in new tab
            window.open(store.crm.links['forgetpw']['url']);
        }
        else {
            this.showResetPassword = true;
            // this.pushByName('ResetPasswordPage', { username: this.form[this.mode], countryCallingCode: this.form.countryCallingCode, isForgotPassword: true, brand: this.brand });
        }
    };
    LoginModal.prototype.initFB = function () {
        console.log('ly: -> login-modal.page.ts ->initFB');
        if (this.facebookLogin) {
            this.configService.loadScriptJS('/assets/script/fb-sdk.js', function () {
                console.log('ly: -> login-modal.page.ts -> fbLoaded');
            }, null, true);
        }
    };
    LoginModal.prototype.initGoogle = function () {
        window['onGoogleSignIn'] = this.onSignIn;
        var params = {};
        params['id'] = 'google-login-script';
        this.configService.loadScript('https://apis.google.com/js/platform.js?onload=renderButton', function () {
        }, params);
    };
    LoginModal.prototype.onSignIn = function (googleUser) {
        var profile = googleUser.getBasicProfile();
        var id = profile.getId();
        var id_token = googleUser.getAuthResponse().id_token;
        console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
        console.log('token:' + id_token);
        var evt = new CustomEvent('google-login-handling', {
            detail: {
                id: id,
                token: id_token
            }
        });
        window.dispatchEvent(evt);
    };
    LoginModal.prototype.clickGoogleLogin = function () {
        this.googleLoginClicked = true;
    };
    LoginModal.prototype.selectMode = function (type) {
        this.mode = type;
    };
    LoginModal.prototype.googleLoginHandling = function (id, token) {
        var _this = this;
        if (this.googleLoginClicked) {
            this.loading(true);
            this.memberApiService.googleLogin(id, token).subscribe(function (member) {
                console.log(member);
                _this.loading(false);
                var sessionId = member.sessionId;
                if (sessionId) {
                    _this.memberService.putMember(member);
                    var branId = _this.brand && _this.brand.id;
                    _this.checkingMembershipOfBrand(branId, function () {
                        if ((!member.membership || !member.membership.marketing) && _this.store) {
                            _this.openTermsModal(_this.brand, function (data) {
                                _this.loading(true);
                                _this.memberApiService.join(_this.store.id, _this.brand.id, data.marketing).subscribe(function (data) {
                                    _this.loading(false);
                                }, function (err) {
                                    console.log(err);
                                    _this.loading(false);
                                    _this.showAlert('Error ' + err.status, err._body);
                                }, function () {
                                    // this.configService.putPreference('termsAgree', data['agree']);
                                    _this.memberService.member.marketing = data.marketing;
                                    if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway')) {
                                        _this.deliveryHandling();
                                    }
                                    else {
                                        _this.dismissClicked({ openAddressDialog: false });
                                    }
                                });
                            }, TermsConditionsPage, true, true);
                        }
                        else {
                            if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway')) {
                                _this.deliveryHandling();
                            }
                            else {
                                _this.dismissClicked({ openAddressDialog: false });
                            }
                        }
                        return;
                    });
                    // this.push(MemberRewardPage);
                }
            }, function (err) {
                _this.loading(false);
                console.error(err);
                _this.showError(err);
            }, function () {
                _this.loading(false);
            });
        }
    };
    LoginModal.prototype.justShowTC = function () {
        _super.prototype.openTermsModal.call(this, this.brand, function () { }, TermsConditionsPage, false, false, false, false, true, true);
    };
    LoginModal.prototype.guestclick = function () {
        var hadLogin = this.configService.getLocal('aigens.member');
        if (!hadLogin) {
            this.guestLogin();
        }
        else {
            this.dismissClicked({ openAddressDialog: true });
        }
    };
    LoginModal.prototype.guestLogin = function () {
        var _this = this;
        this.loading(true);
        var s = UUID.UUID().toString();
        var brandId = this.brand && this.brand.id;
        if (brandId) {
            this.memberApiService.guestLogin(s, brandId).subscribe(function (member) {
                // this.loading(false);
                _this.memberService.putMember(member);
                // add the local to save the guestlogin member in order to upgrade the guest to member flow
                // this.configService.setLocal('guestLogin', member);
                if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway')) {
                    _this.deliveryHandling(true);
                }
                else {
                    _this.loading(false);
                    _this.dismissClicked({ openAddressDialog: false });
                }
            }, function (err) {
                _this.loading(false);
                _this.showError(err);
            });
        }
    };
    LoginModal.prototype.newLoginFlowNext = function () {
        console.log('newLoginFlowNext');
        if (this.emailCheckingAlertExist && this.firstTimeCheckingEamil) {
            this.firstTimeCheckingEamil = false;
            return;
        }
        if (this.phoneCheckingAlertExist && this.firstTimeCheckingPhone) {
            this.firstTimeCheckingPhone = false;
            return;
        }
        var params = {};
        params[this.mode] = this.form[this.mode];
        if (this.mode === 'phone') {
            params['countryCode'] = '+' + (this.form.countryCallingCode ? this.form.countryCallingCode : getCountryCallingCode());
        }
        if (this.mode === 'email') {
            if (!this.emailChangeHandle()) {
                return;
            }
            this.form.phone = null;
        }
        if (this.mode === 'phone') {
            if (!this.phoneChangeHandle()) {
                return;
            }
            this.form.email = null;
        }
        console.log('params', params);
        this.getIsRegister(params);
    };
    LoginModal.prototype.getIsRegister = function (params) {
        var _this = this;
        console.log('getIsRegister params', params);
        // maybe need to change the api to getIsRegister
        this.token = null;
        this.loading(true);
        this.memberApiService.getIsRegister(this.brand.id, this.store ? this.store.id : null, params).subscribe(function (res) {
            // isRegister = true
            console.log('getIsRegister res', res);
            _this.loading(false);
            _this.storeMatketing = res && res.exist && res.exist.membership;
            if (res && res.exist && res.exist[_this.mode]) {
                console.log('已经注册');
                _this.changeToEnterPW();
            }
            else {
                _this.switchRegisterFlowUi();
            }
        }, function (err) {
            console.log('getIsRegister res', err);
            _this.loading(false);
            _this.showError(err);
        });
    };
    LoginModal.prototype.changeToEnterPW = function () {
        console.log('changeToEnterPW');
        this.isEnterPWUi = true;
        setTimeout(function () {
            var loginPassword = document.getElementById('loginPassword');
            if (loginPassword)
                loginPassword.focus();
        }, 500);
    };
    LoginModal.prototype.switchRegisterFlowUi = function () {
        console.log('switchRegisterFlowUi');
        // this.pushByName('VerifyRegistrationPage', { fromPage: LoginModal, store: this.store });
        this.showRegisterFlowUi = true;
        this.initRegisterForm();
    };
    LoginModal.prototype.showCountryCallingCode = function () {
        return getCountryCallingCode();
    };
    LoginModal.prototype.backLastUi = function () {
        if (this.showResetPassword) {
            this.showResetPassword = false;
            return;
        }
        if (this.showRegisterFlowUi) {
            this.showRegisterFlowUi = false;
            this.showSecondsSms = false;
            this.secondsCount = this.basis;
            this.showVerifyCodeInput = false;
            clearInterval(this.secondsTimer);
            return;
        }
        if (this.isEnterPWUi) {
            this.isEnterPWUi = false;
            return;
        }
    };
    LoginModal.prototype.sendSms = function () {
        return __awaiter(this, void 0, void 0, function () {
            var alert_1;
            var _this = this;
            return __generator(this, function (_a) {
                console.log('sendSms');
                if (!this.phoneNumber.value)
                    return [2 /*return*/];
                if (!this.countryCallingCode.value) {
                    this.countryCallingCode.setValue(getCountryCallingCode());
                }
                if (!isPhoneValid(this.phoneNumber.value.toString(), findCountryByCodes(this.countryCallingCode.value.toString()))) {
                    if (!this.phoneCheckingAlertExist) {
                        alert_1 = this.alertController.create({
                            header: this.t.instant('global.wrong-phone-message'),
                            buttons: [{
                                    text: this.t.instant('buttons.ok'),
                                    handler: function () {
                                        _this.phoneCheckingAlertExist = false;
                                    }
                                }]
                        });
                        alert_1.then(function (data) { return data.present(); });
                    }
                    return [2 /*return*/];
                }
                this.countDown = this.basis;
                this.loading(true);
                this.memberApiService.sendSms(this.countryCallingCode.value, this.phoneNumber.value, this.store).subscribe(function (data) {
                    _this.loading(false);
                    _this.showVerifyCodeInput = true;
                    _this.showSecondsSms = true;
                    _this.secondsCount = _this.basis;
                    _this.secondsTimer = setInterval(function () {
                        _this.secondsCount--;
                        if (_this.secondsCount <= 0) {
                            _this.showSecondsSms = false;
                            clearInterval(_this.secondsTimer);
                        }
                    }, 1000);
                }, function (err) {
                    _this.loading(false);
                    _this.showError(err);
                });
                return [2 /*return*/];
            });
        });
    };
    LoginModal.prototype.clickReSend = function () {
        if (this.countDown <= 0) {
            this.sendSms();
            this.countDown = this.basis;
        }
    };
    LoginModal.prototype.verify = function (verifyCode) {
        var _this = this;
        this.loading(true);
        this.memberApiService.verifySms(verifyCode, this.countryCallingCode.value, this.phoneNumber.value).subscribe(function (data) {
            _this.loading(false);
            // this.submited = true;
            _this.token = data['token'];
            _this.secondsCount = 0;
            _this.showSecondsSms = false;
        }, function (err) {
            _this.loading(false);
            _this.showError(err);
        });
    };
    LoginModal.prototype.phoneNumberBlur = function (ev) {
        var _this = this;
        console.log('phoneNumberBlur');
        if (this.token) {
            this.showAlert('', '改变号码会重新发送验证!', [{
                    text: this.t.instant('buttons.ok'),
                    handler: function () {
                        _this.token = null;
                    }
                }, {
                    text: this.t.instant('buttons.cancel'),
                    handler: function () {
                    }
                }]);
        }
    };
    LoginModal.prototype.autoVerifySms = function (ev) {
        var val = ev && ev.detail && ev.detail.value;
        if (val.length === 6) {
            this.verify(val);
        }
    };
    LoginModal.prototype.clickRegister = function () {
        return __awaiter(this, void 0, void 0, function () {
            var input, controlsKeys, hasError, errMessage, newLine, i, control, errAlert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.registrationForm.valid) return [3 /*break*/, 1];
                        console.log('check pass ....');
                        input = {
                            email: this.email.value,
                            phone: this.phoneNumber.value,
                            password: this.password.value,
                        };
                        this.register();
                        return [3 /*break*/, 3];
                    case 1:
                        controlsKeys = Object.keys(this.registrationForm.controls);
                        hasError = false;
                        errMessage = '';
                        newLine = "\r\n";
                        for (i = 0; i < controlsKeys.length; i++) {
                            control = this.registrationForm.get(controlsKeys[i]);
                            if (control.errors) {
                                hasError = true;
                                // console.log('controlsKeys[i]',controlsKeys[i]);   0:"firstName"  1:"lastName"  2:"email"  3:"phoneNumber"  4:"gender"  5:"birthday"  6:"password"
                                if (control.hasError('required')) {
                                    // 必須填寫的
                                    // errMessage += this.translate.instant(`pages.registration.messageInvaild${controlsKeys[i]}Required`);
                                    errMessage += controlsKeys[i].replace(/_/, ' ') + ' is required';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('first name format')) {
                                    // errMessage += this.translate.instant("pages.registration.messageInvaildFirstNameFormat");
                                    errMessage += 'Please input First Name in English';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('last name format')) {
                                    // errMessage += this.translate.instant("pages.registration.messageInvaildLastNameFormat");
                                    errMessage += 'Please input Last Name in English';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('email format')) {
                                    // email format error
                                    // errMessage += this.translate.instant("pages.registration.messageInvaildEmailFormat");
                                    errMessage += 'Invalid Email';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('mobile phone invaild')) {
                                    // phone 格式不正確
                                    errMessage += 'Invalid Mobile No.';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('minlength')) {
                                    // password最少8位
                                    // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                                    errMessage += controlsKeys[i].replace(/_/, ' ') + ' requires at least 8 characters with at least one letter and one number';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('invalid password format')) {
                                    /// password必須包含一個字母和一個數字
                                    // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                                    errMessage += controlsKeys[i].replace(/_/, ' ') + ' requires at least 8 characters with at least one letter and one number';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('equalTo')) {
                                    /// password必須包含一個字母和一個數字
                                    // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                                    errMessage += 'Confirm password is not equal to password';
                                    errMessage += newLine;
                                }
                            }
                        }
                        if (!(hasError && errMessage.length > 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.alertController.create({
                                // message: this.translate.instant("pages.registration.noticeAlertTitle"),
                                message: errMessage,
                                // buttons: [this.translate.instant("pages.registration.ok")],
                                buttons: ['Confirm'],
                                cssClass: 'alert-counter' /*app.scss */
                            })];
                    case 2:
                        errAlert = _a.sent();
                        // errAlert.setMessage(errMessage);
                        errAlert.present();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LoginModal.prototype.openTermsModal = function (brand, handler, page, needToAgreeMarketing, isFb, callback) {
        if (needToAgreeMarketing === void 0) { needToAgreeMarketing = false; }
        if (isFb === void 0) { isFb = false; }
        return __awaiter(this, void 0, void 0, function () {
            var url, params, modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = getTandCUrl(brand, 'regTc');
                        if (!url) return [3 /*break*/, 2];
                        params = { url: url, needToAgreeMarketing: needToAgreeMarketing };
                        if (isFb) {
                            params['fbHandler'] = handler;
                        }
                        params['brandName'] = brand.name;
                        return [4 /*yield*/, this.modalController.create({ component: page, componentProps: params, backdropDismiss: false })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (result) {
                            if (result && handler && !isFb) {
                                handler(result);
                            }
                            callback && callback();
                        });
                        modal.present();
                        return [3 /*break*/, 3];
                    case 2:
                        this.showAlert(this.t.instant('pages.base.missing-tnc'), '');
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LoginModal.prototype.register = function (data) {
        var _this = this;
        console.log(data);
        var registerInfo = {
            email: this.email.value,
            password: this.password.value,
            token: this.token,
            storeId: this.store ? this.store.id : null
        };
        if (this.gender.value) {
            registerInfo['gender'] = this.gender.value;
        }
        if (this.birthday.value) {
            registerInfo['birthdate'] = new Date(this.birthday.value).getTime().toString();
        }
        if (this.lastName.value) {
            registerInfo['lastName'] = this.lastName.value;
        }
        if (this.firstName.value) {
            registerInfo['firstName'] = this.firstName.value;
        }
        // test delivery flow
        // this.popUpAdressModal({});
        // this.pushAndRemovePage('AddressDialog', {}, null, () => {
        // });
        // this.loading(false);
        // return;
        // new flow upgrade or register
        this.loading(true);
        this.upgradeOrRegisterationHandle(function () {
            // call api to 注册 new member
            _this.memberApiService.registeration(registerInfo).subscribe(function (member) {
                // this.loading(false);
                console.log('registered member: ', member);
                // this.memberService.putMember(member);、
                var sessionId = member.sessionId;
                if (sessionId) {
                    _this.memberService.putMember(member);
                    _this.memberApiService.join(_this.store ? _this.store.id : null, _this.brand.id, _this.form.marketing).subscribe(function () {
                        // this.loading(false);
                        if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway')) {
                            _this.loginDeliveryHandling();
                        }
                        else {
                            _this.loading(false);
                            _this.pushAndRemovePage('MemberRewardPage', {}, null, function () {
                            });
                        }
                    }, function (err) {
                        console.log(err);
                        _this.loading(false);
                        _this.showAlert('Error ' + err.status, err._body);
                    }, function () {
                        console.log('finished registration');
                        _this.loading(false);
                    });
                }
            }, function (err) {
                console.log(err);
                _this.loading(false);
                _this.showAlert('Error ' + err.status, err.error);
            });
        });
    };
    LoginModal.prototype.upgradeOrRegisterationHandle = function (registerationHandler) {
        var _this = this;
        var gusetMember = this.configService.getLocal('aigens.member');
        if (gusetMember && gusetMember['method'] === 'device' && false) {
            // hide the pizzzhut demo guestUpgradeMember flow (api err)
            this.guestUpgradeMember(function () {
                _this.loginDeliveryHandling();
            });
        }
        else {
            registerationHandler();
        }
    };
    LoginModal.prototype.login = function (handler) {
        // var username = "garyon.wong@aigens.com"//"peter.liu@aigens.com";
        // var password = "soda1123";
        var _this = this;
        var username = this.email.value;
        var password = this.password.value;
        this.memberApiService.login(username, password, 'email').subscribe(function (member) {
            console.log(member);
            var sessionId = member.sessionId;
            if (sessionId) {
                _this.memberService.putMember(member);
                handler();
                // this.push(MemberRewardPage);
            }
        }, function (err) {
            console.log(err);
            _this.loading(false);
            _this.showAlert('Error ' + err.status, err._body);
        }, function () {
            _this.loading(false);
        });
    };
    LoginModal.prototype.loginDeliveryHandling = function () {
        var _this = this;
        var needRecomend = this.settings.aiRecommendCount && this.settings.aiRecommendCount > 0;
        var fields = ['addresses', 'membership', 'offers'];
        needRecomend && fields.push('recommend');
        if (this.store && this.orderManager.mode) {
            // get address data
            this.orderSessionService.getOrderSession(this.store.id, this.orderManager.mode, fields).subscribe(function (orderSession) {
                if (orderSession) {
                    if (_this.memberService.member && _this.memberService.member.phone) {
                        orderSession.member['phone'] = _this.memberService.member.phone;
                    }
                    _this.configService.setLocal('orderSession', orderSession);
                    var addresses = orderSession.member && orderSession.member.addresses;
                    if (addresses) {
                        _this.addressManagerService.addressArr = addresses;
                        _this.configService.setLocal('addressArrOfStoreList', addresses);
                    }
                    // this.popToRoot();
                    _this.dismissClicked({ openAddressDialog: true });
                }
                else {
                    console.log('register fail');
                    return;
                }
                _this.loading(false);
            }, function (err) {
                _this.loading(false);
                console.log('getReward error', err);
            });
        }
        else {
            this.loading(false);
            console.log('can not find store id or this.orderManager.mode, so geting address data by calling address.json not session.json');
            this.addressManagerService.getAddressesFromAddressJson().subscribe(function (addressArr) {
                console.log('getAddressesFromAddressJson success addressArr', addressArr);
                _this.configService.setLocal('addressArrOfStoreList', addressArr);
                _this.dismissClicked({ openAddressDialog: false });
            }, function (err) {
                console.log('getAddressesFromAddressJson error', err);
            });
        }
    };
    LoginModal.prototype.emailChangeHandle = function () {
        console.log('on email change:', this.form.email);
        if (this.form.email && !isEmailValid(this.form.email)) {
            var alert_2 = this.alertController.create({
                header: this.t.instant('global.wrong-email-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert_2.then(function (data) { return data.present(); });
            this.emailCheckingAlertExist = true;
            return false;
        }
        this.emailCheckingAlertExist = false;
        return true;
    };
    LoginModal.prototype.emailChangeHandleOfAccount = function () {
        console.log('on Account email change:', this.email.value);
        if (this.email.value && !isEmailValid(this.email.value)) {
            var alert_3 = this.alertController.create({
                header: this.t.instant('global.wrong-email-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert_3.then(function (data) { return data.present(); });
            return false;
        }
        return true;
    };
    LoginModal.prototype.phoneChangeHandle = function () {
        console.log('on componenet phone change:', this.form.phone);
        if (this.form.phone && !isPhoneValid(this.form.phone, findCountryByCodes(this.form.countryCallingCode))) {
            var alert_4 = this.alertController.create({
                header: this.t.instant('global.wrong-phone-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert_4.then(function (data) { return data.present(); });
            this.phoneCheckingAlertExist = true;
            return false;
        }
        this.phoneCheckingAlertExist = false;
        return true;
    };
    LoginModal.prototype.phoneChangeHandleOfAccount = function () {
        var _this = this;
        console.log('on componenet Account phone change:', this.phoneNumber.value);
        if (this.phoneNumber.value && !isPhoneValid(this.phoneNumber.value.toString(), findCountryByCodes(this.countryCallingCode.value.toString()))) {
            var alert_5 = this.alertController.create({
                header: this.t.instant('global.wrong-phone-message'),
                buttons: [{
                        text: this.t.instant('buttons.ok'),
                        handler: function () {
                            _this.phoneCheckingAlertExist = false;
                        }
                    }]
            });
            alert_5.then(function (data) { return data.present(); });
            this.phoneCheckingAlertExist = true;
            return false;
        }
        this.phoneCheckingAlertExist = false;
        return true;
    };
    LoginModal.prototype.backButtonClick = function () {
        if (this.isEnterPWUi) {
            this.isEnterPWUi = false;
            return;
        }
        else {
            _super.prototype.backButtonClick.call(this);
        }
    };
    LoginModal.prototype.changeCountryCode = function () {
        var _this = this;
        var el = document.getElementsByClassName('alert-radio-label');
        setTimeout(function () {
            // console.log("el[index]:",el);
            _this.countryCodes.map(function (countryCode, index) {
                var imageUrl = '../../../../../assets/images/flags/' + countryCode.country + '/flat/64.png';
                if (el[index]) {
                    var parent_1 = el[index].parentElement;
                    if (parent_1) {
                        parent_1.setAttribute('style', 'border-bottom: 1px solid #e8e8e8;');
                    }
                    if (_this.orderService.countryCode === countryCode.code) {
                        el[index].scrollIntoView();
                    }
                    el[index].innerHTML =
                        "\n                    <div style=\"display: flex;\">\n                    <img style=\"width: 16px;height: 17px;margin-right: 10px;\" src=\"" + imageUrl + "\">\n                    <span>+" + countryCode.code + "</span>\n                    </div>\n                ";
                }
            });
        }, 150);
    };
    LoginModal.prototype.getAddressField = function () {
        var _this = this;
        var countryCodes = this.storeService.storeCountryCodesArr;
        if (countryCodes && countryCodes.length > 0) {
            this.countryCodes = countryCodes;
            !this.countryCallingCode && (this.form.countryCallingCode = countryCodes[0].code);
            return;
        }
        if (this.brand.id && this.brand.id !== 5746650488242176) {
            this.loading(true);
            this.storeService.getStoreRegionsByBandId(this.brand.id).subscribe(function (res) {
                _this.loading(false);
                if (res) {
                    // save the regions data
                    console.log('getAddressField', res);
                    if (res.form) {
                        var countryCodes_1 = res.form.countryCodes;
                        countryCodes_1.map(function (item, index) {
                            countryCodes_1[index] ? countryCodes_1[index].code = item.code.slice(1) : null;
                        });
                        console.log('countryCodes', countryCodes_1);
                        _this.countryCodes = countryCodes_1;
                        _this.form.countryCallingCode = countryCodes_1[0].code;
                    }
                    else {
                        var defaultCodes = [
                            { country: 'ID', code: '62' },
                            { country: 'SG', code: '65' },
                            { country: 'HK', code: '852' },
                            { country: 'CN', code: '86' }
                        ];
                        _this.countryCodes = defaultCodes;
                        _this.form.countryCallingCode = defaultCodes[0].code;
                        // this.showry_form ');
                        // this.showAlert('', 'server no return the country_form ');
                    }
                    _this.storeService.setStoreCountryCodesArr(_this.countryCodes);
                }
            }, function (err) {
                _this.loading(false);
                _this.showError(err);
                // todo  handle this condition
            });
        }
    };
    // guest upgrade to member
    LoginModal.prototype.guestUpgradeMember = function (callBack) {
        var _this = this;
        var upgradeInfo = {};
        if (this.email.value)
            upgradeInfo['email'] = this.email.value;
        if (this.password.value)
            upgradeInfo['password'] = this.password.value;
        // if (this.phoneNumber.value) upgradeInfo['phone'] = this.phoneNumber.value;
        if (this.brand && this.brand.id)
            upgradeInfo['brandId'] = this.brand.id;
        if (this.countryCallingCode.value)
            upgradeInfo['countryCode'] = '+' + this.countryCallingCode.value;
        if (this.form.marketing)
            upgradeInfo['marketing'] = this.form.marketing;
        // todo guard the upgradeInfo
        this.memberApiService.guestUpgradeMember(upgradeInfo).subscribe(function (res) {
            console.log('guestUpgradeMember success', res);
            res && _this.memberService.putMember(res); // update the local member
            // todo maybe call session.json to get data to update the local ordersession
            if (res && callBack) {
                callBack(res);
            }
        }, function (err) {
            _this.loading(false);
            _this.showError(err);
        });
    };
    LoginModal.prototype.resetPasswordClose = function () {
        this.showResetPassword = false;
    };
    return LoginModal;
}(MobileBasePage));
export { LoginModal };
