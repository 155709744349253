import {BaseService} from '../base/base-service';
import {Member, Offer} from 'aigens-ng-core';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import { AQuery } from '../base/aquery';


@Injectable({providedIn: 'root'})
export class MemberService extends BaseService {

    country: string;
    offers: Offer[];
    hasMembership = false;
    isUrlsessionFlow = false;
    mode: 'member' | 'anno' = 'anno';
    uuid: string;
    name: string;
    memberSubscribe: Subject<Member>;
    member: Member;
    private hadCheckMemberShip = false;
    crmId: string;
    aq: AQuery;

    bookmarksMap: any = {};
    canBookmark = false;

    constructor(public configs: ConfigService) {
        super();
        this.memberSubscribe = new Subject();
        this.loadAigensMember();
    }

    loadAigensMember() {

        let orderSession = this.configs.getLocal('orderSession');
        let member = this.configs.getLocal('aigens.member');
        if (member){
        // if (orderSession && orderSession.member){
            // orderSession 里面的member没有Session 所以拿Preference.member替换
            // this.configs.getPreference('aigens.member').then((member) => {
            //     if (member) {
            //         orderSession.member['sessionId'] = member.sessionId;
            //         this.member = orderSession.member;
            //         console.log("!!!!!!!!!!!!!!!!loadAigensMember", this.member);

            //         this.configs.setSessionId(member.sessionId);
            //     }

            //     this.memberSubscribe.next(this.member);
            // });

            // this.configs.setSessionId(member.sessionId);
            this.putMember(member);
        }else{
            this.memberSubscribe.next(null);
        }


        // this.configs.getPreference('aigens.member').then((member) => {
        //     debugger;
        //     console.log('member loaded', member);

        //     if (member) {
        //         this.member = member;
        //         this.configs.setSessionId(this.member.sessionId);
        //     }

        //     this.memberSubscribe.next(member);

        //     console.log('sub fired');

        // });

        this.configs.getPreference('crmId').then((crmId) => {

            console.log('court crmId loaded', crmId);

            if (crmId) {
                this.crmId = crmId;
            }

        });
    }

    putMember(member: Member) {
        this.member = member;
        console.log('!!!!!!!!!!!!!this.member', this.member, this.memberSubscribe);
        this.memberSubscribe.next(member);
        this.configs.setSessionId(this.member.sessionId);
        this.configs.setLocal('aigens.member', this.member); // 判别登录的唯一标准, orderSession 在storelist page cant call (not exist store.id)
        // this.configs.storage.set('aigens.member', member);

    }

    isAdmin() {

        const member = this.getMember();
        if (member) {
            return member.admin === true;
        }

        return false;

    }

    clearSession() {

        this.member = null;
        this.configs.clearSessionId();
        this.configs.storage.set('aigens.member', null);
        this.configs.setLocal('session.expire', null);
        this.configs.setLocal('aigens.member', null);
    }

    clear() {
        this.clearSession();
        this.offers = [];
        this.hasMembership = false;
    }


    // getSessionId(): string {
    //
    //     const member = this.getMember();
    //     if (member) {
    //         return member.sessionId;
    //     }
    //     return this.configs.getCookie('sid');
    // }


    getMember(): Member {

        return this.member;
    }

    isGuest() {
        let member = this.member;
        return !!(member && member['method'] === 'device');
    }

    isMember() {
        let member = this.member;
        return !!(member && member['method'] !== 'device');
    }

    setHadCheckMemberShip(bool: boolean) {
        this.hadCheckMemberShip = bool;
    }

    // true ==> had check
    getIsHadCheckMemberShip(): boolean {
        return this.hadCheckMemberShip;
    }

    isLogin() {
        // let member = this.configs.getLocal('aigens.member')
        return !!(this.member);
    }


    initMember(mode = 'anno') {
        // if (mode === 'anno') {
        //     this.mode = 'anno';
        //     // make a fake member;
        //     if (this.configs.getLocal('anno.uuid') && this.configs.getLocal('anno.name')) {
        //         this.uuid = this.configs.getLocal('anno.uuid');
        //         this.name = this.configs.getLocal('anno.name');
        //     } else {
        //         this.uuid = UUID.UUID();
        //         // this.name = new chance().name();
        //         this.configs.setLocal('anno.uuid', this.uuid);
        //         this.configs.setLocal('anno.name', this.name);
        //
        //     }
        //
        // }
    }

    isBookmarked( id: string ): boolean {
        return this.bookmarksMap[id];
    }

    addBookmark(id: string) {
        this.bookmarksMap[id] = true;
    }

    removeBookmark(id: string) {
        this.bookmarksMap[id] = false;
    }

    manualRedeemStampCardReward(stampCardTemplateId, stampIdx) {
        let url = '/api/v1/reward/offer.json';
        let aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.shouldAuth = true;

        let params = {};
        params['action'] = 'redeemstamp';
        params['membershipId'] = this.member['membership']['id'];
        params['stampCardTemplateId'] = stampCardTemplateId;
        params['stamp'] = stampIdx;

        aq.params = params;

        return aq.auth(true).getJson().map(jo => jo);
    }
}
