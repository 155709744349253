

<ion-content [ngClass]="{'isModalPopup': true}" class="content-signup-or-acc">
    
    <div class="div-title">
        {{"pages.byod-summary.order-help-title"|translate}}
    </div>

    <div class="div-choices">
        <div class="choice-button">
            <!-- sg need call phone( add the countryCode) -->
            <a *ngIf="orderService?.store && orderService.store['fullPhone']" [href]="'tel:' + orderService.store['fullPhone']">
                    <ion-icon name="call"></ion-icon>
                    {{"pages.byod-summary.call-restaurant"|translate}}
            </a>
            <!-- fullPhone 不存在就显示 lacation.phone -->
            <a *ngIf="!(orderService?.store && orderService.store['fullPhone'])" [href]="'tel:' + orderService?.store?.location?.phone">
                    <ion-icon name="call"></ion-icon>
                    {{"pages.byod-summary.call-restaurant"|translate}}
            </a>
        </div>
    </div>
</ion-content>