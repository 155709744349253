import { Component, OnInit, Injector } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { AddressDialog } from 'src/app/dialogs/address-dialog/address-dialog.component';
import { getSetLayout } from '../../utilities/layout-helper';
import { RouteTable } from 'src/app/core/route-table';
import { LoginModal } from 'src/app/dialogs/login-modal/login-modal.page';

enum PageAction {
  ClickedMember = 1,
  ClickedProfile,
  ClickedMyOrder,
  ClickedFavourite,
  ClickedCoupon,
  ClickedPromotion,
  ClickedHome

}

interface Page {
  title: string;
  index: number;
  disabled: boolean;
  action: PageAction;
  badge?: number;
  transalteStr: string;
  onlyLoginShow: boolean,
  show: boolean
}

@Component({
  selector: 'app-main-slide-menu',
  templateUrl: './main-slide-menu.component.html',
  styleUrls: ['./main-slide-menu.component.scss'],
})
export class MainSlideMenuComponent extends MobileBasePage implements OnInit {

  currentMode = 'dinein';
  currentLanguage = 'zh';
  pages: Page[] = [];

  constructor(injector: Injector) {
    super(injector);
    console.log('MainSlideMenuComponent', this.memberService.getMember());
    this.initPages();

  }

  changeLang() {
    console.log('changeLang');

  }
  logout() {
    console.log('logout');

    let confirm = this.genAlertButton('Confirm', () => {
      this.toggleMenu();
      // logout
    });
    let cancel = this.genAlertButton('Cancel');
    this.showAlert(null, 'Are you sure Logout?', [cancel, confirm]);

  }

  login() {
    this.presentLoginModal({
      store: this.orderService.store,
      fromPage: 'ByodSplashPage',
      isRoute: false
    });
    this.toggleMenu();
  }

  async presentLoginModal(params?, callback?) {
    let modal = await this.modalController.create(
      {
        component: LoginModal,
        componentProps: params,
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: 'modal-largescreen addressDialog-modal login-modal-splash'
      });

    modal.onDidDismiss().then(res => {
      let data = res.data;
      console.log("byod-splash dismiss popUpAdressModal", data);
      if (data && data['close']) {
        if (callback)
          callback();
      }
    });
    modal.present().then(() => {
      //  cb()
    });
  }

  editAddress() {
    console.log('editAddress');
    this.popUpAdressModal({
      goToMenu: true,
      fromPage: 'ByodSplashPage',
      showModeSelect: true,
      // addAddressCb: () => {
      //   this.popUpAdressModal({ orderItems: this.orderManager.order.orderitems }); // in order to after adding address successly but the modal is close and can not to select currentAddress
      // }
    });
    this.toggleMenu();
  }

  private initPages() {
    this.pages = [
      // { title: 'MEMBER', index: 0, disabled: true, action: PageAction.ClickedMember, transalteStr: 'pages.main-slide-menu.member', onlyLoginShow: false , show: true},
      { title: 'PROFILE', index: 1, disabled: true, action: PageAction.ClickedProfile, transalteStr: 'pages.main-slide-menu.profile', onlyLoginShow: true , show: true},
      { title: 'MY CART', index: 2, disabled: false, action: PageAction.ClickedMyOrder, transalteStr: 'pages.main-slide-menu.my-cart', onlyLoginShow: false , show: true},
      // { title: 'FAVOURITE', index: 3, disabled: true, action: PageAction.ClickedFavourite, transalteStr: 'pages.main-slide-menu.favourite', onlyLoginShow: false , show: true},
      // { title: 'COUPON', index: 4, disabled: true, action: PageAction.ClickedCoupon, transalteStr: 'pages.main-slide-menu.coupon', onlyLoginShow: false , show: true},
      // { title: 'PROMOTION', index: 5, disabled: true, action: PageAction.ClickedPromotion, transalteStr: 'pages.main-slide-menu.promotion', onlyLoginShow: false , show: true},
      { title: 'HOME', index: 6, disabled: false, action: PageAction.ClickedHome, transalteStr: 'pages.main-slide-menu.home', onlyLoginShow: false , show: true}
    ];
  }

  isShowTab(boolean){
    return boolean ? (this.memberService.getMember() && this.memberService.getMember()['method'] != 'device') : true;
  }

  ngOnInit() { }

  async popUpAdressModal(params?, callback?) {
    let modal = await this.modalController.create(
      {
        component: AddressDialog,
        componentProps: params,
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: 'modal-largescreen addressDialog-modal'
      });

    modal.onDidDismiss().then(res => {
      let data = res.data;
      console.log('byod-splash dismiss popUpAdressModal', data);
      if (data && data['close']) {
        if (callback)
          callback();
      }
    });
    modal.present().then(() => {
      //  cb()
    });
  }
  getAddressDisplay() {
    let address = this.addressManagerService.currentAddress;
    if (address.name && address.name.length > 0) {
      return address.name;
    } else {
      return address['street'];
    }
  }

  selectedMode(e) {

    let mode = e.detail.value;
    this.currentMode = mode;


    console.log('JJ: MainSlideMenuComponent -> selectedMode -> e', this.currentMode);


  }
  cartClicked() {
    if (false && !this.memberService.getMember() && (this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
      // beacuase of forcing login in blashpage so no need follow code
      console.log('not login and isDelivery ###', this.orderManager.getOrder());

      // if (this.orderManager.getOrder().orderitems.length > 0){
      //     // save the current Order
      //     this.configService.putPreference(this.orderManager.store.id + '.savedOrder', this.orderManager.getOrder());
      // }

      // popUp delivery-login within modal
      // this.popUpDeliveryLogin();

      // force to push delivery-login within route
      // this.pushByName('DeliveryLoginPage', {
      //     store: this.store,
      //     fromPage: 'CategoryListPage',
      //     isRoute: true
      // });

    } else {
      let page = getSetLayout(this.systemConfigService);
      if (page === 'ComboPage') {
        // new layout process
        if (this.orderService.isCourt ? this.multOrderManager.getOrders.length > 0 : this.orderManager.isEmpty()) {
          const warning = this.t.instant('global.order-first');
          const confirm = this.t.instant('buttons.ok');
          this.showAlert('', warning, [confirm]);
        } else {
          this.pushByUrl((this.multOrderManager.enableMutlOrder ? RouteTable['MultiOrderCartPage'] : RouteTable['CartScrollPage']), { store: this.orderService.store });
        }
      } else {
        console.log('on cart clicked');
        this.googleAnalyticEventTrack('shopping cart');
        if (this.multOrderManager.enableMutlOrder && this.multOrderManager.getOrderCount() > 0) {
          this.pushByUrl(RouteTable['MultiOrderCartPage']);
        } else if (!this.multOrderManager.enableMutlOrder && !this.orderManager.isEmpty()) {
          this.pushByName('CartScrollPage', {}, { relativeTo: this.route.root.firstChild });
        } else {
          const subtitle = this.t.instant('global.order-first');
          const confirm = this.t.instant('buttons.ok');
          const alert = this.alertController.create({
            subHeader: subtitle,
            buttons: [confirm]
          });
          alert.then(data => data.present());
        }
      }
    }

  }

  profileClicked() {
    if (this.systemConfigService.hideStampPage) {
      this.pushByName('ProfilePage');
    } else {
      this.pushByName('MemberRewardPage');
    }
  }
  openPage(page: Page) {
    console.log('JJ: MainSlideMenuComponent -> openPage -> page', page);
    this.toggleMenu();

    let action: PageAction = page.action;

    switch (action) {
      case PageAction.ClickedMember:
        break;
      case PageAction.ClickedProfile:
        this.profileClicked();
        break;
      case PageAction.ClickedMyOrder:
        this.cartClicked();
        break;
      case PageAction.ClickedFavourite:
        break;
      case PageAction.ClickedCoupon:
        break;
      case PageAction.ClickedPromotion:
        break;
      case PageAction.ClickedHome:
        this.backButtonClick();
        break;
    }

  }


  ionDidClose() {
    this.menuController.swipeEnable(false, this.MAIN_MENU);
  }

  ionDidOpen() {
    this.menuController.swipeEnable(true, this.MAIN_MENU);
  }
}
