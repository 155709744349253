var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector, OnInit, EventEmitter } from '@angular/core';
import { MemberService } from '../../../core/services/member.service';
import { Brand } from 'aigens-ng-core';
import { MobileBasePage } from '../../../core/base/mobile-base-page';
import { FormBuilder, Validators } from '@angular/forms';
import { confirmPasswordFormatValidator, getCountryCallingCode, isEmailValid, isPhoneValid, passwordFormatValidator, findCountryByCodes } from '../../../shared/utilities/checkFormatTool';
import { StoreService } from 'src/app/core/services/store.service';
var ResetPasswordPage = /** @class */ (function (_super) {
    __extends(ResetPasswordPage, _super);
    function ResetPasswordPage(injector, formBuilder, memberService, storeService) {
        var _this = _super.call(this, injector) || this;
        _this.formBuilder = formBuilder;
        _this.memberService = memberService;
        _this.storeService = storeService;
        _this.submited = false;
        _this.sent = false;
        _this.reSent = false;
        _this.basis = 180;
        _this.countDown = _this.basis;
        _this.changePhoneCounter = 0;
        _this.isForgotPassword = false;
        _this.mode = 'email';
        _this.hasCrmId = false;
        // component parmas
        _this.hideHeader = false;
        _this.isModal = false;
        _this.resetPasswordClose = new EventEmitter();
        _this.countryCodes = [];
        return _this;
        // this.navCtrl.remove(1, 2);
    }
    ResetPasswordPage.prototype.initResetPage = function () {
        this.isForgotPassword = this.getNavParams('isForgotPassword');
        console.log("ResetPasswordPage");
        if (this.isForgotPassword_c) {
            this.isForgotPassword = this.isForgotPassword_c;
        }
        this.creatForm();
        this.brand = this.getNavParams('brand');
        if (this.brand_c) {
            this.brand = this.brand_c;
        }
        if (this.brand) {
            this.hasCrmId = this.brand['crmId'] ? true : false;
            if (this.hasCrmId) {
                this.mode = 'email';
            }
        }
        this.username = this.getNavParams('username');
        if (this.username_c) {
            this.username = this.username_c;
        }
        if (this.username) {
            this.email = this.username;
            if (!isEmailValid(this.email)) {
                this.email = '';
                if (this.username.match(/^\d+$/)) {
                    this.phone = this.username;
                    this.mode = 'phone';
                    var countryCallingCode = this.getNavParams('countryCallingCode');
                    if (this.countryCallingCode_c) {
                        countryCallingCode = this.countryCallingCode_c;
                    }
                    if (countryCallingCode) {
                        this.countryCallingCode = countryCallingCode;
                    }
                }
            }
        }
    };
    ResetPasswordPage.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.initResetPage();
        this.getAddressField();
    };
    ResetPasswordPage.prototype.selectMode = function (type) {
        this.mode = type;
    };
    // TODO
    ResetPasswordPage.prototype.showCountryCallingCode = function () {
        return getCountryCallingCode();
    };
    ResetPasswordPage.prototype.requestResetCode = function () {
        if (this.mode === 'phone') {
            this.sendSms();
        }
        else {
            this.sendEmail();
        }
    };
    ResetPasswordPage.prototype.sendEmail = function () {
        var _this = this;
        if (!isEmailValid(this.email)) {
            this.showAlert('', this.t.instant('pages.verify-registration.invalid-email'));
            return;
        }
        this.countDown = this.basis;
        this.loading(true);
        this.memberApiService.requestResetCode(this.email, 'email', null, this.orderManager.store, this.brand).subscribe(function (data) {
            _this.loading(false);
            _this.sent = true;
        }, function (err) {
            _this.loading(false);
            _this.showError(err);
        });
    };
    ResetPasswordPage.prototype.sendSms = function () {
        var _this = this;
        if (!this.countryCallingCode) {
            this.countryCallingCode = getCountryCallingCode();
        }
        if (!isPhoneValid(this.phone, findCountryByCodes(this.countryCallingCode))) {
            this.showAlert('', this.t.instant('pages.verify-registration.invalid-phone'));
            return;
        }
        if (this.changePhoneCounter <= 1) {
            this.countDown = this.basis;
            this.loading(true);
            this.memberApiService.requestResetCode(this.phone, 'phone', this.countryCallingCode).subscribe(function (data) {
                _this.loading(false);
                _this.sent = true;
            }, function (err) {
                _this.loading(false);
                _this.showError(err);
            });
        }
        else {
            this.sent = true;
            this.countDown = this.basis * this.changePhoneCounter;
        }
        this.changePhoneCounter++;
    };
    ResetPasswordPage.prototype.clickReSend = function () {
        if (this.countDown <= 0 || this.mode === 'email') {
            this.requestResetCode();
            if (this.mode === 'phone') {
                this.changePhoneCounter--;
                this.countDown = this.basis;
            }
        }
    };
    ResetPasswordPage.prototype.verify = function () {
        var _this = this;
        this.loading(true);
        var address = this.mode === 'phone' ? this.phone : this.email;
        if (!this.countryCallingCode && this.mode === 'phone') {
            this.countryCallingCode = getCountryCallingCode();
        }
        else if (this.mode !== 'phone') {
            this.countryCallingCode = null;
        }
        this.memberApiService.verifyResetCode(this.mode, address, this.token, this.countryCallingCode).subscribe(function (data) {
            _this.loading(false);
            _this.submited = true;
            _this.token = data['token'];
        }, function (err) {
            _this.loading(false);
            _this.showError(err);
        });
    };
    ResetPasswordPage.prototype.resetPassword = function () {
        var _this = this;
        this.loading(true);
        if (this.isForgotPassword) {
            this.memberApiService.resetPassword(this.password.value, this.confirmPassword.value, this.token).subscribe(function (data) {
                _this.loading(false);
                _this.showAlert('', _this.t.instant('pages.login.reset-password-success'), [{
                        text: _this.t.instant('buttons.ok'),
                        handler: function () {
                            if (_this.isModal) {
                                _this.resetPasswordClose.emit();
                            }
                            else {
                                _this.navigationService.popPage();
                            }
                        }
                    }]);
            }, function (err) {
                _this.loading(false);
                _this.showError(err);
            });
        }
        else {
            // To add resetPassword function
            this.memberApiService.resetPasswordWithOldPassword(this.oldPassword.value, this.password.value, this.confirmPassword.value).subscribe(function (data) {
                _this.loading(false);
                _this.showAlert('', _this.t.instant('pages.login.reset-password-success'), [{
                        text: _this.t.instant('buttons.ok'),
                        handler: function () {
                            if (_this.isModal) {
                                _this.resetPasswordClose.emit();
                            }
                            else {
                                _this.navigationService.popPage();
                            }
                        }
                    }]);
            }, function (err) {
                _this.loading(false);
                _this.showError(err);
            });
        }
    };
    // 請輸入你嘅驗證碼XXXXXX，登記成為新會員！
    ResetPasswordPage.prototype.handleAndroidSms = function (field) {
        if (!field) {
            return;
        }
        var num = field.replace(/[^0-9]/ig, '');
        // console.log('num: ', num);
        if (!isNaN(parseInt(num)) && num.length >= 6) {
            this.token = num.substr(0, 6);
        }
    };
    ResetPasswordPage.prototype.onRefreshUI = function () {
        if (this.mode === 'phone' && this.sent && this.countDown > 0) {
            this.countDown--;
        }
    };
    ResetPasswordPage.prototype.getUIRefreshInterval = function () {
        return this.isForgotPassword ? 1000 : 0;
    };
    ResetPasswordPage.prototype.creatForm = function () {
        if (this.isForgotPassword) {
            this.resetPasswordForm = this.formBuilder.group({
                Password: [],
                Confirm_Password: []
            });
        }
        else {
            this.resetPasswordForm = this.formBuilder.group({
                Old_Password: [],
                Password: [],
                Confirm_Password: []
            });
            this.oldPassword = this.resetPasswordForm.get('Old_Password');
            this.oldPassword.setValidators(Validators.compose([Validators.required, Validators.minLength(8), passwordFormatValidator]));
        }
        this.password = this.resetPasswordForm.get('Password');
        this.password.setValidators(Validators.compose([Validators.required, Validators.minLength(8), passwordFormatValidator]));
        this.confirmPassword = this.resetPasswordForm.get('Confirm_Password');
        this.confirmPassword.setValidators(Validators.compose([Validators.required, Validators.minLength(8), confirmPasswordFormatValidator]));
        // this.gender = this.registrationForm.get('Gender');
        // this.birthday = this.registrationForm.get('Birthday');
        // this.firstName = this.registrationForm.get('First_Name'); this.firstName.setValidators(Validators.compose([Validators.required, firstNameFormatValidator]));
        // this.lastName = this.registrationForm.get('Last_Name');
        // this.lastName.setValidators(Validators.compose([Validators.required, lastNameFormatValidator]));
    };
    ResetPasswordPage.prototype.clickResetPassword = function () {
        return __awaiter(this, void 0, void 0, function () {
            var controlsKeys, hasError, errMessage, newLine, i, control, errAlert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.resetPasswordForm.valid) return [3 /*break*/, 1];
                        console.log('check pass ....');
                        this.resetPassword();
                        return [3 /*break*/, 3];
                    case 1:
                        controlsKeys = Object.keys(this.resetPasswordForm.controls);
                        hasError = false;
                        errMessage = '';
                        newLine = "\r\n";
                        for (i = 0; i < controlsKeys.length; i++) {
                            control = this.resetPasswordForm.get(controlsKeys[i]);
                            if (control.errors) {
                                hasError = true;
                                // console.log('controlsKeys[i]',controlsKeys[i]);   0:"firstName"  1:"lastName"  2:"email"  3:"phoneNumber"  4:"gender"  5:"birthday"  6:"password"
                                if (control.hasError('required')) {
                                    // 必須填寫的
                                    // errMessage += this.translate.instant(`pages.registration.messageInvaild${controlsKeys[i]}Required`);
                                    errMessage += controlsKeys[i].replace(/_/, ' ') + ' is required';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('minlength')) {
                                    // password最少8位
                                    // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                                    errMessage += controlsKeys[i].replace(/_/, ' ') + ' has at least 8 characters with at least one letter and one number';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('invalid password format')) {
                                    /// password必須包含一個字母和一個數字
                                    // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                                    errMessage += controlsKeys[i].replace(/_/, ' ') + ' has at least 8 characters with at least one letter and one number';
                                    errMessage += newLine;
                                }
                                else if (control.hasError('equalTo')) {
                                    /// password必須包含一個字母和一個數字
                                    // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                                    errMessage += 'Confirm password is not equal to password';
                                    errMessage += newLine;
                                }
                            }
                        }
                        if (!(hasError && errMessage.length > 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.alertController.create({
                                // message: this.translate.instant("pages.registration.noticeAlertTitle"),
                                message: errMessage,
                                // buttons: [this.translate.instant("pages.registration.ok")],
                                buttons: ['Confirm'],
                                cssClass: 'alert-counter' /*app.scss */
                            })];
                    case 2:
                        errAlert = _a.sent();
                        errAlert.present();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ResetPasswordPage.prototype.getAddressField = function () {
        var _this = this;
        var countryCodes = this.storeService.storeCountryCodesArr;
        if (countryCodes && countryCodes.length > 0) {
            this.countryCodes = countryCodes;
            !this.countryCallingCode && (this.countryCallingCode = countryCodes[0].code);
            return;
        }
        if (this.brand.id && this.brand.id !== 5746650488242176) {
            this.storeService.getStoreRegionsByBandId(this.brand.id).subscribe(function (res) {
                if (res) {
                    // save the regions data
                    console.log('getAddressField', res);
                    if (res.form) {
                        var countryCodes_1 = res.form.countryCodes;
                        countryCodes_1.map(function (item, index) {
                            countryCodes_1[index] ? countryCodes_1[index].code = item.code.slice(1) : null;
                        });
                        console.log('countryCodes', countryCodes_1);
                        _this.countryCodes = countryCodes_1;
                        _this.countryCallingCode = countryCodes_1[0].code;
                    }
                    else {
                        var defaultCodes = [
                            { country: 'ID', code: '62' },
                            { country: 'SG', code: '65' },
                            { country: 'HK', code: '852' },
                            { country: 'CN', code: '86' }
                        ];
                        _this.countryCodes = defaultCodes;
                        _this.countryCallingCode = defaultCodes[0].code;
                    }
                    _this.storeService.setStoreCountryCodesArr(_this.countryCodes);
                }
            }, function (err) {
                _this.showError(err);
                // todo  handle this condition
            });
        }
    };
    ResetPasswordPage.prototype.backButtonClick = function () {
        this.googleAnalyticEventTrack('back');
        // if (!this.isForgotPassword) {
        //     this.popToRoot();
        //     return;
        // }
        if (this.submited && this.sent) {
            _super.prototype.backButtonClick.call(this);
            return;
        }
        if (this.submited) {
            this.submited = false;
            return;
        }
        if (this.sent) {
            this.sent = false;
            return;
        }
        _super.prototype.backButtonClick.call(this);
    };
    ResetPasswordPage.prototype.changeCountryCode = function () {
        var _this = this;
        var el = document.getElementsByClassName('alert-radio-label');
        setTimeout(function () {
            // console.log("el[index]:",el);
            _this.countryCodes.map(function (countryCode, index) {
                var imageUrl = '../../../../../assets/images/flags/' + countryCode.country + '/flat/64.png';
                if (el[index]) {
                    var parent_1 = el[index].parentElement;
                    if (parent_1) {
                        parent_1.setAttribute('style', 'border-bottom: 1px solid #e8e8e8;');
                    }
                    if (_this.orderService.countryCode === countryCode.code) {
                        el[index].scrollIntoView();
                    }
                    el[index].innerHTML =
                        "\n                    <div style=\"display: flex;\">\n                    <img style=\"width: 16px;height: 17px;margin-right: 10px;\" src=\"" + imageUrl + "\">\n                    <span>+" + countryCode.code + "</span>\n                    </div>\n                ";
                }
            });
        }, 150);
    };
    return ResetPasswordPage;
}(MobileBasePage));
export { ResetPasswordPage };
