import { Brand, Store, Link } from 'aigens-ng-core';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { AddressDialog } from 'src/app/dialogs/address-dialog/address-dialog.component';
import { NavParams } from '@ionic/angular';
import { UUID } from 'angular2-uuid';
import { MemberService } from 'src/app/core/services/member.service';
import { getCountryCallingCode, isPhoneValid, findCountryByCodes, firstNameFormatValidator, lastNameFormatValidator, emailFormatValidator, phoneValidator, passwordFormatValidator, confirmPasswordFormatValidator, getTandCUrl, isEmailValid } from 'src/app/shared/utilities/checkFormatTool';
import { TermsConditionsPage } from '../terms-conditions/terms-conditions';
import { FormGroup, AbstractControl, Validators, FormBuilder } from '@angular/forms';
import { StoreService } from 'src/app/core/services/store.service';

declare var FB: any;

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.page.html',
    styleUrls: ['./login-modal.page.scss'],
})
export class LoginModal extends MobileBasePage implements OnInit, OnDestroy {

    form: any = {
        marketing: true
    };

    status = '';
    fbui: any;
    brand: Brand;
    store: Store;
    loginCallBack: any;
    googleLoginClicked = false;
    googleLoginHandler: any;
    mode = 'email';

    hasCrmId = false;

    isPopup = false;
    fromPage: string;

    facebookLogin = false;
    googleLogin = false;

    public navParams;
    isFirstLoad = true;

    isEnterPWUi = false;
    showRegisterFlowUi = false;
    isLoginWaysSelecting = true;
    showResetPassword = false;


    sent = false;
    basis = 180;
    countDown = this.basis;
    smscode: string;
    token: string;

    // register message ui data
    registrationForm: FormGroup;
    currentGenderServer: string;
    genders: Array<{ serverString: string, clientString: string }>;
    birthdayText = '';

    isReceivePromotion = true;
    isReceiveTAC = true;

    firstName: AbstractControl;
    lastName: AbstractControl;
    email: AbstractControl;
    countryCallingCode: AbstractControl;
    phoneNumber: AbstractControl;
    verifyCode: AbstractControl;
    gender: AbstractControl;
    birthday: AbstractControl;
    password: AbstractControl;
    confirmPassword: AbstractControl;
    maxDateStr: string;
    links: Link;
    showVerifyCodeInput = false;
    showSecondsSms = false;
    secondsCount = this.basis;
    secondsTimer: any;

    TACKeyObj: any;
    countryCodes = [];
    storeMatketing = false;

    emailCheckingAlertExist = false;
    firstTimeCheckingEamil = true;
    phoneCheckingAlertExist = false;
    firstTimeCheckingPhone = true;

    constructor(injector: Injector, public memberService: MemberService, public formBuilder: FormBuilder, public storeService: StoreService) {
        super(injector);

        console.log('LoginModal constructor');
        this.navParams = injector.get(NavParams);
        this.store = this.navParams.get('store');
        this.isLoginWaysSelecting = this.navParams.get('isLoginWaysSelecting');

        let brand = this.navParams.get('brand');
        if (brand) {
            this.brand = brand;
        } else if (this.store) {
            this.brand = this.store.brand;
        }
        let member = this.memberService.getMember();
        if (member) {
            if (member['method'] === 'device') {
                // no need logout because of the check phone or email isRegister need sid.
            } else
                this.memberService.clearSession();
        }

        if (this.brand) {
            // this.brand['crmId'] = ""
            this.TACKeyObj = this.brand;
            this.hasCrmId = this.brand['crmId'] ? true : false;
            this.facebookLogin = this.brand.hasFeature('flogin') && navigator.userAgent.indexOf('AlipayClient') < 0;
            this.googleLogin = this.brand.hasFeature('glogin') && navigator.userAgent.indexOf('AlipayClient') < 0;
        }
        if (this.googleLogin) {
            // this.initGoogle();
            this.googleLoginHandler = (e) => {
                const data = e.detail;
                this.googleLoginHandling(data.id, data.token);
            };
            window.addEventListener('google-login-handling', this.googleLoginHandler);
        }

        console.log('this.isLoginWaysSelecting', this.isLoginWaysSelecting);

        window['checkFBLoginState'] = () => {
            console.log('window[\'checkFBLoginState\']');
            this.checkFBLoginState();
        };
    }

    changeToLoginAndRegister() {
        this.isLoginWaysSelecting = false;
        this.getAddressField();
    }

    initRegisterForm() {
        this.maxDateStr = new Date().toISOString();
        this.creatForm();
        const male = this.t.instant('global.male');
        const female = this.t.instant('global.female');
        const other = this.t.instant('global.other');

        // let male = "Male";
        // let female = "Female";
        // let other = "Others";

        this.genders = [
            { serverString: 'M', clientString: male },
            { serverString: 'F', clientString: female },
            { serverString: 'O', clientString: other }];
    }

    creatForm() {
        this.registrationForm = this.formBuilder.group({
            First_Name: [],
            Last_Name: [],
            Gender: [],
            Birthday: [],
            Email: [],
            CountryCallingCode: [],
            Phone_Number: [],
            VerifyCode: [],
            Password: [],
            Confirm_Password: []
        });
        this.firstName = this.registrationForm.get('First_Name');
        this.firstName.setValidators(Validators.compose([firstNameFormatValidator]));

        this.lastName = this.registrationForm.get('Last_Name');
        this.lastName.setValidators(Validators.compose([lastNameFormatValidator]));

        this.email = this.registrationForm.get('Email');
        this.email.setValidators(Validators.compose([Validators.required, emailFormatValidator]));
        if (this.form && this.form.email) {
            this.email.setValue(this.form.email);
        }

        this.countryCallingCode = this.registrationForm.get('CountryCallingCode');
        this.countryCallingCode.setValidators(Validators.compose([Validators.required]));
        if (this.form && this.form.countryCallingCode) {
            this.countryCallingCode.setValue(this.form.countryCallingCode);
        }

        this.phoneNumber = this.registrationForm.get('Phone_Number');
        this.phoneNumber.setValidators(Validators.compose([Validators.required, phoneValidator]));
        if (this.form && this.form.phone) {
            this.phoneNumber.setValue(this.form.phone);
        }

        this.verifyCode = this.registrationForm.get('VerifyCode');
        this.verifyCode.setValidators(Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)]));

        this.password = this.registrationForm.get('Password');
        this.password.setValidators(Validators.compose([Validators.required, Validators.minLength(8), passwordFormatValidator]));

        this.confirmPassword = this.registrationForm.get('Confirm_Password');
        this.confirmPassword.setValidators(Validators.compose([Validators.required, Validators.minLength(8), confirmPasswordFormatValidator]));
        this.gender = this.registrationForm.get('Gender');
        this.birthday = this.registrationForm.get('Birthday');
    }

    dismissClicked(params?) {
        console.log('delivery login modal closing');
        this.modalController.dismiss(params);
    }

    ionViewDidEnter() {
        if (this.isFirstLoad) {
            this.initGoogle();

        }
        this.initFB();

        this.isFirstLoad = false;
    }

    ngOnInit() {
        super.ngOnInit();
        if (!this.isLoginWaysSelecting)
            this.getAddressField();
    }

    getCountryCallingCode() {
        return getCountryCallingCode();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        window.removeEventListener('google-login-handling', this.googleLoginHandler);

        if (this.facebookLogin) {
            let fbsdk = document.getElementById('facebook-jssdk');
            const head = document.getElementsByTagName('head')[0];
            if (fbsdk && head) {
                head.removeChild(fbsdk);
                window['FB'] = null;
            }
        }
        window['checkFBLoginState'] = null;

    }


    // login
    submitClicked() {

        console.log('login clicking', this.form);
        const username = (this.form[this.mode] ? this.form[this.mode] : '');
        const password = (this.form['password'] ? this.form['password'] : '');
        let countryCallingCode = this.form['countryCallingCode'];
        const tmpSet = new Set(username);
        let type = tmpSet.has('@') ? 'email' : 'phone';

        if (!countryCallingCode && type === 'phone') {
            countryCallingCode = getCountryCallingCode();
        }

        if (this.mode !== type) {
            if (this.mode === 'email') {
                this.showAlert(null, this.t.instant('pages.login.input-mismatch-email'));
                return;
            }
            if (this.mode === 'phone') {
                this.showAlert(null, this.t.instant('pages.login.input-mismatch-phone'));
                return;
            }
            return;
        }
        let brandId = null;
        if (this.hasCrmId) {

            if (!this.brand || !this.brand.id) {
                this.showAlert(null, 'Missing Brand ID');
                return;
            }
            if (!username) {
                this.showAlert(null, this.t.instant('pages.login.usernameEmpty'));
                return;
            }
            if (!password) {
                this.showAlert(null, this.t.instant('pages.login.passwordEmpty'));
                return;
            }

            type = 'crm';
            countryCallingCode = null;
            brandId = this.brand.id;
        }

        this.loading(true);
        this.memberApiService.login(username, password, type, countryCallingCode, brandId).subscribe(member => {
            this.memberService.clearSession();
            this.memberService.putMember(member);
            let branId = this.brand && this.brand.id;
            this.checkingMembershipOfBrand(branId, (has) => {
                if (has && has[0]) {
                    // call api to get current member's address
                    if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
                        this.deliveryHandling(true);
                        return;
                    } else {
                        this.loading(false);
                        this.dismissClicked({ openAddressDialog: false });
                    }
                    // this.loading(false);
                } else {
                    // 直接加入 join membership when membership not exist
                    this.memberApiService.join(this.store ? this.store.id : null, this.brand.id, this.form.marketing).subscribe((result) => {
                        console.log('result', result);
                        if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
                            this.deliveryHandling(true);
                            return;
                        } else {
                            this.loading(false);
                            this.dismissClicked({ openAddressDialog: false });
                        }

                    }, (err) => {
                        this.showError(err);
                        this.loading(false);
                    });
                }
            }, false);


        }, err => {
            this.loading(false);
            console.error(err);
            this.showError(err);
        });

    }

    deliveryHandling(existLoading?) {
        let needRecomend = this.settings.aiRecommendCount && this.settings.aiRecommendCount > 0;
        let fields = ['addresses', 'membership', 'offers'];
        needRecomend && fields.push('recommend');
        if (this.store && this.orderManager.mode) {
            if(!existLoading) this.loading(true);
            this.orderSessionService.getOrderSession(this.store.id, this.orderManager.mode, fields).subscribe(orderSession => {
                this.loading(false);
                if (orderSession) {
                    let member = orderSession.member;
                    if (this.memberService.member) {
                        if (this.memberService.member.phone)
                            orderSession.member['phone'] = this.memberService.member.phone;
                        orderSession.member.marketing = this.memberService.member.marketing;
                    }
                    if (this.store)
                        this.orderSessionService.setLocalAddress(this.addressManagerService.calAddressesDistance(member.addresses, this.store));

                    this.configService.setLocal('orderSession', orderSession);
                    let addresses = member.addresses;
                    if (addresses) {
                        this.addressManagerService.addressArr = addresses;
                        this.configService.setLocal('addressArrOfStoreList', addresses);
                    }
                    if (member.addresses && member.addresses.length > 0) {
                        let addresses = member.addresses;
                        this.addressManagerService.addressArr = addresses;
                        this.dismissClicked({ openAddressDialog: true });
                    } else {
                        this.dismissClicked({ openAddressDialog: true });
                    }
                } else {
                    return;
                }
            }, err => {
                this.loading(false);
                console.log('getReward error', err);
            });
        } else {
            if(!existLoading) this.loading(true);
            console.log('can not find store id or this.orderManager.mode, so geting address data by calling address.json not session.json');
            this.addressManagerService.getAddressesFromAddressJson().subscribe(addressArr => {
                this.loading(false);
                console.log('getAddressesFromAddressJson success addressArr', addressArr);
                this.configService.setLocal('addressArrOfStoreList', addressArr);
                this.dismissClicked({ openAddressDialog: false });
            }, err => {
                this.loading(false);
                console.log('getAddressesFromAddressJson error', err);
            });
        }

    }
    // checkIsFaceBookLogined() {
    //     FB.getLoginStatus((response) => {
    //         console.log('FB getLoginStatus', response, response.status);
    //     });
    // }

    onFacebookLoginClick() {
        this.loading(true);

        FB.login((response) => {

            if (response.status === 'connected') {
                // Logged into your app and Facebook.
                const data = response.authResponse;
                this.fbLoginHandling(data['userID'], data['accessToken'], true);

            } else {
                this.loading(false);
                this.showAlert('Login Fail', '');
                // The person is not logged into this app or we are unable to tell.
            }
        }, { auth_type: 'reauthenticate' });

    }

    checkFBLoginState() {
        console.log('checkFBLoginState', this);
        FB.getLoginStatus(response => {
            if (response.status === 'connected') {
                // Logged into your app and Facebook.
                const data = response.authResponse;
                this.fbLoginHandling(data['userID'], data['accessToken'], true);

            } else {
                this.showAlert('Login Fail', '');
                // The person is not logged into this app or we are unable to tell.
            }
        });
    }

    fbLoginHandling(fbId: string, token: string, join: boolean = false) {
        // todo handle store.id when open this modal in storelist page
        this.memberApiService.FBloginOrRegistration(fbId, token, true, (this.store ? this.store.id : null), this.brand.id, true).subscribe((member) => {
            console.log('FBloginOrRegistration', member);
            // this.loading(false);
            const sessionId = member.sessionId;
            // this.showAlert("",JSON.stringify(member));
            if (sessionId) {
                this.memberService.putMember(member);
                let branId = this.brand && this.brand.id;
                this.checkingMembershipOfBrand(branId, () => {
                    if ((!member.membership || !member.membership.marketing) && this.store) {
                        this.openTermsModal(this.brand, (data) => {
                            // this.configService.putPreference('termsAgree', data['agree']);
                            this.memberApiService.join(this.store.id, this.brand.id, data.marketing).subscribe((result) => {
                                // this.loading(false);
                            }, err => {
                                console.log(err);
                                // this.loading(false);
                                this.showAlert('Error ' + err.status, err._body);
                            }, () => {
                                this.memberService.member.marketing = data.marketing;
                                if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
                                    this.deliveryHandling(true);
                                } else {
                                    this.loading(false);
                                    this.dismissClicked({openAddressDialog : false});
                                }
                            });
                        }, TermsConditionsPage, true, true);
                    } else {
                        if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
                            this.deliveryHandling(true);
                        } else {
                            this.loading(false);
                            this.dismissClicked({openAddressDialog : false});
                        }
                    }
                });
            }
        }, (err) => {
            this.loading(false);
            console.log(err);
            this.showError(err);
        });

    }




    registration() {
        if (this.store)
            this.pushByName('VerifyRegistrationPage', { fromPage: LoginModal, store: this.store });
    }

    resetPassword() {
        let store = this.orderManager.store;
        if (!store && this.store)
            store = this.store;
        if (store && store.crm && store.crm.links && store.crm.links['forgetpw']) {
            // open in new tab
            window.open(store.crm.links['forgetpw']['url']);
        } else {
            this.showResetPassword = true;
            // this.pushByName('ResetPasswordPage', { username: this.form[this.mode], countryCallingCode: this.form.countryCallingCode, isForgotPassword: true, brand: this.brand });
        }
    }
    private initFB() {
        console.log('ly: -> login-modal.page.ts ->initFB');
        if (this.facebookLogin) {
            this.configService.loadScriptJS('/assets/script/fb-sdk.js', () => {
                console.log('ly: -> login-modal.page.ts -> fbLoaded');
            }, null, true);

        }
    }
    initGoogle() {
        window['onGoogleSignIn'] = this.onSignIn;
        const params = {};
        params['id'] = 'google-login-script';
        this.configService.loadScript('https://apis.google.com/js/platform.js?onload=renderButton', () => {
        }, params);
    }


    onSignIn(googleUser) {
        const profile = googleUser.getBasicProfile();
        const id = profile.getId();
        const id_token = googleUser.getAuthResponse().id_token;

        console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
        console.log('token:' + id_token);
        const evt = new CustomEvent('google-login-handling', {
            detail: {
                id: id,
                token: id_token
            }
        });

        window.dispatchEvent(evt);

    }

    clickGoogleLogin() {
        this.googleLoginClicked = true;
    }

    selectMode(type: string) {
        this.mode = type;
    }

    googleLoginHandling(id: string, token: string) {
        if (this.googleLoginClicked) {
            this.loading(true);
            this.memberApiService.googleLogin(id, token).subscribe(
                member => {
                    console.log(member);
                    this.loading(false);
                    const sessionId = member.sessionId;
                    if (sessionId) {
                        this.memberService.putMember(member);
                        let branId = this.brand && this.brand.id;
                        this.checkingMembershipOfBrand(branId, () => {
                            if ((!member.membership || !member.membership.marketing) && this.store) {
                                this.openTermsModal(this.brand, (data) => {
                                    this.loading(true);
                                    this.memberApiService.join(this.store.id, this.brand.id, data.marketing).subscribe((data) => {
                                        this.loading(false);
                                    }, err => {
                                        console.log(err);
                                        this.loading(false);
                                        this.showAlert('Error ' + err.status, err._body);
                                    }, () => {
                                        // this.configService.putPreference('termsAgree', data['agree']);
                                        this.memberService.member.marketing = data.marketing;
                                        if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
                                            this.deliveryHandling();
                                        } else {
                                            this.dismissClicked({openAddressDialog : false});
                                        }
                                    });

                                }, TermsConditionsPage, true, true);
                            } else {
                                if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
                                    this.deliveryHandling();
                                }else{
                                    this.dismissClicked({openAddressDialog : false});
                                }
                            }
                            return;

                        });
                        // this.push(MemberRewardPage);
                    }
                },
                err => {
                    this.loading(false);
                    console.error(err);
                    this.showError(err);
                },
                () => {
                    this.loading(false);
                }
            );

        }
    }

    justShowTC() {
        super.openTermsModal(this.brand, () => { }, TermsConditionsPage, false, false, false, false, true, true);
    }

    guestclick() {
        let hadLogin = this.configService.getLocal('aigens.member');
        if (!hadLogin) {
            this.guestLogin();
        } else {
            this.dismissClicked({ openAddressDialog: true });
        }
    }

    guestLogin() {
        this.loading(true);
        let s = UUID.UUID().toString();
        let brandId = this.brand && this.brand.id;
        if (brandId) {
            this.memberApiService.guestLogin(s, brandId).subscribe((member) => {
                // this.loading(false);
                this.memberService.putMember(member);
                // add the local to save the guestlogin member in order to upgrade the guest to member flow
                // this.configService.setLocal('guestLogin', member);
                if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
                    this.deliveryHandling(true);
                } else {
                    this.loading(false);
                    this.dismissClicked({openAddressDialog : false});
                }
            }, err => {
                this.loading(false);
                this.showError(err);
            });
        }
    }

    newLoginFlowNext() {
        console.log('newLoginFlowNext');
        if (this.emailCheckingAlertExist && this.firstTimeCheckingEamil) {
            this.firstTimeCheckingEamil = false;
            return;
        }
        if (this.phoneCheckingAlertExist && this.firstTimeCheckingPhone) {
            this.firstTimeCheckingPhone = false;
            return;
        }
        let params = {};
        params[this.mode] = this.form[this.mode];

        if (this.mode === 'phone') {
            params['countryCode'] = '+' + (this.form.countryCallingCode ? this.form.countryCallingCode : getCountryCallingCode());
        }

        if (this.mode === 'email') {
            if (!this.emailChangeHandle()) {
                return;
            }
            this.form.phone = null;
        }

        if (this.mode === 'phone') {
            if (!this.phoneChangeHandle()) {
                return;
            }
            this.form.email = null;
        }
        console.log('params', params);

        this.getIsRegister(params);

    }

    getIsRegister(params) {
        console.log('getIsRegister params', params);
        // maybe need to change the api to getIsRegister
        this.token = null;
        this.loading(true);
        this.memberApiService.getIsRegister(this.brand.id, this.store ? this.store.id : null, params).subscribe((res) => {
            // isRegister = true
            console.log('getIsRegister res', res);
            this.loading(false);
            this.storeMatketing = res && res.exist && res.exist.membership;
            if (res && res.exist && res.exist[this.mode]) {
                console.log('已经注册');
                this.changeToEnterPW();
            } else {
                this.switchRegisterFlowUi();
            }
        }, err => {
            console.log('getIsRegister res', err);
            this.loading(false);
            this.showError(err);
        });

    }

    changeToEnterPW() {
        console.log('changeToEnterPW');
        this.isEnterPWUi = true;
        setTimeout(() => {
            let loginPassword = <HTMLInputElement>document.getElementById('loginPassword');
            if (loginPassword) loginPassword.focus();
        }, 500);
    }

    switchRegisterFlowUi() {
        console.log('switchRegisterFlowUi');
        // this.pushByName('VerifyRegistrationPage', { fromPage: LoginModal, store: this.store });
        this.showRegisterFlowUi = true;
        this.initRegisterForm();
    }

    showCountryCallingCode() {
        return getCountryCallingCode();
    }

    backLastUi() {
        if (this.showResetPassword) {
            this.showResetPassword = false;
            return;
        }
        if (this.showRegisterFlowUi) {
            this.showRegisterFlowUi = false;
            this.showSecondsSms = false;
            this.secondsCount = this.basis;
            this.showVerifyCodeInput = false;
            clearInterval(this.secondsTimer);
            return;
        }
        if (this.isEnterPWUi) {
            this.isEnterPWUi = false;
            return;
        }
    }

    async sendSms() {

        console.log('sendSms');
        if(!this.phoneNumber.value) return;
        if (!this.countryCallingCode.value) {
            this.countryCallingCode.setValue(getCountryCallingCode());
        }

        if (!isPhoneValid(this.phoneNumber.value.toString(), findCountryByCodes(this.countryCallingCode.value.toString()))) {
            if (!this.phoneCheckingAlertExist) {
                const alert = this.alertController.create({
                    header: this.t.instant('global.wrong-phone-message'),
                    buttons: [{
                        text: this.t.instant('buttons.ok'),
                        handler: () => {
                            this.phoneCheckingAlertExist = false;
                        }
                    }]
                });
                alert.then(data => data.present());
            }
            return;
        }

        this.countDown = this.basis;
        this.loading(true);
        this.memberApiService.sendSms(this.countryCallingCode.value, this.phoneNumber.value, this.store).subscribe((data) => {
            this.loading(false);
            this.showVerifyCodeInput = true;
            this.showSecondsSms = true;
            this.secondsCount = this.basis;
            this.secondsTimer = setInterval(() => {
                this.secondsCount--;
                if (this.secondsCount <= 0) {
                    this.showSecondsSms = false;
                    clearInterval(this.secondsTimer);
                }
            }, 1000);
        }, (err) => {
            this.loading(false);
            this.showError(err);
        });

    }



    clickReSend() {
        if (this.countDown <= 0) {
            this.sendSms();
            this.countDown = this.basis;
        }
    }

    verify(verifyCode) {
        this.loading(true);
        this.memberApiService.verifySms(verifyCode, this.countryCallingCode.value, this.phoneNumber.value).subscribe((data) => {
            this.loading(false);
            // this.submited = true;
            this.token = data['token'];
            this.secondsCount = 0;
            this.showSecondsSms = false;

        }, (err) => {
            this.loading(false);
            this.showError(err);
        });
    }

    phoneNumberBlur(ev) {
        console.log('phoneNumberBlur');

        if (this.token) {
            this.showAlert('', '改变号码会重新发送验证!', [{
                text: this.t.instant('buttons.ok'),
                handler: () => {
                    this.token = null;
                }
            }, {
                text: this.t.instant('buttons.cancel'),
                handler: () => {
                }
            }]);
        }
    }

    autoVerifySms(ev) {
        let val = ev && ev.detail && ev.detail.value;
        if (val.length === 6) {
            this.verify(val);
        }
    }

    async clickRegister() {
        if (this.registrationForm.valid) {
            console.log('check pass ....');


            const input = {
                email: this.email.value,
                phone: this.phoneNumber.value,
                password: this.password.value,
                // firstName: this.firstName.value,
                // lastName: this.lastName.value,
                // marketing: this.isReceivePromotion
            };
            this.register();
            // this.openTermsModal(this.orderManager.store.brand, (data) => {
            //     if (data) {
            //         this.register(data);
            //     }
            // }, TermsConditionsPage, true, false);

        } else {
            const controlsKeys = Object.keys(this.registrationForm.controls);
            let hasError = false;
            let errMessage = '';
            const newLine = `\r\n`;

            for (let i = 0; i < controlsKeys.length; i++) {
                const control: AbstractControl = this.registrationForm.get(controlsKeys[i]);

                if (control.errors) {
                    hasError = true;

                    // console.log('controlsKeys[i]',controlsKeys[i]);   0:"firstName"  1:"lastName"  2:"email"  3:"phoneNumber"  4:"gender"  5:"birthday"  6:"password"
                    if (control.hasError('required')) {
                        // 必須填寫的

                        // errMessage += this.translate.instant(`pages.registration.messageInvaild${controlsKeys[i]}Required`);
                        errMessage += controlsKeys[i].replace(/_/, ' ') + ' is required';
                        errMessage += newLine;

                    } else if (control.hasError('first name format')) {
                        // errMessage += this.translate.instant("pages.registration.messageInvaildFirstNameFormat");
                        errMessage += 'Please input First Name in English';
                        errMessage += newLine;
                    } else if (control.hasError('last name format')) {
                        // errMessage += this.translate.instant("pages.registration.messageInvaildLastNameFormat");
                        errMessage += 'Please input Last Name in English';
                        errMessage += newLine;
                    } else if (control.hasError('email format')) {
                        // email format error
                        // errMessage += this.translate.instant("pages.registration.messageInvaildEmailFormat");
                        errMessage += 'Invalid Email';
                        errMessage += newLine;
                    } else if (control.hasError('mobile phone invaild')) {
                        // phone 格式不正確
                        errMessage += 'Invalid Mobile No.';
                        errMessage += newLine;
                    } else if (control.hasError('minlength')) {
                        // password最少8位
                        // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                        errMessage += controlsKeys[i].replace(/_/, ' ') + ' requires at least 8 characters with at least one letter and one number';
                        errMessage += newLine;
                    } else if (control.hasError('invalid password format')) {
                        /// password必須包含一個字母和一個數字
                        // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                        errMessage += controlsKeys[i].replace(/_/, ' ') + ' requires at least 8 characters with at least one letter and one number';
                        errMessage += newLine;
                    } else if (control.hasError('equalTo')) {
                        /// password必須包含一個字母和一個數字
                        // errMessage += this.translate.instant("pages.registration.messageInvaildPasswordFormat");
                        errMessage += 'Confirm password is not equal to password';
                        errMessage += newLine;
                    }

                }
            }


            if (hasError && errMessage.length > 0) {
                const errAlert = await this.alertController.create({
                    // message: this.translate.instant("pages.registration.noticeAlertTitle"),
                    message: errMessage,

                    // buttons: [this.translate.instant("pages.registration.ok")],
                    buttons: ['Confirm'],
                    cssClass: 'alert-counter'/*app.scss */

                });
                // errAlert.setMessage(errMessage);
                errAlert.present();
            }

        }
    }

    async openTermsModal(brand: Brand, handler: any, page: any, needToAgreeMarketing: boolean = false, isFb: boolean = false, callback?) {
        const url = getTandCUrl(brand, 'regTc');
        if (url) {
            const params = { url: url, needToAgreeMarketing: needToAgreeMarketing };
            if (isFb) {
                params['fbHandler'] = handler;
            }
            params['brandName'] = brand.name;
            const modal = await this.modalController.create({ component: page, componentProps: params, backdropDismiss: false });
            modal.onDidDismiss().then((result) => {
                if (result && handler && !isFb) {
                    handler(result);
                }
                callback && callback();
            });

            modal.present();

        } else {
            this.showAlert(this.t.instant('pages.base.missing-tnc'), '');
        }

    }

    register(data?) {
        console.log(data);
        const registerInfo = {
            email: this.email.value,
            password: this.password.value,
            token: this.token,
            storeId: this.store ? this.store.id : null
        };
        if (this.gender.value) {
            registerInfo['gender'] = this.gender.value;
        }
        if (this.birthday.value) {
            registerInfo['birthdate'] = new Date(this.birthday.value).getTime().toString();
        }
        if (this.lastName.value) {
            registerInfo['lastName'] = this.lastName.value;
        }
        if (this.firstName.value) {
            registerInfo['firstName'] = this.firstName.value;
        }

        // test delivery flow
        // this.popUpAdressModal({});
        // this.pushAndRemovePage('AddressDialog', {}, null, () => {
        // });
        // this.loading(false);
        // return;

        // new flow upgrade or register
        this.loading(true);
        this.upgradeOrRegisterationHandle(() => {

            // call api to 注册 new member
            this.memberApiService.registeration(registerInfo).subscribe(member => {
                // this.loading(false);
                console.log('registered member: ', member);
                // this.memberService.putMember(member);、
                const sessionId = member.sessionId;
                if (sessionId) {

                    this.memberService.putMember(member);
                    this.memberApiService.join(this.store ? this.store.id : null, this.brand.id, this.form.marketing).subscribe(() => {
                        // this.loading(false);
                        if ((this.orderManager.mode === 'delivery' || this.orderManager.mode === 'takeaway')) {
                            this.loginDeliveryHandling();
                        } else {
                            this.loading(false);
                            this.pushAndRemovePage('MemberRewardPage', {}, null, () => {
                            });
                        }
                    }, err => {
                        console.log(err);
                        this.loading(false);
                        this.showAlert('Error ' + err.status, err._body);
                    }, () => {
                        console.log('finished registration');
                        this.loading(false);
                    });

                }


            }, err => {

                console.log(err);
                this.loading(false);
                this.showAlert('Error ' + err.status, err.error);

            });
        });


    }

    upgradeOrRegisterationHandle(registerationHandler) {
        let gusetMember = this.configService.getLocal('aigens.member');
        if (gusetMember && gusetMember['method'] === 'device' && false) {
            // hide the pizzzhut demo guestUpgradeMember flow (api err)
            this.guestUpgradeMember(() => {
                this.loginDeliveryHandling();
            });
        } else {
            registerationHandler();
        }
    }




    login(handler: any) {

        // var username = "garyon.wong@aigens.com"//"peter.liu@aigens.com";
        // var password = "soda1123";

        const username = this.email.value;
        const password = this.password.value;

        this.memberApiService.login(username, password, 'email').subscribe(
            member => {
                console.log(member);

                const sessionId = member.sessionId;
                if (sessionId) {

                    this.memberService.putMember(member);
                    handler();
                    // this.push(MemberRewardPage);
                }

            },
            err => {
                console.log(err);
                this.loading(false);
                this.showAlert('Error ' + err.status, err._body);
            },
            () => {
                this.loading(false);
            }
        );

    }

    loginDeliveryHandling() {
        let needRecomend = this.settings.aiRecommendCount && this.settings.aiRecommendCount > 0;
        let fields = ['addresses', 'membership', 'offers'];
        needRecomend && fields.push('recommend');
        if (this.store && this.orderManager.mode) {
            // get address data
            this.orderSessionService.getOrderSession(this.store.id, this.orderManager.mode, fields).subscribe(orderSession => {
                if (orderSession) {
                    if (this.memberService.member && this.memberService.member.phone) {
                        orderSession.member['phone'] = this.memberService.member.phone;
                    }
                    this.configService.setLocal('orderSession', orderSession);
                    let addresses = orderSession.member && orderSession.member.addresses;
                    if (addresses) {
                        this.addressManagerService.addressArr = addresses;
                        this.configService.setLocal('addressArrOfStoreList', addresses);
                    }
                    // this.popToRoot();
                    this.dismissClicked({ openAddressDialog: true });
                } else {
                    console.log('register fail');
                    return;
                }
                this.loading(false);
            }, err => {
                this.loading(false);
                console.log('getReward error', err);
            });
        } else {
            this.loading(false);
            console.log('can not find store id or this.orderManager.mode, so geting address data by calling address.json not session.json');
            this.addressManagerService.getAddressesFromAddressJson().subscribe(addressArr => {
                console.log('getAddressesFromAddressJson success addressArr', addressArr);
                this.configService.setLocal('addressArrOfStoreList', addressArr);
                this.dismissClicked({ openAddressDialog: false });
            }, err => {
                console.log('getAddressesFromAddressJson error', err);
            });
        }
    }

    emailChangeHandle(): boolean {
        console.log('on email change:', this.form.email);
        if (this.form.email && !isEmailValid(this.form.email)) {
            const alert = this.alertController.create({
                header: this.t.instant('global.wrong-email-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert.then(data => data.present());
            this.emailCheckingAlertExist = true;
            return false;
        }
        this.emailCheckingAlertExist = false;
        return true;
    }
    emailChangeHandleOfAccount() {
        console.log('on Account email change:', this.email.value);
        if (this.email.value && !isEmailValid(this.email.value)) {
            const alert = this.alertController.create({
                header: this.t.instant('global.wrong-email-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert.then(data => data.present());
            return false;
        }
        return true;
    }

    phoneChangeHandle(): boolean {
        console.log('on componenet phone change:', this.form.phone);
        if (this.form.phone && !isPhoneValid(this.form.phone, findCountryByCodes(this.form.countryCallingCode))) {
            const alert = this.alertController.create({
                header: this.t.instant('global.wrong-phone-message'),
                buttons: [this.t.instant('buttons.ok')]
            });
            alert.then(data => data.present());
            this.phoneCheckingAlertExist = true;
            return false;
        }
        this.phoneCheckingAlertExist = false;
        return true;
    }
    phoneChangeHandleOfAccount() {
        console.log('on componenet Account phone change:', this.phoneNumber.value);
        if (this.phoneNumber.value && !isPhoneValid(this.phoneNumber.value.toString(), findCountryByCodes(this.countryCallingCode.value.toString()))) {
            const alert = this.alertController.create({
                header: this.t.instant('global.wrong-phone-message'),
                buttons: [{
                    text: this.t.instant('buttons.ok'),
                    handler: () => {
                        this.phoneCheckingAlertExist = false;
                    }
                }]
            });
            alert.then(data => data.present());
            this.phoneCheckingAlertExist = true;
            return false;
        }
        this.phoneCheckingAlertExist = false;
        return true;
    }

    backButtonClick() {
        if (this.isEnterPWUi) {
            this.isEnterPWUi = false;
            return;
        } else {
            super.backButtonClick();
        }
    }

    changeCountryCode() {
        let el = document.getElementsByClassName('alert-radio-label');

        setTimeout(() => {
            // console.log("el[index]:",el);
            this.countryCodes.map((countryCode, index) => {
                let imageUrl = '../../../../../assets/images/flags/' + countryCode.country + '/flat/64.png';
                if (el[index]) {
                    let parent = el[index].parentElement;
                    if (parent) {
                        parent.setAttribute('style', 'border-bottom: 1px solid #e8e8e8;');

                    }
                    if (this.orderService.countryCode === countryCode.code) {
                        el[index].scrollIntoView();
                    }

                    el[index].innerHTML =
                        `
                    <div style="display: flex;">
                    <img style="width: 16px;height: 17px;margin-right: 10px;" src="${imageUrl}">
                    <span>+${countryCode.code}</span>
                    </div>
                `
                        ;
                }
            });

        }, 150);
    }

    getAddressField() {
        let countryCodes = this.storeService.storeCountryCodesArr;
        if (countryCodes && countryCodes.length > 0) {
            this.countryCodes = countryCodes;
            !this.countryCallingCode && (this.form.countryCallingCode = countryCodes[0].code);
            return;
        }
        if (this.brand.id && this.brand.id !== 5746650488242176) {
            this.loading(true);
            this.storeService.getStoreRegionsByBandId(this.brand.id).subscribe((res) => {
                this.loading(false);
                if (res) {
                    // save the regions data
                    console.log('getAddressField', res);
                    if (res.form) {
                        let countryCodes = res.form.countryCodes;
                        countryCodes.map((item, index) => {
                            countryCodes[index] ? countryCodes[index].code = item.code.slice(1) : null;
                        });
                        console.log('countryCodes', countryCodes);
                        this.countryCodes = countryCodes;
                        this.form.countryCallingCode = countryCodes[0].code;
                    } else {
                        let defaultCodes = [
                            { country: 'ID', code: '62' },
                            { country: 'SG', code: '65' },
                            { country: 'HK', code: '852' },
                            { country: 'CN', code: '86' }
                        ];
                        this.countryCodes = defaultCodes;
                        this.form.countryCallingCode = defaultCodes[0].code;
                        // this.showry_form ');

                        // this.showAlert('', 'server no return the country_form ');
                    }
                    this.storeService.setStoreCountryCodesArr(this.countryCodes);
                }
            }, err => {
                this.loading(false);
                this.showError(err);
                // todo  handle this condition
            });
        }

    }

    // guest upgrade to member
    guestUpgradeMember(callBack?) {
        let upgradeInfo = {};

        if (this.email.value) upgradeInfo['email'] = this.email.value;
        if (this.password.value) upgradeInfo['password'] = this.password.value;
        // if (this.phoneNumber.value) upgradeInfo['phone'] = this.phoneNumber.value;
        if (this.brand && this.brand.id) upgradeInfo['brandId'] = this.brand.id;
        if (this.countryCallingCode.value) upgradeInfo['countryCode'] = '+' + this.countryCallingCode.value;
        if (this.form.marketing) upgradeInfo['marketing'] = this.form.marketing;

        // todo guard the upgradeInfo
        this.memberApiService.guestUpgradeMember(upgradeInfo).subscribe((res) => {
            console.log('guestUpgradeMember success', res);

            res && this.memberService.putMember(res); // update the local member

            // todo maybe call session.json to get data to update the local ordersession
            if (res && callBack) {
                callBack(res);
            }

        }, err => {
            this.loading(false);
            this.showError(err);
        });
    }

    resetPasswordClose() {
        this.showResetPassword = false;
    }

}
