var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Address, Store, OrderManager, Order } from 'aigens-ng-core';
import { ConfigService } from './config.service';
import { AQuery } from '../base/aquery';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from '../base/base-service';
import { OrderSessionService } from './order-session.service';
import { MemberService } from './member.service';
import { SystemConfigService } from './systemConfig.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "aigens-ng-core/dist/manager/order-manager";
import * as i4 from "./order-session.service";
import * as i5 from "./member.service";
import * as i6 from "./systemConfig.service";
var AddressManagerService = /** @class */ (function (_super) {
    __extends(AddressManagerService, _super);
    function AddressManagerService(http, configs, orderManager, orderSessionService, memberService, systemConfigService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.orderManager = orderManager;
        _this.orderSessionService = orderSessionService;
        _this.memberService = memberService;
        _this.systemConfigService = systemConfigService;
        _this.couriers = [];
        _this.isDelivery = true; // the switch of the delivery-feature
        _this.isPickup = true; // the mark of pick up
        _this.pickupArr = [];
        _this.note = ''; // delivery order remarks
        _this.cutlery = false;
        _this.contactless = true;
        _this.isChanged = true;
        _this.isDefault = false;
        _this.isAddAddress = false; // control the add address seccuessly and then popup AddressDialog modal
        _this.aq = new AQuery(http, configs);
        _this.addressArr = [];
        _this.initContactless();
        return _this;
        // this.addressArrOfStoreList = [];
    }
    // addressArrOfStoreList: Address[];// store list page using address because cant call session.json to get address without storeId
    AddressManagerService.prototype.getAddresses = function (store, storesList) {
        var addresses = this.orderSessionService.getLocalAddresses(storesList);
        this.addressArr = this.calAddressesDistance(addresses, store);
        // this.addressArr.sort((a, b) => a['distance'] - b['distance']);
        return this.addressArr;
    };
    AddressManagerService.prototype.initContactless = function () {
        this.contactless = this.systemConfigService.contactlessInputIsChecked;
    };
    AddressManagerService.prototype.callCalculate = function (order, address, courierId) {
        var url = '/api/v1/menu/calculate.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.auth(true);
        var body = Order.toOrderData(order);
        // let params = {
        //   addressId: address['id'],
        //   phone: this.memberService.member && this.memberService.member['phone'],
        //   storeId: store['id'],
        //   type: 'delivery'
        // };
        // if (oi) params['orderitems'] = oi;
        // if (courierId) params['courierId'] = courierId;
        body['addressId'] = address['id'];
        if (courierId)
            body['courierId'] = courierId;
        aq.body = body;
        var search = {};
        if (this.orderManager && this.orderManager.store && this.orderManager.store.country) {
            search['country'] = this.orderManager.store.country;
        }
        aq.params = search;
        return aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    AddressManagerService.prototype.getAddressesFromAddressJson = function () {
        var _this = this;
        var url = '/api/v1/store/address.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            var addressArr = jo['data'];
            // this.setAddressArrOfStoreList(addressArr);
            _this.addressArr = addressArr.slice();
            return addressArr;
        }));
    };
    // setAddressArrOfStoreList(addressArr: Address[]) {
    //   this.addressArrOfStoreList = addressArr;
    // }
    // getAddressArrOfStoreList() {
    //   return this.addressArrOfStoreList && this.addressArrOfStoreList.length > 0 ? this.addressArrOfStoreList : [];
    // }
    AddressManagerService.prototype.getPositionByAddressId = function (addressId) {
        var address = this.addressArr.find(function (address) { return address['id'] === addressId; });
        return this.getPositionByAddress(address);
    };
    AddressManagerService.prototype.getPositionByAddress = function (address) {
        if (address) {
            var latlng = { 'latitude': address.latitude, 'longitude': address.longitude };
            return latlng;
        }
        else {
            return null;
        }
    };
    AddressManagerService.prototype.postAddress = function (address, country) {
        var url = '/api/v1/store/address.json';
        // let url = this.getOrderSesisonUrl(storeId,mode,'addresses');
        // todo change new api return val to old api val form
        var aq = this.aq;
        if (country && !address['country']) {
            address['country'] = country;
        }
        else if (this.orderManager && this.orderManager.store && this.orderManager.store.country && !address['country']) {
            address['country'] = this.orderManager.store.country;
        }
        var params = address;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    AddressManagerService.prototype.updateFormByPostal = function (postal, country, autofill, geocode) {
        if (country === void 0) { country = 'SG'; }
        if (autofill === void 0) { autofill = true; }
        if (geocode === void 0) { geocode = true; }
        var url = '/api/v1/store/address.json';
        var params = {
            autofill: false,
            geocode: false,
            type: 'temp'
        };
        if (country) {
            params['country'] = country;
        }
        if (autofill) {
            params['autofill'] = autofill;
        }
        if (postal) {
            params['postal'] = postal;
        }
        if (geocode) {
            params['geocode'] = geocode;
        }
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    AddressManagerService.prototype.deleteAddress = function (addressId) {
        var _this = this;
        if (!addressId)
            return;
        var url = "/api/v1/store/address/" + addressId + ".json";
        var aq = this.aq;
        var search = {};
        if (this.orderManager && this.orderManager.store && this.orderManager.store.country) {
            search['country'] = this.orderManager.store.country;
        }
        aq.params = search;
        aq.url = url;
        aq.method = 'delete';
        aq.auth(true);
        return aq.getJson().pipe(map(function (jo) {
            // local data update
            var index = _this.addressArr.findIndex(function (value) {
                if (value['id'] === addressId) {
                    return true;
                }
                else {
                    return false;
                }
            });
            _this.addressArr.splice(index, 1);
            // let index2 = this.addressArrOfStoreList.findIndex((value) => {
            //   if (value['id'] === addressId) { return true; }
            //   else { return false; }
            // });
            // this.addressArrOfStoreList.splice(index2, 1);
            return jo['data'];
        }));
    };
    AddressManagerService.prototype.getAddressAmount = function () {
        return this.addressArr.length;
    };
    AddressManagerService.prototype.saveCurrentAddress = function (address) {
        this.currentAddress = address;
    };
    AddressManagerService.prototype.calAddressesDistance = function (addresses, store) {
        var _this = this;
        var arr = addresses;
        if (!store)
            return arr;
        var couriers = store['couriers'];
        var radius;
        if (couriers && couriers.length != 0) {
            radius = Math.max.apply(Math, couriers.map(function (o) { return o.radius / 1000; })) || 5;
        }
        else {
            radius = 5;
        }
        console.log('calAddressesDistance with radius: ', radius);
        arr.map(function (a, i) {
            var distance = _this.calDistance(a.latitude, a.longitude, store);
            console.log('距离', distance);
            // arr[i]['beyond'] = distance > radius; // 超出5公里
            arr[i]['distance'] = distance;
        });
        return arr;
    };
    AddressManagerService.prototype.calDistance = function (lat2, lon2, s, unit) {
        if (unit === void 0) { unit = 'K'; }
        if (!s || !s.location || !s.location.latitude || !s.location.longitude)
            return;
        var lat1 = s.location.latitude;
        var lon1 = s.location.longitude;
        // console.log('维度', lat1, lon1, lat2, lon2);
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit === 'K') {
                dist = dist * 1.609344;
            }
            if (unit === 'N') {
                dist = dist * 0.8684;
            }
            return dist;
        }
    };
    // bind the attr in address-dialog-ridio is what ,this filter function using attr is what ;
    AddressManagerService.prototype.findAddress = function (id) {
        return this.addressArr.find(function (address) { return address['id'] === id; });
    };
    AddressManagerService.prototype.findPickupAddress = function (id) {
        return this.pickupArr.find(function (stroeLocation) { return stroeLocation['id'] === id; });
    };
    // get the local delivery address from the addressArr
    AddressManagerService.prototype.findDefaultAddress = function (addresses) {
        this.isDefault = true;
        if (addresses.length > 0) {
            // this.currentAddress = addresses[0];
            return addresses[0];
        }
        else {
            return null;
        }
    };
    // pick up
    AddressManagerService.prototype.setPickup = function (bool) {
        this.isPickup = bool;
        if (bool && this.pickupArr.length === 0) {
            this.initPickupArr();
        }
    };
    AddressManagerService.prototype.checkMinimumCharge = function (courier, total) {
        return courier.minimum > total;
    };
    AddressManagerService.prototype.initPickupArr = function () {
        // todo call api to get pick up address
        // now hardcode current store
        this.pickupArr = [];
        if (this.orderManager.store && this.orderManager.store.location) {
            this.pickupArr.push(this.orderManager.store.location);
        }
        else {
            console.warn('store data is not exsiting location');
        }
    };
    AddressManagerService.prototype.getPickupAddress = function () {
        return this.isPickup ? (this.pickupAddress || null) : false;
    };
    AddressManagerService.prototype.setPickupAddress = function (obj) {
        this.pickupAddress = obj;
        this.isDefault = false;
        this.currentAddress = null;
    };
    // return: if select delivery address and pick up address
    AddressManagerService.prototype.isSetAddress = function () {
        return this.currentAddress || this.pickupAddress;
    };
    AddressManagerService.prototype.setIsChanged = function (bool) {
        this.isChanged = bool;
    };
    // reInit the pickupAddress translation because of the lang change
    AddressManagerService.prototype.reInitPickupAddressBecauseOfLangChanged = function (store) {
        if (!store || !store.location)
            return;
        console.log("reInitPickupAddressBecauseOfLangChanged");
        this.pickupArr = [];
        this.pickupArr.push(store.location);
        if (this.isPickup) {
            // 改变语言的时候 isPickup = true, need to chang the corresponding pickupAddress
            console.log('this.pickupAddress.id', this.pickupAddress);
            if (this.pickupAddress && this.pickupAddress['id']) {
                var newLangPickupaddress = this.findPickupAddress(this.pickupAddress['id']);
                this.setPickupAddress(newLangPickupaddress);
            }
        }
    };
    AddressManagerService.prototype.setPriceRulesOfCurrentAddress = function (rules) {
        if (!rules)
            return;
        this.priceRulesOfCurrentAddress = rules;
    };
    AddressManagerService.prototype.getMinimum = function () {
        if (this.priceRulesOfCurrentAddress && this.priceRulesOfCurrentAddress[0] && this.priceRulesOfCurrentAddress[0].minimum) {
            return this.priceRulesOfCurrentAddress[0].minimum;
        }
        else {
            return 0;
        }
    };
    AddressManagerService.prototype.clear = function () {
        this.currentCourier = null;
        this.note = '';
    };
    AddressManagerService.prototype.clearAll = function () {
        // logout
        this.addressArr = [];
        // this.addressArrOfStoreList = [];
        this.isPickup = false;
        this.currentAddress = null; // save the order using takeout-address
        this.couriers = [];
        this.currentCourier = null;
        this.pickupAddress = null;
    };
    AddressManagerService.prototype.getCouriers = function (storeId, country, addressId) {
        var url = '/api/v1/shipping/availability.json';
        var params = {};
        params['storeId'] = storeId;
        params['country'] = country;
        if (addressId) {
            params['addressId'] = addressId;
        }
        var aq = this.aq;
        aq.url = url;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            // console.log('getCouriers jo', jo);
            return jo['data'];
        }));
    };
    AddressManagerService.ngInjectableDef = i0.defineInjectable({ factory: function AddressManagerService_Factory() { return new AddressManagerService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.OrderManager), i0.inject(i4.OrderSessionService), i0.inject(i5.MemberService), i0.inject(i6.SystemConfigService)); }, token: AddressManagerService, providedIn: "root" });
    return AddressManagerService;
}(BaseService));
export { AddressManagerService };
