import { Injectable } from '@angular/core';
import { BaseService } from '../base/base-service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { MemberService } from './member.service';
import { AQuery } from '../base/aquery';
import { Observable } from 'rxjs';
import { Data, Member, Store, OrderManager, Offer } from 'aigens-ng-core';
import { map, share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MemberApiService extends BaseService {

    aq: AQuery;
    constructor(private http: HttpClient, public configs: ConfigService, public memberService: MemberService, public orderManager: OrderManager) {
        super();
        this.aq = new AQuery(http, configs);

    }
    sessionLogin(brandId): Observable<Member>{

        const url = '/api/v1/store/member/me.json';
        const aq = this.aq;
        aq.url = url;
        aq.shouldAuth = true;
        aq.method = 'get';
        let params = {};
        params['brandId'] = brandId;
        aq.params = params;

        // debugger;
        // let headers = {'Authorization' : `Bearer ${token}`};
        // aq.setHeaders(headers);
        return aq.getJson().pipe(map((jo) => {
            console.log('jadd login jo:', jo);
            return jo['data'];
        }));

    }
    loginWithQrcode(type: string, code, brandId?: number): Observable<Member>{
        const url = '/api/v1/store/login.json?type=' + type;
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';


        aq.auth(true);
        let body = {};
        // let params = {
        //   addressId: address['id'],
        //   phone: this.memberService.member && this.memberService.member['phone'],
        //   storeId: store['id'],
        //   type: 'delivery'
        // };
        // if (oi) params['orderitems'] = oi;
        // if (courierId) params['courierId'] = courierId;
        let params = {};
        params['brandId'] = brandId;
        params['code'] = code;
        aq.params = params;
        return aq.getJson().pipe(map((jo) => {
            return jo['data'];
        }));
    }
    nandosLogin (session, brandId?){
        const url = `${'/api/v1/store/login.json'}`;
        this.aq.url = url;
        this.aq.method = 'post';
        let search = {type: 'crm', subtype: 'code', 'code': session, 'brandId': brandId};
        this.aq.params = search;
        // let body = {code: session, brandId: brandId };

        // this.aq.body = body;
        // this.aq.setHeaders({'Access-Control-Allow-Headers': ''});
        return this.aq.getJson().map((jo) => {
            return jo['data'];
        });

    }

    login(username: string, password: string, type: string, countryCode?: string, brandId?: number): Observable<Member> {

        const url = '/api/v1/store/login.json?type=' + type;
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = { 'username': username, 'password': password };
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        if (brandId) {
            params['brandId'] = brandId;
        }
        aq.params = params;
        return aq.getJson().pipe(map((jo) => {
            console.log('jadd login jo:', jo);
            this.memberService.member = Data.toData(Member, jo['data']);
            return this.memberService.member;
        }));
    }
    loginCourt(username: string, password: string, type: string, countryCode?: string, courtId?: number): Observable<Member> {

        const url = '/api/v1/store/login.json?type=' + type;
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = { 'username': username, 'password': password };
        if (countryCode) {
            params['countryCode'] = countryCode;
        }
        if (courtId) {
            params['courtId'] = courtId;
        }
        aq.params = params;
        return aq.getJson().pipe(map((jo) => {
            console.log('jadd login jo:', jo);
            if (jo['data'].sessionId) {
                this.configs.storage.set('crmId', jo['data'].sessionId);
                this.memberService.crmId = jo['data'].sessionId;

            }
            this.memberService.member = Data.toData(Member, jo['data']);
            return this.memberService.member;
        }));
    }
    FBloginOrRegistration(facebookId: string, token: string, join: boolean = false, storeId?: number, brandId?: number, marketing?: boolean): Observable<Member> {

        const url = '/api/v1/store/flogin.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {
            facebookId: facebookId,
            token: token,
            join: join,
            brandId: brandId,
            marketing: marketing,
            storeId: storeId
        };

        if (join) {
            params['type'] = 'join';
        }

        aq.params = params;

        return aq.getJson().pipe(map((jo) => Data.toData(Member, jo['data'])));
    }

    googleLogin(googleId: string, token: string): Observable<Member> {

        const url = '/api/v1/store/glogin.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = { 'googleId': googleId, 'token': token };
        aq.params = params;

        return aq.getJson().pipe(map((jo) => {
            this.memberService.member = Data.toData(Member, jo['data']);
            return this.memberService.member;
        }));
    }

    alipayLogin(type: string, authCode: string): Observable<Member> {

        const url = '/api/v1/store/alogin.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        // type=alipayhkmini for now, maybe more in future
        const params = { 'type': type, 'authCode': authCode };
        aq.params = params;

        return aq.getJson().pipe(map((jo) => {
            this.memberService.member = Data.toData(Member, jo['data']);
            return this.memberService.member;
        }));
    }

    requestPassword(email: string, resetPageUrl: string): Observable<any> {

        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = { email: email, host: resetPageUrl, action: 'request' };
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    sendSms(countryCallingCodes: string, phone: string, store): Observable<any> {

        const url = '/api/v1/menu/member.json?verify=before';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = { countryCode: countryCallingCodes, phone: phone };
        if (store && store.brand && store.brand.crmId) {
            params['crmId'] = store.brand.crmId;
        } else if (store && store.crmId) {
            params['crmId'] = store.crmId;
        }
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    verifySms(smsCode: string, countryCallingCodes: string, phone: string): Observable<any> {

        const url = '/api/v1/menu/member/sms.json?action=verify';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = { countryCode: countryCallingCodes, code: smsCode, phone: phone };
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    requestResetCode(receivedAddress: string, type: string, countryCode?: string, store?: any, brand?: any): Observable<any> {

        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {};
        params['action'] = 'requestOtp';
        params['type'] = type;
        params[type] = receivedAddress;
        if (countryCode) {
            params['countryCode'] = countryCode;

        }

        if (store && store.brand && store.brand.crmId) {
            params['crmId'] = store.brand.crmId;
        } else if (store && store.crmId) {
            params['crmId'] = store.crmId;
        } else if (brand && !store) {
            params['crmId'] = brand.crmId;
        }

        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    verifyResetCode(type: string, receivedAddress: string, token: string, countryCode?: string): Observable<any> {

        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {};
        params['action'] = 'verifyByOtp';
        params['type'] = type;
        params[type] = receivedAddress;
        params['token'] = token;
        if (countryCode) {
            params['countryCode'] = countryCode;

        }

        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    resetPassword(password: string, confirmPassword: string, token: string): Observable<any> {

        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {};
        params['action'] = 'resetByOtp';
        params['password'] = password;
        params['confirm'] = confirmPassword;
        params['token'] = token;

        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }

    resetPasswordWithOldPassword(oldPassword: string, password: string, confirmPassword: string): Observable<any> {
        const url = '/api/v1/store/password.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        const member = this.memberService.getMember();


        const params = {};
        params['action'] = 'resetInApp';
        params['original'] = oldPassword;
        params['password'] = password;
        params['confirm'] = confirmPassword;
        if (member) {
            params['session'] = member.sessionId;
        }

        aq.params = params;

        return aq.getJson().pipe(map(jo => jo));
    }


    registeration(registerInfo: any): Observable<Member> {
        const url = '/api/v1/menu/member.json';

        // var params = {
        //     email: email,
        //     password: password,
        //     token: token,
        //     storeId:storeId
        // };
        const params = registerInfo;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toData(Member, jo['data'])));
    }

    guestUpgradeMember(upgradeInfo: any): Observable<Member> {
        const url = '/api/v1/menu/member.json';

        const params = upgradeInfo;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.auth(true);
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toData(Member, jo['data'])));
    }

    // pas = { email,phone , countryCode}
    getIsRegister(brandId, storeId, pas) {
        const url = '/api/v1/login/check.json';
        let params = {};
        if (pas) {
            params = { ...pas };
        }
        if (brandId) {
            params['brandId'] = brandId;
        }
        if (storeId) {
            params['storeId'] = storeId;
        }

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;

        return aq.getJson().pipe(map(jo => jo['data']));
    }


    join(storeId: number, brandId: number, marketing: boolean, marketingOrderPlace: boolean = false): Observable<any> {
        const url = '/api/v1/reward/activity.json';
        const member = this.memberService.getMember();
        const headers = {};
        const params = {
            brandId: brandId,
            type: 'join',
            marketing: marketing,
            storeId: storeId,
            marketingOrderPlace: marketingOrderPlace
        };


        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(jo => jo));
    }

    editProfile(registerInfo: any): Observable<Member> {
        const url = '/api/v1/menu/member.json';

        // var params = {
        //     email: email,
        //     password: password,
        //     token: token,
        //     storeId:storeId
        // };
        const params = registerInfo;

        const aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        // aq.auth(true);
        aq.params = params;

        return aq.auth(true).getJson().pipe(map((jo) => {
            const user = Data.toData(Member, jo['data']);
            return user;
        }));
    }

    checkingMembership(brandId: number): Observable<any> {
        const url = '/api/v1/reward/membership.json?brandId=' + brandId;

        // let storeId = this.orderManager.store.id;
        // let mode = this.orderManager.mode;
        // let url = this.getOrderSesisonUrl(storeId,mode,'membership');
        // url = url + '&brandId=' + brandId;
        // todo change new api return val to old api val form

        const aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.auth(true);

        return aq.getJson().pipe(map((jo) => {
            this.memberService.hasMembership = jo['data'].length > 0;
            return jo['data'];
        }));
    }

    getRedeemableRewards(brandId: number) {
        const url = `/api/v1/reward/reward.json?type=stamp&brandId=${brandId}`;
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.auth(true);

        return this.aq.getJson().pipe(share(), map((jo) => {
            return jo['data'];
        }));
    }

    redeemReward(brandId: number, rewardId: number) {
        const url = `/api/v1/reward/redeemstamps.json?rewardId=${rewardId}&brandId=${brandId}`;
        this.aq.url = url;

        this.aq.method = 'post';
        this.aq.auth(true);

        return this.aq.getJson().pipe(map((jo) => {
            return jo['data'];
        }));
    }


    // current only for crystalJade
    // login with cardNo
    loginWithCardNo(cardNo: string, brandId: string | number) {
        const url = `/api/v1/store/login.json`;
        let aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        let params = {};
        params['type'] = 'code';
        params['brandId'] = brandId;
        params['code'] = cardNo;

        aq.params = params;

        return aq.getJson().pipe(map((jo) => {
            return jo['data'];
        }));
    }

    guestLogin(session, brandId) {
        const url = '/api/v1/store/devicelogin.json';
        const aq = this.aq;
        aq.url = url;
        aq.method = 'post';

        const params = {};
        if (brandId) {
            params['brandId'] = brandId;
        }
        if (session) {
            params['deviceId'] = session;
        }
        aq.params = params;

        return aq.getJson().pipe(map((jo) => {
            console.log('guest login result:', jo);
            jo['data']['sessionId'] = jo['sessionId'];
            this.memberService.member = Data.toData(Member, jo['data']);
            return this.memberService.member;
        }));
    }

    putLottery(storeId: any, brandId: any, spot: any, rewardId: any, session: any, orderSessionId: any, batchId: any, orderId: any): Observable<any>
    {
        let url = '/api/v1/reward/lottery.json';
        let aq = this.aq;
                
        let params = {
            storeId: storeId,
            brandId: brandId,
            spot: spot,
            rewardId: rewardId,
            orderId
        };

        if (this.memberService.getMember())
        {
            let member = this.memberService.getMember();
            if (member.membership)
            {
                params['memberId'] = member.membership.memberId;
                params['membershipId'] = member.membership.id;
            }else {
                params['memberId'] = member.id;
            }
        }else
        {
            params['memberId'] = 1;
            params['membershipId'] = 1;
        }

        params['session'] = session;
        if (orderSessionId.length > 0) {
            params['orderSessionId'] = orderSessionId;
        }
        
        aq.url = url;
        aq.method = 'put';
        aq.params = params;

        return aq.auth(true).getJson().map(jo => jo);
        
    }

    // can member and non - member
    playLottery(storeId: any, brandId: any, spot: any, session: any, orderTotal: any, orderSessionId: any, batchId: any, orderId: any, giveUpChance= false): Observable<Offer>
    {
        let url = '/api/v1/reward/lottery.json';
        let aq = this.aq;
        
        let params = {
            storeId: storeId,
            brandId: brandId,
            spot: spot,
            total: orderTotal
        };
        if (this.memberService.getMember())
        {
            let member = this.memberService.getMember();
            if (member.membership)
            {
                params['memberId'] = member.membership.memberId;
                params['membershipId'] = member.membership.id;
            }else {
                params['memberId'] = member.id;
            }
        }else
        {
            params['memberId'] = 1;
            params['membershipId'] = 1;
        }

        params['session'] = session;
        if (orderSessionId.length > 0) {
            params['orderSessionId'] = orderSessionId;
        }

        if(batchId && batchId.length > 0) {
            params['batchId'] = batchId;
        }

        if(orderId.length > 0) {
            params['orderId'] = orderId;
        }

        if (giveUpChance) {
            params['giveUpChance'] = true;
        }
        aq.url = url;
        aq.method = 'get';
        aq.params = params;

        return aq.auth(true).getJson().map((jo) => {
            let offer = Data.toData(Offer, jo['data'])
            return offer;
        });
    }

    getBookmarks(brand: any): Observable<any> {
        let url = '/api/v1/store/bookmark.json';
        let params = {};
        // params["memberId"] = memebrId;
        params['brandId'] = brand.id;
        params['groupId'] = brand.groupId;
        params['type'] = 'Item';
        // params["source"] = "BYOD";
        // params["action"] = "getFav";
        params['locale'] = 'en';
        let aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;
        // aq.headers['sid'] = this.configs.getSessionId();

        return aq.auth(true).getJson().map(jo => {
            return jo['data'];
        });
    }

    setBookmarkItem(itemId: any, brand: any): Observable<any> {

        let url = '/api/v1/store/bookmark.json';

        let params = {};
        // params["memberId"] = memebrId;
        params['brandId'] = brand.id;
        params['groupId'] = brand.groupId;
        params['type'] = 'Item';
        // params["source"] = "BYOD";
        // params["action"] = "createFav";
        params['contentId'] = itemId;
        params['locale'] = 'en';
        let aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        // aq.headers['sid'] = this.configs.getSessionId();

        return aq.auth(true).getJson().map(jo => {
            return jo['data'];
        });
    }


    deleteBookmarkItem(itemId: any, brand: any): Observable<any> {
        let url = '/api/v1/store/bookmark.json';

        let params = {};
        // params["memberId"] = memebrId;
        params['brandId'] = brand.id;
        params['groupId'] = brand.groupId;
        params['type'] = 'Item';
        // params["source"] = "BYOD";
        // params["action"] = "createFav";
        params['contentId'] = itemId;
        params['locale'] = 'en';
        params['favorite'] = false;
        let aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = params;
        // aq.headers['sid'] = this.configs.getSessionId();

        return aq.auth(true).getJson().map(jo => {
            return jo['data'];
        });
    }

}
