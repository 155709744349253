import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { Data, Item, ItemGroup, ModifierBuilder, OrderItem, Store } from 'aigens-ng-core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';

@Component({
    selector: 'sidebar-modifier-dialog',
    templateUrl: 'sidebar-modifier.html',
    styleUrls: ['sidebar-modifier.scss']
})
export class SidebarModifierDialog extends MobileBasePage implements OnInit {

    group: ItemGroup;
    itemMap: any;
    store: Store;
    orderItem: OrderItem;
    builder: ModifierBuilder;
    confirm = false;
    modifiableItems: Item[];
    currentItem: Item;
    tmps: Item[];
    mmap = {};
    absentItemsId: string[] = [];
    currentIndex = 0;
    itemsCount = {};
    item: Item;
    selectItems = [];

    mgroupItemsMap: {};

    constructor(public injector: Injector, public navParams: NavParams, private cd: ChangeDetectorRef) {

        super(injector);

        this.orderItem = this.navParams.get('orderitem');
        this.store = this.navParams.get('store');
        this.group = this.navParams.get('group');
        this.item = this.navParams.get('item');
        this.itemMap = this.navParams.get('itemMap');
        this.item = this.navParams.get('item');
        console.log('SidebarModifierDialog constructor()');
        if (!this.store) {
            this.store = this.orderManager.store;
        }
        this.builder = new ModifierBuilder(this.store, this.orderItem);
        if (this.group) {
            const items = this.group.items.map((item) => {
                this.itemsCount[item.id] = item.quantity;
                const tmpItem: Item = Data.toData(Item, { ...item });
                const tmpMgroups = this.itemMap[item.id].mgroups;
                tmpItem.mgroups = Data.toDataArray(ItemGroup, [...tmpMgroups]);
                return tmpItem;
            });
            this.modifiableItems = items.filter(item => item.mgroups && item.mgroups.length > 0);
        }
        if (this.item) {
            this.modifiableItems = [];
            this.modifiableItems.push(this.item);
        }

        this.modifiableItems.forEach((item) => {

            item.mgroups = item.mgroups.filter(mgroup => (mgroup.min === 0 && mgroup.items.length > 0) || mgroup.min > 0);

            item.mgroups.forEach((mgroup) => {
                // let tmp = mgroup.items.filter(item => item.absentId != null && item.absentId != "")
                // tmp.forEach((item)=>{
                //     this.absentItemsId.push(item.absentId);

                // })
                mgroup.items.forEach((mitem) => {
                    if (mitem.absentId != null && mitem.absentId !== '') {
                        this.absentItemsId.push(mitem.absentId);
                    }

                    if (mitem.mgroup && this.has(item, mgroup, mitem)) {
                        this.mmap[mitem.mgroup] = mitem;
                    }

                    if (mgroup.defaultId && mitem.id === mgroup.defaultId) {
                        this.has(item, mgroup, mitem) || this.itemClicked(item, mgroup, mitem, true);
                    }

                });

            });
        });

        this.currentIndex = this.navParams.get('showLastOne') ? this.modifiableItems.length - 1 : 0;
        this.currentItem = this.modifiableItems[this.currentIndex];
        // console.log('this.modifiableItems soldout', this.modifiableItems[0].mgroups[0].items[1], this.modifiableItems[0].mgroups[0].items[1].baseQty);

        // console.log('this.modifiableItems', this.modifiableItems[0].mgroups[0], this.modifiableItems[0].mgroups[0].items[0].baseQty);
        // console.log('this.modifiableItems', this.modifiableItems[0].mgroups[1], this.modifiableItems[0].mgroups[1].items[0].baseQty);
        // console.log('this.modifiableItems', this.modifiableItems[0].mgroups[2], this.modifiableItems[0].mgroups[2].items[0].baseQty);
        console.log('this.modifiableItems', this.modifiableItems);
        // console.log('this.currentItem', this.currentItem, this.currentItem.mgroups[0].id);


        // to separate the mgroup.items when the mgroup.repeat is false according to the same string of mgroups[i].items[i].mgroup;
        this.setMgroupItemsMap();

        console.log('this.mgroupItemsMap', this.mgroupItemsMap);
        // console.log('数据', this.mgroupItemsMap[this.currentItem.mgroups[0].id]);


    }

    ngOnInit() {
        this.showSelectedItems();
        console.log('ngOnInit side bar VariationPage');
    }

    setMgroupItemsMap() {
        let obj = {};
        this.modifiableItems.forEach((item) => {
            item.mgroups && item.mgroups.forEach(mgroup => {
                if (this.classify(mgroup.items, 'mgroup').length > 0)
                    obj[mgroup.id] = this.classify(mgroup.items, 'mgroup');
            });
        });
        console.log('obj', obj);
        this.mgroupItemsMap = obj;

    }

    classify(arr, key: string): Array<any> {
        // 根据 对象数据的某一项 key 值 来新建一个对象
        let obj = {};
        arr.map(item => {
            if (item[key])
                obj[item[key]] = null;
            else
                obj['noMgroupStr'] = null;
        });
        let valuesArr = Object.keys(obj); // 包含 key 所有值的数组

        console.log('valuesArr', valuesArr);
        // 根据 key 的值 把对象数组 分类
        let res = valuesArr.map(val => {
            return arr.filter(item => {
                if(item[key]){
                    return val === item[key];
                }else{
                    return true;
                }
            });
        });
        return res;
    }

    getSelectRules(igroup: ItemGroup): string {
        if (!igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return '';
        }
        const min = Number(igroup.min);
        const max = Number(igroup.max);
        if (min === max && min === 0) {
            return '';
        }
        // 請選擇{{count}}項
        if (min === max) {
            return this.t.instant('pages.item-select.select-items', { count: min });
        }
        // 加配項目
        if (min === 0 && max === 99) {
            return this.t.instant('pages.item-select.optional');
        }
        // 請選最多max項
        if (min === 0 && max !== 99) {
            return this.t.instant('pages.item-select.chooseAtMost', { max: max });
        }
        // 請選至少{{min}}項
        if (min !== 0 && max === 99) {
            return this.t.instant('pages.item-select.chooseUp', { min: min });
        }
        // 請選{{min}} - {{max}}項
        if (min < max && max !== 99) {
            return this.t.instant('pages.item-select.chooseTo', { min: min, max: max });
        }
        return '';
    }


    dismissClicked(confirem: boolean = false) {
        this.modalController.dismiss(confirem);

    }

    isGroupSelected(item: Item, igroup: ItemGroup): boolean {
        if (!item || !igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return false;
        }
        let min = Number(igroup.min);
        let max = Number(igroup.max);
        let items = igroup.items || [];
        let isSelect = false;
        let quantities = 0;

        for (let tmpItem of items) {
            quantities += this.builder.getQuantity(item, igroup, tmpItem);
        }
        if (min > 0) {
            isSelect = quantities >= igroup.min;
        } else if (quantities > 0) { /// min = 0
            isSelect = true;
        }
        return isSelect;
    }

    itemClicked(item: Item, group: ItemGroup, mItem: Item, isPreselect: boolean = false) {
        const max: number = group.max;
        const contain: boolean = this.has(item, group, mItem);

        if (mItem.baseQty > 0) {
            const count = this.builder.getQuantity(item, group, mItem);
            if (count < mItem.max) {
                this.builder.plus(item, group, mItem);
            }
        } else if (group.repeat) {
            if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                this.builder.plus(item, group, mItem);
            } else {
                // when group.repeat==true&&group.max==1
                if (max === 1)
                    this.minusClicked(item, group, mItem);
            }
        } else {
            if (max === 1) {
                this.builder.clearForItem(item, group);
                if (!contain) {
                    this.builder.setMod(item, group, mItem, 1);

                }
            } else if (max > 1) {
                let q: number;
                if (contain) {
                    q = 0;
                    if (mItem.mgroup) {
                        this.mmap[mItem.mgroup] = null;
                    }
                } else {

                    if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                        q = 1;
                    } else {
                        q = 0;
                    }
                }

                if (mItem.mgroup && q === 1) {
                    this.clearSameMGroup(item, group, mItem);
                }
                this.builder.setMod(item, group, mItem, q);
            }

        }

        if (!isPreselect && group.min > 0 && this.isItemCompleted(item) && this.currentIndex + 1 < this.modifiableItems.length) {
            this.selectItem(this.modifiableItems[this.currentIndex + 1], this.currentIndex + 1);
        }

        if (!isPreselect) {
            this.cd.detectChanges();

        }

        this.showSelectedItems(); // update
    }

    clearSameMGroup(item: Item, group: ItemGroup, mItem: Item) {
        const i = this.mmap[mItem.mgroup];
        if (i) {
            this.builder.setMod(item, group, i, 0);
        }


        this.mmap[mItem.mgroup] = mItem;

    }

    confirmClicked() {
        if (!this.isAllCompleted(this.modifiableItems)) {
            this.showAlert('', 'Please complete all requried fields');
            return;
        }
        if (!this.isAllCompleted(this.modifiableItems)) {
            console.log('modifier not completed, cannot confirm');
        }
        const orderManager = this.multOrderManager.enableMutlOrder ? this.multOrderManager.getOrderManager(this.store.id) : this.orderManager;
        if (OrderItem.isSet(this.orderItem) || this.orderItem.quantity === 1) {
            const oi = this.builder.build();
            orderManager.refreshOrderItem(oi);
        } else {
            const duplicated: OrderItem = orderManager.duplicateOrderItem(this.orderItem);
            duplicated.quantity--;
            const oi = this.builder.build();
            oi.quantity = 1;
            orderManager.refreshOrderItem(oi);
        }
        this.dismissClicked(true);
    }

    isSet(oi: OrderItem) {
        // sometimes combo-oi because of the oi.category.groups is [],so return false
        return oi.combo || OrderItem.isSet(oi);
    }

    has(item: Item, group: ItemGroup, mItem: Item): boolean {
        // console.log(mItem.name + ":" + this.builder.getQuantity(item, group, mItem));
        return this.builder.getQuantity(item, group, mItem) > 0;
    }

    showSelectedItems() {
        let selectItems = [];
        this.currentItem && this.currentItem.mgroups.map((mgroup, midx) => {
            selectItems[midx] = [];
            mgroup.items.map((item) => {
                let quantity = this.builder.getQuantity(this.currentItem, mgroup, item);
                if (quantity > 1) {
                    selectItems[midx].push(`${item.name} x ${quantity}`);
                } else if (quantity === 1) {
                    selectItems[midx].push(`${item.name}`);
                }
            });

        });
        this.selectItems = selectItems;
    }

    canAdd(item: Item, group: ItemGroup, mItem?: Item): boolean {
        const max: number = group.max;
        let quantities = 0; // this.builder.getSelectedModifierCount(item);
        for (const tmpItem of group.items) {
            quantities += this.builder.getQuantity(item, group, tmpItem);
        }
        this.mmap = {};
        group.items.forEach((mitem) => {
            if (mitem.mgroup && this.has(item, group, mitem)) {
                this.mmap[mitem.mgroup] = mitem;
            }
        });
        if (max > quantities && mItem && this.mmap[mItem.mgroup] && mItem.id !== this.mmap[mItem.mgroup].id) {
            return true;
        }

        return max > quantities;
    }

    minusClicked(item: Item, group: ItemGroup, mItem: Item) {
        const count = this.builder.getQuantity(item, group, mItem);
        if (count > mItem.min) {
            this.builder.minus(item, group, mItem);

        }
        this.showSelectedItems();
        this.cd.detectChanges();

    }

    selectItem(item, index) {
        this.currentItem = item;
        this.currentIndex = Number(index);
        this.showSelectedItems();
    }

    mgroupSegmentChanged(e) {
        console.log('mgroupSegmentChanged event', e);
        this.selectItem(this.modifiableItems[e.detail.value], e.detail.value);
    }

    isItemCompleted(item: Item): boolean {
        let completed = true;
        const mgroups: ItemGroup[] = this.itemMap[item.id].mgroups;
        if (mgroups) {
            for (let i = 0; i < mgroups.length; i++) {
                let quantities = 0;

                for (const tmpItem of mgroups[i].items) {
                    quantities += this.builder.getQuantity(item, mgroups[i], tmpItem);
                }

                if (mgroups[i].min > 0) {
                    completed = quantities >= mgroups[i].min;

                } else {
                    completed = true;
                }

                if (!completed) {
                    break;
                }


            }
        }

        return completed;
    }

    isAllCompleted(items: Item[]): boolean {
        // return item.mgroups
        // .filter(mgroup => mgroup.min > 0)
        // .some((mgroup)=>{
        //     return mgroup.items.length >= mgroup.min;
        // });
        let completed = true;
        for (const item of items) {
            completed = this.isItemCompleted(item);
            if (!completed) {
                break;
            }

        }
        return completed;


    }

}
