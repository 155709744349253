import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {NavigationService} from '../services/navigation.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentGuard implements CanActivate {
    constructor(public router: Router, public route: ActivatedRoute, public navigationService: NavigationService) {
    }

    // TODO: check if store is provided in route params?
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let t = this.navigationService.getNavigationParams()['payment'];
        console.log('payment guard', t);
        if (!t) {
            // TODO: not allowed to enter target root. go to parent route?
            this.router.navigate([this.navigationService.getRootPageUrl()], {replaceUrl: true});
        }
        return !!t;
    }
}
