import {Pipe, PipeTransform} from '@angular/core';
import {SystemConfigService} from '../../core/services/systemConfig.service';
import {DecimalPipe} from '@angular/common';
import {OrderService} from '../../core/services/order.service';

@Pipe({
    name: 'priceDisplay'
})
export class PriceDisplayPipe implements PipeTransform {
    constructor(public systemConfigService: SystemConfigService, public decimalPipe: DecimalPipe, public orderService: OrderService) {
    }

    transform(price: number, shouldDisplaySign: boolean = true): string {
        let p = this.systemConfigService.itemPricePrecision;
        let prefix = shouldDisplaySign ? this.orderService.sign : '';
        if (p >= 0) {
            let dc = '1.' + p + '-' + p;
                return prefix + this.decimalPipe.transform(price, dc);
        } else {
            let dc = '1.0-2';
            return prefix + this.decimalPipe.transform(price, dc);
        }
    }
}
