import { Injectable } from '@angular/core';
import { BaseService } from '../base/base-service';
declare var my;


@Injectable({ providedIn: 'root' })
export class MiniAlipayHKService extends BaseService {

    private setMiniMessageCallback(callback: Function) {
        my.onMessage = callback;
    }
    private postMiniMessage(msg) {
        my.postMessage(msg);
    }

    public tradePay(alipayOrderStr, callback: Function) {
        this.setMiniMessageCallback(callback);

        const messageToMini = { action: 'tradePay', alipayOrderStr: alipayOrderStr };
        console.log('alipayOrderStr', messageToMini);
        my.postMessage(messageToMini);

    }

    public getAuthCode(callback: Function) {
        this.setMiniMessageCallback(callback);

        const messageToMini = { action: 'getAuthCode' };
        console.log('getAuthCode', messageToMini);
        this.postMiniMessage(messageToMini);
    }

    public getLocation(params: GetLocationOptions) {
        return my.getLocation(params);

        /*{
            success(res) {
                my.hideLoading();
                console.log(res)
                my.alert({
                    content: JSON.stringify(res),
                });
                // that對象為Page可以設置數據刷新界⾯
                // that.setData({ hasLocation: true, location: formatLocation(res.longitude, res.latitude) })
            },
            fail() {
                my.hideLoading(); my.alert({ title: '定位失敗' });
            },
        }*/
    }



}

export class GetLocationOptions {
    cacheTimeout?: number;
    type?: number;
    success?: Function;
    fail?: Function;
    complete?: Function;
}