import {TranslateLoader} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {Observable, zip} from 'rxjs';
import {ConfigService} from './services/config.service';
import {map} from 'rxjs/operators';
import * as deepmerge from 'deepmerge';
import {ThemeService} from './services/theme.service';

export class MixTranslateLoader implements TranslateLoader {

    constructor(private http: HttpClient, public configService: ConfigService, public themeService: ThemeService) {
    }

    public prefix = './../assets/i18n/';
    public suffix = '.json';


    public getTranslation(lang: string): Observable<any> {
        console.log('load language', lang);
        let cloud;
        const local = this.http.get(`${this.prefix}${lang}${this.suffix}`);
        if (this.configService.translations && this.configService.translations['data']) {
            cloud = Observable.of(this.configService.translations);
        } else {
            const temp = window.location.pathname;
            let storeId = '';
            let brandId = '';
            let courtId = '';
            const storeIndex = temp.indexOf('store/');
            const brandIndex = temp.indexOf('brand/');
            const courtListIndex = temp.indexOf('court-store-list/');
            const courtIndex = temp.indexOf('court/');
            let modeEnd = 0;
            if (storeIndex !== -1) {
                modeEnd = temp.indexOf('/', storeIndex + 6);
                if (modeEnd === -1) {
                    modeEnd = temp.length;
                }
                storeId = temp.substring(storeIndex + 6, modeEnd);
            } else if (courtListIndex !== -1) {
                modeEnd = temp.indexOf('/', courtListIndex + 16);
                if (modeEnd === -1) {
                    modeEnd = temp.length;
                }
                courtId += temp.substring(courtListIndex + 16, modeEnd);
            } else if (courtIndex !== -1) {
                modeEnd = temp.indexOf('/', courtIndex + 6);
                if (modeEnd === -1) {
                    modeEnd = temp.length;
                }
                courtId += temp.substring(courtIndex + 6, modeEnd !== -1 ? modeEnd : temp.length + 1);
            } else if (brandIndex !== -1) {
                modeEnd = temp.indexOf('/', brandIndex + 6);
                if (modeEnd === -1) {
                    modeEnd = temp.length;
                }
                brandId += temp.substring(brandIndex + 6, modeEnd);
            }
            let suffix = '';
            if (storeId !== '') {
                suffix = `&storeId=${storeId}`;
                this.configService.GAsuffix = `/store/${storeId}`;
            } else if (brandId !== '') {
                suffix = `&brandId=${brandId}`;
                this.configService.GAsuffix = `/brand/${brandId}`;
            } else if (courtId !== '') {
                 // TODO: what if court id?
                 console.log('return null', courtId);
                 suffix = `&courtId=${courtId}`;
                 this.configService.GAsuffix = `/court/${courtId}`;
            }
            cloud = this.http.get(`${this.configService.getApi()}/api/v1/store/config.json?type=byod${suffix}`).map(data => data['data']);
        }
        return zip(local, cloud).pipe(map(result => {
            const localTranslate = result[0];
            let cloudTranslate = result[1];
            if (cloudTranslate['data']) {
                this.configService.settings = cloudTranslate['data'];
                this.themeService.setConfigTheme(cloudTranslate['data']['colors']);
                if (cloudTranslate['data']['translations']) {
                    this.configService.translations = cloudTranslate;
                }
            }
            if (this.configService.translations && this.configService.translations['data'] &&
                this.configService.translations['data']['translations'] && this.configService.translations['data']['translations'][lang]) {
                cloudTranslate = this.configService.translations['data']['translations'][lang];
            } else {
                cloudTranslate = {};
            }
            console.log('localTranslate', localTranslate);
            console.log('cloudTranslate', cloudTranslate);
            const merged = deepmerge(localTranslate, cloudTranslate);
            console.log('merged translate', merged);
            return merged;
        }));
    }
}
