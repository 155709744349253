import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { MemberService } from 'src/app/core/services/member.service';
import { StoreService } from 'src/app/core/services/store.service';
import { Brand, Store } from 'aigens-ng-core';
import { NavParams } from '@ionic/angular';
import { TermsConditionsPage } from '../terms-conditions/terms-conditions';
import { getTandCUrl } from 'src/app/shared/utilities/checkFormatTool';

declare var FB: any;

@Component({
    selector: 'app-join-or-logout-modal',
    templateUrl: './join-or-logout-modal.page.html',
    styleUrls: ['./join-or-logout-modal.page.scss'],
})
export class JoinOrLogoutModal extends MobileBasePage implements OnInit, OnDestroy {

    marketing = true;
    TACKeyObj: any;
    brand: Brand;
    store: Store;
    hasTAndC = true;

    constructor(injector: Injector, public memberService: MemberService, public storeService: StoreService) {
        super(injector);
        this.navParams = injector.get(NavParams);
        this.brand = this.navParams.get('brand');
        this.store = this.navParams.get('store');
        if(this.store){
            this.TACKeyObj = this.store;
        }

        const url = getTandCUrl(this.brand, 'regTc');
        this.hasTAndC = !!url;
    }

    dismissClicked(params?) {
        console.log('delivery login modal closing');
        this.modalController.dismiss(params);
    }

    joinMembership() {
        console.log('joinMembership');
        this.loading(true);
        this.memberApiService.join(this.store.id, this.brand.id, this.marketing).subscribe((result) => {
            console.log('JoinOrLogoutModal joinMembership result', result);
            this.loading(false);
            this.showToast(this.t.instant('global.join-membership-toast'), 2000, 'bottom');
            this.dismissClicked({ joinMembership: true })
            return;

        }, (err) => {
            this.loading(false);
            this.showError(err);
            this.dismissClicked({ joinMembership: false });
            // logout
            this.memberService.clear();
            this.orderSessionService.clearLocalSession();

        });
    }

    justShowTC() {
        super.openTermsModal(this.brand, () => { }, TermsConditionsPage, false, false, false, false, true, true);
    }

    logoutClicked() {
        console.log("JoinOrLogoutModal logoutClicked");
        this.logout();
    }

    logout() {
        this.showAlert('', this.t.instant('pages.profile.alert-title'), [{
            text: this.t.instant('buttons.no'),
            handler: () => {
                console.log('cancel');
            }
        }, {
            text: this.t.instant('buttons.yes'),
            handler: () => {
                this.memberService.clear();
                this.orderSessionService.clearLocalSession();
                this.configService.setLocal('termsAgree', null);
                this.addressManagerService.clearAll();
                this.dismissClicked({})
            }
        }]);

    }

}