<ion-menu contentId="content" side="start" menuId="mainMenu" type="overlay" (ionDidClose)="ionDidClose()"
  (ionDidOpen)="ionDidOpen()">
  <ion-header ion-no-padding>
    <ion-toolbar ion-no-padding>
      <div class="div-brand-logo">
        <!-- <img class="brand-logo" src="assets/images/demo-this-logo.png"> -->
        <h2 class="title-store-name" *ngIf="orderManager?.store">{{orderManager?.store?.name}}</h2>
      </div>
      <span class="toolbar-line"></span>

    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div class="greeting-name">
      <h5 *ngIf="memberService?.getMember() && memberService?.getMember()['method'] !== 'device'">
        {{"pages.main-slide-menu.greetings" | translate}} {{memberService?.getMember()?.name}},</h5>
      <h5 *ngIf="memberService?.getMember() && memberService?.getMember()['method'] == 'device'">
        {{"pages.main-slide-menu.greetings" | translate}},</h5>
    </div>


    <!-- dine in / takeaway mode -->
    <!-- <div>
      <ion-segment (ionChange)="selectedMode($event)" color="primary" [value]="currentMode">
        <ion-segment-button value="dinein">
          <ion-label>DINE IN</ion-label>
        </ion-segment-button>
        <ion-segment-button value="takeaway">
          <ion-label>TAKEAWAY</ion-label>
        </ion-segment-button>

      </ion-segment>

    </div> -->



    <div class="wrapper-lower">
      <div class="div-deli-addr">
        <div class="left-addr">
          <ng-container *ngIf="orderManager.mode === 'takeaway' || orderManager.mode === 'delivery'; else elseTemplate">
            <ng-container *ngIf="orderManager.mode === 'takeaway'; else elseTemplate">
              <p>{{"pages.cart.pickup-card-subtitle" | translate}}</p>
            </ng-container>
            <ng-template #elseTemplate>
              <p>{{"pages.cart.delivery-card-subtitle" | translate}}</p>
            </ng-template>
            <div
              *ngIf="!(orderManager.mode === 'takeaway' && addressManagerService.pickupAddress && addressManagerService.pickupArr.length == 1)"
              (click)="editAddress()" class="edit-btn">{{'pages.cart.edit-cap'|translate}}
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <p>{{"pages.splash.dine-in"|translate}}</p>
          </ng-template>

        </div>
        <span class="addr-red-line"></span>
      </div>
      <div class="addr-content">
        <!-- delivery address message -->
        <ng-container *ngIf="addressManagerService.currentAddress && !this.addressManagerService.isPickup">
          <p class="addr-alias">
            {{getAddressDisplay()}}
          </p>
          <!-- <p class="address-location">
                  {{addressManagerService.currentAddress?.full}}
              </p>
              <p class="address-location">
                  {{"pages.cart.delivery-phone" | translate}}
                  {{addressManagerService.currentAddress?.phone}}
              </p> -->
        </ng-container>

        <!-- pick up address message -->
        <ng-container *ngIf="addressManagerService.pickupAddress && this.addressManagerService.isPickup">
          <p class="addr-alias">
            {{addressManagerService.pickupAddress?.name}}
          </p>
          <p class="addr-full">
            {{addressManagerService.pickupAddress?.full}}
          </p>
          <p class="phone-num">
            {{"pages.cart.delivery-phone" | translate}}
            {{addressManagerService.pickupAddress?.phone}}
          </p>
        </ng-container>

        <!-- dine in same as pickup mode -->
        <ng-container *ngIf="orderManager.mode === 'dinein'">
          <p class="addr-alias">
            {{orderManager?.store?.location?.name}}
          </p>
          <p class="addr-full">
            {{orderManager?.store?.location?.full}}
          </p>
          <p class="phone-num">
            {{"pages.cart.delivery-phone" | translate}}
            {{orderManager?.store?.location?.phone}}
          </p>
        </ng-container>
      </div>


      <div class="title-list">
        <h3>{{"pages.main-slide-menu.title" | translate}}</h3>
        <span class="memb-red-line"></span>
        <ng-container *ngFor="let p of pages">
          <div *ngIf="isShowTab(p?.onlyLoginShow)" (click)="openPage(p)" class="div-main-title" [ngClass]="{'disabled': p.disabled}">
            <h2>
              {{ p.transalteStr | translate}}
            </h2>
            <ng-container *ngIf="p.title === 'MY CART' && orderManager.count > 0; else elseTemplate">
              <ion-badge color="primary">{{orderManager.count}}</ion-badge>

            </ng-container>
            <ng-template #elseTemplate>
              <ion-badge color="primary" *ngIf="p.badge">{{p.badge}}</ion-badge>
            </ng-template>

          </div>
        </ng-container>

      </div>
    </div>
  </ion-content>

  <ion-footer>
    <ion-toolbar class="footer-toolbar">
      <div class="div-footer">
        <!-- <div (click)="changeLang()" class="lang-btn">
          <h5>{{currentLanguage}}</h5>
          <ion-icon name="md-arrow-dropdown"></ion-icon>
        </div> -->
        <div *ngIf="memberService?.getMember() && memberService?.getMember()['method'] == 'device'" (click)="login()"
          class="logout-btn">
          <h5>{{"pages.main-slide-menu.signup-login" | translate}}</h5>
        </div>
      </div>
    </ion-toolbar>
  </ion-footer>
</ion-menu>