
<ion-app>

<!-- menu should only be available when user enter via place.order -->
      <!-- <ion-menu contentId="content" side="start" menuId="mainMenu" type="overlay" (ionDidClose)="ionDidClose()" (ionDidOpen)="ionDidOpen()">
      <ion-header>
        <ion-toolbar>
          <ion-title>Aigens BYOD</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-list>
          <button menuClose ion-item *ngFor="let p of pages" (click)="openPage(p)">
            {{p.title}}
          </button>
        </ion-list>
      </ion-content>

    </ion-menu> -->

    <app-main-slide-menu></app-main-slide-menu>

<!-- Disable swipe-to-go-back because it's poor UX to combine STGB with side menus -->
    <ion-router-outlet #outlet [swipeGesture]="false"
                       [ngClass]="{'wechat': isWechat, 'fb': isFacebook}" id="content"></ion-router-outlet>
    <!--<ion-nav [root]="rootPage" #content swipeBackEnabled="false" [ngClass]="{'wechat': browserService.isWeChat(), 'fb': browserService.isFacebook()}"></ion-nav>-->
  <!--<ion-nav [root]="rootPage" #content swipeBackEnabled="false" [ngClass]="{'wechat': browserService.isWeChat(), 'fb': browserService.isFacebook()}"></ion-nav>-->
</ion-app>
