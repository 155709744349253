<!--
  Generated template for the DineIn page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->

<div class="outer-div">
  <div>
    <h1>{{"pages.is-member-popup.title1" | translate}}</h1>
    <h2>{{"pages.is-member-popup.title2" | translate}}</h2>
  </div>

  <div class="div-img">
    <img src="assets/images/login_earn_b.svg">
  </div>

  <div class="div-btns">

    <ng-container *ngIf="!fromGridPage">
        <ion-button color="primary" (click)="ok()">
            {{"pages.is-member-popup.login-ok" | translate}}
          </ion-button>
          <ion-button color="primary" fill="outline" (click)="cancel()">
            {{"pages.is-member-popup.login-no" | translate}}
          </ion-button>
    </ng-container>

    <ng-container *ngIf="fromGridPage">
        <ion-button color="primary" (click)="dismiss('login')">
            {{"pages.login.login" | translate}}
          </ion-button>
          <ion-button color="primary" (click)="dismiss('register')">
            {{"pages.login.register" | translate}}
          </ion-button>
    </ng-container>

  </div>

</div>