var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// import { ViewController } from 'ionic-angular';
import { Injector, OnInit } from '@angular/core';
// import { KioskBasePage } from '../../base/kiosk-base-page';
// import { CurrencyService } from '../../services/currency-service';
import { Data, Offer, OrderItem, Recommendation, Store } from 'aigens-ng-core';
import { Item } from 'aigens-ng-core';
// import { LocalStorageHelper } from "../../utility/local-storage-helper";
import { Order } from 'aigens-ng-core';
import { MathUtils } from 'aigens-ng-core';
import { OrderManager } from 'aigens-ng-core';
import { NavParams } from '@ionic/angular';
import { MobileBaseItemPage } from '../../core/base/mobile-base-item-page';
import { getSetLayout } from '../../shared/utilities/layout-helper';
import { MultiStepComboPage } from '../../routes/item/multi-step-combo/multi-step-combo-page.component';
import { ComboPage } from '../../routes/item/combo-page/combo-page';
import { VariationScrollDialog } from '../variation-scroll/variation-scroll';
import { popDrownAnimation, popUpAnimation } from '../../shared/animations/leaveAnimation';
import { ModifierItemSelectPageModal } from '../../routes/item/modifier-item-select-modal/modifier-item-select-modal';
import { TranslateService } from '@ngx-translate/core';
/**
 * Generated class for the RecommendList page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */
var UnlockPopupDialog = /** @class */ (function (_super) {
    __extends(UnlockPopupDialog, _super);
    function UnlockPopupDialog(injector, navParams, translateService) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        _this.translateService = translateService;
        // localStorageHelper: LocalStorageHelper;
        _this.animateQtyMap = {};
        _this.newTotal = 0;
        _this.addItems = []; // all single items in recommendation list
        _this.qtyTag = [];
        // pref: PreferenceService;
        _this.count = 0;
        _this.isGift = false; // deal with free items
        _this.showImage = true;
        _this.displayTitle = '';
        _this.soldoutMap = {};
        _this.modifiableMap = {};
        _this.requiredModifierMap = {};
        _this.isVariationOpened = false;
        _this.comboCache = new Map();
        _this.unlockComboItems_selected = []; // 保存执行 toComboPage 添加到购物车的 combo unlockItem ()
        _this.tagsImage = {};
        _this.unlockComboItemMap = {}; // 保存unlock comboItem 加入 unlockCatOrderManager 里的数量(不能用 mainItem.id 做唯一标识，比如汉堡 和 特大汉堡 的mainItem.id 可能就是一样的，但分属不同的combo)
        _this.unlockParentItemMap = {};
        _this.unlockComboOIMap = {}; //
        _this.unlockParentOIMap = {};
        _this.recommendList = _this.navParams.get('recommendList');
        _this.order = _this.orderManager.order;
        _this.store = _this.navParams.get('store');
        _this.addItems = _this.navParams.get('items');
        _this.isGift = _this.navParams.get('isGift');
        _this.max = _this.navParams.get('max'); // for unlocks
        _this.min = _this.navParams.get('min');
        _this.offer = _this.navParams.get('offer');
        _this.recommendations = _this.navParams.get('recommendations'); // for AI recommendations
        _this.unlockCat = _this.navParams.get('unlockCat');
        console.log('UnlockPopupDialog unlockCat', _this.unlockCat);
        if (_this.max) {
            if (_this.min) {
                if (_this.min > _this.max)
                    _this.min = _this.max;
                _this.displayTitle = _this.t.instant('pages.item-select.chooseTo', { max: _this.max, min: _this.min });
            }
            else {
                _this.displayTitle = _this.t.instant('pages.item-select.chooseAtMost', { max: _this.max });
            }
        }
        _this.init();
        // 创建一个新的orderManager to save the unlock cat item selected(may be comboItem or modifierItem)
        _this.unlockCatOrderManager = new OrderManager();
        _this.unlockCatOrderManager.mode = _this.orderManager.mode;
        _this.unlockCatOrderManager.createOrder(_this.storeId, null, null, _this.orderManager.mode, _this.store);
        _this.initTagsImage();
        return _this;
    }
    UnlockPopupDialog.prototype.init = function () {
        var _this = this;
        if (this.recommendations) {
            this.addItems = [];
            this.recommendations.forEach(function (rm) {
                _this.addItems.push(rm.item);
                _this.qtyTag.push(0);
                if (_this.showImage && (!rm.item.images || !rm.item.images['default']))
                    _this.showImage = false;
            });
            this.count = this.recommendations.length;
        }
        else {
            for (var i = 0; i < this.addItems.length; i++) {
                this.qtyTag[i] = 0;
                if (!this.addItems[i].parentId || this.addItems[i].parentId === this.addItems[i].id) {
                    if (this.showImage && (!this.addItems[i].images || !this.addItems[i].images['default']))
                        this.showImage = false;
                }
            }
            this.category = this.recommendList;
            this.itemgroups = this.recommendList.groups;
        }
        console.log('input category', this.category);
    };
    UnlockPopupDialog.prototype.isModifiable = function (item) {
        if (!this.modifiableMap[item.id]) {
            this.modifiableMap[item.id] = Item.isModifiable(item);
        }
        return this.modifiableMap[item.id];
    };
    UnlockPopupDialog.prototype.checkStoreMaxItem = function () {
        var maxItems = this.orderManager.store.menu.maxItems;
        var infinity = maxItems === 0;
        return (maxItems <= this.orderManager.count && !infinity);
    };
    UnlockPopupDialog.prototype.isRequiredModifier = function (item) {
        if (this.isModifiable(item)) {
            if (!this.requiredModifierMap[item.id]) {
                this.requiredModifierMap[item.id] = item.mgroups.some(function (mgroup) { return mgroup.min > 0; });
            }
            return this.requiredModifierMap[item.id];
        }
        else {
            return false;
        }
    };
    UnlockPopupDialog.prototype.isSet = function (item) {
        return Item.isSet(this.category, item);
    };
    UnlockPopupDialog.prototype.toComboPage = function (item, index, parentIndex) {
        return __awaiter(this, void 0, void 0, function () {
            var page, componentProps, cssClass, modal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        page = getSetLayout(this.orderManager.store.menu.setLayout);
                        // PopUpSetMenuPage 会通过路由打开combo页面，但当前是在 modal里选combo, 路由跳转后 modal自动关闭(explosion)
                        if (page === 'PopUpSetMenuPage') {
                            page = 'ComboPage';
                        }
                        componentProps = {
                            store: this.store,
                            category: this.category,
                            groups: this.category.groups,
                            item: Item.cloneArray(Item, [item])[0],
                            handler: this.getNavParams('handler'),
                            onHold: true,
                            quantityLimit: this.max - this.checkTotalQty(),
                            formPage: 'unlock',
                        };
                        cssClass = 'modal-largescreen combo-modal-backdrop-dismiss';
                        return [4 /*yield*/, this.navToAssignLayoutItems(page === 'MultiStepComboPage' ? MultiStepComboPage : ComboPage, page, componentProps, {
                                relativeTo: this.route,
                                skipLocationChange: false
                            }, cssClass)];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            console.log('unlock combo modal dismiss', res);
                            var ois = res && res.data && res.data['ois'];
                            ois && ois.forEach(function (oi_) {
                                _this.unlockCatOrderManager.addOrderItem(oi_);
                                var mainItem = OrderItem.getMainItem(oi_);
                                _this.unlockComboItemMap[mainItem.id + '_' + index] ? (_this.unlockComboItemMap[mainItem.id + '_' + index] += 1) : (_this.unlockComboItemMap[mainItem.id + '_' + index] = 1);
                                // this.unlockComboOIMap[mainItem.id + '_' + index] = oi_;
                                var oiMap_combo = _this.unlockComboOIMap[mainItem.id + '_' + index];
                                oiMap_combo && oiMap_combo.length > 0 ? _this.unlockComboOIMap[mainItem.id + '_' + index].push(oi_) : _this.unlockComboOIMap[mainItem.id + '_' + index] = [oi_];
                            });
                            console.log('add combo success :unlockCatOrderManager', _this.unlockCatOrderManager, _this.unlockComboItemMap, _this.unlockComboOIMap);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    UnlockPopupDialog.prototype.isVariationParent = function (item, index) {
        return __awaiter(this, void 0, void 0, function () {
            var exist, items, input, modal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        exist = this.isParent(item);
                        if (!exist) return [3 /*break*/, 2];
                        items = item.variations.slice(0) || [];
                        if (item.id === item.parentId) {
                            items.splice(0, 0, item);
                        }
                        else {
                            items.push(item);
                        }
                        // sort the same type of combo
                        items = items.sort(function (a, b) { return a.price - b.price; });
                        input = {
                            items: items,
                            store: this.store,
                            category: this.category,
                            orderManager: this.orderManager
                        };
                        console.log('isVariationParent input', input);
                        return [4 /*yield*/, this.modalController.create({
                                component: VariationScrollDialog,
                                componentProps: input,
                                showBackdrop: true,
                                backdropDismiss: true,
                                cssClass: 'modal-largescreen',
                                animated: true,
                                enterAnimation: popUpAnimation,
                                leaveAnimation: popDrownAnimation
                            })];
                    case 1:
                        modal = _a.sent();
                        modal.onWillDismiss().then(function (data) { return __awaiter(_this, void 0, void 0, function () {
                            var selected, j, oi_1, params, m;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        selected = (data['data']);
                                        if (!selected) return [3 /*break*/, 6];
                                        console.log('select & add item,', selected);
                                        if (!(this.isSet(selected) || selected.pgroup)) return [3 /*break*/, 2];
                                        j = void 0;
                                        for (j = 0; j < this.addItems.length; j++) {
                                            if (this.addItems[j].id === selected.id)
                                                break;
                                        }
                                        return [4 /*yield*/, this.toComboPage(selected, index, j)];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 6];
                                    case 2:
                                        oi_1 = this.addItemMap(selected);
                                        if (!(this.isRequiredModifier(selected) || (this.isModifiable(selected) && !this.store.menu.quickSingleItem))) return [3 /*break*/, 4];
                                        params = { store: this.store, orderitem: oi_1, edit: true, onHold: true };
                                        return [4 /*yield*/, this.modalController.create({
                                                component: ModifierItemSelectPageModal,
                                                componentProps: params,
                                                showBackdrop: false,
                                                enterAnimation: popUpAnimation,
                                                leaveAnimation: popDrownAnimation,
                                                cssClass: 'modal-largescreen variation-item-modifier-select'
                                            })];
                                    case 3:
                                        m = _a.sent();
                                        m.onDidDismiss().then(function (data) {
                                            console.log('ModifierItemSelectPageModal res', data);
                                            if (data['data'] === 'confirm') {
                                                // save item to temp list
                                                _this.parentHandling(item, index, oi_1);
                                            }
                                            else {
                                                console.log('not confirm modifier-setting, so remove the selected-item');
                                                _this.unlockCatOrderManager.minusItem(selected, true);
                                            }
                                        });
                                        m.present();
                                        return [3 /*break*/, 5];
                                    case 4:
                                        //
                                        this.parentHandling(item, index, oi_1);
                                        this.showToast(this.t.instant('global.item-added'));
                                        _a.label = 5;
                                    case 5:
                                        console.log('unlockParentItemMap', this.unlockParentItemMap, this.unlockParentOIMap);
                                        _a.label = 6;
                                    case 6:
                                        this.isVariationOpened = false;
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        this.isVariationOpened = true;
                        modal.present();
                        _a.label = 2;
                    case 2: return [2 /*return*/, exist];
                }
            });
        });
    };
    // 成功添加 parentItem 时的handling
    UnlockPopupDialog.prototype.parentHandling = function (item, index, oi) {
        var parentKey = item.parentId + '_' + index;
        parentKey && this.unlockParentItemMap[parentKey] ? (this.unlockParentItemMap[parentKey] += 1) : (this.unlockParentItemMap[parentKey] = 1);
        // 记录 同一个 parent oi 数组用于delete 对应的 oi
        var oiMap_prarent = this.unlockParentOIMap[parentKey];
        oiMap_prarent && oiMap_prarent.length > 0 ? this.unlockParentOIMap[parentKey].push(oi) : this.unlockParentOIMap[parentKey] = [oi];
    };
    UnlockPopupDialog.prototype.isParent = function (item) {
        return !!(item && item.variations && item.variations.length > 0);
    };
    //
    UnlockPopupDialog.prototype.getItemQuanlity = function (item, index) {
        var qua = 0;
        // let oi = this.unlockCatOrderManager.findOrderItem(item); // ng-core 没有对combo进行处理
        // if (oi && !OrderItem.isSet(oi)) {
        if (!item.combo) {
            // 非combo
            if (!this.isParent(item)) {
                // 非parnent
                qua = this.unlockCatOrderManager.getQuantity(item);
            }
            else {
                // parent
                var parentId = item.parentId;
                qua = this.unlockParentItemMap[parentId + '_' + index];
            }
        }
        else {
            // combo
            if (!this.isParent(item)) {
                // 非parnent
                qua = this.unlockComboItemMap[item.id + '_' + index];
            }
            else {
                // parent
                var parentId = item.parentId;
                qua = this.unlockParentItemMap[parentId + '_' + index];
            }
        }
        return qua;
    };
    UnlockPopupDialog.prototype.isSoldout = function (item) {
        // let now = this.getNow();
        return !this.isParent(item) && (this.inventoryManager.isSoldout(item)); // || !this.menuManager.isAvailableCombo(this.store, this.category, item, this.order.takeout, now));
    };
    // i: parent, j:selected
    UnlockPopupDialog.prototype.onAddClicked = function (i, j, oi) {
        var _this = this;
        this.animateQtyMap['#' + this.addItems[i].id] = false;
        setTimeout(function () {
            _this.animateQtyMap['#' + _this.addItems[i].id] = true;
        }, 10);
        this.addItems[j].quantity++;
        this.qtyTag[i]++;
        this.newTotal += this.round(this.addItems[j].price);
        if (oi) {
            this.comboCache.set(j, oi);
        }
    };
    UnlockPopupDialog.prototype.checkTotalQty = function () {
        // let qty = 0;
        // this.addItems.forEach(item => {
        //     qty += item.quantity;
        // });
        // console.log('selected qty:', qty);
        // return qty;
        var qty = Order.getCount(this.unlockCatOrderManager.getOrder());
        return qty;
    };
    // true == > can add UnlockCatItem || (special) max == 0 && min== 0
    UnlockPopupDialog.prototype.checkUnlockCatItemMax = function (item, index) {
        return (item && item.max > 0) ? (item.max > this.qtyTag[index]) : (item && item.max === 0 && item.min === 0);
    };
    UnlockPopupDialog.prototype.addClicked = function (i) {
        return __awaiter(this, void 0, void 0, function () {
            var item, t, cate_1, oi, _groups, groups, oi;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.max && this.checkTotalQty() >= this.max) {
                            // if (this.max === 1) {
                            // clear other selection if single select
                            // for (let index = 0; index < this.addItems.length; index++) {
                            //     while (this.qtyTag[index] > 0) this.minusItem(index);
                            // }
                            // this.unlockCatOrderManager.clearOrderItems();
                            // this.unlockParentOIMap = {};
                            // this.unlockParentItemMap = {};
                            // } else {
                            this.showAlert('', this.t.instant('pages.unlockPopup.meeting-unlockItem-max'));
                            return [2 /*return*/];
                            // }
                        }
                        console.log('add clicked, i:', i);
                        if (this.checkStoreMaxItem()) {
                            this.showStoreMaxItemAlert();
                            return [2 /*return*/];
                        }
                        item = this.addItems[i];
                        console.log('add clicked, item：', item);
                        if (!this.checkUnlockCatItemMax(item, i)) {
                            this.showAlert('', this.t.instant('pages.unlockPopup.meeting-unlockItem-max'));
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.isVariationParent(item, i)];
                    case 1:
                        t = _a.sent();
                        console.log('variation parent', t);
                        if (t) {
                            return [2 /*return*/];
                        }
                        if (this.isSoldout(item)) {
                            return [2 /*return*/];
                        }
                        if (!this.isSet(item)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.toComboPage(item, i)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        // if cannot skip modifier page
                        if (this.isRequiredModifier(item) || (this.isModifiable(item) && !this.store.menu.quickSingleItem)) {
                            cate_1 = this.category;
                            oi = this.addItemMap(item);
                            _groups = cate_1.groups, groups = _groups && _groups.filter(function (group) {
                                return _this.menuManager.isAvailableGroup(_this.store, cate_1, group, _this.orderManager.isTakeout(), Date.now(), false, _this.orderManager.mode);
                            });
                            this.onAddClicked(i, i);
                        }
                        else {
                            oi = this.addItemMap(item);
                        }
                        _a.label = 4;
                    case 4:
                        if (this.orderService.isCourt) {
                            this.gaService.eventTrack('item-list-page', 'select item - ' + this.store.name, item.name);
                        }
                        // this.bounce();
                        console.log(this.orderManager.getQuantity(item));
                        return [2 /*return*/];
                }
            });
        });
    };
    UnlockPopupDialog.prototype.addItem = function (item) {
        // this.orderService.addSingleItem(this.category, this.groups[0], item);
        var oi = this.orderManager.addSingleItem(this.recommendList, this.recommendList.groups[0], item, false);
        this.orderManager.getQuantity(item);
        if (!(this.isRequiredModifier(item) || (this.isModifiable(item) && !this.settings.quickSingleItem))) {
            // this.showToast(this.t.instant('global.item-added'), 600, 'bottom', 'add-item-toast');
        }
        return oi;
    };
    UnlockPopupDialog.prototype.addItemMap = function (item) {
        // this.orderService.addSingleItem(this.category, this.groups[0], item);
        var oi = this.unlockCatOrderManager.addSingleItem(this.recommendList, this.recommendList.groups[0], item, false);
        this.unlockCatOrderManager.getQuantity(item);
        // if (!(this.isRequiredModifier(item) || (this.isModifiable(item) && !this.settings.quickSingleItem))) {
        //     this.showToast(this.t.instant('global.item-added'), 600, 'bottom', 'add-item-toast');
        // }
        return oi;
    };
    UnlockPopupDialog.prototype.removeVariation = function (i) {
        var _this = this;
        var item = this.addItems[i];
        var items = item.variations.slice(0);
        items.push(item);
        this.qtyTag[i] = 0;
        items.forEach(function (i) {
            for (var j = 0; j < _this.addItems.length; j++) {
                if (_this.addItems[j].id === i.id) {
                    _this.newTotal -= _this.round(_this.addItems[j].price * _this.addItems[j].quantity);
                    _this.addItems[j].quantity = 0;
                }
            }
        });
    };
    UnlockPopupDialog.prototype.minusItem = function (i) {
        if (this.isParent(this.addItems[i])) {
            this.removeVariation(i);
        }
        else {
            this.addItems[i].quantity--;
            this.qtyTag[i]--;
            this.newTotal -= this.round(this.addItems[i].price);
        }
    };
    UnlockPopupDialog.prototype.removeClicked = function (item, index) {
        console.log('remove item from unlockCatOrderManager');
        if (item && !item.combo) {
            // 非combo
            if (!this.isParent(item)) {
                this.unlockCatOrderManager.minusItem(item);
            }
            else {
                this.removeParentHandling(item, index);
            }
        }
        else {
            if (!this.isParent(item)) {
                this.removeComboHanding(item, index);
            }
            else {
                this.removeParentHandling(item, index);
            }
        }
    };
    // remove 当前item 对应的combo 随意减一
    UnlockPopupDialog.prototype.removeComboHanding = function (item, index) {
        // 找到当前 item 对应的combo
        var ois_combo = this.unlockComboOIMap[item.id + '_' + index];
        var oi = ois_combo[ois_combo.length - 1]; // 找到最后一次添加的combo item
        ois_combo.pop();
        this.unlockCatOrderManager.minusOrderItem(oi);
        this.unlockComboItemMap[item.id + '_' + index] -= 1;
    };
    // delete all same parentId oi
    UnlockPopupDialog.prototype.removeParentHandling = function (item, index) {
        var _this = this;
        var parentKey = item.parentId + '_' + index;
        var oi_array = this.unlockParentOIMap[parentKey];
        oi_array.forEach(function (oi) {
            _this.unlockCatOrderManager.minusOrderItem(oi);
        });
        this.unlockParentOIMap[parentKey] = null;
        this.unlockParentItemMap[parentKey] = 0;
    };
    UnlockPopupDialog.prototype.clearClicked = function () {
        // this.playClick();
        this.modalController.dismiss('cancel');
    };
    UnlockPopupDialog.prototype.okClicked = function () {
        var _this = this;
        // this.playClick();
        if (this.round(this.newTotal) <= 0 && !this.isGift)
            return;
        console.log('Buy clicked');
        var order = this.orderManager.order;
        var oi;
        var unlockOrderItems = this.unlockCatOrderManager.getOrder() && this.unlockCatOrderManager.getOrder().orderitems;
        if (unlockOrderItems.length > 0) {
            unlockOrderItems.forEach(function (oi) {
                // 真正加到 全局orderManager 里
                _this.offer && (oi['unlockCatOfferId'] = _this.offer.id);
                // oi['offerId'] = this.offerService.getUnlockCat_offerId_lastest();
                _this.orderManager.addOrderItem(oi);
                if (_this.isGift) {
                    if (!order.gifts)
                        _this.orderManager.getOrder().gifts = [];
                    _this.orderManager.getOrder().gifts.push(oi);
                }
            });
        }
        else {
            this.orderService.selectedReward = null;
            // delete coupon becase 无选unlock的item
            // let selectedRewards = this.orderService.selectedRewards;
            // let length = selectedRewards.length;
            // if (length > 0 && this.offer) {
            //     for (let i = 0; i < length; i++) {
            //         if (selectedRewards[i] && selectedRewards[i].id === this.offer.id) {
            //             this.orderService.selectedRewards.splice(i, 1);
            //             break;
            //         }
            //     }
            // }
        }
        this.modalController.dismiss('ok');
    };
    UnlockPopupDialog.prototype.round = function (value) {
        return MathUtils.round(value, 2);
    };
    UnlockPopupDialog.prototype.getItemName = function (item) {
        var name = item.name;
        if (this.isParent(item)) {
            name = item.title;
        }
        if (!name || name.length === 0) {
            name = item.name;
        }
        return name;
    };
    UnlockPopupDialog.prototype.getItemImage = function (item) {
        var url = null;
        url = Data.getImage(item, 'default', 300, true);
        return url;
    };
    UnlockPopupDialog.prototype.initTagsImage = function () {
        var _this = this;
        var badges = this.store.menu.badges;
        if (badges) {
            badges.forEach(function (badge) {
                var tagImg = badge.getImage('default', 400, true);
                if (tagImg) {
                    _this.tagsImage[badge.tag] = tagImg;
                }
            });
        }
    };
    UnlockPopupDialog.prototype.getTempUrl = function (item) {
        var url = this.getItemImage(item);
        var tempUrl = url && "url(" + url + ")";
        return tempUrl;
    };
    return UnlockPopupDialog;
}(MobileBaseItemPage));
export { UnlockPopupDialog };
