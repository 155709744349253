<div  (click)="dismissClicked()" [ngStyle]="{'height': '100vh'}">
    <div class="variation-wrapper" (click)="$event.stopPropagation()">
        <div class="list-header-wrapper">
            <div class="list-header">
                <span>{{title}}</span>
                <ion-button fill="clear" (click)="dismissClicked()" size="small">
                    <ion-icon slot="icon-only" src="assets/images/v3_close.svg"></ion-icon>
                </ion-button>
            </div>
        </div>
        <div class="content" (click)="dismissClicked()">
    
            <div class="content-box">
    
                <div class="couponBS-wrapper">

                    
                    <div *ngFor="let item of items; let i = index" (click)="itemClicked(i);$event.stopPropagation()" class="item-box" [ngClass]="{'is-sold-out': isSoldout(item)}">
                        <div class="sold-out-box AQA-soldOut" *ngIf="isSoldout(item)" item-content>
                            <span>{{"pages.item-grid.soldout" | translate}}</span>
                        </div>
    
                        <!--<ion-radio></ion-radio>-->
    
                        <div class="item-info">
                            <div class="item-info-detail">
                                <h2 class="itemName-text">{{item.name}}</h2>
                                <span class="itemSpan"></span>
                                <p class="itemDesc-text" *ngIf="!settings?.hideIgName">{{getModifierString(item)}}</p>
                            </div>
                            <span class="itemPrice-text">{{orderManager?.store?.currencySign}}
                                {{item|itemPrice}}</span>
                        </div>
    
                        <div *ngIf="item.images && item.images['default']" class="thumbnail-img">
                            <img src="{{item.getImage('default', 100)}}">
                        </div>
    
                        <!-- <ion-checkbox color="success" slot="end" [checked]="selectedIndex === i"></ion-checkbox> -->
                    </div>
                    <ng-container *ngIf="systemConfigService?.overWriteTakeawayChargeDesc && orderManager.mode === 'takeaway' ; else descTemp">
                        <span class="charge-desc">{{systemConfigService?.overWriteTakeawayChargeDesc}}</span>
                    </ng-container>
                    <ng-template #descTemp>
                        <span class="charge-desc">{{settings?.chargeDesc}}</span>
                    </ng-template>
                </div>
            </div>
        </div>
    
        <!-- <div class="div-footer">
            <div class="btn-box">
                <ion-button expand="block" class="confirm-btn key-btn-box AQA-key-button"
                    [class.confirmed]="selectedIndex >= 0" (click)="okClicked()">
                    {{"pages.variation.confirm" | translate}}
                </ion-button>
            </div>
        </div> -->
    
    </div>

</div>
