var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MobileBasePage } from './mobile-base-page';
import { Injector, OnInit } from '@angular/core';
import { OrderManager } from 'aigens-ng-core';
var BaseComboPage = /** @class */ (function (_super) {
    __extends(BaseComboPage, _super);
    function BaseComboPage(injector) {
        var _this = _super.call(this, injector) || this;
        // setting flags:
        _this.isAutoPickup = false;
        _this.preselectItem = false;
        _this.groupIdMap = {};
        _this.builderGroupsCount = 0;
        _this.itemMap = {};
        _this.total = 0;
        _this.itemGroupMap = {};
        _this.sameTitleItems = {};
        _this.isTitleGroupExpand = {};
        _this.openedTitleGroup = {};
        _this.titles = {};
        _this.modifierShown = false;
        _this.modifiable = false;
        _this.missing = false;
        _this.noNeedConfirm = {};
        // preselectItem
        _this.orderMap = new Map();
        _this.isAutoPickupHasModifier = false;
        // TODO: make it a function?
        // auto pick groups have incomplete modifier?
        _this.isAutoPickupModifierCompleted = false;
        return _this;
    }
    BaseComboPage.prototype.checkDifference = function (index, input) {
        return input && input['id'];
    };
    BaseComboPage.prototype.handleOrderManager = function () {
        if (this.multOrderManager.enableMutlOrder) {
            this.settingMultOrderManager();
        } /*else if (this.orderManager.isEmpty()) {
            this.orderManager.createOrder(this.store.id, null, null, null, this.store);
        }
        this.orderManager.settingOrderType(this.orderManager.mode);*/
    };
    // check if mainitems/auto pick up items has modifiers, should add pre-selected ones
    BaseComboPage.prototype.checkAutoPickupHasModifier = function () {
        this.isAutoPickupHasModifier = false;
        // fist check if main item is selected and check itself has modiifer or not
        if (this.builder.groups && this.builder.groups[0] && this.mainItem) {
            if (this.builder.groups[0].items[0] && this.builder.groups[0].items[0].id === this.mainItem.id) {
                console.log('this.builder.groups[0].itemGroupMap[0].id', this.builder.groups[0].items[0].id);
                for (var _i = 0, _a = this.mainItem.mgroups; _i < _a.length; _i++) {
                    var mgroup = _a[_i];
                    if (mgroup) {
                        this.isAutoPickupHasModifier = true;
                        break;
                    }
                }
            }
        }
        if (this.isAutoPickupHasModifier) {
            return;
        }
        var mgroups = this.mainItem && this.mainItem.mgroups || [];
        this.isAutoPickupHasModifier = mgroups.length > 0;
        // second check if autopick group need modifier or not
        for (var i = 0; i < this.groups.length; i++) {
            if (this.isAutoPickup && this.isAutoPickGroup(this.groups[i])) {
                if (this.hasModifier(this.groups[i].items, this.itemMap, false)) {
                    this.isAutoPickupHasModifier = true;
                    return;
                }
            }
        }
    };
    // TODO: merge this with flatten
    // TODO: merge with the function above
    BaseComboPage.prototype.checkAutoPickupModifierCompleted = function () {
        console.log('checking isAutoPickupmodifiercompleted');
        var completed = true;
        console.log('this.builder.groups', this.builder.groups);
        console.log('this.mainItem', this.mainItem);
        // fist check if main item is selected and check itself has modiifer or not
        if (this.builder.groups && this.builder.groups[0] && this.mainItem) {
            if (this.builder.groups[0].items[0] && this.builder.groups[0].items[0].id === this.mainItem.id) {
                for (var _i = 0, _a = this.mainItem.mgroups; _i < _a.length; _i++) {
                    var mgroup = _a[_i];
                    if (mgroup.min > 0) {
                        var mgroups = this.builder.groups[0].items[0].mgroups;
                        if (mgroups) {
                            for (var i = 0; i < mgroups.length; i++) {
                                var quantities = 0;
                                for (var _b = 0, _c = mgroups[i].items; _b < _c.length; _b++) {
                                    var tmpItem = _c[_b];
                                    quantities += 1 * tmpItem.quantity;
                                }
                                if (mgroups[i].min > 0) {
                                    completed = quantities >= mgroups[i].min;
                                    if (!completed) {
                                        return;
                                    }
                                }
                            }
                        }
                        else {
                            completed = false;
                        }
                    }
                }
            }
        }
        for (var i = 0; i < this.mainItem.groups.length; i++) {
            console.log('i', i);
            // check autopickup group with mainItem to see if it needs modifier
            if (this.isAutoPickup && this.isAutoPickGroup(this.mainItem.groups[i])) {
                console.log('mainitem.group', this.mainItem.groups[i]);
                for (var _d = 0, _e = this.mainItem.groups[i].items; _d < _e.length; _d++) {
                    var item = _e[_d];
                    if (item.mgroups.length > 0) {
                        console.log('this.builder.groups', this.builder.groups);
                        console.log('this.builder.groups[i]', this.builder.groups[i + 1]);
                        var isItemMgroupMinGreaterThanZero = false;
                        if (!this.builder.groups[i + 1] || !this.builder.groups[i + 1].items || !this.builder.groups[i + 1].items[0] || !this.builder.groups[i + 1].items[0].mgroups) {
                            for (var _f = 0, _g = item.mgroups; _f < _g.length; _f++) {
                                var itemMgroup = _g[_f];
                                if (itemMgroup.min > 0) {
                                    isItemMgroupMinGreaterThanZero = true;
                                    break;
                                }
                            }
                            if (isItemMgroupMinGreaterThanZero) {
                                completed = false;
                                return;
                            }
                            else {
                                if (completed) {
                                    this.isAutoPickupModifierCompleted = true;
                                    return;
                                }
                            }
                        }
                        for (var j = 0; j < this.builder.groups[i + 1].items[0].mgroups.length; j++) {
                            var quantities = 0;
                            for (var _h = 0, _j = this.builder.groups[i + 1].items[0].mgroups[j].items; _h < _j.length; _h++) {
                                var tmpItem = _j[_h];
                                quantities += 1 * tmpItem.quantity;
                            }
                            if (item.mgroups[0].min > 0) {
                                completed = quantities >= item.mgroups[0].min;
                                if (!completed) {
                                    return;
                                }
                            }
                        }
                    }
                    else {
                        completed = true;
                    }
                }
            }
        }
        if (completed) {
            this.isAutoPickupModifierCompleted = true;
        }
        console.log('isAutoPickupModifierCompleted', this.isAutoPickupModifierCompleted);
    };
    BaseComboPage.prototype.discard = function (item, group) {
        /* if (this.quantityMap[item.id] && this.quantityMap[item.id] > 0) {
            this.quantityMap[item.id]--;
        } */
        this.builder.minusItem(null, item, this.getGroupIndex(group));
        this.builder.build();
    };
    BaseComboPage.prototype.isAutoPickGroup = function (group, items) {
        if (items) {
            return group.min === group.max && items.length === group.min;
        }
        else {
            var availableItems = group.items;
            var result = group.min === group.max && availableItems.length === group.min;
            return result;
        }
    };
    BaseComboPage.prototype.minusClicked = function (item, group) {
        console.log('minusClicked');
        // to do
        // this.googleAnalyticEventTrack("click", "minus-button");
        if (!this.checkCanMinus(item, group))
            return;
        this.discard(item, group);
        // this.checkingGroupCompleted(item['groupIndex']);
        this.handleModifier();
    };
    BaseComboPage.prototype.checkCanMinus = function (item, group) {
        var qua = this.getQuantity(item, group);
        if (group.items.length !== 1) {
            return true;
        }
        else {
            return qua > item.min;
        }
    };
    BaseComboPage.prototype.pageName = function () {
        return '/multi-step-combo';
    };
    BaseComboPage.prototype.numberToFixed = function (number) {
        if ('HKD' === this.orderService.currency) {
            return Number(number.toFixed(1));
        }
        else if ('CNY' === this.orderService.currency) {
            return Number(number.toFixed(1));
        }
        else if ('SGD' === this.orderService.currency) {
            return Number(number.toFixed(2));
        }
        else {
            return Number(number.toFixed(1));
        }
    };
    BaseComboPage.prototype.matchingGroupIndex = function (oi) {
        var groups = oi.groups;
        if (groups.length !== this.builderGroupsCount && groups.length - 1 <= this.groups.length) {
            this.groupIdMap = {};
            for (var i = 0; i < groups.length; i++) {
                this.groupIdMap[groups[i].id] = i;
            }
        }
        this.builderGroupsCount = groups.length;
    };
    BaseComboPage.prototype.updateSelectedModifier = function () {
        var _this = this;
        var oi = this.builder.build();
        var modifiers = [];
        var total = 0;
        oi.groups.forEach(function (group) {
            var items = group.items;
            items.forEach(function (item) {
                total = _this.numberToFixed(total + item.price * item.quantity);
            });
            items.filter(function (item) { return item.mgroups && item.mgroups.length > 0; }).forEach(function (item) {
                var mgroups = item.mgroups;
                mgroups.forEach(function (mgroup) {
                    modifiers = modifiers.concat(mgroup.items);
                });
            });
        });
        modifiers = modifiers.filter(function (item) { return item.quantity > 0; });
        modifiers.forEach(function (item) {
            total = _this.numberToFixed(total + item.price * item.quantity);
        });
        console.log('update price');
        this.total = total;
    };
    BaseComboPage.prototype.handleModifier = function () {
        var oi = this.builder.build();
        this.currentOrderItem = oi;
        var modifiable = this.modifierBuilder.findItems(oi);
        this.modifiable = modifiable.length > 0;
        this.matchingGroupIndex(this.currentOrderItem);
        console.log('modifiable?', this.modifiable, modifiable);
        this.updateSelectedModifier();
    };
    BaseComboPage.prototype.addOrderItemMap = function (group, item) {
        if (!group || !item) {
            return;
        }
        if (!this.orderMap.has(group.id)) {
            this.orderMap.set(group.id, []);
        }
        this.orderMap.get(group.id).push(item.name);
        this.updateOrderMap(group.id);
    };
    BaseComboPage.prototype.updateOrderMap = function (id) {
        var newOrderArr = Object.assign([], this.orderMap.get(id));
        this.orderMap.set(id, newOrderArr);
    };
    BaseComboPage.prototype.groupingTitle = function (group, items) {
        var tmp = [];
        this.sameTitleItems[group.id] = {};
        this.titles[group.id] = [];
        var _loop_1 = function (item) {
            if (item.variations && item.variations.length > 0) {
                this_1.titles[group.id].push(item.title);
                this_1.sameTitleItems[group.id][item.title] = [item].concat(item.variations);
            }
            else if (item.group) {
                var title_1 = item.group;
                // if any items has sub group field
                if (this_1.titles[group.id].findIndex(function (t) { return title_1 === t; }) === -1) {
                    // save sub group title
                    this_1.titles[group.id].push(title_1);
                }
                // push item to sub item groups under groupId.title
                if (!this_1.sameTitleItems[group.id][title_1]) {
                    this_1.sameTitleItems[group.id][title_1] = [item];
                }
                else {
                    this_1.sameTitleItems[group.id][title_1].push(item);
                }
                var index = tmp.findIndex(function (tmpItem) { return tmpItem.id === item.id; });
                if (index !== -1) {
                    tmp.splice(index, 1);
                }
            }
            else {
                tmp.push(item);
            }
        };
        var this_1 = this;
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            _loop_1(item);
        }
        return tmp;
    };
    BaseComboPage.prototype.getBuilderGroup = function (group) {
        return this.builder.groups.find(function (g) {
            return (g !== null && g !== undefined && g.id === group.id);
        });
    };
    BaseComboPage.prototype.getSelectedItemNamesArr = function (group) {
        var itemGroup = this.getBuilderGroup(group);
        if (!itemGroup) {
            return [];
        }
        if (this.isNoNeedSelected(group)) {
            return [];
            // return this.t.instant('pages.item-select.no-need');
        }
        var selected = itemGroup.items;
        if (!selected) {
            return [];
        }
        var arr = [];
        selected.filter(function (item) { return item.quantity > 0; }).map(function (item) {
            var qtyPart = '';
            if (item.quantity > 1) {
                qtyPart = item.quantity + 'x ';
            }
            arr.push(qtyPart + item.name);
        });
        return arr;
    };
    BaseComboPage.prototype.getQuantity = function (item, group) {
        return this.builder.getQuantityInGroup(this.getGroupIndex(group), item.id);
    };
    BaseComboPage.prototype.has = function (item, group) {
        // console.log(item, group);
        return this.getQuantity(item, group) > 0;
    };
    BaseComboPage.prototype.totalQuantity = function (groupIndex) {
        return this.builder.getChoices(groupIndex);
    };
    BaseComboPage.prototype.isCompleted = function () {
        // const groups: ItemGroup[] = this.builder.groups;
        // index 0 is main group, which must has an selected item
        for (var i = 0; i < this.groups.length; i++) {
            /*
            * Suppose now there are 5 groups and they are [A, B, C, D, E] and A is the main group,
            * this.groups is an array [B, C, D, E],
            * builder.groups is an array with groups which have selected itemGroupMap, says [A, B, C, (null), (null)]
            *
            * then we check this.groups one by one (except main group)
            * if this.groups[i] is not optional
            * builder.groups[i + 1] must has an selected item
            * if not, then it is incompleted
            */
            if (!this.isGroupCompleted(this.groups[i])) {
                return false;
            }
            if (!this.isRequiredModifierSelect(i)) {
                return false;
            }
        }
        return true;
    };
    BaseComboPage.prototype.isGroupCompleted = function (group) {
        // console.log(group);
        if (!group) {
            return true;
        }
        var index = this.getGroupIndex(group);
        var quantity = this.totalQuantity(index);
        if (group.min <= quantity || quantity === group.max) {
            return true;
        }
        if (group.optional) {
            if (group.skippable) {
                return true;
            }
            else {
                return index === -1;
            }
        }
        return false;
    };
    BaseComboPage.prototype.isNoNeedSelected = function (group) {
        return this.noNeedConfirm[group.id] && this.getGroupIndex(group) === -1;
    };
    BaseComboPage.prototype.getGroupIndex = function (group) {
        return this.builder.groups.findIndex(function (g) {
            // TODO: is compared by id safe?
            return (g !== null && g !== undefined && g.id === group.id);
        });
    };
    BaseComboPage.prototype.handlingModifier = function (oi, realGroup, itemMap, addHandler, notAddHandler, showLastOne) {
        if (showLastOne === void 0) { showLastOne = false; }
        if (!oi) {
            return;
        }
        var group = oi.groups.find(function (g) { return g.id === realGroup.id; });
        group = oi.groups.find(function (g) { return g.id === realGroup.id; });
        if (this.hasRequiredModifier(group.items)) {
            this.presentModifierPopup(this.store, oi, group, itemMap, this.defaultModifierPage, function (confirm) {
                if (confirm && confirm['data']) {
                    addHandler();
                }
                else {
                    notAddHandler(group);
                }
            }, showLastOne);
        }
        else {
            addHandler();
        }
    };
    BaseComboPage.prototype.hasModifier = function (items, itemMap, checkRequired) {
        if (checkRequired === void 0) { checkRequired = false; }
        var required = false;
        var isAvailableMod = false;
        for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
            var item = items_2[_i];
            var tmpItem = itemMap[item.id];
            // console.log("checking tmpItem", tmpItem);
            if (tmpItem && tmpItem.mgroups && tmpItem.mgroups.length > 0) {
                for (var _a = 0, _b = tmpItem.mgroups; _a < _b.length; _a++) {
                    var mgroup = _b[_a];
                    for (var _c = 0, _d = mgroup.items; _c < _d.length; _c++) {
                        var mItem = _d[_c];
                        if (mItem && mItem.name) {
                            isAvailableMod = true;
                            break;
                        }
                    }
                }
                if (checkRequired) {
                    for (var _e = 0, _f = tmpItem.mgroups; _e < _f.length; _e++) {
                        var mgroup = _f[_e];
                        if (mgroup.min && mgroup.min > 0) {
                            required = true;
                            break;
                        }
                    }
                }
                else {
                    required = true;
                    break;
                }
            }
        }
        // console.log("checking required", required);
        // console.log("checking isAvailableMod", isAvailableMod);
        return required && isAvailableMod;
    };
    BaseComboPage.prototype.hasRequiredModifier = function (items) {
        return this.hasModifier(items, this.itemMap, true);
    };
    BaseComboPage.prototype.getGroupMode = function (group) {
        return group.repeat ? 'quantity' : 'single';
    };
    BaseComboPage.prototype.isCurrentGroupHasModifier = function (group, map) {
        var index = this.groupIdMap[group.id];
        var g = this.currentOrderItem && this.currentOrderItem.groups ? this.currentOrderItem.groups[index] : null;
        if (g) {
            var has = this.hasModifier(g.items, this.itemMap);
            if (map) {
                map[g.id] = has;
            }
            return has;
        }
        else {
            return false;
        }
    };
    BaseComboPage.prototype.showModifierPopup = function (group) {
        var _this = this;
        var oi = this.builder.build();
        var index = this.groupIdMap[group.id];
        var g = oi.groups[index];
        if (this.hasModifier(g.items, this.itemMap)) {
            this.presentModifierPopup(this.store, oi, g, this.itemMap, this.defaultModifierPage, function (result) {
                _this.handleModifier();
            });
        }
    };
    BaseComboPage.prototype.preOpenTitleGroup = function (groupId, overwrite) {
        console.log('preOpenTitleGroup');
        /*const tmp = this.hasItemTitleGroup[groupId];
        if (tmp) {
            this.isTitleGroupExpand[groupId][tmp.title] = false;
            this.titleGroupClicked(tmp.title, tmp.index, groupId, isOpeningGroupCell);

        } else*/
        if (this.titles[groupId] && this.titles[groupId].length > 0) {
            for (var i = 0; i < this.titles[groupId].length; i++) {
                this.titleGroupClicked(this.titles[groupId][i], i, groupId, overwrite);
            }
        }
    };
    BaseComboPage.prototype.settingMultOrderManager = function () {
        if (this.multOrderManager.getOrderManager(this.store.id)) {
            this.orderManager = this.multOrderManager.getOrderManager(this.store.id);
        }
        else {
            var orderManager = new OrderManager();
            orderManager.mode = this.orderManager.mode;
            orderManager.createOrder(this.store.id, null, null, null, this.store);
            this.multOrderManager.setOrderManagerMap(orderManager, this.store.id);
            this.orderManager = this.multOrderManager.getOrderManager(this.store.id);
        }
    };
    return BaseComboPage;
}(MobileBasePage));
export { BaseComboPage };
