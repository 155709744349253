import { Component, OnInit, Injector, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Store, Address } from 'aigens-ng-core';
import { StoreService } from 'src/app/core/services/store.service';
import { IonInput, NavParams } from '@ionic/angular';

declare var google;
@Component({
  selector: 'search-add-address',
  templateUrl: './search-address.page.html',
  styleUrls: ['./search-address.page.scss'],
})
export class SearchAddressPage extends MobileBasePage implements OnInit {

  brandId;
  // store: Store;
  country;
  countryName: string;

  addressFields: any;
  validations_form_set_value: any;

  enableMapSearch: boolean;
  searchInfo: any;
  detectingGps = false;
  autocomplete: any;
  map: any;
  place: any;
  ref: ChangeDetectorRef;
  marker: any;

  componentMap: any = {};
  displayMain: boolean = false;

  showSearchAddressUI = false;
  notBackToPickupSegment = false;
  postalCode: number;

  // address list ui
  addressArr: Address[] = [];
  currentAddressId: string;
  pickupAddressId: string;
  pickupAddressGroups: any = [];

  pickupArr: any = [];

  isRemove = false;
  isRoute: boolean;
  fromPage: string;
  confirmCb: any;
  goToMenu: false;
  storeLocation: Location;
  showModeSelect: false;
  segmentOption = 'pickup';
  isChangeDeliveryMode: any;
  displayName: any = '';
  hideLogin = false;
  hideDeliveryTab = false;

  constructor(injector: Injector, public formBuilder: FormBuilder, public storeService: StoreService, public navParams: NavParams) {
    super(injector);
    this.ref = injector.get(ChangeDetectorRef);
    this.showSearchAddressUI = this.navParams.get('showSearchAddressUI');
    this.notBackToPickupSegment = this.navParams.get('notBackToPickupSegment');
    console.log("showSearchAddressUI", this.showSearchAddressUI);
    console.log("notBackToPickupSegment", this.notBackToPickupSegment);

    this.brandId = this.navParams.get('brandId');
    this.initAddressArr();
  }


  validations_form: FormGroup;
  outOfDistance = false; // save the new add address is valid
  validation_messages: any;

  ngOnInit() {
    this.country = this.navParams.get('country') || 'HK';
    this.countryName = this.showCountryName();
    this.enableMapSearch = this.country.toLowerCase() !== 'sg';
    console.log('map search?', this.enableMapSearch);

    if (this.showSearchAddressUI)
      this.getAddressField();

    if (!google) {
      console.log('no google map');
      return;
    }

    // this.getLocation();
    this.initAutoComplete();
    this.setStyle()

  }

  initAddressArr() {
    this.addressArr = this.addressManagerService.getAddresses(null, true);
  }

  ionViewDidEnter() {
    this.autoFocus();
  }

  autoFocus() {
    setTimeout(() => {
      if (this.country.toLowerCase() === 'sg') {
        // postal input
        let postal = <HTMLInputElement>document.getElementById('postal');
        if (postal) postal.focus();
      } else {
        let address = <HTMLInputElement>document.getElementById('address');
        if (address) address.focus();
      }
    }, 100);
  }

  setStyle() {
    let countryInput = document.getElementById('countryInput');
    if (countryInput)
      countryInput.classList.add("country-input");
  }

  getAddressField() {
    if (this.brandId) {
      this.loading(true);
      this.storeService.getStoreRegionsByBandId(this.brandId).subscribe((res) => {
        this.loading(false);
        if (res) {
          // save the regions data
          console.log('getAddressField', res);
          if (res.form) {
            let addressFields = res.form.addressFields;
            this.searchInfo = res.form.addressFields.find(field => field.input === "gmap")
            console.log(this.searchInfo)
            this.addressFields = addressFields.filter(field => field.name != null);
            this.addressFields.forEach(field => {
              if (field.editable === undefined) {
                field.editable = true;
              }
              console.log(field.name, ':', field.editable);
            })
            this.initValidationsFormByData(addressFields);
          } else {
            // this.showAlert('', 'server return date not exsit form including addressFields to create the form');
          }

        }
      }, err => {
        this.loading(false);
        this.showError(err);
        // todo  handle this condition
      });
    }
  }

  clearAddress() {
    let address = <HTMLInputElement>document.getElementById('address');
    address.value = '';
  }

  clearPostalCode() {
    this.postalCode = null;
  }

  initValidationsFormByData(addressFields) {
    if (!addressFields) return;
    let formControlsConfig = {};
    let formMessage = {};

    addressFields && addressFields.map(field => {
      if (!field) return;
      let formControl, itemInputMessage = [];
      if (field.required) {
        formControl = new FormControl('', Validators.compose([Validators.required]));
        itemInputMessage.push({ type: 'required', message: '' });
      }
      else {
        formControl = new FormControl('');
      }
      if (field.name) {
        formControlsConfig[field.name] = formControl;
        if (itemInputMessage.length > 0) {
          formMessage[field.name] = itemInputMessage;
        }
      }

    });

    console.log('formControlsConfig', formControlsConfig, formMessage);
    this.validations_form = this.formBuilder.group(formControlsConfig);
    this.validation_messages = formMessage;
  }

  /**SG address system */
  postalCodeCheck(ev) {
    console.log('postalCodeCheck', ev && ev.detail && ev.detail.value);
    let postal = ev.detail.value;
    if (!postal) return;
    if (postal.length !== 6) return;

    this.loading(true);
    this.addressManagerService.updateFormByPostal(postal, this.country).subscribe(res => {
      console.log('updateFormByPostal', res);
      let pos = {
        lat: res.latitude,
        lng: res.longitude
      };
      this.displayMain = true;
      if (!this.map) {
        this.initMap(pos)
      } else {
        this.setCenter(pos);
      }
      this.loading(false);
      if (res) {
        this.setValidationsFormValue(res);
      }

    }, err => {
      this.showError(err);
      this.loading(false);
    });

  }

  setValidationsFormValue(object) {
    this.validations_form_set_value = object;
    let val = this.validations_form.getRawValue();
    let newVal = {};
    console.log('form val', val);
    for (const key in val) {
      if (val.hasOwnProperty(key)) {
        const elment = object[key];
        if (!elment) {
          newVal[key] = '';
        } else {
          newVal[key] = elment;
        }
      }
    }
    console.log('newVal', newVal);
    this.validations_form.setValue(newVal);

  }

  submitClicked(fromValue) { // check the all address data valiable
    console.log('add address clicking', fromValue);
    if (this.checkBuildingIsValid()) {
      this.postAddress(fromValue);
    } else {
      this.popUpTooFarAlert(fromValue);
    }

  }


  checkBuildingIsValid(): boolean {
    //
    // if (true){
    //   this.outOfDistance = true;
    //   return false;
    // }
    return true;
  }
  async popUpTooFarAlert(fromValue) {
    const alert = this.alertController.create({
      header: this.t.instant('dialog.address-dialog.add-alert-title'),
      message: this.t.instant('dialog.address-dialog.add-alert-message'),
      buttons: [
        {
          text: this.t.instant('dialog.address-dialog.add-alert-btn-no'),
          role: 'no',
          cssClass: '',
          handler: (data) => {
            console.log('no clicking', data);
          }
        }, {
          text: this.t.instant('dialog.address-dialog.add-alert-btn-add'),
          role: 'add',
          cssClass: '',
          handler: (data) => {
            this.outOfDistance = false;
            console.log('add clicking', data);

            this.postAddress(fromValue);

          }
        }
      ]
    });
    alert.then(data => data.present());
  }

  // all
  postAddress(formValue) {
    this.loading(true);
    formValue['geocode'] = true;
    formValue['type'] = 'shipping'; // for server. only one option

    formValue.unit = formValue.floor ? formValue.unit + ',' + formValue.floor : formValue.unit;
    delete formValue.floor;

    if (!formValue.name) {
      formValue.name = formValue.street;
    }

    this.addressManagerService.postAddress(formValue, this.country).subscribe((result) => {
      this.loading(false);
      if (result) {
        this.showToast(this.t.instant('dialog.address-dialog.address-added'));
        console.log('postAddress result', result);

        this.updateAddress(result);
        // this.getAddress && this.getAddress();
        this.addressManagerService.isAddAddress = true;
        // this.closeClicked();
        let position = this.addressManagerService.getPositionByAddress(result);
        console.log("searching address position", position);

        if (this.orderManager.store) {
          if (result.id) {
            // 如果店铺存在 就 call api to getCouriers to confirm 是否可以配送
            this.loading(true);
            this.storeService.getCouriers(this.orderManager.store.id, this.orderManager.store.country, result.id).subscribe(data => {
              this.loading(false);
              console.log('SearchAddressPage postAddress getCouriers', data);
              this.addressManagerService.sameAddressCouriersData = data;
              this.handlingCouriers(data, true, true, () => {
                // save the.addressManagerService.currentAddress
                let address = this.addressManagerService.findAddress(result.id);
                this.addressManagerService.saveCurrentAddress(address);
              });

            }, err => {
              this.loading(false);
              this.showError(err)
            })
          }

        } else {
          if (position) {
            let address = this.addressManagerService.findAddress(result.id);
            this.addressManagerService.saveCurrentAddress(address);
            this.modalController.dismiss({ position, deliveryStoreOnlyShowOne: true, notBackToPickupSegment: this.notBackToPickupSegment })
          }
        }

      }

    }, (err) => {
      this.loading(false);
      this.showError(err);
    });
  }

  updateAddress(result) {
    this.addressManagerService.addressArr.unshift(result);
    let newAddresses = this.addressManagerService.calAddressesDistance(this.addressManagerService.addressArr, this.orderManager.store);
    this.orderSessionService.setLocalAddress(newAddresses);
    this.configService.setLocal('addressArrOfStoreList', newAddresses);
    this.addressArr = newAddresses;
  }
  // show the country/region text
  showCountryName() {
    console.log('ly: -> add-address.page.ts -> showCountryName() -> country:', this.country);
    let countryObj = {
      'HK': this.t.instant('dialog.address-dialog.hongkong'),
      'SG': this.t.instant('dialog.address-dialog.singapore'),
      'ID': this.t.instant('dialog.address-dialog.indonesia'),
    };
    if (this.country) {
      return countryObj[this.country] || this.country;

    } else {
      return countryObj['HK'];
    }
  }

  getLocation() {
    if (!this.enableMapSearch) {
      return;
    }
    if (this.detectingGps) {
      return;
    }
    if (navigator.geolocation) {
      this.detectingGps = true;

      navigator.geolocation.getCurrentPosition((position) => {
        this.detectingGps = false;

        console.log('Current position', position);

        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.initMap(pos)

      });
    }
  }

  initMap(pos) {
    this.map = new google.maps.Map(
      document.getElementById('map'),
      {
        center: pos,
        zoom: 13,
        fullscreenControl: false,
        disableDefaultUI: true
      }
    )

    let circle = new google.maps.Circle({
      center: pos,
      //radius: position.coords.accuracy
    });

    if (this.enableMapSearch) {
      this.autocomplete.setBounds(circle.getBounds());
    }

    this.map.setCenter(pos);
    this.marker = new google.maps.Marker({
      position: pos,
      map: this.map
    })
    this.searchPlaces(pos);
  }

  searchPlaces(pos) {
    const geocoder = new google.maps.Geocoder;
    geocoder.geocode({ 'location': pos }, (results, status) => {
      if (status === 'OK') {
        console.log(results);

        if (results[0]) {

        } else {
          console.log('no result found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  }

  initAutoComplete() {
    if (!this.enableMapSearch) {
      return;
    }
    const options = {
      type: ['address'],
      componentRestrictions: { country: this.country.toLowerCase() }
    };

    let autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('address'),
      options
    );

    autocomplete.addListener('place_changed', () => {
      this.placeChanged()
    })

    this.autocomplete = autocomplete;
  }

  placeChanged() {
    if (!this.displayMain) {
      this.displayMain = true;
    }

    this.place = this.autocomplete.getPlace();
    console.log(this.place);

    document.getElementById("map").style.display = "inline";
    if (!this.map) {
      this.initMap(this.place.geometry.location)
    } else {
      this.setCenter(this.place.geometry.location);
    }


    let components = [];
    components = this.place.address_components;
    this.componentMap = {};
    components.forEach(component => {
      this.componentMap[component.types[0]] = component.long_name;
    });

    console.log(this.componentMap);
    this.validations_form.reset();
    let obj = {};
    this.addressFields.forEach(addressField => {
      obj[addressField['name']] = this.componentMap[addressField['component']];
    });
    this.setValidationsFormValue(obj);
    this.detectChanges();

  }


  setCenter(pos) {
    if (this.marker) {
      this.marker.setMap(null);
    }

    this.marker = new google.maps.Marker({
      position: pos,
      map: this.map
    });
    if (this.map) {
      this.map.setCenter(pos);
    }
  }

  detectChanges() {
    setTimeout(() => {
      this.ref.detectChanges();
    }, 10);
  }


  closeClicked() {
    // modal
    if (this.showSearchAddressUI) {
      if (this.addressArr && this.addressArr.length > 0) {
        this.showSearchAddressUI = false;
      } else {
        this.modalController.dismiss({ notBackToPickupSegment: this.notBackToPickupSegment });
      }
    } else {
      if (!this.isRemove) {
        this.modalController.dismiss({ notBackToPickupSegment: this.notBackToPickupSegment, close: true });
      } else {
        this.isRemove = false;
      }
    }
  }

  deleteHandle() {
    if (this.currentAddressId) {
      this.popUpRemoveAlert(this.currentAddressId);
    }
  }

  async popUpRemoveAlert(addressId) {
    let alert = await this.alertController.create({
      // header: 'Alert',
      // subHeader: 'Subtitle',
      message: 'Are you sure you want to remove this address?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Keep',
          handler: () => {
            console.log('Keep clicked');

          }
        }, {
          text: 'Remove',
          handler: () => {
            console.log('Remove clicked');
            this.postRemoveAddress(addressId);
          }
        }]
    });
    await alert.present();
  }

  postRemoveAddress(addressId) {
    this.loading(true);
    if (addressId)
      this.addressManagerService.deleteAddress(addressId).subscribe((result) => {
        console.log('deleteAddress() result:', result);
        this.loading(false);
        this.addressArr = this.addressManagerService.addressArr;
        // 修改本地 addressArr
        this.orderSessionService.setLocalAddress(this.addressManagerService.addressArr);
        // 修改本地 addressArrOfStoreList
        this.configService.setLocal('addressArrOfStoreList', this.addressManagerService.addressArr);

        this.isRemove = false;
        if (this.addressManagerService.currentAddress && addressId === this.addressManagerService.currentAddress.id) {
          console.log('remove the selecting address');
          this.addressManagerService.setIsChanged(true);
          this.currentAddressId = null;
          this.addressManagerService.currentAddress = null;
        }
      }, err => {
        this.loading(false);
        return this.showAlert(err.status, err['error']);
      });
  }

  addNewAdress() {
    console.log('addNewAdress cliking');
    this.showSearchAddressUI = true;
    this.getAddressField();
  }

  isAddressNull(): Boolean {
    return !(!!this.addressManagerService.currentAddress);
  }

  addressConfirmClicked() {
    let position = this.addressManagerService.getPositionByAddressId(this.currentAddressId);
    console.log("currentAddress position", position);
    // let paramsObj = {};
    // paramsObj = { addressId: this.currentAddressId }
    if (this.orderManager.store) {
      if (this.currentAddressId) {
        // 如果店铺存在 就 call api to getCouriers to confirm 是否可以配送
        this.loading(true);
        this.storeService.getCouriers(this.orderManager.store.id, this.orderManager.store.country, this.currentAddressId).subscribe(data => {
          this.loading(false);
          console.log('SearchAddressPage addressConfirmClicked getCouriers', data);
          this.addressManagerService.sameAddressCouriersData = data;
          this.handlingCouriers(data, true, false, () => {
            // save the.addressManagerService.currentAddress
            let address = this.addressManagerService.findAddress(this.currentAddressId);
            this.addressManagerService.saveCurrentAddress(address);
          });

        }, err => {
          this.loading(false);
          this.showError(err)
        })
      }

    } else {
      if (position) {
        // call api to get 可以配送的 store[]
        let address = this.addressManagerService.findAddress(this.currentAddressId);
        this.addressManagerService.saveCurrentAddress(address);
        this.modalController.dismiss({ position, deliveryStoreOnlyShowOne: true, notBackToPickupSegment: this.notBackToPickupSegment })
      }
    }
  }

  handlingCouriers(data, changed, isDismiss_notCoverCurrentAddress, callback?) {
    if (data && data[0] && data[0]['couriers'] && data[0]['couriers'].length > 0) {
      let rules = data[0]['couriers'];
      this.addressManagerService.setPriceRulesOfCurrentAddress(rules);
      // comfire btn click save the address is change
      this.addressManagerService.setIsChanged(changed);
      if (callback) callback();
      this.modalController.dismiss({ canDelivery: true });
    } else {
      // 
      this.showAlert('', this.t.instant('pages.cart.not-delivered'));
      if (this.orderManager.store && isDismiss_notCoverCurrentAddress) {
        this.modalController.dismiss({ canDelivery: false });
      }
    }
  }

}
