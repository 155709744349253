import { Injectable } from '@angular/core';
import { Address, Store, OrderItem } from 'aigens-ng-core';
import { ConfigService } from './config.service';
import { AQuery } from '../base/aquery';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base-service';

@Injectable({
  providedIn: 'root'
})
export class OrderSessionService extends BaseService {

  aq: AQuery;
  addressArr: Address[];
  constructor(private http: HttpClient, public configs: ConfigService) {
    super();
    this.aq = new AQuery(http, configs);
    this.addressArr = [];
  }

  /***ABOUT getOrderSesison——API https://docs.google.com/document/d/1MW6fddUSM2SXjeXBo395wK3tCPvTllU0uZEYpPvjDLA/edit** */

  getOrderSession(storeId, mode = 'byod', fields: string[] = [], spot?, session?): Observable<any> {// payable要有session
    if (fields.length === 0) return;
    let fv;
    let url = '/api/v1/menu/session.json?';

    fields.map((str) => {
      fv = fv ? fv + ',' + str : str;
    });
    let params = {
      storeId, mode, fields: fv, spot, session
    };
    for (let name in params) {
      if (!params[name]) delete params[name];
    }
    const aq = this.aq;
    aq.url = url;
    aq.method = 'post';
    aq.params = params;
    aq.auth(true);
    return aq.getJson().pipe(map((jo) => {
      return jo['data'];
    }));
  }
  getLocalOrderSession() {
    return this.configs.getLocal('orderSession');
  }
  getLocalAddresses(storesList?) {
    let orderSession = this.getLocalOrderSession();
    let addressArrOfStoreList = this.configs.getLocal('addressArrOfStoreList');
    let addresses;
    if (storesList) {
      // store list cant call session.json to get addresses so save the addresses in local'addressArrOfStoreList' 
      if (addressArrOfStoreList) {
        return addressArrOfStoreList || [];
      }
    } else {
      // 有 orderSession就读 orderSession里的地址
      if (orderSession && orderSession.member && orderSession.member.addresses) {
        addresses = orderSession.member.addresses;
      } else {
        return addressArrOfStoreList || [];
      }
    }
    return addresses || [];
  }
  setLocalAddress(address) {
    let orderSession = this.getLocalOrderSession();
    if (orderSession && orderSession.member) {
      orderSession.member.addresses = address;
      this.configs.setLocal('orderSession', orderSession);
    }
  }
  getLocalMembership() {
    let orderSession = this.getLocalOrderSession();
    let membership;
    if (orderSession && orderSession.member) {
      membership = orderSession.membership;
    }
    return membership;
  }
  setLocalMembershipOffers(offers): boolean {
    let orderSession = this.getLocalOrderSession();
    if (offers && orderSession && orderSession.membership) {
      orderSession.membership.offers = offers;
      this.configs.setLocal('orderSession', orderSession);
      return true;
    }
    return false;
  }
  clearLocalSession() {
    this.configs.setLocal('orderSession', null);
  }
}
