import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IonicModule} from '@ionic/angular';
import { MainSlideMenuComponent } from './main-slide-menu.component';
import { SharedModule } from '../../shared.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        SharedModule
    ],
    declarations: [MainSlideMenuComponent],
    entryComponents: [MainSlideMenuComponent],

    exports: [
        MainSlideMenuComponent
    ]
})
export class MainSlideMenuModule { }
