<ng-container *ngIf="!showSearchAddressUI">

  <ion-header>
    <ion-toolbar class="toolbar-container" color="primary">
      <!-- <ion-buttons slot="start"> -->
      <!-- <ion-button (click)="closeClicked()" class="back-btn AQA-back-btn"> -->
      <div (click)="closeClicked()" class="header-back-btn">
        <ion-icon name="ios-arrow-back"></ion-icon>
      </div>
      <!-- </ion-button> -->
      <!-- </ion-buttons> -->
    </ion-toolbar>

  </ion-header>

  <h2 class="hi-name">
    {{"pages.store-list.greetings" | translate}}<span>{{displayName}},</span>
  </h2>

  <ion-segment value="delivery" class="status-segment-box">
    <ion-segment-button value="delivery">
      {{"pages.store-list.deliver-to" | translate}}
    </ion-segment-button>
  </ion-segment>


  <ion-content [ngClass]="{'search-address-modal': true}">



    <ng-container>
      <!-- 配送地址 -->
      <div class="content-container">
        <div class="title-container">
          <div class="title-address">
            <div class="title-image">
              <img src="assets/images/motorcycle.svg">
            </div>
            <span>{{'dialog.address-dialog.title'|translate}}</span>
          </div>
          <div class="remove-btn" (click)="deleteHandle()" *ngIf="addressArr.length > 0 && currentAddressId">
            <p>{{'dialog.address-dialog.delete'|translate}}</p>
          </div>
        </div>

        <!-- when add-handle show  and the radio only select the one -->
        <ion-list>
          <!-- allow-empty-selection  (ionChange)="selectAddressChange($event)" -->
          <ion-radio-group [(ngModel)]="currentAddressId" *ngIf="addressArr.length > 0">

            <div class="dialog-address ">
              <ion-item lines="none" class="address-too-far"
                *ngFor="let address of addressArr;let i = index; trackBy:trackAddressById">
                <div class="address-click-btn">
                  <ion-radio slot="start" [value]="address?.id" mode="md"></ion-radio>
                </div>
                <div class="showed-address-wrapper">
                  <p class="address-name" *ngIf="address?.name">{{address?.name}}</p>
                  <p class="address-full" *ngIf="address?.full">{{address?.full}}</p>
                  <!-- <p class="address-phone" *ngIf="address?.phone">{{'dialog.address-dialog.label-mobile'|translate}}: {{address?.phone}}</p> -->
                  <div *ngIf="address['beyond']" class="too-far-row">
                    <div class="too-far-icon">
                      <ion-icon name="md-alert"></ion-icon>
                    </div>
                    <p>Delivery service <b>DOES NOT</b> cover this location.</p>
                  </div>
                </div>
              </ion-item>

            </div>
          </ion-radio-group>
          <!-- add new address box -->
          <div class="add-address-column" [ngClass]="{'animated flashit': isAddressNull()}"
            (click)="addNewAdress();$event.stopPropagation;">
            <div class="add-address-btn">
              <ion-icon name="ios-add"></ion-icon>
            </div>
            <div class="add-address-text">
              <p>{{'dialog.address-dialog.add-new-address'|translate}}</p>
            </div>
          </div>


        </ion-list>
      </div>
    </ng-container>

  </ion-content>

  <ion-footer [ngClass]="{'address-dialog-modal': true}">

    <!-- confirm -->
    <div class="btn-box" *ngIf="currentAddressId">
      <ion-button expand="block" class="confirm-btn key-btn-box AQA-key-button ai-anime headShake"
        (click)="addressConfirmClicked()">
        {{'dialog.address-dialog.confirm'|translate}}
      </ion-button>
    </div>
    <!-- remove -->
    <!-- <div class="btn-box" *ngIf="isRemove">
          <ion-button expand="block" class="confirm-btn key-btn-box AQA-key-button" (click)="removeClicked()">
            {{'dialog.address-dialog.remove'|translate}}
          </ion-button>
        </div> -->

  </ion-footer>
</ng-container>

<ng-container>
  <!-- <div class="add-address-container">
    <div class="order-header">
      <div class="back-btn" appThrottleClick (throttleClick)="backButtonClick()">
        <ion-icon name="ios-arrow-back"></ion-icon>
      </div>
      <p>{{'dialog.address-dialog.add-address-page-title'|translate}}</p>
    </div>
  </div> -->

  <!-- <ion-header>
    <ion-toolbar class="toolbar-container" color="primary">
      <ion-buttons slot="start">
        <ion-button (click)="closeClicked()" class="back-btn AQA-back-btn">
          <div class="header-back-btn">
            <ion-icon name="ios-arrow-back"></ion-icon>
          </div>
        </ion-button>
      </ion-buttons>

      <ion-title class="header-title">
        <p>{{'dialog.address-dialog.add-address-page-title'|translate}}</p>
      </ion-title>
    </ion-toolbar>

  </ion-header> -->


  <ion-header [hidden]="!showSearchAddressUI">
      <ion-toolbar class="add-addr-header">
          <ion-button (click)="closeClicked()">
            <ion-icon name="ios-arrow-back"></ion-icon>
          </ion-button>
          <ion-title>{{'dialog.address-dialog.add-address-page-title'|translate}}</ion-title>
      
        </ion-toolbar>
  </ion-header>

  <ion-content class="form-content" [hidden]="!showSearchAddressUI">
    <div [ngClass]="{'hidden':!enableMapSearch}" class="div-enter-addr">

      <p class="input-title">{{searchInfo?.title? searchInfo?.title : 'dialog.address-dialog.enter-addr'|translate}}</p>
      <div class="input-w-clear">
        <input class="input-addr" id="address" type="text" autocomplete="off" [placeholder]="searchInfo?.example" />
        <ion-icon name="close-circle-outline" (click)="clearAddress()"></ion-icon>
      </div>


    </div>

    <ng-container *ngIf="!enableMapSearch">
      <div class="div-enter-postal">
        <p class="input-title">{{'dialog.address-dialog.enter-postal'|translate}}</p>
        <div class="input-w-clear">
          <ion-input class="input-postal" id="postal" type="number" autocomplete="off" placeholder="postal code"
            [(ngModel)]="postalCode" [attr.maxlength]="6" (ionChange)="postalCodeCheck($event)"></ion-input>
          <ion-icon name="close-circle-outline"   (click)="clearPostalCode()"></ion-icon>
        </div>
      </div>



    </ng-container>

    <div [ngClass]="{'mainHidden':!displayMain,'mainVisable':displayMain}">
      <div id="map"></div>

      <div class="div-form-item div-country">
        <ion-label color="primary">{{'dialog.address-dialog.country-select'|translate}}</ion-label>
        <!-- <ion-input *ngIf="country === 'SG'; else notAvailable" disabled value="{{'dialog.address-dialog.singapore'|translate}}"></ion-input>
            <ng-template #notAvailable> -->
        <ion-input id="countryInput" readonly [value]="countryName" class="disable"></ion-input>
        <!-- </ng-template> -->

        <!-- <ion-select [(ngModel)]="country" (ionChange)="switchCountryForm()">
              <ion-select-option value="SG">{{'dialog.address-dialog.singapore'|translate}}</ion-select-option>
              <ion-select-option value="HK">{{'dialog.address-dialog.hongkong'|translate}}</ion-select-option>
            </ion-select> -->
      </div>

      <!-- dynamic form -->
      <form class="form" *ngIf="validations_form" [formGroup]="validations_form"
        (ngSubmit)="submitClicked(validations_form?.value)">
        <ng-container *ngFor="let field of addressFields">
          <div class="div-form-item div-country">
            <ion-label color="primary">{{field?.title}}
              <!-- {{'dialog.address-dialog.unit'|translate}} -->
              <ng-container *ngIf="field?.required"><span class="text-required">*</span></ng-container>
            </ion-label>

            <!-- postal 独有的 feature -->
            <ng-container *ngIf="field?.name === 'postal'">
              <ion-input [type]="field?.input" [formControlName]="field?.name" class="form-controll"
                (ionChange)="postalCodeCheck($event)" [placeholder]="field?.example"></ion-input>
            </ng-container>
            <ng-container *ngIf="field?.name !== 'postal'">
              <ion-input [readonly]="!(field?.editable)" [type]="field?.input" [formControlName]="field?.name"
                [ngClass]="{'disable':!(field?.editable)}" [placeholder]="field?.example" class="form-controll">
              </ion-input>
            </ng-container>
            <div class="validation-errors" *ngIf="validation_messages[field?.name]">
              <ng-container *ngFor="let validation of validation_messages[field?.name]">
                <div class="error-message"
                  *ngIf="validations_form.get(field?.name).hasError(validation.type) && (validations_form.get(field?.name).dirty || validations_form.get(field?.name).touched)">
                  {{'dialog.address-dialog.required-tips'|translate}}
                </div>
              </ng-container>
            </div>

          </div>
          <!-- <div class="validation-errors" *ngIf="validation_messages[field?.name]">
                <ng-container *ngFor="let validation of validation_messages[field?.name]">
                  <div class="error-message"
                    *ngIf="validations_form.get(field?.name).hasError(validation.type) && (validations_form.get(field?.name).dirty || validations_form.get(field?.name).touched)">
                    {{'dialog.address-dialog.required-tips'|translate: field}}
                  </div>
                </ng-container>
              </div> -->
        </ng-container>
      </form>

    </div>
  </ion-content>

  <ion-footer [hidden]="!showSearchAddressUI">
    <div class="btn-box">
      <ion-button expand="block" [disabled]="!validations_form?.valid"
        class="confirm-btn key-btn-box AQA-key-button ai-anime headShake"
        (click)="submitClicked(validations_form?.value)">

        {{'dialog.address-dialog.add'|translate}}
      </ion-button>
    </div>
  </ion-footer>

</ng-container>