var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TermsConditionsPage } from '../../../dialogs/terms-conditions/terms-conditions';
import { MemberService } from '../../../core/services/member.service';
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { getCountryCallingCode } from '../../../shared/utilities/checkFormatTool';
import { MobileBasePage } from 'src/app/core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
import { UUID } from 'angular2-uuid';
var DeliveryLoginPage = /** @class */ (function (_super) {
    __extends(DeliveryLoginPage, _super);
    function DeliveryLoginPage(injector, memberService) {
        var _this = _super.call(this, injector) || this;
        _this.memberService = memberService;
        _this.form = {};
        _this.status = '';
        _this.googleLoginClicked = false;
        _this.mode = 'email';
        _this.hasCrmId = false;
        _this.isPopup = false;
        _this.facebookLogin = false;
        _this.googleLogin = false;
        _this.isFirstLoad = true;
        _this.isNewLoginFlow = true;
        _this.isEnterPWUi = false;
        console.log('delivery login page');
        _this.loginCallBack = _this.getNavParams('logincb');
        _this.store = _this.getNavParams('store');
        _this.fromPage = _this.getNavParams('fromPage');
        if (!_this.store) {
            // when this component is using for modal-pop-up ,it can be injector NavParams;otherwise be err;
            _this.navParams = injector.get(NavParams);
            _this.store = _this.navParams.get('store');
        }
        if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway') && !_this.getNavParams('isRoute')) {
            //   when this component is not using for modal-pop-up , the next code will be err;
            _this.isPopup = _this.navParams.get('isPopup');
        }
        _this.brand = _this.store.brand;
        var member = _this.memberService.getMember();
        if (member) {
            if (member['method'] === 'device') {
                // no need logout because of the check phone or email isRegister need sid.
            }
            else
                _this.memberService.clearSession();
        }
        if (_this.brand) {
            // this.brand['crmId'] = ""
            _this.hasCrmId = _this.brand['crmId'] ? true : false;
            _this.facebookLogin = _this.brand.hasFeature('flogin') && navigator.userAgent.indexOf('AlipayClient') < 0;
            _this.googleLogin = _this.brand.hasFeature('glogin') && navigator.userAgent.indexOf('AlipayClient') < 0;
        }
        if (_this.googleLogin) {
            // this.initGoogle();
            _this.googleLoginHandler = function (e) {
                var data = e.detail;
                _this.googleLoginHandling(data.id, data.token);
            };
            window.addEventListener('google-login-handling', _this.googleLoginHandler);
        }
        return _this;
        // console.log('store:', this.store);
    }
    DeliveryLoginPage.prototype.dismissClicked = function () {
        console.log('delivery login modal closing');
        this.modalController.dismiss();
    };
    DeliveryLoginPage.prototype.ionViewDidEnter = function () {
        if (this.isFirstLoad) {
            this.initGoogle();
        }
        this.isFirstLoad = false;
    };
    DeliveryLoginPage.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    DeliveryLoginPage.prototype.getCountryCallingCode = function () {
        return getCountryCallingCode();
    };
    DeliveryLoginPage.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        window.removeEventListener('google-login-handling', this.googleLoginHandler);
    };
    DeliveryLoginPage.prototype.submitClicked = function () {
        var _this = this;
        console.log('login clicking', this.form);
        var username = (this.form[this.mode] ? this.form[this.mode] : '');
        var password = (this.form['password'] ? this.form['password'] : '');
        var countryCallingCode = this.form['countryCallingCode'];
        var tmpSet = new Set(username);
        var type = tmpSet.has('@') ? 'email' : 'phone';
        if (!countryCallingCode && type === 'phone') {
            countryCallingCode = getCountryCallingCode();
        }
        if (this.mode !== type) {
            if (this.mode === 'email') {
                this.showAlert(null, this.t.instant('pages.login.input-mismatch-email'));
                return;
            }
            if (this.mode === 'phone') {
                this.showAlert(null, this.t.instant('pages.login.input-mismatch-phone'));
                return;
            }
            return;
        }
        var brandId = null;
        if (this.hasCrmId) {
            if (!this.brand || !this.brand.id) {
                this.showAlert(null, 'Missing Brand ID');
                return;
            }
            if (!username) {
                this.showAlert(null, this.t.instant('pages.login.usernameEmpty'));
                return;
            }
            if (!password) {
                this.showAlert(null, this.t.instant('pages.login.passwordEmpty'));
                return;
            }
            type = 'crm';
            countryCallingCode = null;
            brandId = this.brand.id;
        }
        this.loading(true);
        this.memberApiService.login(username, password, type, countryCallingCode, brandId).subscribe(function (member) {
            _this.memberService.putMember(member);
            _this.checkingMembership(_this.store, function () {
                if (sessionId) {
                    // check membership first
                    if ((!member.membership || member.membership === 0)) {
                        // prompt alert for asking membership
                        _this.memberHandle();
                        return;
                    }
                    // delivery new logic
                    // call api to get current member's address
                    if (!member.phone) {
                        _this.pushByName('PhoneVerification', {
                            fromPage: DeliveryLoginPage,
                            store: _this.store,
                            deliveryLoginCb: function () {
                                _this.deliveryHandling();
                            }
                        });
                    }
                    else {
                        _this.deliveryHandling();
                    }
                    return;
                    if (_this.systemConfigService.hideMemberProfilePage) {
                        _this.navigationService.popPage();
                    }
                    else {
                        var page = _this.isProfileCompleted(member) ? 'MemberRewardPage' : 'ProfilePage';
                        var params = { isEditMode: !_this.isProfileCompleted(member) };
                        _this.pushByName(page, params, { replaceUrl: true }).then(function () {
                            if (!_this.isProfileCompleted(member)) {
                                _this.showAlert('', _this.t.instant('pages.login.incomplete-profile'));
                            }
                        });
                    }
                }
            });
            var sessionId = member.sessionId;
        }, function (err) {
            _this.loading(false);
            console.error(err);
            _this.showError(err);
        }, function () {
            _this.loading(false);
        });
    };
    DeliveryLoginPage.prototype.memberHandle = function () {
        var _this = this;
        this.openTermsModal(this.orderManager.store.brand, function (handle) {
            console.log('handle', handle);
            if (handle['fail'] === 'failed') {
                console.log('focus out');
                _this.memberService.clear();
                _this.navigationService.popToRoot();
            }
            else {
                // do membership here
                var marketing = false;
                marketing = handle['marketing'];
                var marketingOrderPlace = false;
                marketingOrderPlace = handle['marketingBrand'];
                console.log('marketing', marketing);
                console.log('will do membership');
                _this.loading(true);
                _this.memberApiService.join(_this.orderManager.store.id, _this.orderManager.store.brandId, marketing).subscribe(function (result) {
                    console.log('result', result);
                    _this.loading(false);
                    if ((_this.orderManager.mode === 'delivery' || _this.orderManager.mode === 'takeaway')) {
                        // delivery new logic
                        // call api to get current member's address
                        if (!_this.memberService.member.phone) {
                            _this.pushByName('PhoneVerification', {
                                fromPage: DeliveryLoginPage,
                                store: _this.store,
                                deliveryLoginCb: function () {
                                    _this.deliveryHandling();
                                }
                            });
                        }
                        else {
                            _this.deliveryHandling();
                        }
                    }
                }, function (err) {
                    _this.loading(false);
                });
            }
        }, TermsConditionsPage, true, false, true, this.memberService.member.marketing, false, false, function (callBack) {
            _this.showAlert('', _this.t.instant('global.t&c-dimiss-reminder'), [{
                    text: _this.t.instant('global.t&c-logout'),
                    handler: function () {
                        callBack();
                    }
                }, {
                    text: _this.t.instant('global.t&c-cancel'),
                    handler: function () {
                    }
                }]);
        });
    };
    DeliveryLoginPage.prototype.deliveryHandling = function () {
        var _this = this;
        var needRecomend = this.settings.aiRecommendCount && this.settings.aiRecommendCount > 0;
        var fields = ['addresses', 'membership', 'offers'];
        needRecomend && fields.push('recommend');
        this.loading(true);
        this.orderSessionService.getOrderSession(this.orderManager.store.id, this.orderManager.mode, fields).subscribe(function (orderSession) {
            _this.loading(false);
            if (orderSession) {
                var member = orderSession.member;
                if (_this.memberService.member && _this.memberService.member.phone) {
                    orderSession.member['phone'] = _this.memberService.member.phone;
                    orderSession.member.marketing = _this.memberService.member.marketing;
                }
                _this.orderSessionService.setLocalAddress(_this.addressManagerService.calAddressesDistance(member.addresses, _this.store));
                _this.configService.setLocal('orderSession', orderSession);
                if (member.addresses && member.addresses.length > 0) {
                    var addresses = member.addresses;
                    _this.addressManagerService.addressArr = addresses;
                    var addressArrBeyond = addresses.filter(function (address) { return !address['beyond']; });
                    if (addressArrBeyond.length >= 1) {
                        if (!(_this.addressManagerService.pickupAddress || _this.addressManagerService.currentAddress)) {
                            var defautlAddress = _this.addressManagerService.findDefaultAddress(addressArrBeyond);
                            _this.addressManagerService.saveCurrentAddress(defautlAddress);
                        }
                        if (_this.fromPage === 'CategoryListPage' || _this.fromPage === 'ItemGridScrollPage') {
                            _this.backButtonClick();
                            // before forcing login , save the order message with Tourists,so need to clear the local object
                            var key = _this.store.id + '.savedOrder';
                            _this.configService.putPreference(key, null);
                            return;
                        }
                        _this.popToRoot().then(function () {
                            if (_this.loginCallBack) {
                                _this.loginCallBack();
                            }
                        });
                    }
                    else {
                        var page = 'AddressDialog';
                        _this.pushByName(page, {
                            fromPage: 'deliveryLogin',
                            isRoute: true,
                            goToMenu: true,
                            showModeSelect: true,
                        }, {}).then(function () {
                        });
                    }
                }
                else {
                    var page = 'AddressDialog';
                    _this.pushByName(page, {
                        fromPage: 'deliveryLogin',
                        isRoute: true,
                        goToMenu: true,
                        showModeSelect: true,
                    }, {}).then(function () {
                        _this.showToast('Please add yout first address', 2000, 'top', 'normal-toast');
                    });
                }
            }
            else {
                return;
            }
        }, function (err) {
            _this.loading(false);
            console.log('getReward error', err);
        });
    };
    DeliveryLoginPage.prototype.onFacebookLoginClick = function () {
        var _this = this;
        this.loading(true);
        FB.login(function (response) {
            _this.loading(false);
            if (response.status === 'connected') {
                // Logged into your app and Facebook.
                var data = response.authResponse;
                _this.fbLoginHandling(data['userID'], data['accessToken'], true);
            }
            else {
                _this.showAlert('Login Fail', '');
                // The person is not logged into this app or we are unable to tell.
            }
        }, { auth_type: 'reauthenticate' });
    };
    DeliveryLoginPage.prototype.fbLoginHandling = function (fbId, token, join) {
        var _this = this;
        if (join === void 0) { join = false; }
        this.memberApiService.FBloginOrRegistration(fbId, token, true, this.store.id, this.brand.id, true).subscribe(function (member) {
            console.log(member);
            _this.loading(false);
            var sessionId = member.sessionId;
            // this.showAlert("",JSON.stringify(member));
            if (sessionId) {
                _this.memberService.putMember(member);
                _this.checkingMembership(_this.store, function () {
                    if (!member.membership || !member.membership.marketing) {
                        _this.openTermsModal(_this.brand, function (data) {
                            // this.configService.putPreference('termsAgree', data['agree']);
                            _this.memberApiService.join(_this.orderManager.store.id, _this.orderManager.store.brandId, data.marketing).subscribe(function (result) {
                                _this.loading(false);
                            }, function (err) {
                                console.log(err);
                                _this.loading(false);
                                _this.showAlert('Error ' + err.status, err._body);
                            }, function () {
                                _this.memberService.member.marketing = data.marketing;
                                if (member.phone) {
                                    _this.deliveryHandling();
                                }
                                else {
                                    _this.pushByName('PhoneVerification', {
                                        fromPage: DeliveryLoginPage,
                                        store: _this.store,
                                        deliveryLoginCb: function () {
                                            _this.deliveryHandling();
                                        }
                                    });
                                }
                            });
                        }, TermsConditionsPage, true, true);
                    }
                    else {
                        // force to input phone
                        if (member.phone) {
                            _this.deliveryHandling();
                        }
                        else {
                            _this.pushByName('PhoneVerification', {
                                fromPage: DeliveryLoginPage,
                                store: _this.store,
                                deliveryLoginCb: function () {
                                    _this.deliveryHandling();
                                }
                            });
                        }
                    }
                });
                // deal with the delivery condition with fblogin
                // this.deliveryHandling();
                return;
                var page = _this.isProfileCompleted(member) ? 'MemberRewardPage' : 'ProfilePage';
                var params = { isEditMode: !_this.isProfileCompleted(member) };
                _this.pushAndRemovePage(page, params, null, function () {
                    if (!_this.isProfileCompleted(member)) {
                        _this.showAlert('', _this.t.instant('pages.login.incomplete-profile'));
                    }
                });
            }
        }, function (err) {
            console.log(err);
            _this.showError(err);
        });
    };
    DeliveryLoginPage.prototype.registration = function () {
        this.pushByName('VerifyRegistrationPage', { fromPage: DeliveryLoginPage, store: this.store });
    };
    DeliveryLoginPage.prototype.resetPassword = function () {
        var store = this.orderManager.store;
        if (!store)
            store = this.store;
        if (store && store.crm && store.crm.links && store.crm.links['forgetpw']) {
            // open in new tab
            window.open(store.crm.links['forgetpw']['url']);
        }
        else {
            this.pushByName('ResetPasswordPage', { username: this.form.email, isForgotPassword: true, brand: this.brand });
        }
    };
    DeliveryLoginPage.prototype.initGoogle = function () {
        window['onGoogleSignIn'] = this.onSignIn;
        var params = {};
        params['id'] = 'google-login-script';
        this.configService.loadScript('https://apis.google.com/js/platform.js?onload=renderButton', function () {
        }, params);
    };
    DeliveryLoginPage.prototype.onSignIn = function (googleUser) {
        var profile = googleUser.getBasicProfile();
        var id = profile.getId();
        var id_token = googleUser.getAuthResponse().id_token;
        console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
        console.log('token:' + id_token);
        var evt = new CustomEvent('google-login-handling', {
            detail: {
                id: id,
                token: id_token
            }
        });
        window.dispatchEvent(evt);
    };
    DeliveryLoginPage.prototype.clickGoogleLogin = function () {
        this.googleLoginClicked = true;
    };
    DeliveryLoginPage.prototype.selectMode = function (type) {
        this.mode = type;
    };
    DeliveryLoginPage.prototype.googleLoginHandling = function (id, token) {
        var _this = this;
        if (this.googleLoginClicked) {
            this.loading(true);
            this.memberApiService.googleLogin(id, token).subscribe(function (member) {
                console.log(member);
                _this.loading(false);
                var sessionId = member.sessionId;
                if (sessionId) {
                    _this.memberService.putMember(member);
                    _this.checkingMembership(_this.store, function () {
                        if (!member.membership || !member.membership.marketing) {
                            _this.openTermsModal(_this.brand, function (data) {
                                _this.loading(true);
                                _this.memberApiService.join(_this.orderManager.store.id, _this.orderManager.store.brandId, data.marketing).subscribe(function (data) {
                                    _this.loading(false);
                                }, function (err) {
                                    console.log(err);
                                    _this.loading(false);
                                    _this.showAlert('Error ' + err.status, err._body);
                                }, function () {
                                    // this.configService.putPreference('termsAgree', data['agree']);
                                    _this.memberService.member.marketing = data.marketing;
                                    if (member.phone) {
                                        _this.deliveryHandling();
                                    }
                                    else {
                                        _this.pushByName('PhoneVerification', {
                                            fromPage: DeliveryLoginPage,
                                            store: _this.store,
                                            deliveryLoginCb: function () {
                                                _this.deliveryHandling();
                                            }
                                        });
                                    }
                                });
                            }, TermsConditionsPage, true, true);
                        }
                        else {
                            if (member.phone) {
                                _this.deliveryHandling();
                            }
                            else {
                                _this.pushByName('PhoneVerification', {
                                    fromPage: DeliveryLoginPage,
                                    store: _this.store,
                                    deliveryLoginCb: function () {
                                        _this.deliveryHandling();
                                    }
                                });
                            }
                        }
                        return;
                    });
                    // this.push(MemberRewardPage);
                }
            }, function (err) {
                _this.loading(false);
                console.error(err);
                _this.showError(err);
            }, function () {
                _this.loading(false);
            });
        }
    };
    DeliveryLoginPage.prototype.justShowTC = function () {
        this.openTermsModal(this.brand, function () { }, TermsConditionsPage, false, false, false, false, true, true);
    };
    DeliveryLoginPage.prototype.guestLogin = function () {
        var _this = this;
        this.loading(true);
        var s = UUID.UUID().toString();
        this.memberApiService.guestLogin(s, this.store.brandId).subscribe(function (member) {
            _this.loading(false);
            _this.memberService.putMember(member);
            _this.deliveryHandling();
        }, function (err) {
            _this.loading(false);
            _this.showError(err);
        });
    };
    DeliveryLoginPage.prototype.newLoginFlowNext = function () {
        console.log('newLoginFlowNext');
        var params = {};
        params[this.mode] = this.form[this.mode];
        // if (this.mode === 'email') {
        //     console.log('mode', this.mode);
        //     params['phone'] = this.form[this.mode];
        // }
        // if (this.mode === 'phone') {
        //     console.log('mode', this.mode);
        //     params['phone'] = this.form[this.mode];
        // }
        console.log('params', params);
        this.getIsRegister(params);
    };
    DeliveryLoginPage.prototype.getIsRegister = function (params) {
        var _this = this;
        console.log("getIsRegister params", params);
        // maybe need to change the api to getIsRegister
        this.loading(true);
        this.memberApiService.getIsRegister(this.orderManager.store.brandId, this.orderManager.store.id, params).subscribe(function (res) {
            // isRegister = true
            _this.loading(false);
            if (res && res.exist && res.exist[_this.mode]) {
                console.log("已经注册");
                _this.changeToEnterPW();
            }
            else {
                _this.gotoRegisterPage();
            }
        }, function (err) {
            console.log('getIsRegister res', err);
            _this.loading(false);
            _this.showError(err);
            // if(err.error === 'PHONE_ALREADY_EXIST' || err.error === 'EMAIL_ALREADY_EXIST'){
            //     // isRegister = true
            //     this.changeToEnterPW();
            // }else{
            //     // isRegister = true
            //     this.gotoRegisterPage();
            // }
        });
        // not register
        // this.gotoRegisterPage();
        // already registered
        // this.changeToEnterPW();
    };
    DeliveryLoginPage.prototype.changeToEnterPW = function () {
        console.log("changeToEnterPW");
        this.isEnterPWUi = true;
    };
    DeliveryLoginPage.prototype.gotoRegisterPage = function () {
        console.log("gotoRegisterPage");
        this.pushByName('VerifyRegistrationPage', { fromPage: DeliveryLoginPage, store: this.store });
    };
    DeliveryLoginPage.prototype.backButtonClick = function () {
        if (this.isEnterPWUi) {
            this.isEnterPWUi = false;
            return;
        }
        else {
            _super.prototype.backButtonClick.call(this);
        }
    };
    return DeliveryLoginPage;
}(MobileBasePage));
export { DeliveryLoginPage };
