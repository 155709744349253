import { Item, ItemGroup, ModifierBuilder, OrderItem, Store } from 'aigens-ng-core';
import { ChangeDetectorRef, Component, Injector, OnDestroy } from '@angular/core';
import { MobileBasePage } from '../../../core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';

@Component({
    selector: 'modifier-item-select-modal',
    templateUrl: 'modifier-item-select-modal.html',
    styleUrls: ['modifier-item-select-modal.scss']
})
export class ModifierItemSelectPageModal extends MobileBasePage implements OnDestroy {

    // selected: any;

    groups: ItemGroup[];
    store: Store;
    // originGroups: ItemGroup[];

    orderItem: OrderItem;
    builder: ModifierBuilder;
    confirm = false;
    modifiableItems: Item[];
    tmp: Item[];
    mmap = {};
    absentItemsId: string[] = [];

    confirmHandling = false;

    mods: any = []; // save ItemGroup-data selected modifier
    mgroupItemsMap: {};
    isEqualMaxMap = {};

    constructor(private injector: Injector, private cd: ChangeDetectorRef, public navParams: NavParams) {
        super(injector);

        // this.originGroups = this.getNavParams("groups");
        /*if(this.getNavParams("oneTime")){
            this.navController.pop({animate:false});

        }*/
        console.log('ModifierItemSelectPage');
        // this.orderItem = this.getNavParams('orderitem');
        // this.store = this.getNavParams('store');

        this.orderItem = this.navParams.get('orderitem');
        this.store = this.navParams.get('store');

        if (!this.store) {
            this.store = this.orderManager.store;
        }
        //
        // if (!this.orderItem || !this.store) {
        //     //TODO: Guard?
        //     this.router.navigate([this.navigationService.getRootPageUrl()]);
        // }
        this.builder = new ModifierBuilder(this.store, this.orderItem);

        const items = this.builder.findItems(this.orderItem);

        this.modifiableItems = items;
        this.modifiableItems.forEach((item) => {

            item.mgroups = item.mgroups.filter(mgroup => (mgroup.min === 0 && mgroup.items.length > 0) || mgroup.min > 0);

            item.mgroups.forEach((mgroup) => {
                // let tmp = mgroup.items.filter(item => item.absentId != null && item.absentId != "")
                // tmp.forEach((item)=>{
                //     this.absentItemsId.push(item.absentId);

                // })
                mgroup.items.forEach((mitem) => {
                    if (mitem.absentId != null && mitem.absentId !== '') {
                        this.absentItemsId.push(mitem.absentId);
                    }

                    if (mitem.mgroup && this.has(item, mgroup, mitem)) {
                        this.mmap[mitem.mgroup] = mitem;
                    }

                    if (mgroup.defaultId && mitem.id === mgroup.defaultId) {
                        this.has(item, mgroup, mitem) || this.itemClicked(item, mgroup, mitem, true);
                    }

                });

            });
        });

        this.tmp = this.modifiableItems;
        console.log(this.modifiableItems);

        this.setModSelected();

        console.log('ly: -> modifier-item-select-modal.ts -> setModSelected() -> this.mods:', this.mods);

        this.setMgroupItemsMap();

        console.log('this.mgroupItemsMap', this.mgroupItemsMap);

    }

    setModSelected() {
        let mods = [];
        this.modifiableItems.forEach((currentItem) => {
            currentItem && currentItem.mgroups.map((mgroup) => {
                for (let midx = 0; midx < mgroup.items.length; midx++) {
                    let quantity = this.builder.getQuantity(currentItem, mgroup, mgroup.items[midx]);
                    if (quantity > 0 && mods.indexOf(currentItem) === -1) {
                        mods.push(currentItem);
                        break;
                    }
                }
            });
        });
        this.mods = mods;
    }

    setMgroupItemsMap() {
        let obj = {};
        this.modifiableItems.forEach((item) => {
            item.mgroups && item.mgroups.forEach(mgroup => {
                if (this.classify(mgroup.items, 'mgroup').length > 0)
                    obj[mgroup.id] = this.classify(mgroup.items, 'mgroup');
            });
        });
        // console.log('obj', obj);
        this.mgroupItemsMap = obj;

    }

    classify(arr, key: string): Array<any> {
        // 根据 对象数据的某一项 key 值 来新建一个对象
        let obj = {};
        arr.map(item => {
            if (item[key])
                obj[item[key]] = null;
            else
                obj['noMgroupStr'] = null;
        });
        let valuesArr = Object.keys(obj); // 包含 key 所有值的数组

        // console.log('valuesArr', valuesArr);
        // 根据 key 的值 把对象数组 分类
        let res = valuesArr.map(val => {
            return arr.filter(item => {
                if(item[key]){
                    return val === item[key];
                }else{
                    return true;
                }
            });
        });
        return res;
    }

    public getPageUrl() {
        return 'modifier-item-select';
    }

    public pageName(): string {
        return '/modifier-item-select';
    }
    closeModal() {
        this.modalController.dismiss();

        // debugger
        // if ((this.navigationService.lastParams && this.navigationService.lastParams['orderitem']) || this.navigationService.poping) {
        //    this.modalController.dismiss();
        // } else {
        //     // this.navController.pop().then(data => {
        //     //     console.log('pop ok', data);
        //     // }, error => {
        //     //     console.log('cannot pop', error);
        //     const t = this.navigationService.getRootPageUrl();
        //     console.log('home guard', t);

        //     if (t) {
        //         // TODO: not allowed to enter target root. go to parent route?
        //         this.navController.setDirection('root');
        //         this.router.navigate([this.navigationService.getRootPageUrl()]);
        //     }

        // }
    }

    dismissClicked(confirem: boolean = false) {
        this.modalController.dismiss(confirem);
    }
    getSelectRules(igroup: ItemGroup): string {
        if (!igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return '';
        }
        const min = Number(igroup.min);
        const max = Number(igroup.max);
        if (min === max && min === 0) {
            return '';
        }
        // 請選擇{{count}}項
        if (min === max) {
            return this.t.instant('pages.item-select.select-items', { count: min });
        }
        // 加配項目
        if (min === 0 && max === 99) {
            return this.t.instant('pages.item-select.optional');
        }
        // 請選最多max項
        if (min === 0 && max !== 99) {
            return this.t.instant('pages.item-select.chooseAtMost', { max: max });
        }
        // 請選至少{{min}}項
        if (min !== 0 && max === 99) {
            return this.t.instant('pages.item-select.chooseUp', { min: min });
        }
        // 請選{{min}} - {{max}}項
        if (min < max && max !== 99) {
            return this.t.instant('pages.item-select.chooseTo', { min: min, max: max });
        }
        return '';
    }

    isMGroupSelected(item: Item, igroup: ItemGroup): boolean {
        if (!item || !igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return false;
        }
        let min = Number(igroup.min);
        let max = Number(igroup.max);
        let items = igroup.items || [];
        let isSelect = false;
        let quantities = 0;

        for (let tmpItem of items) {
            quantities += this.builder.getQuantity(item, igroup, tmpItem);
        }
        if (min > 0) {
            isSelect = quantities >= igroup.min;
        } else if (quantities > 0) { /// min = 0
            isSelect = true;
        }
        return isSelect;
    }

    itemClicked(item: Item, group: ItemGroup, mItem: Item, isPreselect: boolean = false) {
        const max: number = group.max;
        const contain: boolean = this.has(item, group, mItem);
        if (contain && isPreselect) {
            return;
        }
        if (mItem.baseQty > 0) {
            const count = this.builder.getQuantity(item, group, mItem);
            if (count < mItem.max) {
                this.builder.plus(item, group, mItem);
            }
        } else if (group.repeat) {
            if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                this.builder.plus(item, group, mItem);
            } else {
                // when group.repeat==true&&group.max==1
                if (max === 1)
                    this.minusClicked(item, group, mItem);
            }
        } else {
            if (max === 1) {
                this.builder.clearForItem(item, group);
                if (!contain) {
                    this.builder.setMod(item, group, mItem, 1);

                }
            } else if (max > 1) {
                let q: number;
                if (contain) {
                    q = 0;
                    if (mItem.mgroup) {
                        this.mmap[mItem.mgroup] = null;
                    }
                } else {

                    if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                        q = 1;
                    } else {
                        // 选取的 配置数量等于max
                        this.setIsEqualMax(item.id);
                        q = 0;
                    }
                }

                if (mItem.mgroup && q === 1) {
                    this.clearSameMGroup(item, group, mItem);
                }
                this.builder.setMod(item, group, mItem, q);
            }

        }

        if (!isPreselect) {

            this.fix();
        }

        this.setModSelected();
        console.log('this.builder', this.mods);

    }

    setIsEqualMax(id = null) {
        this.isEqualMaxMap[id] = true;
        setTimeout(() => {
            this.isEqualMaxMap[id] = false;
        }, 800);
    }

    // item-selected-tap-tips
    getModifierItemDisplay(imap: any, item: Item): string {

        // if (item.quantity > 0) {

        let name = item.name || null;

        if (item.quantity > 1) {
            name = name + ' x ' + item.quantity;
        }

        if (item.marker !== 'no-receipt') return name;
        // } else if (imap && item.quantity === 0) {

        //     let result = null;
        //     const i: Item = imap[item.id];

        //     if (i && i.absentId) {

        //         const absent: Item = imap[i.absentId];

        //         if (absent) {
        //             const name = absent.name;

        //             result = name;
        //         } else {
        //             console.log('absent item not found', i.absentId);
        //         }
        //     }

        //     return result;

        // }

        // return null;
    }

    clearSameMGroup(item: Item, group: ItemGroup, mItem: Item) {
        const i = this.mmap[mItem.mgroup];
        if (i) {
            this.builder.setMod(item, group, i, 0);
        }


        this.mmap[mItem.mgroup] = mItem;

    }

    fix() {
        this.cd.detectChanges();
        // this.push(ModifierItemSelectPage, { store: this.store, orderitem: this.builder.build(),oneTime:true },{animate:false});
        // this.modifiableItems = this.tmp;
    }

    ngOnDestroy() {
        // this.handleCallback(this.confirm)
        this.confirmHandling = false;
    }

    confirmClicked() {
        if (!this.isCompleted()) {
            this.showAlert('', 'Please complete all requried fields');
            return;
        }
        if (this.confirmHandling) {
            console.log('is processing, return');
            return;
        } else {
            this.confirmHandling = true;
        }
        const orderManager = this.multOrderManager.enableMutlOrder ? this.multOrderManager.getOrderManager(this.store.id) : this.orderManager;
        if (OrderItem.isSet(this.orderItem) || this.orderItem.quantity === 1) {
            const oi = this.builder.build();
            orderManager.refreshOrderItem(oi);
        } else {
            const duplicated: OrderItem = orderManager.duplicateOrderItem(this.orderItem);
            duplicated.quantity--;
            const oi = this.builder.build();
            oi.quantity = 1;
            orderManager.refreshOrderItem(oi);
        }
        this.confirm = true;
        console.log('on pop');
        // this.navController.setDirection('back');
        // this.router.navigate(['../'], {relativeTo: this.route, replaceUrl: true}).then(() => {
        // });
        // this.navigationService.popPage();
        this.modalController.dismiss('confirm');

        // this.dismissClicked(this.confirm);
        console.log('pop done');
    }

    has(item: Item, group: ItemGroup, mItem: Item): boolean {
        // console.log(mItem.name + ':' + this.builder.getQuantity(item, group, mItem));
        return this.builder.getQuantity(item, group, mItem) > 0;
    }

    hasModifier(item: Item, mgroup: ItemGroup): boolean {
        let bool = false;
        mgroup && mgroup.items && mgroup.items.forEach(mitem => {
            if (this.builder.getQuantity(item, mgroup, mitem) > 0) {
                bool = true;
            }
        });
        return bool;
    }


    canAdd(item: Item, group: ItemGroup, mItem?: Item): boolean {
        const max: number = group.max;
        let quantities = 0; // this.builder.getSelectedModifierCount(item);
        for (const tmpItem of group.items) {
            quantities += this.builder.getQuantity(item, group, tmpItem);
        }
        this.mmap = {};
        if (max <= quantities) {
            return false;
        }
        group && group.items && group.items.forEach((mitem) => {
            if (mitem.mgroup && this.has(item, group, mitem)) {
                this.mmap[mitem.mgroup] = mitem;
            }
        });
        if (max > quantities && mItem && this.mmap[mItem.mgroup] && mItem.id !== this.mmap[mItem.mgroup].id) {
            return true;
        }

        return max > quantities;
    }

    minusClicked(item: Item, group: ItemGroup, mItem: Item) {
        const count = this.builder.getQuantity(item, group, mItem);
        if (count > mItem.min) {
            this.builder.minus(item, group, mItem);
            this.setModSelected();
            this.fix();

        }
    }

    backButtonClick() {
        this.handleCallback(this.confirm);
        super.backButtonClick();
    }

    isCompleted(): boolean {

        let completed = true;
        for (const item of this.modifiableItems) {
            const mgroups: ItemGroup[] = item.mgroups;
            if (mgroups) {
                for (let i = 0; i < mgroups.length; i++) {
                    let quantities = 0;

                    for (const tmpItem of mgroups[i].items) {
                        quantities += this.builder.getQuantity(item, mgroups[i], tmpItem);
                    }

                    if (mgroups[i].min > 0) {
                        completed = quantities >= mgroups[i].min;

                    } else {
                        completed = true;
                    }

                    if (!completed) {
                        break;
                    }


                }
                if (!completed) {
                    break;
                }
            }


        }
        return completed;

    }
}
