import { Component, OnInit, Injector, ChangeDetectorRef } from '@angular/core';
import { MobileBasePage } from '../../core/base/mobile-base-page';

import { Address, Location, Offer } from 'aigens-ng-core';
import { getCategoryPage } from 'src/app/shared/utilities/layout-helper';
import { UUID } from 'angular2-uuid';
import { StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialog extends MobileBasePage implements OnInit {

  // addressArr = [];
  addressArr: Address[] = [];
  currentAddressId: string;
  pickupAddressId: string;
  pickupAddressGroups: any = [];

  pickupArr: any = [];

  isRemove = false;
  isRoute: boolean;
  fromPage: string;
  confirmCb: any;
  addAddressCb: any; // it is addressDialog when popup but open new page(add address page) and add address success callback;
  goToMenu: false;
  storeLocation: Location;
  showModeSelect: false;
  segmentOption = 'pickup';
  isChangeDeliveryMode: any;
  displayName: any = '';
  hideLogin = false;
  hideDeliveryTab = false;

  constructor(public injector: Injector, private cd: ChangeDetectorRef, public storeService: StoreService) {
    super(injector);
    this.storeLocation = this.orderManager.store.location;
    this.isRoute = this.getNavParams('isRoute') || false;
    this.confirmCb = this.getNavParams('confirmCb');
    this.goToMenu = this.getNavParams('goToMenu');
    this.fromPage = this.getNavParams('fromPage');
    this.addAddressCb = this.getNavParams('addAddressCb');
    this.showModeSelect = this.getNavParams('showModeSelect');

    this.isChangeDeliveryMode = this.getNavParams('isChangeDeliveryMode');
    this.hideLogin = this.getNavParams('hideLogin');
    this.getAddresses();
    console.log('this.ordermanager', this.orderManager);
    this.hideDeliveryTab = this.systemConfigService.hideDeliveryTab;
    console.log('ly: -> address-dialog.component.ts -> constructor() -> hideDeliveryTab:', this.hideDeliveryTab);

    if (this.orderManager.mode === 'delivery') {
      this.segmentOption = 'delivery';
    }
  }

  ngOnInit() {
    // todo 暂时pickup地址为store location
    let member = this.memberService.getMember();
    if (member && member['method'] !== 'device') {
      this.displayName = '';
    } else if (member) {
      this.displayName = '';
    } else {
      this.displayName = '';
    }
    this.addressManagerService.initPickupArr();
    let { currentAddress, pickupAddress, addressArr, pickupArr } = this.addressManagerService;
    // this.addressArr = addressArr;
    if (pickupArr) {
      this.pickupAddressGroups = pickupArr;
    }
    if (currentAddress) {
      this.currentAddressId = currentAddress.id;
    } else if (pickupAddress) {
      this.pickupAddressId = pickupAddress.id;
    }

    this.addressManagerService.isAddAddress = false;
  }

  ionViewDidEnter() {
    // this.addressArr = this.addressManagerService.addressArr;
    if (this.pickupAddressGroups.length === 1 && this.orderManager.mode === 'takeaway') {
      this.pickupAddressId = this.pickupAddressGroups[0].id;
      this.okClicked();
    }
  }

  getAddresses() {
    this.addressArr = this.addressManagerService.getAddresses(this.orderManager.store);
    console.log('this.addressArr after resort', this.addressArr);
  }
  addNewAdress() {
    console.log('addNewAdress cliking', this.addAddressCb);
    let parmas = { store: this.orderManager.store };
    if (this.addAddressCb)
      parmas['addAddressCb'] = this.addAddressCb;
    parmas['getAddress'] = () => this.getAddresses();
    this.pushByName('AddAddressPage', parmas, {}).then(() => {
    });
  }
  closeClicked() {
    if (this.isRoute) {
      // 路由导航 from the delivery-login registe successly
      if (this.isRemove) {
        this.isRemove = false;
      } else {
        if (this.fromPage === 'memberReward') {
          this.backButtonClick();
          return;
        }
        this.popToRoot();

      }
    } else {
      // modal
      if (!this.isRemove) {
        this.modalController.dismiss({ close: true }).then(() => {
          // callback
          if (this.addressManagerService.pickupAddress) {
            this.addressManagerService.setPickup(true);
          } else
            this.addressManagerService.setPickup(false);
          // this.checkAndForceAdd();
        });
      }
      else {
        this.isRemove = false;
      }
    }

  }
  private pushToNext(offer?: Offer) {
    this.menuManager.clearAvailableMap();
    // TODO: use this as default layout?
    // call general start order function here
    // i.e set up order manager, settings, etc.
    // this.initOrder();

    this.pushAndRemovePage(getCategoryPage(this.systemConfigService, this.orderManager.store.menu.catLayout), {
      // this.pushAndRemovePage('TopMenuCategoryListPage', {
      store: this.orderManager.store,
      storeId: this.orderManager.store.id
    }, null, () => {
      if (offer) {
        let isForceOldLayout = this.getIsForceOldLayout();
        let page = 'ItemGridPage';
        if (!isForceOldLayout) {
          page = 'ItemGridScrollPage';
        }
        this.clickReward(offer, this.orderManager.store, page);
      }

    });
  }
  deliveryComfirm() {
    let changed = true;
    if (this.pickupAddressId) {
      console.log('pickup');

      let { pickupAddress } = this.addressManagerService; //
      if (pickupAddress && pickupAddress.id === this.pickupAddressId) {
        console.log('pickup-select not change');
        changed = false;
      }
      // else {
      let newPickupAddress = this.addressManagerService.findPickupAddress(this.pickupAddressId);
      this.addressManagerService.setPickupAddress(newPickupAddress);
      this.addressManagerService.setPickup(true);
      // }

      if (this.isRoute) {
        // todo
        // 1. if currentAddress is valid push to cat-list-page with login
        this.popToRoot().then(() => {
          // this.checkPhone(() => {
          //   this.goToMenu && this.pushToNext();
          // })
          this.goToMenu && this.pushToNext();
        });
        // 2. if invalid push to cat-list-page with not login
      } else {
        // comfire btn click save the address is change
        this.addressManagerService.setIsChanged(changed);
        this.modalController.dismiss().then((() => {
          // this.checkAndForceAdd();
          // this.checkPhone(() => {
          //   this.goToMenu && this.pushToNext();
          // })
          this.goToMenu && this.pushToNext();
        }));
      }

    } else {

      let { currentAddress } = this.addressManagerService;
      if (currentAddress && currentAddress.id === this.currentAddressId && this.addressManagerService.sameAddressCouriersData) {
        console.log('currentAddress-select not change');
        changed = false;
        this.handlingCouriers(this.addressManagerService.sameAddressCouriersData, changed);
      } else {
        let address = this.addressManagerService.findAddress(this.currentAddressId);
        // save the ordering-time address
        if (address) {
          this.addressManagerService.pickupAddress = null;
          this.addressManagerService.saveCurrentAddress(address);
          this.addressManagerService.isDefault = false;
          this.addressManagerService.setPickup(false);

        } else {
          console.log('not find the current addressId of Address');
          return;
        }
        if (this.currentAddressId) {
          this.loading(true);
          this.storeService.getCouriers(this.orderManager.store.id, this.orderManager.store.country, this.currentAddressId).subscribe(data => {
            this.loading(false);
            console.log('AddressDialog getCouriers', data);
            this.addressManagerService.sameAddressCouriersData = data;
            this.handlingCouriers(data, changed);

          }, err => {
            this.loading(false);
            this.showError(err)
          })
        }

      }
    }
  }

  handlingCouriers(data, changed) {
    if (data && data[0] && data[0]['couriers'] && data[0]['couriers'].length > 0) {
      let rules = data[0]['couriers'];
      this.addressManagerService.setPriceRulesOfCurrentAddress(rules);
      if (this.isRoute) {
        // todo
        // 1. if currentAddress is valid push to cat-list-page with login
        this.popToRoot().then(() => {
          this.goToMenu && this.pushToNext();
        });
        // 2. if invalid push to cat-list-page with not login
      } else {
        // comfire btn click save the address is change
        this.addressManagerService.setIsChanged(changed);
        this.modalController.dismiss({}).then(() => {
          this.goToMenu && this.pushToNext();
        });
      }
    } else {
      // 
      this.showAlert('', this.t.instant('pages.cart.not-delivered'))
      this.addressManagerService.currentAddress = null;
    }
  }

  checkPhone(callback) {
    console.log('AddressDialog check phone exist');
    let userMes = this.memberService.getMember();
    if (!userMes.phone) {
      this.pushByName('PhoneVerification', {
        store: this.orderManager.store,
        deliveryLoginCb: () => {
          callback();
        }
      });
      return;
    } else {
      if (callback) {
        callback();
      }
    }
  }

  guestLogin() {

    this.loading(true);
    this.memberService.clearSession();
    let s = UUID.UUID().toString();
    this.memberApiService.guestLogin(s, this.orderManager.store.brandId).subscribe((member) => {
      this.loading(false);
      this.memberService.putMember(member);
      this.confirmHandle();
    }, err => {
      this.loading(false);
      this.showError(err);
    });
  }
  isAddressNull(): Boolean {
    return !(!!this.addressManagerService.currentAddress);
  }
  okClicked() {
    let member = this.configService.getLocal('aigens.member');
    if (!member) {
      // guestlogin first
      // this.guestLogin();
    } else {
      this.confirmHandle();
    }
  }

  confirmHandle() {
    // 判断delivery mode有没有改变
    if ((this.addressManagerService.currentAddress && this.pickupAddressId)
      || (this.addressManagerService.pickupAddress && this.currentAddressId)) {
      if (this.orderManager.getOrder() && this.orderManager.getOrder().orderitems.length > 0) {
        this.showAlert('', this.t.instant('dialog.address-dialog.change-mode-clear-order'), [{
          text: this.t.instant('dialog.address-dialog.no'),
          handler: () => {
            console.log('cancel');
          }
        }, {
          text: this.t.instant('dialog.address-dialog.yes'),
          handler: () => {
            this.isChangeDeliveryMode && this.isChangeDeliveryMode(true);
            this.orderManager.clearOrderItems();
            this.deliveryComfirm();
          }
        }]);
      } else {
        this.isChangeDeliveryMode && this.isChangeDeliveryMode(true);
        this.deliveryComfirm();
      }


    } else {
      this.deliveryComfirm();
    }
  }
  segmentChanged(ev: any) {
    console.log('ev', ev);
    console.log(ev['detail']);
    this.segmentOption = ev['detail']['value'];
  }

  // check the addressArr length and force to add new address
  checkAndForceAdd() {
    if (this.addressArr.length > 0 || this.addressManagerService.isPickup) return;
    if (this.fromPage === 'ByodSplashPage') return;
    console.log('current member not address to delivery');
    let alert = this.alertController.create({
      header: 'No available address',
      message: 'Please add an address / Select Pickup',
      backdropDismiss: false,
      buttons: [{
        text: this.t.instant('buttons.ok'),
        handler: () => {
          this.popUpAdressModal({
            goToMenu: false,
            isRoute: false
          });
        }
      }]
    });
    alert.then(data => data.present());
  }

  async popUpAdressModal(params?) {
    let modal = await this.modalController.create(
      {
        component: AddressDialog,
        componentProps: params,
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: ''
      });

    modal.onDidDismiss().then((data) => {

    });
    modal.present().then(() => {
      //  cb()
    });
  }

  checkComfirmAvai() {
    if (this.currentAddressId) {
      let address = this.addressArr.find((add) => add.id === this.currentAddressId);
      return address ? address['beyond'] : false;
    } else if (this.pickupAddressId) {
      return true;
    }
    return false;

  }
  // removeClicked(address) {
  //   console.log('removeClicked', address);
  //   let addressId = address['id'] || null;

  //   this.popUpRemoveAlert(addressId);

  // }

  setCurrentAddressIdNull() {
    this.currentAddressId = null;
  }
  setPickupAddressIdNull() {
    console.log('setPickupAddressNull');
    this.pickupAddressId = null;
  }

  postRemoveAddress(addressId) {
    this.loading(true);
    if (addressId)
      this.addressManagerService.deleteAddress(addressId).subscribe((result) => {
        console.log('deleteAddress() result:', result);
        this.loading(false);
        this.addressArr = this.addressManagerService.addressArr;
        this.orderSessionService.setLocalAddress(this.addressArr);
        this.configService.setLocal('addressArrOfStoreList', this.addressArr);
        this.isRemove = false;
        if (this.addressManagerService.currentAddress && addressId === this.addressManagerService.currentAddress.id) {
          console.log('remove the selecting address');
          this.addressManagerService.setIsChanged(true);
          this.currentAddressId = null;
          this.addressManagerService.currentAddress = null;
          this.addressManagerService.currentCourier = null;
        }
      }, err => {
        this.loading(false);
        return this.showAlert(err.status, err['error']);
      });
  }

  removeHandle() {
    console.log('removeHandle clicking');
    this.isRemove = true;
    this.cd.detectChanges();

  }

  deleteHandle() {
    if (this.currentAddressId) {
      this.popUpRemoveAlert(this.currentAddressId);
    }
  }

  trackAddressById(index, address) {
    return address && address.id;
  }

  async popUpRemoveAlert(addressId) {
    let alert = await this.alertController.create({
      // header: 'Alert',
      // subHeader: 'Subtitle',
      message: 'Are you sure you want to remove this address?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Keep',
          handler: () => {
            console.log('Keep clicked');

          }
        }, {
          text: 'Remove',
          handler: () => {
            console.log('Remove clicked');
            this.postRemoveAddress(addressId);
          }
        }]
    });
    await alert.present();
  }

  goToLogin() {
    let member = this.memberService.getMember();
    let orderSession = this.orderSessionService.getLocalOrderSession();
    if (member && orderSession && orderSession.member && member['method'] !== 'device') {

      if (this.systemConfigService.hideStampPage) {
        this.pushByName('ProfilePage');
      } else {
        this.pushByName('MemberRewardPage');
      }
    } else {
      console.log('mode ###', this.orderManager.mode);
      let mode = this.orderManager.mode;
      let loginPageName = 'LoginPage';
      if (mode === 'delivery') {
        loginPageName = 'DeliveryLoginPage';
      }
      // this.pushByName('LoginPage', {
      // new login logic
      this.pushByName(loginPageName, {
        store: this.orderManager.store,
        fromPage: 'ByodSplashPage',
        isRoute: true,
        logincb: () => {
          // this.originNextClicked();
        }
      });
    }
  }

  private getIsForceOldLayout(): boolean {
    // return true;
    return this.systemConfigService['systemConfig'] && this.systemConfigService['systemConfig']['data'] && this.systemConfigService['systemConfig']['data']['forceOldLayout'];
  }
}
