import { Component, Input, Injector } from '@angular/core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
// import { ViewController } from 'ionic-angular';
import { Offer, Order } from 'aigens-ng-core';
/*
  Generated class for the ProgressBar component.

  See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
  for more info on Angular 2 Components.
*/
@Component({
  selector: 'lottery-page',
  templateUrl: './lottery.html',
  styleUrls: ['./lottery.scss']
})
export class LotteryComponent extends MobileBasePage {

  /** progress : 0 -100  */
  @Input() progress;

  stage: Number = 1;
  isFadeOut: boolean = false;
  shoot: boolean = false;
  offer: Offer = null;
  prizeNumber = 0;
  bg = '';

  orderTotal: any;
  orderSessionId: any;

  constructor(injector: Injector) {

    super(injector);
    console.log('Lottery Component Construct');
    this.orderTotal = this.getNavParams('orderTotal');
    this.orderSessionId = this.orderService.lotteryOrderSessionId;

    console.log('orderTotal', this.orderTotal);
  }


  soundClicked() {

    // if(this.soundService.isMute)
    // {
    //   this.soundService.isMute = !this.soundService.isMute;

    //   this.soundService.loopPlay("bgm");
    //   this.soundService.setVolume("bgm", 0.8);
    //   this.soundService.unMuteAll();
    // }else
    // {
    //   this.soundService.isMute = !this.soundService.isMute;
    //   this.soundService.muteAll();
    // }

  }

  startClicked() {
    // 1. chance checking
    // 2. Call api for drawing
    // 3. return res
    console.log('start clicked');
  }

  async dismissClicked(action: any = null) {

    // this.soundService.stopPlay();
    // this.soundService.isMute = true;
    if (action === 'leave' && this.offer) {
      
      // 基本上按X是放棄抽獎機會
      if (!this.memberService.getMember()) {
        // 可以給non-member的人抽了後選擇login來領獎
        let alert = await this.alertController.create({
          header: 'Register or log in now to claim your reward!',
          buttons: [
            {
              text: 'No Thanks',
              handler: () => {
                this.leaveApi('finished');
              }
            },
            {
              text: 'Yes Please',
              handler: () => {
                //this.leaveApi('view');
                // this.viewCtrl.dismiss('view');
                this.modalController.dismiss('view');
              }
            }
          ]
        });
        alert.present();
      } else {
        // this.leaveApi('finished');
        // 但member抽了後就已經直接放到他的帳戶內，就不能再放棄
        this.modalController.dismiss('finished');
      }
  

    } else if (action === 'leave') {
      // let orderTotal = this.get('orderTotal');

      let alert = await this.alertController.create({
        header: 'You sure you want to give up your guaranteed free gift?',
        buttons: [
          {
            text: 'Play',
            handler: () => {
              //
            }
          },
          {
            text: 'Leave',
            handler: () => {
              this.loading(true);
              this.memberApiService.playLottery(this.orderService.store.id, this.orderService.store.brandId, this.orderService.spot, this.orderService.session, this.orderTotal, this.orderSessionId, this.orderService.lotteryBatchId, this.orderService.lotteryOrderId, true).subscribe((res) => {
                console.log('res', res);
                this.loading(false);
                // this.viewCtrl.dismiss('leave');
                this.modalController.dismiss('leave');
             
              }, err => {
                this.loading(false);
                this.showError(err);
          
              });
            }
          }
        ]
      });
      alert.present();

    } else if (action === 'view') {
      //this.viewCtrl.dismiss('view');
      this.modalController.dismiss('view');
    }
  }

  leaveApi(type) {
    // let orderTotal = this.navParams.get('orderTotal');
    this.loading(true);
    this.memberApiService.playLottery(this.orderService.store.id, this.orderService.store.brandId, this.orderService.spot, this.orderService.session, this.orderTotal, this.orderSessionId, this.orderService.lotteryBatchId, this.orderService.lotteryOrderId, true).subscribe((res) => {
      console.log('res', res);
      this.loading(false);
      this.modalController.dismiss(type);
      // this.viewCtrl.dismiss(type);
   
    }, err => {
      this.loading(false);
      this.showError(err);

    });
  }

  shootClicked() {


    if (this.shoot) return;

    console.log('shoot clicked');
    this.shoot = true;
    // this.soundService.loopPlay("bgm");
    // this.soundService.setVolume("bgm", 0.8);
    // let orderTotal = this.navParams.get('orderTotal');

    this.memberApiService.playLottery(this.orderService.store.id, this.orderService.store.brandId, this.orderService.spot, this.orderService.session, this.orderTotal, this.orderSessionId, this.orderService.lotteryBatchId, this.orderService.lotteryOrderId).subscribe((res) => {
      console.log('res', res);
      this.loading(false);
      this.offer = res;
      this.orderService.tempLotteryOffer = res;
      this.bg = this.offer.reward.getImage('default', 200, true);
      //this.prizeNumber = this.offer.reward.name;

      switch (this.offer.reward.name) {
        case 'Prawn Aglio Olio': {
          this.prizeNumber = 1;
          break;
        }

        case 'Curry Zazzle': {
          this.prizeNumber = 6;
          break;
        }

        case 'Hawaiian (Personal Pan)': {
          this.prizeNumber = 5;
          break;
        }

        case '4pcs Honey Roasted Wing': {
          this.prizeNumber = 4;
          break;
        }

        case 'Soup of the day': {
          this.prizeNumber = 3;
          break;
        }

        case 'Garlic Bread': {
          this.prizeNumber = 2;
          break;
        }
      }
      //let TIME_IN_MS = 500;
      let fadeout = setTimeout(() => {
        this.isFadeOut = true;
        let goToStage2 = setTimeout(() => {
          this.stage = 2;
          this.isFadeOut = false;
        }, 1000);
      }, 500);

    }, err => {
      this.loading(false);
      this.shoot = false;
      this.showError(err);
    });
  }

}
