var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Data, Store } from 'aigens-ng-core';
import 'rxjs/Rx';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
var StoreService = /** @class */ (function (_super) {
    __extends(StoreService, _super);
    function StoreService(http, configs) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    // use in store list
    StoreService.prototype.searchStores = function (groupId, q, latitude, longitude, regionCodes, radius, limit) {
        if (latitude === void 0) { latitude = null; }
        if (longitude === void 0) { longitude = null; }
        if (regionCodes === void 0) { regionCodes = null; }
        if (radius === void 0) { radius = null; }
        if (limit === void 0) { limit = null; }
        var url = '/api/v1/store/store.json?groupId=' + groupId;
        var params = {};
        params['q'] = q;
        if (latitude != null && longitude != null) {
            params['latitude'] = latitude;
            params['longitude'] = longitude;
        }
        if (radius != null) {
            params['radius'] = radius;
        }
        if (limit != null) {
            params['limit'] = limit;
        }
        if (regionCodes != null && regionCodes.length > 0) {
            params['region'] = regionCodes.join(',');
        }
        var aq = this.aq;
        aq.url = url;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toDataArray(Store, jo['data']); }));
    };
    StoreService.prototype.verifyTableCode = function (code, storeId) {
        var url = '/api/v1/menu/spot.json?storeId=' + storeId + '&code=' + code;
        this.aq.url = url;
        this.aq.method = 'get';
        return this.aq.getJson().pipe(map(function (jo) {
            return jo['data'];
        }));
    };
    // search store by keys
    StoreService.prototype.searchStore = function (key, id) {
        var url = '/api/v1/store/store.json';
        var params = {};
        params['q'] = 'feature:byod';
        params[key] = id;
        var aq = this.aq;
        aq.url = url;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toDataArray(Store, jo['data']); }));
    };
    // search delivery store
    StoreService.prototype.searchDeliveryStore = function (brandId, country, paramObj) {
        var url = '/api/v1/shipping/availability.json';
        var postal = paramObj.postal, latitude = paramObj.latitude, longitude = paramObj.longitude, addressId = paramObj.addressId;
        var params = {};
        params['brandId'] = brandId;
        params['country'] = country;
        if (postal) {
            params['postal'] = postal;
        }
        if (latitude && longitude) {
            params['latitude'] = latitude;
            params['longitude'] = longitude;
        }
        // if (addressId) {
        //     params['addressId'] = addressId;
        // }
        var aq = this.aq;
        aq.url = url;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            console.log('searchDeliveryStore jo', jo);
            return jo['data'];
        }));
    };
    StoreService.prototype.getCouriers = function (storeId, country, addressId) {
        var url = '/api/v1/shipping/availability.json';
        var params = {};
        params['storeId'] = storeId;
        params['country'] = country;
        if (addressId) {
            params['addressId'] = addressId;
        }
        var aq = this.aq;
        aq.url = url;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) {
            // console.log('getCouriers jo', jo);
            return jo['data'];
        }));
    };
    StoreService.prototype.searchStoreByPostal = function (keys, groupId, postal, country) {
        if (keys === void 0) { keys = []; }
        var url = '/api/v1/store/store.json';
        // let keywords = 'feature:';
        // keys.forEach((key)=>{
        //     keywords = keywords + key;
        // });
        console.log('searchStoreByKey', url);
        var params = {};
        params['q'] = 'feature:byod';
        params['groupId'] = groupId;
        params['postal'] = postal;
        params['country'] = country;
        var aq = this.aq;
        aq.url = url;
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toDataArray(Store, jo['data']); }));
    };
    StoreService.prototype.getStoreRegionsByBandId = function (brandId) {
        var url = '/api/v1/store/directory.json?brandId=' + brandId;
        console.log('getStoreRegionsByBandId', url);
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(function (jo) {
            console.log('getStoreRegionsByBandId jo', jo);
            return jo['data'];
        }));
    };
    StoreService.prototype.setStoreCountryCodesArr = function (arr) {
        this.storeCountryCodesArr = null;
        if (arr && arr.length > 0)
            this.storeCountryCodesArr = arr;
    };
    // 计算距离 s: user currentPos
    StoreService.prototype.calDistance = function (lat2, lon2, pos, unit) {
        if (unit === void 0) { unit = 'K'; }
        if (!pos || !pos.latitude || !pos.longitude)
            return;
        var lat1 = pos.latitude;
        var lon1 = pos.longitude;
        // console.log('维度', lat1, lon1, lat2, lon2);
        if ((lat1 === lat2) && (lon1 === lon2)) {
            return 0;
        }
        else {
            var radlat1 = Math.PI * lat1 / 180;
            var radlat2 = Math.PI * lat2 / 180;
            var theta = lon1 - lon2;
            var radtheta = Math.PI * theta / 180;
            var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;
            if (unit === 'K') {
                dist = dist * 1.609344;
            }
            if (unit === 'N') {
                dist = dist * 0.8684;
            }
            return dist;
        }
    };
    StoreService.ngInjectableDef = i0.defineInjectable({ factory: function StoreService_Factory() { return new StoreService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService)); }, token: StoreService, providedIn: "root" });
    return StoreService;
}(BaseService));
export { StoreService };
