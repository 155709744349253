var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Member, Offer, Order, Period, Store, OrderManager } from 'aigens-ng-core';
import { ConfigService } from '../services/config.service';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { HttpClient } from '@angular/common/http';
var AiService = /** @class */ (function (_super) {
    __extends(AiService, _super);
    function AiService(http, configs, orderManager) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.configs = configs;
        _this.orderManager = orderManager;
        _this.member = {};
        _this.address = {};
        _this.count = 0;
        _this.total = 0;
        _this.redirectOrderId = null;
        _this.currency = 'HKD';
        _this.sign = '$';
        _this.mode = 'byod';
        _this.selectedDineinTakeoutPrompt = 'firstView';
        _this.selfPickUp = false;
        _this.delivery = false;
        _this.takeout = false;
        _this.isBK = false;
        _this.isLP = false;
        _this.hiddenNav = false;
        _this.hideTotal = false;
        _this.comeInHasSession = false;
        _this.hasServiceChargeOrTax = false;
        _this.isCourt = false;
        _this.preOrder = false;
        _this.login = false;
        _this.isStaff = false;
        _this.isHomeButton = false;
        _this.settings = {};
        // cache of main item quantities
        _this.quantities = {};
        _this.promoCodeGift = [];
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    /**
     * @deprecated Use getOrderSession instead.
     */
    AiService.prototype.getRecommend = function (member) {
        var url = '/api/v1/ai/recommend.json';
        var storeId = this.orderManager.store.id;
        var mode = this.orderManager.mode;
        // let url = this.getOrderSesisonUrl(storeId, mode, 'recommend');
        // todo change new api return val to old api val form
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        if (member) {
            aq.body = member;
            return aq.auth(true).getJson().map(function (jo) { return jo['data']; });
        }
        else {
            aq.body = member;
            return aq.auth(false).getJson().map(function (jo) { return jo['data']; });
        }
    };
    return AiService;
}(BaseService));
export { AiService };
