import {Component, Injector, OnInit} from '@angular/core';
import {Offer} from 'aigens-ng-core';
import {MobileBasePage} from '../../core/base/mobile-base-page';
import {NavParams} from '@ionic/angular';
import {isAvailableOffer} from '../../shared/utilities/checkFormatTool';


@Component({
    selector: 'page-offer-list',
    templateUrl: 'offer-list.html',
    styleUrls: ['offer-list.scss']
})
export class OfferListDialog extends MobileBasePage implements OnInit {

    offers: Offer[] = [];
    isBounce = false;

    offer_map_promotion = {};

    constructor(public injector: Injector, public navParams: NavParams) {

        super(injector);

        this.offers = this.navParams.get('offers');
    }


    ngOnInit() {
        console.log('ngOnInit VariationPage');
        this.initOffer_map_promotion();
    }

    // offer(some logic copy form ng-core)
    initOffer_map_promotion() {
        this.offers.map(offer => {
            let ok: boolean = true;
            if (!offer) {
                ok = false;
            } else {
                // 复制一个新 order
                let order = JSON.parse(JSON.stringify(this.orderManager.order));
                order.offers = [];
                order.offers.push(offer);
                // 使用当前offer 组建新的order 通过ng-core 判断是否满足offer 对应的discount的所有conditions
                // let promo = this.findPromotion(offer);
                // offer.reward && offer.reward.discount && offer.reward.discount.conditions.forEach(c => {
                //     if (!c.isSatisfied(order, promo)) ok = false;
                // });
                ok = offer.reward && offer.reward.isReawardSatisfied(order); //"d21829bc-3a9e-4db8-ac31-da2446e602fa"
            }
            this.offer_map_promotion[offer.id] = ok;
        });
    }

    dismissClicked() {
        this.modalController.dismiss(null);

    }

    bounce() {
        this.isBounce = true;
        setTimeout(() => {
            this.isBounce = false;
        }, 1000);
    }
    isAvailableReward(offer) {
        let isMeeting = this.offerIsMeetingConditions(offer);
        return isAvailableOffer(offer) && isMeeting;
    }
    
    offerIsMeetingConditions(offer: Offer) {
        return offer && offer.id && this.offer_map_promotion[offer.id];
    }

    // findPromotion(offer) {
    //     let offer_map_promotion = this.orderManager.order && this.orderManager.order.promotions && this.orderManager.order.promotions.filter(pro => offer && offer.reward && offer.reward.discountId === pro.id)[0];
    //     return offer_map_promotion;
    // }

    clickReward(offer: Offer) {
        if (this.isAvailableReward(offer)) {
            this.modalController.dismiss(offer);
            this.bounce();
        }
    }


}
