var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base/base-service';
import * as i0 from "@angular/core";
var MiniAlipayHKService = /** @class */ (function (_super) {
    __extends(MiniAlipayHKService, _super);
    function MiniAlipayHKService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MiniAlipayHKService.prototype.setMiniMessageCallback = function (callback) {
        my.onMessage = callback;
    };
    MiniAlipayHKService.prototype.postMiniMessage = function (msg) {
        my.postMessage(msg);
    };
    MiniAlipayHKService.prototype.tradePay = function (alipayOrderStr, callback) {
        this.setMiniMessageCallback(callback);
        var messageToMini = { action: 'tradePay', alipayOrderStr: alipayOrderStr };
        console.log('alipayOrderStr', messageToMini);
        my.postMessage(messageToMini);
    };
    MiniAlipayHKService.prototype.getAuthCode = function (callback) {
        this.setMiniMessageCallback(callback);
        var messageToMini = { action: 'getAuthCode' };
        console.log('getAuthCode', messageToMini);
        this.postMiniMessage(messageToMini);
    };
    MiniAlipayHKService.prototype.getLocation = function (params) {
        return my.getLocation(params);
        /*{
            success(res) {
                my.hideLoading();
                console.log(res)
                my.alert({
                    content: JSON.stringify(res),
                });
                // that對象為Page可以設置數據刷新界⾯
                // that.setData({ hasLocation: true, location: formatLocation(res.longitude, res.latitude) })
            },
            fail() {
                my.hideLoading(); my.alert({ title: '定位失敗' });
            },
        }*/
    };
    MiniAlipayHKService.ngInjectableDef = i0.defineInjectable({ factory: function MiniAlipayHKService_Factory() { return new MiniAlipayHKService(); }, token: MiniAlipayHKService, providedIn: "root" });
    return MiniAlipayHKService;
}(BaseService));
export { MiniAlipayHKService };
var GetLocationOptions = /** @class */ (function () {
    function GetLocationOptions() {
    }
    return GetLocationOptions;
}());
export { GetLocationOptions };
