// import { ViewController } from 'ionic-angular';
import { Component, Injector, OnInit } from '@angular/core';
// import { KioskBasePage } from '../../base/kiosk-base-page';
// import { CurrencyService } from '../../services/currency-service';
import { Data, Discount, Offer, OrderItem, Recommendation, Store } from 'aigens-ng-core';
import { Category } from 'aigens-ng-core';
import { ItemGroup } from 'aigens-ng-core';
import { Item } from 'aigens-ng-core';

// import { LocalStorageHelper } from "../../utility/local-storage-helper";
import { Order } from 'aigens-ng-core';
import { MathUtils } from 'aigens-ng-core';

import { OrderManager } from 'aigens-ng-core';

import { NavParams } from '@ionic/angular';
import { MobileBaseItemPage } from '../../core/base/mobile-base-item-page';
import { getSetLayout } from '../../shared/utilities/layout-helper';
import { MultiStepComboPage } from '../../routes/item/multi-step-combo/multi-step-combo-page.component';
import { ComboPage } from '../../routes/item/combo-page/combo-page';
import { VariationScrollDialog } from '../variation-scroll/variation-scroll';
import { popDrownAnimation, popUpAnimation } from '../../shared/animations/leaveAnimation';
import { ModifierItemSelectPageModal } from '../../routes/item/modifier-item-select-modal/modifier-item-select-modal';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the RecommendList page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({

    selector: 'page-unlockPopup',
    templateUrl: 'unlockPopup.html',
    styleUrls: ['unlockPopup.scss']

})

export class UnlockPopupDialog extends MobileBaseItemPage implements OnInit {

    store: Store;
    storeId: number;
    recommendList: Category;
    order: Order;
    // localStorageHelper: LocalStorageHelper;

    animateQtyMap = {};
    newTotal = 0;
    addItems: Item[] = []; // all single items in recommendation list
    qtyTag: number[] = [];

    category: any;
    itemgroups: any;
    // pref: PreferenceService;
    count = 0;
    isGift = false; // deal with free items
    max: number;
    min: number;
    showImage = true;
    displayTitle = '';
    recommendations: Recommendation[];
    unlockCatOrderManager: OrderManager;
    unlockCat: any;
    offer: Offer;
    soldoutMap = {};
    modifiableMap = {};
    requiredModifierMap = {};

    isVariationOpened = false;
    comboCache = new Map<number, OrderItem>();

    unlockComboItems_selected: Item[] = []; // 保存执行 toComboPage 添加到购物车的 combo unlockItem ()

    tagsImage = {};
    unlockComboItemMap = {}; // 保存unlock comboItem 加入 unlockCatOrderManager 里的数量(不能用 mainItem.id 做唯一标识，比如汉堡 和 特大汉堡 的mainItem.id 可能就是一样的，但分属不同的combo)
    unlockParentItemMap = {};
    unlockComboOIMap = {}; //
    unlockParentOIMap = {};

    constructor(private injector: Injector, public navParams: NavParams, public translateService: TranslateService,) {
        super(injector);

        this.recommendList = this.navParams.get('recommendList');
        this.order = this.orderManager.order;
        this.store = this.navParams.get('store');
        this.addItems = this.navParams.get('items');
        this.isGift = this.navParams.get('isGift');
        this.max = this.navParams.get('max'); // for unlocks
        this.min = this.navParams.get('min');
        this.offer = this.navParams.get('offer');
        this.recommendations = this.navParams.get('recommendations'); // for AI recommendations

        this.unlockCat = this.navParams.get('unlockCat');
        console.log('UnlockPopupDialog unlockCat', this.unlockCat);

        if (this.max) {
            if (this.min) {
                if (this.min > this.max) this.min = this.max;
                this.displayTitle = this.t.instant('pages.item-select.chooseTo', { max: this.max, min: this.min });
            } else {
                this.displayTitle = this.t.instant('pages.item-select.chooseAtMost', { max: this.max });

            }
        }
        this.init();

        // 创建一个新的orderManager to save the unlock cat item selected(may be comboItem or modifierItem)
        this.unlockCatOrderManager = new OrderManager();
        this.unlockCatOrderManager.mode = this.orderManager.mode;
        this.unlockCatOrderManager.createOrder(this.storeId, null, null, this.orderManager.mode, this.store);
        this.initTagsImage();
    }

    init() {
        if (this.recommendations) {
            this.addItems = [];
            this.recommendations.forEach(rm => {
                this.addItems.push(rm.item);
                this.qtyTag.push(0);
                if (this.showImage && (!rm.item.images || !rm.item.images['default'])) this.showImage = false;
            });
            this.count = this.recommendations.length;

        } else {

            for (let i = 0; i < this.addItems.length; i++) {
                this.qtyTag[i] = 0;
                if (!this.addItems[i].parentId || this.addItems[i].parentId === this.addItems[i].id) {
                    if (this.showImage && (!this.addItems[i].images || !this.addItems[i].images['default'])) this.showImage = false;
                }
            }

            this.category = this.recommendList;
            this.itemgroups = this.recommendList.groups;
        }
        console.log('input category', this.category);
    }

    isModifiable(item: Item): boolean {
        if (!this.modifiableMap[item.id]) {
            this.modifiableMap[item.id] = Item.isModifiable(item);
        }
        return this.modifiableMap[item.id];
    }

    checkStoreMaxItem(): boolean {
        const maxItems = this.orderManager.store.menu.maxItems;
        const infinity = maxItems === 0;
        return (maxItems <= this.orderManager.count && !infinity);
    }

    isRequiredModifier(item: Item): boolean {
        if (this.isModifiable(item)) {
            if (!this.requiredModifierMap[item.id]) {
                this.requiredModifierMap[item.id] = item.mgroups.some(mgroup => mgroup.min > 0);
            }
            return this.requiredModifierMap[item.id];
        } else {
            return false;
        }
    }

    isSet(item: Item) {
        return Item.isSet(this.category, item);
    }

    async toComboPage(item: Item, index: number, parentIndex?: number) {
        let page = getSetLayout(this.orderManager.store.menu.setLayout);

        // PopUpSetMenuPage 会通过路由打开combo页面，但当前是在 modal里选combo, 路由跳转后 modal自动关闭(explosion)
        if (page === 'PopUpSetMenuPage') {
            page = 'ComboPage';
        }

        let componentProps = {
            store: this.store,
            category: this.category,
            groups: this.category.groups,
            item: Item.cloneArray(Item, [item])[0],
            handler: this.getNavParams('handler'),
            onHold: true,
            quantityLimit: this.max - this.checkTotalQty(),
            formPage: 'unlock',
        };

        let cssClass = 'modal-largescreen combo-modal-backdrop-dismiss';
        const modal = await this.navToAssignLayoutItems(page === 'MultiStepComboPage' ? MultiStepComboPage : ComboPage, page, componentProps, {
            relativeTo: this.route,  // replaceUrl: true,
            skipLocationChange: false
        }, cssClass);
        modal.onDidDismiss().then(res => {
            console.log('unlock combo modal dismiss', res);
            let ois = res && res.data && res.data['ois'];
            ois && ois.forEach((oi_) => {
                this.unlockCatOrderManager.addOrderItem(oi_);
                let mainItem = OrderItem.getMainItem(oi_);
                this.unlockComboItemMap[mainItem.id + '_' + index] ? (this.unlockComboItemMap[mainItem.id + '_' + index] += 1) : (this.unlockComboItemMap[mainItem.id + '_' + index] = 1);
                // this.unlockComboOIMap[mainItem.id + '_' + index] = oi_;
                let oiMap_combo = this.unlockComboOIMap[mainItem.id + '_' + index];
                oiMap_combo && oiMap_combo.length > 0 ? this.unlockComboOIMap[mainItem.id + '_' + index].push(oi_) : this.unlockComboOIMap[mainItem.id + '_' + index] = [oi_];
            });
            console.log('add combo success :unlockCatOrderManager', this.unlockCatOrderManager, this.unlockComboItemMap, this.unlockComboOIMap);
        });
    }

    private async isVariationParent(item: Item, index: number) {

        const exist: boolean = this.isParent(item);

        if (exist) {

            let items = item.variations.slice(0) || [];

            if (item.id === item.parentId) {
                items.splice(0, 0, item);
            } else {
                items.push(item);
            }

            // sort the same type of combo
            items = items.sort((a, b) => a.price - b.price);

            // modal to select the cat-Item combo type
            const input = {
                items: items,
                store: this.store,
                category: this.category,
                orderManager: this.orderManager
            };
            console.log('isVariationParent input', input);
            const modal = await this.modalController.create({
                component: VariationScrollDialog,
                componentProps: input,
                showBackdrop: true,
                backdropDismiss: true,
                cssClass: 'modal-largescreen',
                animated: true,
                enterAnimation: popUpAnimation,
                leaveAnimation: popDrownAnimation
            });
            modal.onWillDismiss().then(async data => { // 若有variationParent 则弹出的modal后再跳转
                const selected = <Item>(data['data']);

                if (selected) {
                    console.log('select & add item,', selected);


                    if (this.isSet(selected) || selected.pgroup) {
                        let j: number;
                        for (j = 0; j < this.addItems.length; j++) {
                            if (this.addItems[j].id === selected.id) break;
                        }
                        await this.toComboPage(selected, index, j);
                    } else {

                        // 直接添加到购物车, user 不点击 unlockDailog confirm-btn 应该delete掉添加的 unlockItem
                        // let oi = this.addItem(selected);
                        // 不直接添加到购物车
                        let oi = this.addItemMap(selected);

                        if (this.isRequiredModifier(selected) || (this.isModifiable(selected) && !this.store.menu.quickSingleItem)) {
                            // let cate = this.category;
                            // this.addItem(selected);
                            let params = { store: this.store, orderitem: oi, edit: true, onHold: true };
                            let m = await this.modalController.create({
                                component: ModifierItemSelectPageModal,
                                componentProps: params,
                                showBackdrop: false,
                                enterAnimation: popUpAnimation,
                                leaveAnimation: popDrownAnimation,
                                cssClass: 'modal-largescreen variation-item-modifier-select'
                            });
                            m.onDidDismiss().then((data) => {
                                console.log('ModifierItemSelectPageModal res', data);
                                if (data['data'] === 'confirm') {
                                    // save item to temp list
                                    this.parentHandling(item, index, oi);
                                } else {
                                    console.log('not confirm modifier-setting, so remove the selected-item');
                                    this.unlockCatOrderManager.minusItem(selected, true);
                                }

                            });
                            m.present();

                        } else {
                            //
                            this.parentHandling(item, index, oi);
                            this.showToast(this.t.instant('global.item-added'));
                        }

                        console.log('unlockParentItemMap', this.unlockParentItemMap, this.unlockParentOIMap);

                    }
                }
                this.isVariationOpened = false;
            });
            this.isVariationOpened = true;
            modal.present();
        }

        return exist;
    }

    // 成功添加 parentItem 时的handling
    parentHandling(item: Item, index, oi: OrderItem) {
        let parentKey = item.parentId + '_' + index;
        parentKey && this.unlockParentItemMap[parentKey] ? (this.unlockParentItemMap[parentKey] += 1) : (this.unlockParentItemMap[parentKey] = 1);
        // 记录 同一个 parent oi 数组用于delete 对应的 oi
        let oiMap_prarent = this.unlockParentOIMap[parentKey];
        oiMap_prarent && oiMap_prarent.length > 0 ? this.unlockParentOIMap[parentKey].push(oi) : this.unlockParentOIMap[parentKey] = [oi];
    }

    isParent(item: Item) {

        return !!(item && item.variations && item.variations.length > 0);

    }

    //
    getItemQuanlity(item: Item, index) {
        let qua = 0;
        // let oi = this.unlockCatOrderManager.findOrderItem(item); // ng-core 没有对combo进行处理
        // if (oi && !OrderItem.isSet(oi)) {
        if (!item.combo) {
            // 非combo
            if (!this.isParent(item)) {
                // 非parnent
                qua = this.unlockCatOrderManager.getQuantity(item);
            } else {
                // parent
                let parentId = item.parentId;
                qua = this.unlockParentItemMap[parentId + '_' + index];
            }
        } else {
            // combo
            if (!this.isParent(item)) {
                // 非parnent
                qua = this.unlockComboItemMap[item.id + '_' + index];
            } else {
                // parent
                let parentId = item.parentId;
                qua = this.unlockParentItemMap[parentId + '_' + index];
            }
        }
        return qua;
    }

    isSoldout(item: Item): boolean {
        // let now = this.getNow();
        return !this.isParent(item) && (this.inventoryManager.isSoldout(item)); // || !this.menuManager.isAvailableCombo(this.store, this.category, item, this.order.takeout, now));
    }


    // i: parent, j:selected
    onAddClicked(i: number, j: number, oi?: OrderItem) {

        this.animateQtyMap['#' + this.addItems[i].id] = false;

        setTimeout(() => {
            this.animateQtyMap['#' + this.addItems[i].id] = true;
        }, 10);

        this.addItems[j].quantity++;
        this.qtyTag[i]++;
        this.newTotal += this.round(this.addItems[j].price);
        if (oi) {
            this.comboCache.set(j, oi);
        }

    }

    checkTotalQty() {
        // let qty = 0;
        // this.addItems.forEach(item => {
        //     qty += item.quantity;
        // });
        // console.log('selected qty:', qty);
        // return qty;
        let qty = Order.getCount(this.unlockCatOrderManager.getOrder());
        return qty;
    }

    // true == > can add UnlockCatItem || (special) max == 0 && min== 0
    checkUnlockCatItemMax(item: Item, index) {
        return (item && item.max > 0) ? (item.max > this.qtyTag[index]) : (item && item.max === 0 && item.min === 0);
    }

    async addClicked(i: number) {

        if (this.max && this.checkTotalQty() >= this.max) {
            // if (this.max === 1) {
            // clear other selection if single select
            // for (let index = 0; index < this.addItems.length; index++) {
            //     while (this.qtyTag[index] > 0) this.minusItem(index);
            // }
            // this.unlockCatOrderManager.clearOrderItems();
            // this.unlockParentOIMap = {};
            // this.unlockParentItemMap = {};
            // } else {
            this.showAlert('', this.t.instant('pages.unlockPopup.meeting-unlockItem-max'));
            return;
            // }

        }

        console.log('add clicked, i:', i);

        if (this.checkStoreMaxItem()) {
            this.showStoreMaxItemAlert();
            return;
        }
        let item = this.addItems[i];
        console.log('add clicked, item：', item);

        if (!this.checkUnlockCatItemMax(item, i)) {
            this.showAlert('', this.t.instant('pages.unlockPopup.meeting-unlockItem-max'));
            return;
        }

        const t = await this.isVariationParent(item, i);
        console.log('variation parent', t);

        if (t) {
            return;
        }


        if (this.isSoldout(item)) {
            return;
        }


        if (this.isSet(item)) {
            await this.toComboPage(item, i);

        } else {
            // if cannot skip modifier page
            if (this.isRequiredModifier(item) || (this.isModifiable(item) && !this.store.menu.quickSingleItem)) {
                let cate = this.category;
                // let oi = this.addItem(item);
                let oi = this.addItemMap(item);
                // let oi = this.orderManager.createOrderItem(cate, cate.groups[0], item);
                let _groups = cate.groups,
                    groups = _groups && _groups.filter(group => {
                        return this.menuManager.isAvailableGroup(this.store, cate, group, this.orderManager.isTakeout(), Date.now(), false, this.orderManager.mode);
                    });

                this.onAddClicked(i, i);
            } else {
                // const oi = this.addItem(item);
                let oi = this.addItemMap(item);
            }
        }
        if (this.orderService.isCourt) {
            this.gaService.eventTrack('item-list-page', 'select item - ' + this.store.name, item.name);
        }
        // this.bounce();
        console.log(this.orderManager.getQuantity(item));
    }

    addItem(item: Item) {
        // this.orderService.addSingleItem(this.category, this.groups[0], item);

        const oi = this.orderManager.addSingleItem(this.recommendList, this.recommendList.groups[0], item, false);
        this.orderManager.getQuantity(item);
        if (!(this.isRequiredModifier(item) || (this.isModifiable(item) && !this.settings.quickSingleItem))) {
            // this.showToast(this.t.instant('global.item-added'), 600, 'bottom', 'add-item-toast');
        }
        return oi;
    }

    addItemMap(item: Item) {
        // this.orderService.addSingleItem(this.category, this.groups[0], item);

        const oi = this.unlockCatOrderManager.addSingleItem(this.recommendList, this.recommendList.groups[0], item, false);
        this.unlockCatOrderManager.getQuantity(item);
        // if (!(this.isRequiredModifier(item) || (this.isModifiable(item) && !this.settings.quickSingleItem))) {
        //     this.showToast(this.t.instant('global.item-added'), 600, 'bottom', 'add-item-toast');
        // }
        return oi;
    }


    private removeVariation(i: number) {
        let item = this.addItems[i];

        let items = item.variations.slice(0);
        items.push(item);

        this.qtyTag[i] = 0;
        items.forEach(i => {
            for (let j = 0; j < this.addItems.length; j++) {
                if (this.addItems[j].id === i.id) {
                    this.newTotal -= this.round(this.addItems[j].price * this.addItems[j].quantity);
                    this.addItems[j].quantity = 0;
                }
            }
        });
    }

    minusItem(i: number) {

        if (this.isParent(this.addItems[i])) {
            this.removeVariation(i);
        } else {
            this.addItems[i].quantity--;
            this.qtyTag[i]--;
            this.newTotal -= this.round(this.addItems[i].price);
        }
    }

    removeClicked(item: Item, index) {
        console.log('remove item from unlockCatOrderManager');
        if (item && !item.combo) {
            // 非combo
            if (!this.isParent(item)) {
                this.unlockCatOrderManager.minusItem(item);
            } else {
                this.removeParentHandling(item, index);
            }
        } else {
            if (!this.isParent(item)) {
                this.removeComboHanding(item, index);

            } else {
                this.removeParentHandling(item, index);
            }
        }
    }

    // remove 当前item 对应的combo 随意减一
    removeComboHanding(item, index) {
        // 找到当前 item 对应的combo
        let ois_combo = this.unlockComboOIMap[item.id + '_' + index];
        let oi = ois_combo[ois_combo.length - 1]; // 找到最后一次添加的combo item
        ois_combo.pop();
        this.unlockCatOrderManager.minusOrderItem(oi);
        this.unlockComboItemMap[item.id + '_' + index] -= 1;
    }

    // delete all same parentId oi
    removeParentHandling(item, index) {
        let parentKey = item.parentId + '_' + index;
        let oi_array = this.unlockParentOIMap[parentKey];
        oi_array.forEach(oi => {
            this.unlockCatOrderManager.minusOrderItem(oi);
        });
        this.unlockParentOIMap[parentKey] = null;
        this.unlockParentItemMap[parentKey] = 0;
    }

    clearClicked() {

        // this.playClick();
        this.modalController.dismiss('cancel');

    }

    okClicked() {

        // this.playClick();
        if (this.round(this.newTotal) <= 0 && !this.isGift) return;

        console.log('Buy clicked');
        let order: Order = this.orderManager.order;




        let oi;
        let unlockOrderItems = this.unlockCatOrderManager.getOrder() && this.unlockCatOrderManager.getOrder().orderitems;
        if (unlockOrderItems.length > 0) {
            unlockOrderItems.forEach((oi) => {
                // 真正加到 全局orderManager 里
                this.offer && (oi['unlockCatOfferId'] = this.offer.id);
                // oi['offerId'] = this.offerService.getUnlockCat_offerId_lastest();
                this.orderManager.addOrderItem(oi);
                if (this.isGift) {
                    if (!order.gifts) this.orderManager.getOrder().gifts = [];
                    this.orderManager.getOrder().gifts.push(oi);
                }
            });
        } else {

            this.orderService.selectedReward = null;
            // delete coupon becase 无选unlock的item
            // let selectedRewards = this.orderService.selectedRewards;
            // let length = selectedRewards.length;
            // if (length > 0 && this.offer) {
            //     for (let i = 0; i < length; i++) {
            //         if (selectedRewards[i] && selectedRewards[i].id === this.offer.id) {
            //             this.orderService.selectedRewards.splice(i, 1);
            //             break;
            //         }
            //     }
            // }
        }

        this.modalController.dismiss('ok');
    }

    round(value: number) {

        return MathUtils.round(value, 2);
    }

    getItemName(item: Item) {

        let name: string = item.name;

        if (this.isParent(item)) {
            name = item.title;
        }

        if (!name || name.length === 0) {
            name = item.name;
        }

        return name;
    }

    
    getItemImage(item: Item) {
        let url: string = null;
        url = Data.getImage(item, 'default', 300, true);
        return url;
    }

    initTagsImage() {
        const badges = this.store.menu.badges;
        if (badges) {
            badges.forEach(badge => {
                const tagImg = badge.getImage('default', 400, true);
                if (tagImg) {
                    this.tagsImage[badge.tag] = tagImg;
                }
            });

        }
    }

    getTempUrl(item: Item) {
        let url = this.getItemImage(item);
        let tempUrl = url && `url(${url})`;
        return tempUrl;
    }

}
